.block-features-wrapper {
	> h3 {
		text-align: center;
		padding-bottom: 4rem;
		@include media-breakpoint-up( $bp ) {
			margin: 0;
		}
		@include media-breakpoint-down($bp-m) {
			padding-bottom: 1rem;
		}
	}
	.block-features {
		display: flex;
		position: relative;
		flex-direction: row;
		justify-content: space-between;
		background-color: #000;
		margin: 0 0 18rem;
		@include media-breakpoint-down($bp-m) {
			margin: 0 0 10rem;
			padding: 2rem;
		}

		@include media-breakpoint-down(md) {
			flex-direction: column;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: -2.5rem;
			right: -3rem;
			z-index: -1;
			width: 20rem;
			height: 16.5rem;
			background: url(../images/dots.svg) repeat bottom right;
			opacity: 0.5;

			@include media-breakpoint-up($bp) {
				width: 51.9rem;
				height: 18rem;
			}
		}

		&-item {
			padding: 3rem;
			flex: 1 1;

			@include media-breakpoint-down($bp-m) {
				padding: 1rem;
			}

			@include media-breakpoint-down(md) {
				padding-bottom: 3rem;

				&:last-child {
					padding-bottom: 0.5rem;
				}
			}

			&-image {
				width: 100px;
				height: 100px;
				margin: 0 auto 3rem;
				position: relative;
				@include media-breakpoint-down($bp-m) {
					margin: 0 auto 1rem;
				}

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: contain;
					object-position: center;
				}
			}

			&-title {
				text-align: center;
				color: $pink;
			}

			&-description {
				color: #fff;
			}
		}
	}

	&.chamfr-membership {
		.block-features {
			&-item {
				&-title {
					font-size: 350%;
					font-weight: 700;
				}
			}
		}
	}
}