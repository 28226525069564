// for loading button state.
@keyframes pulsate {
	0% {
		transform: scale( 0.1 );
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale( 1.2 );
		opacity: 0;
	}
}
