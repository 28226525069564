.woocommerce-MyAccount-navigation ul,
.wcv-navigation ul.menu.horizontal {
	list-style-type: none;
	margin: 0 -0.5rem 3rem;
	padding: 0;
	text-align: center;
	@include media-breakpoint-up( $bp ) {
		text-align: left;
	}

	/*@include media-breakpoint-up( $bp ) {
		display: flex;
		justify-content: space-between;
		margin: 0 0 3.5rem;
	}*/

	li {
		display: inline-block;
		vertical-align: top;
		float: none;
		width: 17rem;
		padding: 0 0.5rem 1.5rem;
		/*@include media-breakpoint-up( $bp ) {
			display: block;
			flex: 0 0 auto;
			width: auto;
			margin-right: 2.4rem;
			padding: 0;
		}*/

		a {
			@include toggle-button();
			display: block;
			width: 100%;
			padding: 1.3rem 1rem;
			white-space: nowrap;
			text-align: center;
			/*@include media-breakpoint-up( $bp ) {
				display: inline-block;
				padding: 1.3rem 2rem;
			}*/
			@include media-breakpoint-up( xl ) {
				padding: 1.3rem 3rem;
			}
		}

		&.woocommerce-MyAccount-navigation-link--payment-methods {
			a {
				font-size: 0;
				@include media-breakpoint-up( xl ) {
					font-size: 1.4rem;
				}

				&:before {
					content: 'payment';
					font-size: 1.4rem;

					@include media-breakpoint-up( xl ) {
						display: none;
					}
				}
			}
		}

		&.is-active,
		&.active {
			a {
				opacity: 1;
			}
		}

		&:last-child {
			/*@include media-breakpoint-up( $bp ) {
				flex-grow: 1;
				margin-right: 0;
				text-align: right;

				a {
					width: auto;
				}
			}*/

			a {
				opacity: 1;
				border-color: $pink;
				background-color: $pink;
			}
		}
	}
}
.woocommerce-MyAccount-navigation ul {
	@include media-breakpoint-up( $bp ) {
		display: flex;
		justify-content: space-between;
		margin: 0 0 3.5rem;

		li {
			display: block;
			flex: 0 0 auto;
			width: auto;
			margin-right: 2.4rem;
			padding: 0;
			&:last-child {
				flex-grow: 1;
				margin-right: 0;
				text-align: right;

				a {
					width: auto;
				}
			}

			a {
				display: inline-block;
				padding: 1.3rem 2rem;
			}
		}
	}
	@include media-breakpoint-up( xl ) {
		li a {
			padding: 1.3rem 3.6rem;
		}
	}

}

.wcv-dashboard-navigation {
	ul {
		list-style-type: none;
		margin: 0 -0.5rem 3rem;
		padding: 0;
		text-align: center;

		@include media-breakpoint-up( $bp ) {
			display: flex;
			margin: 0 0 3.5rem;
		}
		li {
			display: inline-block;
			vertical-align: top;
			width: 17rem;
			padding: 0 0.5rem 1.5rem;
			@include media-breakpoint-up( $bp ) {
				display: block;
				flex: 0 0 auto;
				width: auto;
				margin-right: 2.6rem;
				padding: 0;

				&:last-child {
					margin-right: 0;
				}
			}

			a {
				@include toggle-button();
				display: block;
				width: 100%;
				padding: 1.3rem 1rem;
				white-space: nowrap;
				text-align: center;
				@include media-breakpoint-up( $bp ) {
					padding: 1.3rem 2rem;
				}
				@include media-breakpoint-up( xl ) {
					padding: 1.3rem 3.6rem;
				}
			}
		}
	}
}
