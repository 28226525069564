.woocommerce-order{
	&.order-status{
		.order-status-form{
			max-width: 450px;
			margin: 32px auto;

			form{
				label{
					color: $chamfr-black;
					font-size: 14px;
					line-height: 16.45px;
				}
	
				input{
					border: 1px solid $chamfr-gray;
					padding: 15px 16px;
					border-radius: 4px;
					margin-bottom: 20px;
					transition: all 0.3s ease-in;
		
					&:focus{
						border-color: $chamfr-pink;
					}
				}
				
			}
		}

		.order-status-btn{
			width: 100%;
			border-radius: 8px !important;
			box-shadow: none;
			letter-spacing: 0;
			@include button-primary;
			transition: all 0.3s ease-in;
			&:hover{
				background: $chamfr-white;
				color: $chamfr-pink;
				border-color: $chamfr-pink;
			}

			&.back-button{
				display: inline;
			}
		}

		.order-status-details{
			#breadcrumb{
				display: none;
			}
		}
	}
}