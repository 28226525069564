.header-wrapper {
	background-color: $chamfr-deep-gray;

	.header-mid-area{
		padding: 22px 0 20px;
		box-shadow: 0px 4px 24px 0px #00000026;
		position: relative;

		@include media-breakpoint-down(sm){
			padding: 20px 18px;
		}
	}
}

.page-header {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	@include wide-container();

	@include media-breakpoint-down($bp-m) {
		max-width: none;
		padding-top: 0.8rem;
	}

	@include media-breakpoint-down(sm){
		padding: 0;
	}

	.navbar-toggler {
		order: 1;
		width: 3.4rem;
		margin: 0 1.5rem -0.3rem 0;
		padding: 0 1rem 0 0;
		background: transparent;
		cursor: pointer;
		z-index: 3500;

		@include media-breakpoint-up(sm) {
			width: 4.5rem;
			margin: 0 2.5rem 0 0;
			padding: 1rem 1rem 0 0;
		}

		@include media-breakpoint-up($bp-m) {
			display: none;
		}

		@include media-breakpoint-down(xsm){
			width: 18px;
			margin: 0 14px 0 0;
			padding: 0;
		}

		&.mobile-navbar-toggler{
			position: absolute;
			top: 26px;
			left: 18px;
		}

		// styling for 3 bars
		.icon-bar {
			display: block;
			height: 2px;
			width: 100%;
			margin: 0.7rem 0;
			background: $black;
			border-radius: 4px;
			backface-visibility: hidden;
			@include media-breakpoint-up(sm) {
				margin: 1rem 0;
			}

			@include media-breakpoint-down(xsm){
				margin: 3px 0;
			}
		}
	}

	.logo {
		order: 0;
		@include chamfr-logo();
		@include media-breakpoint-down(sm) {
			order: 1;
		}
	}

	.primary-menu {
		order: 5;
		width: 100%;
		flex-grow: 1;

		&__close {
			position: absolute;
			top: 27px;
			right: 26px;
			z-index: 100;
			width: 15px;
			height: 15px;
			background: url(../images/menu-close-gray.svg) no-repeat center/contain;
			transition: opacity 0.3s ease-out;
			opacity: 1;
			@include media-breakpoint-up($bp-m) {
				display: none;
			}
			@include media-breakpoint-up(sm){
				top: 38px;
				width: 22px;
				height: 22px;
			}
			&.close-hide {
				opacity: 0;
			}
		}
	}

	/* stylelint-disable-next-line no-descending-specificity */
	.header-search {
		position: relative;
		flex-grow: 1;
		margin-right: 62px;
		max-width: 608px;
		overflow: hidden;

		@include media-breakpoint-down(lg) {
			padding-top: $spacer;
			order: 10;
			max-width: 100%;
			width: 100%;
			margin: 0;
		}

		@include media-breakpoint-down(sm){
			display: none;
		}

		&__field {
			width: 100%;
			max-width: 100%;
			height: auto;
			padding: 0.9rem 4rem 0.8rem 1rem;
			background-color: #fafafa;
			border-radius: 0.2rem;
			border: none;
			appearance: none;
			@include tiny-shadow();
			/* stylelint-disable-next-line function-url-quotes */
			font-size: 1.4rem;
			line-height: 1.36;
			@include media-breakpoint-up($bp-m) {
				width: 300px;
			}
		}

		&__submit {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 3.5rem;
			background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
			background-position: 1.2rem 1.3rem;
			background-size: 12px;
			background-repeat: no-repeat;
			background-color: #e11d73;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-top-right-radius: 0.2rem;
			border-bottom-right-radius: 0.2rem;
		}
	}

	&.menu-on{
		.navbar-toggler{
			z-index: 350;
		}
	}
}

// Dark style.
.page-header .navbar-toggler .icon-bar {
	background: $white;
}

.checkout-header {
	padding: $spacer 15px;

	.continue-shopping {
		a {
			color: $white;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

// Iframe hide header footer
.iframe>.header-wrapper, .iframe>.page-footer, .iframe .wcv-header-container, .iframe .wcv-store-grid {
	display: none !important;
}
