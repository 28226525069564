.page-header {
	.secondary {
		order: 4;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		position: relative;
		margin-left: auto;
		z-index: 260;

		ul {
			list-style: none;
			display: flex;
			align-items: center;
			margin: 0 0 -0.3rem;
			padding: 0;
			font-size: 1.6rem;
			@include media-breakpoint-up(sm) {
				margin: 0;
			}
			@include media-breakpoint-up($bp-m) {
				font-size: 1.4rem;
			}

			& > li{
				&:not(:last-child){
					padding-right: 29px;
					border-right: 2px solid $chamfr-dark-gray;

					@include media-breakpoint-down(sm){
						padding-right: 16px;
					}
				}
			}

			li {
				margin-right: 69px;
				display: flex;
				align-items: center;
				
				&:last-child {
					margin-right: 0;
				}
				&.menu-item-has-children a {
					color: $white;
					@include media-breakpoint-up($bp-m) {
						color: $text-black;
					}
				}

				@include media-breakpoint-down(xsm){
					margin-right: 45px;
				}
			}
			a {
				color: $text-black;
				position: relative;
			}
			.minicart {
				display: inline-block;
				width: 36px;
				height: 33px;
				// background-image: svg-load( 'minicart.svg' );
				/* stylelint-disable-next-line function-url-quotes */
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.326' height='16.252' viewBox='0 0 17.326 16.252'%3E%3Cg id='Trolley' transform='translate(0 -3)'%3E%3Cpath id='Path_90' data-name='Path 90' d='M17.259,6.391c-.144-.191.968-.076-14.056-.849L2.889,3.311A.361.361,0,0,0,2.528,3H.361a.361.361,0,1,0,0,.722H2.214c.433,3.019.137.964,1.351,9.455A1.445,1.445,0,0,0,3.973,16H14.446a.361.361,0,1,0,0-.722H3.973a.722.722,0,0,1,0-1.445H15.53a.361.361,0,0,0,.361-.285C17.454,6.084,17.4,6.579,17.259,6.391Zm-2.022,6.721H4.287l-.979-6.84,13.225.679Z' transform='translate(0 0)'/%3E%3Cpath id='Path_91' data-name='Path 91' d='M13.445,40a1.445,1.445,0,1,0,1.445,1.445A1.445,1.445,0,0,0,13.445,40Zm0,2.167a.722.722,0,1,1,.722-.722A.722.722,0,0,1,13.445,42.167Z' transform='translate(-7.666 -23.637)'/%3E%3Cpath id='Path_92' data-name='Path 92' d='M31.445,40a1.445,1.445,0,1,0,1.445,1.445A1.445,1.445,0,0,0,31.445,40Zm0,2.167a.722.722,0,1,1,.722-.722A.722.722,0,0,1,31.445,42.167Z' transform='translate(-19.165 -23.637)'/%3E%3C/g%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				background-size: contain;
				top: -6px;
				padding-right: 4px;
				position: absolute;
				left: -41px;

				@include media-breakpoint-down(xsm){
					width: 24px;
					height: 22px;
					top: -1px;
					left: -30px;
				}
			}
			
			.mobile-search{
				display: inline-block;
				width: 36px;
				height: 33px;
				background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
				background-repeat: no-repeat;
				background-size: contain;

				@include media-breakpoint-up(md){
					display: none;
				}

				@include media-breakpoint-down(xsm){
					width: 24px;
					height: 22px;
				}

				&.is-active{
					background-image: url(../images/menu-close-gray.svg);
				}
			}
		}

		.secondary-toggler {
			display: none;
			position: relative;
			z-index: 2000;
			width: 38px;
			height: 37.4px;
			background: url(../images/user-menu-black.svg) no-repeat center/contain;

			@include media-breakpoint-down(xsm){
				width: 24px;
				height: 22px;
			}

			&.logged-in{
				display: block;
				@include media-breakpoint-down(sm){
					display: none;
				}
			}
		}

		.menu {
			display: block;
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1000;
			padding-top: 7.5rem;
			background: $black;
			transition: all 0.6s ease-out;
			transform: translateX(100%);

			@include media-breakpoint-up($bp-m) {
				display: flex;
				position: relative;
				z-index: 2500;
				padding-top: 0;
				background: transparent;
				transform: translateX(0);
			}

			> .menu-item {
				width: 100%;
				padding: 1.8rem 3.5rem 0;
				margin: 0;
				border-top: 1px solid #464646;

				&.top-login-btn{
					a{
						@include button-secondary;
						
						&:hover{
							text-decoration: none;
							background-color: $chamfr-pink;
							color: $chamfr-white;
							border-color: $chamfr-pink;
						}
					}
				}

				&.top-register-btn{
					a{
						@include button-primary;

						&:hover{
							text-decoration: none;
							background: transparent;
							color: $chamfr-pink;
							border-color: $chamfr-pink;
						}
					}
				}

				&.top-login-btn, &.top-register-btn{
					a{
						min-width: 125px;
						width: 100%;
						height: 48px;
						border-radius: 8px;
						transition: all 0.3s ease-in;
					}
				}

				@include media-breakpoint-up($bp-m) {
					width: auto;
					margin-left: 7px;
					padding: 0;
					border: none;

					a{
						span{
							display: block;
							font-weight: 800;
						}
					}
				}
				&-has-children {
					transition: all 0.3s ease-out;
					> a {
						padding-right: 20px;
						@include media-breakpoint-down(sm){
							display: none;
						}
						&.menu-open{
							&::after{
								transform: scaleY(-1);
							}
						}
						&:after {
							@include submenu-bracket();
							transition: all 0.3s ease-in;
						}
					}
				}
				&:first-child > a:after {
					display: none;
					@include media-breakpoint-up($bp-m) {
						display: inline;
					}
				}
			}

			.sub-menu {
				display: block;
				width: calc(100% + 7rem);
				margin: 1.8rem -3.5rem 0;
				padding: 0;
				
				@include media-breakpoint-up($bp-m) {
					position: absolute;
					z-index: 10;
					top: 4.5rem;
					left: -5rem;
					overflow: hidden;
					min-width: 300px;
					width: auto;
					max-height: 0;
					margin: 0;
					padding: 0 20px;
					background-color: $white;
					@include medium-shadow();
					border-radius: 8px;
					transition: max-height 0.3s ease-out;
				}

				@include media-breakpoint-down(md){
					min-width: 280px;
				}

				> .menu-item {
					padding: 1.8rem 3.5rem;
					margin: 0;
					border-top: 1px solid #464646;
					> a {
						color: $white;
					}
					&:last-child > a {
						color: $pink;
					}

					@include media-breakpoint-up($bp-m) {
						white-space: nowrap;
						margin-bottom: 20px;
						padding: 0;
						padding-top: 20px;
						border: none;
						border-top: 1px solid $light-gray;

						> a {
							color: $black;
						}

						&:first-child {
							padding-top: 29px;
							border: none;
						}

						&:last-child {
							margin-bottom: 0;
							padding-top: 47px;
							padding-bottom: 32px;
							border-top: 1px solid $light-gray;
						}
					}
				}
			}
		}

		&.menu-open {
			.menu {
				transform: translateX(0);
				.sub-menu {
					@include media-breakpoint-up($bp-m) {
						max-height: 50rem;
					}
				}
			}
		}
	}
}

// Dark style.
.page-header {
	.secondary {
		.secondary-toggler {
			background-image: url(../images/user-white.svg);
		}
		ul {
			/* stylelint-disable-next-line no-descending-specificity */
			a {
				color: $white;
				font-size: 16px;
				line-height: 18.8px;
			}
			li.menu-item-has-children a {
				@include media-breakpoint-up($bp-m) {
					color: $white;
				}
			}
			.minicart {
				/* stylelint-disable-next-line function-url-quotes */
				background-image: url(../images/minicart-white.svg);
			}
		}
		.menu {
			/* stylelint-disable-next-line no-descending-specificity */
			> .menu-item {
				&-has-children {
					/* stylelint-disable-next-line no-descending-specificity */
					> a {
						&:after {
							border-color: $white transparent transparent;
						}
					}
				}
			}
		}
	}
}
