html {
	font-size: 10px;
}

/* Prevent horizontal scrolling */
html,
body {
	overflow-x: hidden;
}

html.no-scroll {
	overflow: hidden;

	@media (min-device-width: 1025px) {
		padding-right: 15px;
	}
}

/* Input elements */

button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
	outline: 0 !important;
	border: none;
	cursor: pointer;
}
* {
	box-sizing: border-box;
	&:hover,
	&:focus,
	&:active {
		outline: none;
	}
}

/* Typography */

body {
	width: 100%;
	margin: 0;
	color: $black;
	background-color: #ddd;
	background-image: url(../images/bg-light.svg);
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	@include p;

	&.body-style {
		&--light {
			color: $black;
			background-color: #ddd;
			background-image: url(../images/bg-light.svg);
		}
		&--dark {
			color: $white;
			background-color: #0f0f0f;
			background-image: url(../images/bg-dark.svg);
		}
	}
}

b,
strong {
	font-weight: 700;
}

h1 {
	@include h1;
}

h2 {
	@include h2;
}

h3 {
	@include h3;
}

h4 {
	@include h4;
}

h5 {
	@include h5;
}

h6 {
	@include h6;
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0 0 2rem;

	@include media-breakpoint-down(sm) {
		margin-bottom: 1rem;
	}
}

p {
	margin: 0 0 2rem;

	&:last-child {
		margin-bottom: 0;
	}
}

a {
	transition: all 0.2s;
	text-decoration: none;
	color: $pink;

	&:hover,
	&:focus,
	&:active {
		color: $pink;
	}
}

.lead {
	font-size: 1.6rem;

	@include media-breakpoint-down(sm) {
		font-size: 1.2rem;
	}
}

/* Typography Colors */

$typography-colors: (
	"white": $white,
	"gray": $dark-gray,
);

@each $classname, $color in $typography-colors {
	.t-color-#{$classname} {
		color: $color;
	}
}

/* Other */

img {
	max-width: 100%;
	height: auto;
}
