.woocommerce {
	#respond input#submit,
	a.button,
	button.button,
	input.button {
		@extend .btn;
	}

	#respond input#submit.alt,
	a.button.alt,
	button.button.alt,
	input.button.alt {
		@extend .btn-secondary;
	}

	table {
		&.shop_attributes {
			border-top: 1px solid $border-color;

			tr {
				border-bottom: 1px solid $border-color;
			}

			th {
				display: block;
				width: auto;
				padding: 12px 0 3px;
				border: none;
				opacity: 0.5;
				font-weight: 400;
				@include media-breakpoint-up( sm ) {
					display: table-cell;
					padding: 12px 12px 12px 0;
				}
			}

			td {
				display: block;
				padding: 3px 0 12px;
				border: none;
				font-style: normal;
				@include media-breakpoint-up( sm ) {
					display: table-cell;
					padding: 12px 0;
				}

				p {
					padding: 0;
				}
			}

			tr:nth-child( even ) td,
			tr:nth-child( even ) th {
				background-color: transparent;
			}
		}
	}
}

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button,
#add_payment_method .wc-proceed-to-checkout a.checkout-button {
	padding: 1.558rem 3rem;
	font-size: 1.6rem;
}
.woocommerce #respond input#submit.disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce #respond input#submit:disabled[disabled],
.woocommerce a.button.disabled,
.woocommerce a.button:disabled,
.woocommerce a.button:disabled[disabled],
.woocommerce button.button.disabled,
.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce input.button.disabled,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled] {
	padding: 1.558rem 3rem;
}

.checkout .form-row,
#order_review, .form-row {
	display: block;
}
.woocommerce form .form-row {
	padding: 0;
	margin: 0 0 2rem;

	.woocommerce-input-wrapper {
		.field-hint {
			display: inline;
			background: none;
			color: #000;
			border-radius: 0;
			padding: 1em;
			margin: .5em 0 0;
			font-size: 1.2rem;
		}
	}
}
.woocommerce form .form-row label {
	line-height: 1.2;
}
.woocommerce-form__label-for-checkbox span {
	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: $form_field_height-radio;
		height: $form_field_height-radio;
		margin-right: 1rem;
		background-color: $form_field_bg;
		border-radius: $form_field_border_radius;
		border: $form_field_border-radio;
		transition: background-color 0.2s ease-out 0.05s,
			border-color 0.2s ease-out 0.05s, background-image 0.1s 0.3s;
		cursor: pointer;
	}
}

.woocommerce-form__label-for-checkbox input:checked + span {
	&:before {
		background: $form_checkbox-checked_bg;
		border: $form_field_border-radio_checked;
	}
}
.woocommerce form .form-row-first,
.woocommerce form .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last {
	float: none;
	width: 100%;
	@include media-breakpoint-up( md ) {
		width: 50%;
	}
}
.woocommerce form .form-row-first,
.woocommerce-page form .form-row-first {
	@include media-breakpoint-up( md ) {
		float: left;
		padding-right: calc($form_spacer/2);
	}
}
.woocommerce form .form-row-last,
.woocommerce-page form .form-row-last {
	@include media-breakpoint-up( md ) {
		float: right;
		padding-left: calc($form_spacer/2);
	}
}
.form-row-last[style='display: none'] + .form-row-first {
	@include media-breakpoint-up( md ) {
		float: right;
		padding-left: calc($form_spacer/2);
		padding-right: 0;
	}
}
#ship-to-different-address {
	margin: 0 0 2rem;
}
#customer_details {
	margin-bottom: 5rem;
}
.woocommerce-shipping-fields {
	margin: 5rem 0 0;
	@include media-breakpoint-up( lg ) {
		margin: 0;
	}
}
.woocommerce ul.order_details {
	margin: 0 0 5rem;
	padding: 0;

	li {
		padding-top: 2em;
		&:last-child {
			margin-right: 0;
			padding-right: 0;
		}
		@include media-breakpoint-down( $bp-down ) {
			border-right: none;
		}
	}
}
.woocommerce-thankyou-order-received,
.woocommerce-thankyou-order-failed {
	margin: 0 0 5rem;
}
.woocommerce-pagination {
	margin: 5rem 0 0;
}

.woocommerce .quantity .qty {
	width: 8rem;
}
.product-price ins {
	text-decoration: none;
}
.woocommerce table.shop_table td del {
	margin-right: 0.8rem;
}

.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select {
	border-color: #ddd;
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
	list-style: none outside;
	position: relative;
	width: auto;
	margin: 0 0 2em !important;
	padding: 1em 2em 1em 3.5em;
	background-color: $lightest-gray;
	border-top: 3px solid $pink;
	color: $dark-gray;
	word-wrap: break-word;
}
.woocommerce-error:before,
.woocommerce-info:before,
.woocommerce-message:before {
	content: '\e028';
	display: inline-block;
	position: absolute;
	top: 1em;
	left: 1.5em;
	font-family: WooCommerce; /* stylelint-disable-line */
	color: $pink;
}
.woocommerce-info {
	border-top-color: $dark-gray;
	&:before {
		color: $dark-gray;
	}
}
.woocommerce-message {
	//border-top-color: #8fae1b;
	border-top-color: $dark-gray;
	&:before {
		content: '\e015';
		//color: #8fae1b;
		color: $dark-gray;
	}
}

.woocommerce .woocommerce-form-login .woocommerce-form-login__rememberme {
	display: block;
	width: 100%;
	margin: 2rem 0 3rem;
}
.woocommerce-ResetPassword.lost_reset_password {
	.password-input,
	label {
		width: 100%;
	}
}
.woocommerce-billing-fields {
	margin-bottom: 5rem;
}
.wc-stripe-elements-field,
.wc-stripe-iban-element-field {
	padding: 15px;
}
.woocommerce #payment #place_order,
.woocommerce-page #payment #place_order,
.woocommerce-page #payment .btn-secondary {
	float: none;
	padding: 1.558rem 7rem;
}
.woocommerce-privacy-policy-text {
	margin: 3rem 0 0;
	font-size: 1.4rem;
}
