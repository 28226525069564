.page.page-contact {
	.site {
		padding: 6rem 0 0;

		@include media-breakpoint-up( $bp ) {
			padding: 10rem 0 0;
		}
	}
}
body.page-contact .gform_wrapper .gform_body .top_label {
	.gfield_label_before_complex {
		margin: 0 0 0.8rem;
		font-size: 1.4rem;
	}
}
