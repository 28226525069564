@mixin chamfr-logo() {
	width: 145px;
	height: 32px;
	background-image: url( ../images/logo.svg );
	background-repeat: no-repeat;
	background-size: contain;
	font-size: 0;
	@include media-breakpoint-up( sm ) {
		width: 209px;
		height: 48px;
		margin-right: 4rem;
	}
	@include media-breakpoint-up( hd ) {
		margin-right: 57px;
	}
}
