/* stylelint-disable no-descending-specificity */
.woocommerce-hi {
	margin: 0 0 5rem;
	font-size: 1.8rem;

	@include media-breakpoint-down( xs ) {
		display: none;
	}

	&__name {
		@include h3;
		font-weight: 600;
		display: inline-block;
		margin: 0 2rem 0 0;
	}
}
.woocommerce-account .woocommerce-MyAccount-navigation {
	float: none;
	width: auto;
}
.woocommerce-account .woocommerce-MyAccount-content,
.chamfr-vendor-dashboard-content {
	float: none;
	width: auto;
	margin: 0 $grid-gutter-margin;
	padding: 4rem 2rem;
	@include gradient-light();
	@include medium-shadow();
	color: $black;
	@include media-breakpoint-up( md ) {
		margin: 0;
		padding: 5rem 6rem;
		border-radius: 1rem;
	}
	@include media-breakpoint-up( lg ) {
		padding: 8rem 14rem 9rem;
	}

	h1 {
		@include h3;
		margin: 0 0 4rem;
		text-align: center;
		@include media-breakpoint-up( $bp ) {
			text-align: left;
		}
	}

	.gform_wrapper .gsection {
		padding: 4rem 0 1.5rem !important;
		border: none;
		@include media-breakpoint-up( md ) {
			padding: 8rem 0 1.5rem !important;
		}

		h2 {
			@include content-font();
			font-size: 1.6rem;
			font-weight: 600;
			@include media-breakpoint-up( sm ) {
				font-size: 1.8rem;
			}
		}
	}
	.gform_wrapper .gfield_radio li {
		@include media-breakpoint-up( sm ) {
			display: inline-block !important;
			width: 8rem !important;
			margin: 0 6rem 0 0 !important;
			&:last-of-type {
				margin: 0 !important;
			}
		}
	}
	.gform_wrapper .gform_body .gform_fields li:first-of-type {
		padding-top: 0 !important;
	}
	.gfield_label,
	.ginput_complex.ginput_container label {
		font-size: 1.6rem !important;
	}
	.gfield_label.gfield_label-big {
		margin: 2rem 0 1.7rem !important;
		font-size: 1.6rem !important;
	}
	.gform_wrapper li.hidden_label input {
		margin: 0;
	}
	.gform_wrapper .gsection_description {
		font-size: 1.4rem;
		margin: 1rem 0 !important;
		padding: 0 !important;
	}
	.gform_wrapper .gfield_password_strength {
		background: #cdcdcd;
	}
	.gform_footer {
		margin-top: 4rem !important;
	}
}
.chamfr-vendor-dashboard-content {
	@include media-breakpoint-up( md ) {
		padding: 4rem 3rem;
	}
	@include media-breakpoint-up( lg ) {
		padding: 6rem 5rem 7rem;
	}
	@include media-breakpoint-up( xl ) {
		padding: 8rem 7rem;
	}
	@include media-breakpoint-up( hd ) {
		padding: 8rem 14rem 9rem;
	}
}

/* Account - Address */
.woocommerce {
	.woocommerce-customer-details address {
		width: auto;
		margin-bottom: 5rem;
		padding: 0;
		border: none;
		border-radius: 0;
	}
	.woocommerce-customer-details .woocommerce-customer-details--phone:before,
	.woocommerce-customer-details .woocommerce-customer-details--email:before {
		line-height: 2.2;
	}
}

.woocommerce-addresses {
	margin: 5rem 0 0;
	@include media-breakpoint-up( sm ) {
		display: flex;
		flex-wrap: wrap;
		margin: 5rem $grid-gutter-margin 0;
	}

	&__info {
		text-align: center;
		@include media-breakpoint-up( $bp ) {
			text-align: left;
		}
	}

	.woocommerce-address {
		padding: 0 0 4rem;
		@include media-breakpoint-up( sm ) {
			flex: 0 0 50%;
			width: 50%;
			padding: 0 $grid-gutter-padding 4rem;
		}
		@include media-breakpoint-up( hd ) {
			flex: 0 0 25%;
			width: 25%;
		}

		&__title {
			font-size: 1.8rem;
			font-weight: 600;
			margin: 0 0 2.5rem;
		}

		address {
			margin: 0 0 2.5rem;
			line-height: 2;
			@include media-breakpoint-up( $bp ) {
				margin: 0 0 3rem !important;
			}
		}
		.btn-edit {
			&:before {
				content: '\e603';
				display: inline-block;
				vertical-align: middle;
				width: 2.8rem;
				height: 2.8rem;
				margin-left: 1rem;
				//background: url( ../images/pencil.svg ) no-repeat center;
				//background-size: 1.5rem;
				border: 0.2rem solid $dark-pink;
				border-radius: 50%;
				font-family: WooCommerce; /* stylelint-disable-line */
				font-size: 1.4rem;
				line-height: 2.4rem;
				text-align: center;
				color: $dark-pink;
			}
		}
	}
}

/* Account - Edit Address */

.woocommerce-address-form {
	.woocommerce-address__title {
		font-size: 1.6rem;
		font-weight: 600;
		margin: 0 0 2.5rem;
		@include media-breakpoint-up( sm ) {
			font-size: 1.8rem;
		}
	}
}
.woocommerce-address-fields {
	&:after {
		content: '';
		display: block;
		height: 1px;
		clear: both;
	}

	.form-row {
		display: block;
		margin: 0 0 3rem;
		@include media-breakpoint-up( $bp ) {
			width: 50%;
			margin: 0 0 4rem;
		}

		&-first {
			@include media-breakpoint-up( $bp ) {
				float: left;
				padding-right: 0.8rem;
			}
		}
		&-last {
			@include media-breakpoint-up( $bp ) {
				float: right;
				padding-left: 0.8rem;
			}
		}
		&-wide {
			float: none;
			clear: both;
			@include media-breakpoint-up( $bp ) {
				padding-right: 0.8rem;
			}
		}
	}
	label {
		display: block;
		margin: 0 0 0.8rem;
		font-size: 1.6rem;
		font-weight: 400;

		abbr {
			text-decoration: none;
			cursor: default;
		}
	}
	.woocommerce-input-wrapper {
		display: block;
	}

	&__submit {
		margin-top: 2rem;
		@include media-breakpoint-up( sm ) {
			margin-top: 3rem;
		}

		.btn {
			margin-right: 1.6rem;

			&-secondary {
				margin-right: 0;
			}
		}
	}
}

/* Account - Orders */

.woocommerce-orders {
	&-table {
		@include media-breakpoint-only( lg ) {
			width: calc( 100% + 16rem );
			margin: 0 -8rem;
		}
		tbody {
			tr:first-of-type {
				td {
					padding-top: 0;
					@include media-breakpoint-up( $bp ) {
						padding-top: 2.5rem;
					}
				}
			}
		}
		tr {
			td {
				b {
					font-weight: 600;
					@include media-breakpoint-up( $bp ) {
						display: none;
					}
				}
			}
		}
		&__cell-order-number {
			font-size: 1.8rem;
			font-weight: 600;
			@include media-breakpoint-up( $bp ) {
				font-size: 1.6rem;
				font-weight: 400;
			}
		}
		&__cell-order-actions {
			> div {
				margin-bottom: 1rem;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.ced_my_account_reorder {
			color: $pink;

			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				width: 23px;
				height: 22px;
				margin-right: 0.6rem;
				background: url( ../images/cart.svg ) no-repeat center;
			}
		}

		.chamfr_invoice {
			margin-top: 2rem;
			color: $pink;

			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				width: 23px;
				height: 22px;
				margin-right: 0.6rem;
				background: url( ../images/invoice.svg ) no-repeat center;
			}
		}
	}
	.woocommerce-orders-message__btn {
		margin: 3rem 0 0;
	}
}

/* Account - Payment */
#add_payment_method #payment {
	background: transparent;
}
#add_payment_method #payment ul.payment_methods {
	padding: 0;
	border: none;
	margin: 0;
	list-style: none;
}
#add_payment_method #payment div.payment_box {
	margin: 0;
	padding: 0;
	background: transparent;
	font-size: 1.4rem;
	color: $text-black;

	&:before {
		display: none;
	}
}
.woocommerce-payment-methods {
	&-info {
		max-width: 64.5rem;
		margin: -2.5rem 0 5rem;
		text-align: center;
		@include media-breakpoint-up( $bp ) {
			text-align: left;
		}
	}
	&-table {
		tr {
			td {
				b {
					font-weight: 600;
					@include media-breakpoint-up( $bp ) {
						display: none;
					}
				}
			}
		}
		&__cell-order-number {
			font-size: 1.8rem;
			font-weight: 600;
			@include media-breakpoint-up( $bp ) {
				font-size: 1.6rem;
				font-weight: 400;
			}
		}
		.delete {
			color: $dark-pink;

			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				width: 23px;
				height: 23px;
				margin-right: 0.6rem;
				background: url( ../images/del.svg ) no-repeat center;
			}
		}
	}

	&-btn {
		margin: 7rem 0 0;
	}

	ul.payment_methods {
		list-style: none;
		margin: 0;
		padding: 0;

		label {
			display: block;
		}
	}
	.wc-stripe-elements-field,
	.wc-stripe-iban-element-field {
		margin: 5px 0;
		padding: 15px;
		background-color: #fff;
		border: 1px solid #ddd;
		border-radius: 0.5rem;
		outline: 0;
	}
	#wc-stripe-cc-form {
		label {
			font-size: 1.6rem;
		}
	}
	&-submit {
		display: flex;
		justify-content: space-between;
		margin: 4rem 0 0;
		@include media-breakpoint-up( $bp ) {
			display: block;
			margin: 6rem 0 0;
		}

		.btn {
			padding: 0 1rem;
			margin-top: 1rem;
			margin-right: 0.8rem;
			font-size: 1.4rem;
			@media ( min-width: 370px ) {
				padding: 0 2rem;
			}
			@include media-breakpoint-up( sm ) {
				padding: 0 3rem;
				font-size: 1.6rem;
			}

			&-secondary {
				margin-right: 0;
			}
		}
	}
}
#add_payment_method #payment ul.payment_methods li img.stripe-icon,
.woocommerce-checkout #payment ul.payment_methods li img.stripe-icon {
	max-width: 40px;
	margin: 0;
	padding: 3px 3px 0 0 !important;
	@include media-breakpoint-up( md ) {
		padding: 0 3px 0 0 !important;
	}
}
.stripe-source-errors {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
}
