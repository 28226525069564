.chamfr-pattern-card {
	text-align: center;
	padding: 2rem;
	border-radius: 1rem;
	height: 100%;
	@include medium-shadow();

	.wp-block-image {
		margin: 0 0 2rem 0;
		img {
			width: 12rem;
		}
	}

	h4 {
		color: $pink;
		text-transform: uppercase;
	}

	&-light {
		background-color: $lightest-gray;
		color: $text-black;
	}

	&-dark {
		background-color: #424142;
		color: $white;
	}
}
