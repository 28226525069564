.gateway-balance {
	&-qualification {
		&-data {
			span {
				display: inline-block;
				width: 240px;
			}
		}

		&-fields {
			.form-row {
				label {
					flex: 0 0 240px;
					margin-bottom: 0;
				}
			}

			input:not([type="submit"]),
			select,
			textarea {
				padding: 0.5rem;
				height: 3rem;
			}
		}
	}
}
