/* stylelint-disable no-descending-specificity */
.blog,
.archive {
	.site {
		padding: 3rem 0 0;

		@include media-breakpoint-up( $bp ) {
			padding: 4rem 0;
		}

		@include media-breakpoint-down( xs ) {
			padding: 0;
		}
	}

	.post {
		margin: 4rem 0 0;
		padding: 0 0 4rem;
		border-bottom: 1px solid $darker-gray;

		@include media-breakpoint-up( $bp ) {
			display: flex;
			align-items: stretch;
		}

		&__img {
			margin: 0 0 2.36rem;

			@include media-breakpoint-up( $bp ) {
				width: 46%;
				flex: 0 0 46%;
				margin: 0;
			}
			&__wrapper {
				overflow: hidden;
				//height: 100%;
				border-radius: 0.5rem;
			}

			img {
				display: block;
				width: 100%;
				@include media-breakpoint-up( $bp ) {
					height: 100%;
					object-fit: cover;
				}
			}
		}

		&__body {
			@include media-breakpoint-up( $bp ) {
				padding-left: 4rem;
			}
		}

		&__meta {
			margin: 0 0 0.5rem;
			font-size: 1.2rem;
			font-weight: 600;
			color: $darker-gray;

			@include media-breakpoint-up( sm ) {
				font-size: 1.4rem;
				margin: 0 0 0.75rem;
			}
		}
		&__categories,
		&__date {
			display: inline-block;
		}
		&__categories {
			text-transform: uppercase;

			a {
				color: $darker-gray;

				&:hover,
				&:focus,
				&:active {
					color: $darker-gray;
				}
			}
		}

		&__title {
			h2 {
				font-family: $content-font;
				font-size: 1.8rem;
				font-weight: 400;
				margin: 0 0 1rem;

				@include media-breakpoint-up( sm ) {
					font-size: 2.2rem;
					font-weight: 500;
				}

				a {
					color: inherit;
				}
			}
		}

		&__excerpt {
			margin: 0 0 3.5rem;
			font-size: 1.4;

			@include media-breakpoint-up( sm ) {
				font-size: 1.6rem;
			}
		}

		&__link {
			&__a {
				display: inline-block;
				padding-bottom: 0.3rem;
				border-bottom: 1px solid $pink;
				text-decoration: none;

				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
				}
			}
		}
	}

	.subscribe {
		margin: 4rem -15px 0;
		padding: 5.5rem 4rem;
		background: $black;
		border-radius: 0;

		@include media-breakpoint-up( sm ) {
			margin: 4rem 0 0;
			border-radius: 0.5rem;
		}

		&:before,
		&:after {
			display: none;
		}

		+ .post {
			padding-top: 4rem;
			border-top: 1px solid $darker-gray;
		}
	}

	.sticky {
		margin-bottom: 4rem;

		.post {
			border: none;

			&__img {
				position: relative;
				margin: 0 0 3rem 2rem;

				@include media-breakpoint-up( sm ) {
					margin-left: 0;
				}

				@include media-breakpoint-up( $bp ) {
					width: calc( 100% - 415.8px );
					flex: 0 0 calc( 100% - 415.8px );
					margin: 0;
				}

				@include media-breakpoint-up( xl ) {
					width: calc( 100% - 496.8px );
					flex: 0 0 calc( 100% - 496.8px );
				}
				@include media-breakpoint-up( hd ) {
					width: calc( 100% - 595.8px );
					flex: 0 0 calc( 100% - 595.8px );
				}

				&:before {
					content: '';
					display: block;
					position: absolute;
					top: -1.8rem;
					left: -2rem;
					z-index: -1;
					width: 20rem;
					height: 16.5rem;
					background: url( ../images/dots.svg ) repeat top left;
					opacity: 0.7;

					@include media-breakpoint-up( $bp ) {
						width: 58.7rem;
						height: 30.8rem;
					}
				}
			}
			&__body {
				@include media-breakpoint-up( $bp ) {
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 415.8px;
					flex: 0 0 415.8px;
				}
				@include media-breakpoint-up( xl ) {
					width: 496.8px;
					flex: 0 0 496.8px;
				}
				@include media-breakpoint-up( hd ) {
					width: 595.8px;
					flex: 0 0 595.8px;
				}
			}
			&__title {
				h2 {
					font-family: $headings-font;
					font-size: 2.4rem;
					font-weight: 600;
					margin: 0 0 1rem;
					@include media-breakpoint-up( sm ) {
						font-size: 3.6rem;
						font-weight: 500;
					}
				}
			}
			&__excerpt {
				font-size: 1.6rem;

				@include media-breakpoint-up( sm ) {
					font-size: 1.8rem;
				}
			}
			&__link {
				a {
					@extend .btn;

					&:hover,
					&:focus,
					&:active {
						text-decoration: none;
					}
				}
			}
		}
	}

	.categogy-list {
		list-style: none;
		margin: 1rem 0 0;
		padding: 0;
		text-align: center;

		@include media-breakpoint-up( $bp ) {
			margin: 4rem 0 0;
			text-align: left;
		}

		li {
			display: inline-block;
			margin: 1em;
			padding: 0;

			@include media-breakpoint-up( $bp ) {
				display: block;
				margin: 0 0 2.5rem;
			}

			a {
				font-size: 1.8rem;
				text-decoration: none;
				color: $darker-gray;
				transition: all 0.3s ease-out;

				&:hover,
				&:focus,
				&:active {
					color: $white;
				}
			}

			&.current-cat a {
				color: $white;
				//font-weight: 600;
				//text-decoration: underline;
			}
		}
	}

	.more-posts {
		margin: 4rem 0 0;
		text-align: center;
	}
}
.blog {
	.categogy-list {
		li.cat-item-all a {
			color: $white;
			//font-weight: 600;
			//text-decoration: underline;
		}
	}
}
