.page-customer-registration {
	.container {
		// padding-top: 5rem;
		padding-bottom: 5rem;

		.gform_wrapper form {
			margin-top: 5rem;
		}
	}

	.gform_wrapper {
		@include media-breakpoint-up(md) {
			margin-left: auto;
			margin-right: auto;
			max-width: 720px;
		}
	}
}
