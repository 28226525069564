.subscribe {
	position: relative;
	color: $white;

	@include media-breakpoint-down(xsm){
		padding: 76px 0 47px;
	}

	h3{
		font-size: 38px;
		font-weight: 800;
		line-height: 48px;
		margin-bottom: 16px;

		@include media-breakpoint-down(xsm){
			font-size: 29px;
		}

		+p{
			font-size: 16px;
			font-weight: 800;
			line-height: 18.8px;
			margin-bottom: 32px;

			@include media-breakpoint-down(xsm){
				font-size: 14px;
				line-height: 16.45px;
			}
		}
	}

	

	#mc_embed_shell{
		height: 100%;
		display: flex;
		align-items: center;
		margin: 2.5rem 0 0;
	}
	#mc_embed_signup{
		width: 100%;

		form{
			margin: 0;
		}

		.mc-field-group{
			padding: 0;
			width: 100%;

			@include media-breakpoint-up(sm){
				margin-right: 1rem;
			}

			input{
				background: $chamfr-black;
				width: 100%;
				height: 52px;
				padding: 16px 24px;
				border-radius: 8px;
				border: 1px solid $chamfr-black;
				text-align: left;
				color: $chamfr-white;
				text-indent: inherit;
				transition: all 0.2s ease-in;

				&::placeholder{
					color: $chamfr-gray;
					opacity: 1;
				}

				&:focus{
					border-color: $chamfr-pink;
				}
			}
		}

		.button{
			@include button-primary;
			box-shadow: none;
			border-radius: 8px;
			min-width: 160px;
			width: 100%;
			height: 51px;

			&:hover{
				background: $chamfr-raspberry;
			}
		}

		div.mce_inline_error{
			font-size: 1.4rem;
			font-weight: 500;
			color: $chamfr-pink;
			height: 2rem;
			margin: .5rem 0 0;
			padding: 0;
			background: transparent;
		}

		#mce-success-response, #mce-error-response{
			color: $chamfr-pink;
			font: 400 1.6rem / 1.5 "Open Sans", sans-serif;
		}
	}
	.mailchimp-form-outer{
		text-align: left;
		max-width: 100%;
		margin: 0 auto;
	}
	@media (min-width: 768px) {
		.mailchimp-form-outer{
			display: flex;
		}
	}
	@media (min-width: 1200px) {
		#mc_embed_shell{
			margin: 0;
		}
	}
	@media (max-width: 767px) {
		#mc_embed_signup .button{
			margin-top: 2rem;
		}
	}
}
