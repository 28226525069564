/* stylelint-disable no-descending-specificity */
.woocommerce-table {
	width: 100%;

	thead,
	tbody {
		tr {
			border-bottom: 1px solid #b7b7b7;

			th {
				padding: 0 1rem 1rem;
				font-size: 1.8rem;
				font-weight: 600;

				&:first-of-type {
					padding-left: 0;
				}
				&:last-of-type {
					padding-right: 0;
				}
			}
			td {
				padding: 2.5rem 1rem;

				&:first-of-type {
					padding-left: 0;
				}
				&:last-of-type {
					padding-right: 0;
				}
			}
		}
	}
	tfoot {
		tr {
			border: none;
			th,
			td {
				padding: 0.5rem 1rem;

				&:first-of-type {
					padding-left: 0;
				}
				&:last-of-type {
					padding-right: 0;
				}
			}
			th {
				font-size: 1.8rem;
				font-weight: 600;
			}
			td {
				text-align: right;
			}
			&:first-of-type {
				th,
				td {
					padding-top: 3rem;
				}
			}
		}
	}

	&-responsive {
		thead {
			display: none;
			@include media-breakpoint-up( $bp ) {
				display: table-header-group;
			}
		}
		tbody {
			tr:first-of-type {
				td {
					padding-top: 0;
					@include media-breakpoint-up( $bp ) {
						padding-top: 2.5rem;
					}
				}
			}
			tr {
				display: block;

				@include media-breakpoint-up( $bp ) {
					display: table-row;
				}
				td {
					display: block;
					padding: 0 0 1rem;

					&:first-of-type {
						padding: 3rem 0 1rem;
					}
					&:last-of-type {
						padding: 2rem 0;
					}

					@include media-breakpoint-up( $bp ) {
						display: table-cell;
						padding: 2.5rem 1rem;

						&:first-of-type {
							padding: 2.5rem 1rem 2.5rem 0;
						}
						&:last-of-type {
							padding: 2.5rem 0 2.5rem 1rem;
						}
					}
				}
			}
		}
	}
}
