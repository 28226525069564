.chamfr-cart {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	@include media-breakpoint-down(lg) {
		display: block;
	}

	&-header {
		flex: 0 0 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include media-breakpoint-down(xsm){
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 10px;
		}
	}

	&-content {
		flex: 0 0 65%;
	}

	&-collaterals {
		flex: 0 0 30%;

		&-header {
			line-height: 14px;
			margin-top: 31px;
			font-size: 14px;
			margin-bottom: $spacer;
			padding-right: $spacer;
			text-align: right;

			a {
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

		.cart-collaterals {
			.cart_totals {
				float: none;
				width: 100%;
				background-color: $chamfr-white;
				padding: 24px;
				border-radius: 16px;

				h2 {
					@include h2();
				}

				table {
					border: none;

					th,
					td {
						width: auto;
					}

					th {
						font-weight: 400;
					}

					td {
						text-align: right;
						font-weight: 400;
					}

					tr.order-total {
						th,
						td {
							@include h3();
							font-weight: 800;
						}
					}
				}

				.wc-proceed-to-checkout {
					.checkout-button {
						@include button-primary();
						box-shadow: none;
						display: block;
						margin: 0 8px;
						width: auto;
					}
				}
			}
		}
	}

	&-actions {
		display: flex;
		flex-wrap: wrap;

		.left,
		.right {
			flex: 0 0 50%;
		}

		.right {
			text-align: right;
		}

		.clear-cart {
			text-decoration: underline;
			position: relative;

			&:before {
				content: '';
				position: relative;
				transform: translateY(4px);
				display: inline-block;
				margin-right: 4px;
				width: 20px;
				height: 19px;
				background-image: url("data:image/svg+xml,%3Csvg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.06885 3.06897L5.13781 17.5517H14.862L17.1378 3.06897' stroke='%23E11D73' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.82756 15.483L6.58618 6.17261' stroke='%23E11D73' stroke-linecap='round'/%3E%3Cpath d='M13.207 6.17244L11.9656 15.4828' stroke='%23E11D73' stroke-linecap='round'/%3E%3Cpath d='M9.68945 15.483V6.17261' stroke='%23E11D73' stroke-linecap='round'/%3E%3Cpath d='M1 2.86194H19' stroke='%23E11D73' stroke-linecap='round'/%3E%3Cpath d='M7 2.86207V1H13V2.86207' stroke='%23E11D73' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			}
		}
	}

	table.cart {
		border: none;

		@include media-breakpoint-down(xsm){
			thead{
				display: block;
			}
			tbody{
				& > tr{
					display: flex;
					flex-direction: column;
					margin-bottom: 5px;
				}
			}
		}

		th {
			@include media-breakpoint-down(xsm){
				width: 100%;
			}

			background-color: #d8d8d8;

			&:first-child {
				border-top-left-radius: 8px;
			}

			&:last-child {
				border-top-right-radius: 8px;
			}
		}

		td {
			background-color: $chamfr-lightest-gray;
		}

		tr:last-child {
			td:first-child {
				border-bottom-left-radius: 8px;
			}

			td:last-child {
				border-bottom-right-radius: 8px;
			}
			@include media-breakpoint-down(xsm){
				td:first-child {
					border-bottom-left-radius: 0;
				}
	
				td:last-child {
					border-radius: 0 0 8px 8px;
				}
			}
		}

		img {
			width: 42px;
		}

		td.product-name {
			font-weight: 600;
			line-height: 1.5;
			padding-top: $spacer;
			padding-bottom: $spacer;

			dl.variation {
				@include typo-label();

				dt {
					font-weight: 300;
					line-height: 1.5;
				}

				dd {
					font-weight: 500;
					line-height: 1.5;
				}
			}
		}

		.product-price,
		.product-subtotal {
			text-align: right;
			font-weight: 700;
			@include media-breakpoint-down(xsm){
				text-align: left;
			}
		}

		a.remove {
			display: inline-block;
			width: 24px;
			height: 24px;
			font-size: 0;
			line-height: 0;
			transform: translateY(-10px);
			background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10.9999 10.9999' stroke='%23111111' stroke-linecap='round'/%3E%3Cpath d='M11 1L1.00008 10.9999' stroke='%23111111' stroke-linecap='round'/%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-position: center;

			&:hover {
				background-color: #a003;
			}
		}
	}

	&-update-button {
		@include button-secondary();
	}

	.coupon {
		&-link {
			padding: $spacer $spacer 0;
		}

		padding: $spacer;
		display: flex;
		justify-content: space-between;
		gap: $spacer;
		flex-wrap: wrap;

		input {
			flex-basis: 45%;
			flex-grow: 1;
		}

		.coupon-error-notice {
			flex: 0 0 100%;
		}

		input.button {
			box-shadow: none;
			@include button-secondary();
		}
	}
}
