.chamfr-shop-all {
	&-components {
		@include wide-container();
		padding-top: 2rem;
		padding-bottom: 10rem;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;

		.search {
			@include rich-search();

			form:before {
				height: 90px;
				width: 585px;
			}
		}

		.chamfr-filter {
			flex: 0 0 18%;
			margin-bottom: 2rem;
		}
	}

	&-categories {
		flex: 0 0 82%;
		display: flex;
		margin: -1rem;
		margin-left: 1rem;
		flex-wrap: wrap;

		.category {
			flex: 0 0 33.333%;
			padding: 1rem;
			color: $black;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: stretch;

			.heading {
				background-color: $white;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				padding: 2.5rem;
				display: flex;
				align-items: flex-start;
				flex-grow: 0;

				.image {
					flex: 0 0 6rem;
					margin-right: 1rem;

					img {
						width: 6rem;
						height: 6rem;
						object-fit: contain;
					}
				}

				.summary {
					h5 {
						font-weight: 400;
						margin: 0 1rem 0 0;
						line-height: 1;
					}

					.products {
						font-size: 1.4rem;
						opacity: 0.5;
					}

					.description {
						margin-top: 1rem;
						flex: 0 0 100%;
					}
				}
			}
			.links {
				padding: 2.2rem 2.5rem 5rem;
				position: relative;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				background-color: $light-gray;
				flex-grow: 1;

				.columns {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					margin: 0 -1rem;
					padding-bottom: 4rem;

					a {
						color: $dark-gray;
					}

					.column {
						font-size: 1.6rem;
						line-height: 1.375;
						min-width: 35%;
						margin: 0 1rem;
						> div {
							margin-bottom: 1rem;
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}

				.all {
					display: inline-block;
					position: absolute;
					bottom: 2.5rem;
					left: 2.5rem;
					height: 2.8rem;
					border-bottom: 1px solid $pink;
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
}

@media ( max-width: 1759px ) {
	.chamfr-shop-all {
		&-components {
			.search {
				form {
					max-width: 732px;
				}
			}
			.chamfr-filter {
				flex: 0 0 21%;
			}
		}
		&-categories {
			flex: 0 0 79%;
		}
	}
}

@media ( max-width: 1439px ) {
	.chamfr-shop-all {
		&-components {
			.chamfr-filter {
				flex: 0 0 25%;
			}
		}
		&-categories {
			flex: 0 0 75%;
			.category {
				flex: 0 0 50%;
			}
		}
	}
}

@include media-breakpoint-down( xl ) {
	.chamfr-shop-all {
		&-components {
			.chamfr-filter {
				flex: 0 0 30%;
			}
		}
		&-categories {
			flex: 0 0 70%;
		}
	}
}

@include media-breakpoint-down( md ) {
	.chamfr-shop-all {
		&-components {
			display: block;
		}
		&-categories {
			margin-left: -1rem;
			.category {
				flex: 0 0 100%;
			}
		}
	}
}

@include media-breakpoint-down( xs ) {
	.chamfr-shop-all {
		&-components {
			.search {
				margin-bottom: 3rem;
				form {
					&:before {
						position: absolute;
						content: '';
						left: -0.5em;
						bottom: -0.7em;
						height: 85%;
						background: url( ../images/dots.svg ) repeat top left;
						background-size: 25%;
					}
				}
				input[type='text'] {
					font-size: 1.4rem;
					padding: 1em 1em 1em 3em;
					background-position: 1.1em 1.2em;
				}
			}
		}
		&-categories {
			.category {
				.links {
					.columns {
						display: block;
						.column {
							margin-bottom: 1rem;
						}
					}
				}
			}
		}
	}
}
