@charset "UTF-8";

/* Slider */

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}
.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		//cursor: hand;
	}
	//.slick-loading & {
	//background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
	//}
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	//display: block;
	display: flex;
	align-items: stretch;
	margin-left: auto;
	margin-right: auto;

	//&:before,
	//&:after {
	//  content: "";
	//  display: table;
	//}
	//
	//&:after {
	//  clear: both;
	//}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	transform: translate3d( 0, 0, 0 );
	@include optimize2DAnimationsOn3D;
}

.slick-slide {
	//float: left;
	//height: 100%;
	min-height: 1px;

	height: inherit;
	display: flex !important;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	@include optimize2DAnimationsOn3D;

	> div {
		height: 100%;
		width: 100%;
	}

	img {
		display: block;
	}
	&.slick-loading img {
		display: none;
	}

	//display: none;

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: flex;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}
.slick-arrow.slick-hidden {
	display: none;
}

/* Arrows */

.slick-prev {
	@include inline-svg-bg( $arrow-left );
}

.slick-next {
	@include inline-svg-bg( $arrow-right );
}

.slick-prev,
.slick-next {
	display: inline-block;
	height: 36px;
	width: 36px;
	margin: 0 8px;
	padding: 0;
	background-color: transparent;
	background-position: center;
	background-repeat: no-repeat;
	border: 2px solid $black;
	border-radius: 50%;
	cursor: pointer;
	outline: none;
	&:hover,
	&:focus {
		outline: none;
		background-color: transparent;
	}
	&.slick-disabled {
		opacity: $slick-opacity-not-active;
	}
}
.body-style--dark {
	.slick-prev {
		@include inline-svg-bg( $arrow-left-white );
	}
	.slick-next {
		@include inline-svg-bg( $arrow-right-white );
	}
	.slick-prev,
	.slick-next {
		border-color: $white;
	}
}

/* Dots */

.slick-dots {
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
	li {
		position: relative;
		display: inline-block;

		height: 10px;
		width: 10px;
		@include media-breakpoint-up( lg ) {
			height: 15px;
			width: 15px;
		}
		@include media-breakpoint-up( xxl ) {
			height: 20px;
			width: 20px;
		}
		margin: 0 5px;
		padding: 0;
		cursor: pointer;

		button {
			border: 0;
			background: transparent;
			display: block;
			height: 10px;
			width: 10px;
			@include media-breakpoint-up( lg ) {
				height: 15px;
				width: 15px;
			}
			@include media-breakpoint-up( xxl ) {
				height: 20px;
				width: 20px;
			}
			outline: none;
			line-height: 0;
			font-size: 0;
			color: transparent;
			padding: 5px;
			cursor: pointer;
			&:before {
				position: absolute;
				top: 0;
				left: 0;
				content: ' ';
				height: 10px;
				width: 10px;
				line-height: 20px;

				@include media-breakpoint-up( lg ) {
					height: 15px;
					width: 15px;
					line-height: 15px;
				}
				@include media-breakpoint-up( xxl ) {
					height: 20px;
					width: 20px;
				}

				border: solid 2px;
				text-align: center;
				color: $slick-dot-color;
				opacity: 1;
			}
			&:hover,
			&:focus {
				outline: none;
				&:before {
					background: fade_out( $slick-dot-color, $slick-opacity-not-active );
					//opacity: $slick-opacity-on-hover;
				}
			}
		}
		&.slick-active button:before {
			background: $slick-dot-color-active;
			color: $slick-dot-color-active;
			opacity: $slick-opacity-default;
		}
	}
}

// class modifier for slider behavior
.overflow-slider-with-all-visible {
	@include overflow-slider-with-all-visible;
}

.overflow-slider-with-fade {
	@include overflow-slider-with-fade;
}
.overflow-slider-fade-single {
	@include overflow-slider-fade-single;
}
