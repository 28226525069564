@mixin chevron-white-down() {
	width: 12px;
	height: 8px;
	display: inline-block;
	background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
}

@mixin chevron-grey-down() {
	width: 12px;
	height: 7px;
	display: inline-block;
	background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%238A8A8A' stroke-linecap='round'/%3E%3C/svg%3E");
}

@mixin chevron-pink-down() {
    width: 12px;
    height: 8px;
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%23fa006a' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
}

