#chamfr-search-results,
#chamfr-recently-added-products {
	.chamfr-drawers {
		.companies {
			padding-left: 0;
			margin-top: -10px;
			background-color: #fff;
			border-radius: 10px;

			.company {
				flex: 0 0 100%;
				width: 100%;
				max-width: 100%;
				border-top: 1px solid rgba(0, 0, 0, 0.05);
				padding: 0;

				&:first-child {
					border-top: none;
				}

				@include media-breakpoint-up(md) {
					.details {
						display: flex;
						flex-wrap: wrap;

						.col {
							flex: 0 0 50%;
						}
					}
				}
			}
		}

		.showmore {
			padding: 20px 45px;
		}
		
		.all-results {
			margin-top: 1.5rem;
		}
	}

	.all-results {
		margin-top: 3rem;
		margin-bottom: 6rem;
	}
	.search-blog {
		.showmore {
			padding: 20px 45px;
		}
		.blog-posts {
			padding-left: 0;
			margin-top: -10px;
			list-style: none;
			background-color: #fff;
			border-radius: 10px;

			.blog-post {
				list-style: none;
				flex: 0 0 100%;
				width: 100%;
				max-width: 100%;
				border-top: 1px solid rgba(0, 0, 0, 0.05);

				&:first-child {
					border-top: none;
				}

				.details {
					display: flex;
					flex-direction: row;
					padding: 20px 30px;

					@include media-breakpoint-down(sm) {
						display: block;
					}

					.type {
						color: rgba(49, 49, 49, 0.5);
						font-size: 1.4rem;
						letter-spacing: 0.04em;
					}
					h4 {
						font-size: 2.2rem;
						margin: 0.5rem 0 1.5rem;
						font-weight: 400;
					}
					p {
						color: #313131;
					}
					a {
						display: inline-block;
						padding-bottom: 0.3rem;
						text-decoration: none;
						border-bottom: 1px solid transparent;

						&:hover,
						&:focus,
						&:active {
							text-decoration: none;
							border-bottom: 1px solid $pink;
						}
					}
				}

				@include media-breakpoint-up(md) {
					.details {
						display: flex;
						flex-wrap: wrap;

						.col {
							flex: 0 0 50%;
						}
					}
				}
			}
		}
	}
}
