.home {
	.subscribe {
		margin: 0;
		background: transparent;
		color: inherit;

		&:before,
		&:after {
			display: none;
		}
	}
}
