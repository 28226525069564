.woocommerce-breadcrumb {
	flex: 0 0 100%;
}

#breadcrumb {
	color: $black;
	opacity: 0.5;
	font-size: 80%;
	padding-bottom: 2rem;

	a {
		color: $black;
	}
}
