.print-order-details {
	background: $white;
	color: $black;

	.modal-close,
	.modal-print {
		display: none;
	}

	h3 {
		font-size: 24px;
	}

	h4 {
		font-size: 20px;
	}

	.all-50 {
		display: inline-block;
		width: 49%;
		padding-left: 0;
		padding-right: 50px;
	}

	.wcv-order-table {
		margin-bottom: 1em;

		#order_line_items {
			tr td {
				border-bottom: 1px solid $light-gray;
			}
		}

		th,
		td {
			padding: 5px;
		}
		th {
			font-weight: 700;
			background-color: $light-gray;
			text-align: right;

			&:first-child {
				text-align: left;
			}
		}
		td {
			vertical-align: top;
		}
		.wcv-order-thumb {
			width: 64px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.item_cost,
		.quantity,
		.line_cost {
			text-align: right;
		}
	}

	.wcv-order-totals {
		&-label,
		.total {
			text-align: right;
		}
	}
}
