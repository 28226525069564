/* Multiple shipping addresses*/

.woocommerce-account
	.woocommerce-MyAccount-content
	.woocommerce-address-form
	h1 {
	text-align: left;
	white-space: nowrap;
	@include media-breakpoint-up( sm ) {
		padding-right: 15rem;
	}
}
.woocommerce a.remove.delete-address-button {
	width: auto;
	height: auto;
	margin-top: 4rem;
	border-radius: 0;
	font-size: 1.6rem;
	font-weight: 400;
	text-align: left;
	color: $dark-pink !important;
	@include media-breakpoint-up( sm ) {
		margin-top: 1rem;
	}

	&:hover {
		background: transparent;
	}

	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 23px;
		height: 24px;
		margin-right: 1rem;
		border-radius: 50%;
		background: url( ../images/del.svg ) center no-repeat;
	}
}

.woocommerce .wc-shipping-multiple-addresses.shop_table {
	margin-top: 1rem;

	/* stylelint-disable-next-line no-descending-specificity */
	th,
	td { /* stylelint-disable-line */
		@include media-breakpoint-down( xs ) {
			padding: 9px 5px;
			font-size: 1rem;
		}
	}

	input,
	select {
		@include media-breakpoint-down( xs ) {
			height: 4rem;
			padding: 0.5rem;
		}
	}
	.quantity .qty {
		@include media-breakpoint-down( xs ) {
			width: 6rem;
		}
	}

	.button {
		@include media-breakpoint-down( sm ) {
			height: 3rem;
			padding: 0 1rem;
			font-size: 1rem;
			line-height: 3rem; /* stylelint-disable-line */
		}
		@include media-breakpoint-down( xs ) {
			height: 2rem;
			font-size: 0.9rem;
			line-height: 2rem; /* stylelint-disable-line */
		}
	}
}
.woocommerce .update-shipping-addresses,
.woocommerce .set-shipping-addresses {
	margin-bottom: 1rem;

	.button { /* stylelint-disable-line */
		@include media-breakpoint-down( xs ) {
			height: 3rem;
			padding: 0 2rem;
			font-size: 1.2rem;
			line-height: 3rem; /* stylelint-disable-line */
		}
	}
}
.woocommerce .update-shipping-addresses .button { /* stylelint-disable-line */
	margin-right: 1rem;
}

/* checkout page */
#wcms_message {
	display: none; // hide the "set addresses" button as it does not work properly.
}
#shipping_addresses {
	.no_shipping_address,
	.ship_address {
		float: none;
		width: 100%;
		margin: 3rem 0;
		padding: 2rem;
		background-color: #eee;
		border-radius: 5px;
		border: 1px solid #ddd;
		font-weight: 400;
		line-height: 1.5em;

		em {
			display: block;
			margin-bottom: 2rem;
		}

		ul {
			list-style: none;
			margin: 0 0 2rem;
			padding: 0;
		}
		select {
			max-width: 30rem;
		}
	}
}
#ship-to-multiple {
	display: none;
}
tr.multi_shipping div.ship_address small.data {
	display: none;
}

/* Page: /checkout/shipping-addresses/ */
.address-container {
	display: flex;
	flex-wrap: wrap;
	margin: 0 $grid-gutter-margin;

	.address-block {
		float: none;
		width: 25rem;
		min-height: 170px;
		margin: 0;
		padding: 0 $grid-gutter-padding calc( #{$grid-gutter-width} + 6rem );

		.buttons {
			left: $grid-gutter-padding;
			right: $grid-gutter-padding;
			bottom: $grid-gutter-width;
			width: auto;
			text-align: left;

			.button {
				width: 9.6rem;
				height: 4rem;
				padding: 0 1rem;
				line-height: 4rem;
			}
		}
	}
}
#ms_shipping_addresses_field:after {
	height: 4rem;
}
.wcms-address-form {
	label {
		display: block;
	}
	.woocommerce-input-wrapper {
		width: 100%;
	}
}
.woocommerce a.btn-import-billing,
.woocommerce a.btn-import-shipping {
	margin-bottom: 1rem;
}

