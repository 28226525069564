.wp-block-column {
	&.chamfr-light {
		padding: 2rem;
		background-color: $white;
		color: $black;

		.wp-block-heading {

		}

		.wp-block-buttons {
			.wp-block-button {

			}
		}
	}

	&.chamfr-dark {
		padding: 2rem;
		background-color: $black;
		color: $white;

		.wp-block-heading {

		}

		.wp-block-buttons {
			.wp-block-button {

			}
		}
	}

	&.chamfr-transparent {
		padding: 2rem;
		background-color: none;
		color: $white;

		.wp-block-heading {

		}

		.wp-block-buttons {
			.wp-block-button {

			}
		}
	}
}

ul.left-align {
	text-align: left;
}

.theme-chamfr2020 .schema-faq {
	display: block;
	margin: 0 auto;
	width: 90%;

	.schema-faq-section {
		margin-bottom: 2rem;

		.schema-faq-question {
			border-bottom: 2px solid $pink;
			cursor: pointer;

			&::after {
				color: $pink;
				content: '\f13a';
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				display: inline-block;
				transition: transform 0.3s;
				position: absolute;
				right: 10%;
			}
			&.expanded {
				&::after {
					transform: rotate(180deg);
				}
			}
		}
		.schema-faq-answer {
			display: none;
			margin: 2rem;
		}
	}
}


//Custom headings
.page-become-a-partner {
	h1.wp-block-heading {
		font-size: 2.6rem;
	}
	h2.wp-block-heading {
		font-size: 4rem;
	}
	h3.wp-block-heading {
		font-size: 3rem;
	}
	h4.wp-block-heading {
		font-size: 2.5rem;
	}
	h5.wp-block-heading {
		font-size: 2rem;
	}
	p {
		font-size: 1.5rem;
	}
}