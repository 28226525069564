.chamfr-shipping-accounts {
	.error {
		color: $chamfr-red;

		> div {
			padding: $spacer 0;
		}
	}
}
.chamfr-shipping-account-number {
	position: relative;
	color: $chamfr-black;
	max-width: 424px;
	padding: 16px 0 32px 40px;

	.not-selected {
		padding: 16px;
		display: none;
	}

	.new-account-form {
		display: none;

		a {
			color: $chamfr-pink;
			text-decoration: none;

			&:before {
				content: "";
				display: inline-block;
				width: 12px;
				height: 12px;
				margin-right: 4px;
				background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10.9999 10.9999' stroke='%23E11D73' stroke-linecap='round'/%3E%3Cpath d='M11 1L1.00008 10.9999' stroke='%23E11D73' stroke-linecap='round'/%3E%3C/svg%3E%0A");
			}
		}

		> div {
			display: flex;
			gap: 4px;
		}

		.save-number {
			@include button-outlined();
		}
	}

	.options {
		position: absolute;
		display: none;
		background-color: $chamfr-white;
		border: 1px solid $chamfr-gray;
		z-index: 10;
		width: 100%;

		> div {
			padding: 16px;
		}

		a {
			color: $chamfr-black;

			&:hover {
				color: $chamfr-pink;
			}
		}

		.new-account a {
			text-decoration: underline;
		}

		.saved-account {
			display: flex;

			span {
				font-weight: 700;
				cursor: pointer;
			}

			.delete {
				display: none;
			}
		}
	}

	.action {
		border: 1px solid $chamfr-gray;
		border-radius: 4px;
		background-color: $chamfr-white;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			right: 16px;
			top: calc(50% - 4px);
			display: block;
			width: 14px;
			height: 8px;
			background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1.5L7 6.5L2 1.5' stroke='%23B4B4B4' stroke-width='1.5' stroke-linecap='square'/%3E%3C/svg%3E%0A");
		}

		.not-selected {
			display: block;
		}

		.saved-account {
			display: flex;
			justify-content: space-between;
			padding: 16px 40px 16px 16px;

			span {
				font-weight: 700;
			}

			.delete {
				display: inline;
				text-decoration: underline;
				color: $chamfr-gray;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}
