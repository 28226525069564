.floating-share,
.single__container .floating-share {
	display: none;
	position: absolute;
	top: 0;
	margin-left: -11.5rem;

	@include media-breakpoint-up( $bp ) {
		display: block;
	}

	&.fixed {
		position: fixed;
	}
	&.fixed-bottom {
		position: absolute;
		top: auto;
		bottom: 0;
	}

	ul {
		list-style: none;
		margin: 4rem 0 0;
		padding: 0;

		li {
			display: block;
			margin: 0 0 1.5rem;

			&:last-of-type {
				margin: 0;
			}

			a {
				display: block;
				width: 34px;
				height: 34px;
				border: 2px solid $dark-pink;
				border-radius: 50%;
				line-height: 30px;
				text-decoration: none;
				text-align: center;
				color: $dark-pink;
			}
		}
	}
}
