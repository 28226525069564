.btn {
	display: inline-block;
	//height: 5rem;
	padding: 1.558rem 3rem;
	border-radius: 0.5rem;
	background: $pink;
	transition: all 0.3s ease-out;
	cursor: pointer;
	user-select: none;

	font: 600 1.6rem/1.2 $content-font;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	letter-spacing: 0.04em;
	color: $white;

	@include small-shadow();

	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
		color: $white;
		background: $pink;
		border: none;
		outline: none;
	}

	&:disabled {
		@include disabled-button;
	}

	&-primary {
		// set style for primary button here

		&:disabled {
			@include disabled-button;
		}
	}

	&-secondary {
		background: $white;
		color: $pink;

		&:hover,
		&:focus,
		&:active {
			background: $white;
			color: $pink;
		}
		&:disabled {
			@include disabled-button;
		}
	}

	&-link {
		display: inline-block;
		transition: all 0.2s;
	}

	/*
  Colored button which allows to select background and text color in tinyMCE
  just set rules like font\size\paddings\border radius if necessary.
  */
	&-colored {
		position: relative;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: transparentize($black, 1);
			transition: all 0.3s;
		}

		// set style for colored hover overlay here
		&:hover,
		&:focus {
			&:after {
				background: transparentize($black, 0.9);
			}
		}
	}

	&-outlined {
		background-color: transparent;
		color: $pink;
		border: 2px solid $pink;

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
			color: $pink;
			background-color: transparent;
			border: 2px solid $pink;
			outline: none;
		}
	}
}
