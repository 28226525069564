.advantages-counter {
	position: relative;
	margin: 0 0 18rem;
	padding: 4rem 6rem;
	background: $black;
	border-radius: 0.5rem;
	@include medium-shadow();

	@include media-breakpoint-down($bp-m) {
		margin: 0 0 10rem;
		padding: 2rem 3rem;
	}

	@include media-breakpoint-up( lg ) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: -2.5rem;
		right: -3rem;
		z-index: -1;
		width: 20rem;
		height: 16.5rem;
		background: url( ../images/dots.svg ) repeat bottom right;
		opacity: 0.5;

		@include media-breakpoint-up( $bp ) {
			width: 51.9rem;
			height: 18rem;
		}
	}

	&__info {
		margin: 0 0 3.5rem;

		@include media-breakpoint-up( lg ) {
			flex: 0 1 38.6rem;
			width: 38.6rem;
			margin: 0 10rem 2rem 0;
		}

		h3 {
			margin: 0;
		}
	}
	&__link {
		margin: 1.5rem 0 0;

		a {
			text-decoration: none;
			padding-bottom: 0.3rem;

			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
				border-bottom: 1px solid $pink;
			}
		}
	}

	&__stats {
		flex: 0 2 100%;
		@include media-breakpoint-up( md ) {
			display: flex;
			justify-content: space-between;
		}
	}
	.stat-item {
		margin: 0 auto;
		padding: 0 0 4.5rem;
		text-align: center;

		@include media-breakpoint-up( md ) {
			margin: 0;
			padding: 0 4rem;
		}

		&:last-of-type {
			padding: 0;

			@include media-breakpoint-up( md ) {
				padding: 0 0 0 4rem;
			}
		}

		.value {
			font-size: 5.6rem;
			font-weight: 600;
		}
		&__description {
			font-size: 2.2rem;
		}
	}
}
