.primary-menu {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 3000;
	// min-width: 30rem;
	background: $chamfr-deep-gray;
	transition: all 0.6s ease-out;
	transform: translateX(-100%);

	@include media-breakpoint-up(md) {
		position: static;
		z-index: 250;
		background: transparent;
		transform: translateX(0);
	}
	@include media-breakpoint-down(sm){
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow: auto;
	}
	.menu-on & {
		transform: translateX(0);
	}

	.side-account-menu{
		display: block;
		padding: 13px 17px;
		margin-top: 20px;

		@include media-breakpoint-up(md){
			display: none;
		}

		&.user-logged-in{
			background: $chamfr-pink;
			padding: 20px 17px;

			.menu{
				margin: 0;
				& > li{
					& > a{
						color: $chamfr-white;
						font-size: 16px;
						line-height: 18.8px;
						font-weight: 800;
						display: block;
						position: relative;
						text-decoration: none;

						&.is-active{
							&::after{
								transform: rotate(0deg);
							}
						}
						&::after{
							content: "";
							@include chevron-white-down;
							transform: rotate(-90deg);
							position: absolute;
							right: 0;
							top: 3px;
						}
					}
				}
			}

			.sub-menu{
				display: none;

				&.is-open{
					display: flex;
					flex-direction: column;
					list-style: none;
					background: #fff;
    				margin: 10px 0 0;
    				padding: 10px;
				}

				li{
					&:not(:first-child){
						a{
							border-top: 1px solid $chamfr-light-gray;
						}
					}
					&:last-child{
						a{
							padding: 20px 0 10px;
							color: $chamfr-pink;
						}
					}
				}

				a{
					padding: 1rem 0;
					display: block;
					color: $chamfr-black;
					font-weight: 600;
				}
			}
		}

		.my-account-head{
			color: $chamfr-white;
			font-size: 16px;
			line-height: 18.8px;
			margin: 0;
		}

		.menu{
			margin: 11px 0 0;
			padding: 0;
			list-style: none;
			display: flex;
			column-gap: 16px;

			li{
				flex: 1 1 100%;
			}

			.top-login-btn{
				a{
					@include button-secondary;
				}
			}

			.top-register-btn{
				a{
					@include button-primary;
				}
			}

			.top-login-btn, .top-register-btn{
				a{
					width: 100%;
					padding: 16px 24px;
					border-radius: 8px;
					text-align: center;
					line-height: 18.8px;
					text-underline-position: from-font;
					text-decoration-skip-ink: none;
				}
			}
			
		}
	}
}

.chamfr-mega-menu {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;

	@include media-breakpoint-up(md) {
		display: flex;
		justify-content: flex-start;
	}

	@include media-breakpoint-down(sm){
		margin-top: 95px;
	}

	@include media-breakpoint-down(xsm){
		margin-top: 65px;
	}

	> .menu-item {
		margin-right: 5rem;

		> a {
			color: $white;
		}

		&.menu-item-has-children {
			> a::after {
				@include chevron-white-down();

				transition: all 0.3s ease;
				content: "";
				margin-left: 0.4rem;
			}
		}

		&:last-child {
			margin-right: 0;
		}

		> a {
			display: inline-block;
			padding: 1rem 0;
		}

		> .sub-menu {
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			list-style: none;

			padding: 4rem;
			padding-bottom: 16rem;
			margin: 0;

			background-color: $white;
			color: $darkest-gray;

			display: none;
			flex-wrap: wrap;

			line-height: 2.4rem;

			.menu-title {
				flex-basis: 100%;
				font-size: 2rem;
				font-weight: 600;
				margin-bottom: 1rem;

				> a {
					color: inherit;
				}
			}

			.menu-footer {
				order: 1;
				flex-basis: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				padding: 4rem;
				background-color: $lightest-gray;
				display: flex;
				justify-content: center;

				@include media-breakpoint-down(sm) {
					display: none;
				}

				> div:first-child {
					margin-right: 4rem;
				}

				&-title {
					font-size: 2.4rem;
					font-weight: 800;
					margin-bottom: 1rem;
				}
			}

			.menu-columns {
				display: flex;
				flex-basis: 100%;
				flex-grow: 1;

				&.scrollable {
					max-height: 50vh;
					overflow-y: scroll;
				}

				.menu-col {
					flex-grow: 1;
					flex-basis: 25%;

					@include media-breakpoint-down(md) {
						flex-basis: 33.333%;
					}

					@include media-breakpoint-down(sm) {
						flex-basis: 100%;
					}
				}
			}

			> .menu-item,
			.menu-col > .menu-item {
				font-weight: 600;
				margin-bottom: 1rem;

				> a {
					color: $darkest-gray;

					&:hover {
						text-decoration: none;
						color: $pink;
					}
				}

				&.menu-item-has-children {
					& > a::after {
						@include chevron-grey-down();

						position: relative;
						top: -1px;
						transition: all 0.3s ease;
						content: "";
						margin-left: 0.4rem;
					}
				}

				> .sub-menu {
					list-style: none;
					padding: 0 0 0 2rem;
					margin: 0;
					font-size: 1.4rem;
					font-weight: 400;

					display: none;

					> .menu-item {
						@include media-breakpoint-down(sm) {
							margin: 0.6rem 0;
						}

						> a {
							color: $darkest-gray;
						}

						&.see-all {
							font-weight: 700;
						}
					}
				}

				&.menu-open {
					> a {
						color: $pink;
					}

					> a::after {
						transform: scaleY(-1);
					}

					> .sub-menu {
						display: block;
					}
				}
			}
		}

		&.menu-open {
			> a::after {
				transform: scaleY(-1);
			}

			> .sub-menu {
				display: flex;
			}
		}

		@include media-breakpoint-down(sm) {
			margin: 0 $spacer;
			max-height: calc(100vh - 50px);

			&:last-child {
				margin-right: $spacer;
			}

			> a {
				display: block;
				border-bottom: 1px solid rgba($chamfr-dark-gray, 0.5);
			}

			&:first-child > a {
				border-top: 1px solid rgba($chamfr-dark-gray, 0.5);
			}

			&.menu-open > a {
				border-bottom: none;
			}

			> .sub-menu {
				position: relative;
				width: auto;
				margin-left: -$spacer;
				margin-right: -$spacer;
				padding: $spacer;
				max-height: calc(100vh - 95px);
				overflow-y: scroll;
				max-height: calc(100dvh - 95px);

				> .menu-item {
					margin-bottom: 1rem;
				}
			}
		}
	}
}
