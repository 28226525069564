/* stylelint-disable no-descending-specificity */
.latest-blog {
	padding: 0 0 16rem;
	@include media-breakpoint-down($bp-m) {
		padding: 0 0 8rem;
	}

	@include media-breakpoint-up( $bp ) {
		padding: 0 0 16rem 0;
		margin: 0 -3rem;
	}

	@include media-breakpoint-up( hd ) {
		margin: 0 4rem;
	}

	> h3 {
		text-align: center;
		padding-bottom: 4rem;
		@include media-breakpoint-up( $bp ) {
			margin: 0;
		}
		@include media-breakpoint-down($bp-m) {
			padding-bottom: 1rem;
		}
	}

	&__posts {
		@include media-breakpoint-up( $bp ) {
			position: relative;
			overflow: hidden;
			padding: 2rem 3rem 0rem; // for boxshadow

			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 1rem;
				z-index: -1;
				width: 31.9rem;
				height: 41.5rem;
				background: url( ../images/dots.svg ) repeat top left;
				opacity: 0.5;
			}
		}

		&--desktop {
			display: none;
			@include media-breakpoint-up( $bp ) {
				display: flex;
				justify-content: space-between;
				align-items: stretch;
			}
		}
		&--mobile {
			display: block;
			@include media-breakpoint-up( $bp ) {
				display: none;
			}
		}

		&__col {
			flex: 0 0 50%;
			width: 58%;

			&:first-of-type {
				width: 58%;
				padding-right: 16px;

				.post {
					display: block;

					.post__img,
					.post__body {
						@include media-breakpoint-up( $bp ) {
							width: 100%;
							flex: 0 0 100%;
						}
					}

					.post__body {
						@include media-breakpoint-up( $bp ) {
							padding: 4rem 3.5rem;
						}
					}
					&__txt {
						@include media-breakpoint-up( $bp ) {
							position: static;
						}
					}
					.post__meta {
						@include media-breakpoint-up( $bp ) {
							font-size: 1.4rem;
							margin: 0 0 1rem;
						}
					}
					.post__title {
						h2 {
							@include media-breakpoint-up( $bp ) {
								font-family: $headings-font;
								font-size: 3rem;
								font-weight: 500;
								max-height: none;
							}
						}
					}
					.post__excerpt {
						@include media-breakpoint-up( $bp ) {
							display: block;
							margin: 0 0 2.5rem;
							font-size: 1.8rem;
						}
					}
					.post__link {
						@include media-breakpoint-up( $bp ) {
							position: static;
						}
					}
				}
			}
			&:last-of-type {
				@include media-breakpoint-up( $bp ) {
					display: flex;
					flex-direction: column;
				}
				.post {
					.post__img {
						@include media-breakpoint-up( $bp ) {
							padding: 2rem 1rem 2rem 2rem;
							img {
								border-radius: 0.5rem;
							}
						}
					}
				}
			}
		}

		&__wrapper {
			padding-bottom: 16px;
			flex: 1;

			&:last-of-type {
				padding: 0;
			}
		}

		&.slick-slider {
			margin: 0 -5px;
			@include overflow-slider-with-all-visible();

			.slick-slide {
				margin: 0 5px;

				.post {
					height: 100%;
				}
			}
		}

		.post {
			overflow: hidden;
			background: $darkest-gray;
			border-radius: 0.5rem;
			@include medium-shadow();

			@include media-breakpoint-up( $bp ) {
				display: flex;
				align-items: stretch;
				height: 100%;
				//min-height: 19.5rem;
			}

			&__img {
				margin: 0;

				img {
					display: block;
					width: 100%;
				}
			}

			&__img,
			&__body {
				@include media-breakpoint-up( $bp ) {
					width: 50%;
					flex: 0 0 50%;
				}
			}
			&__body {
				padding: 2rem 3rem 2.6rem;

				@include media-breakpoint-up( $bp ) {
					position: relative;
					padding: 2rem 1.5rem 5rem;
				}
			}
			&__meta {
				margin: 0 0 0.5rem;
				font-size: 1.2rem;
				font-weight: 600;
				color: $light-gray;
			}
			&__categories,
			&__date {
				display: inline-block;
			}
			&__categories {
				text-transform: uppercase;
				@include media-breakpoint-up( $bp ) {
					font-size: 1rem;
				}
				@include media-breakpoint-up( hd ) {
					font-size: 1.2rem;
				}

				a {
					color: $light-gray;

					&:hover,
					&:focus,
					&:active {
						color: $light-gray;
					}
				}
			}

			&__title {
				h2 {
					font-family: $content-font;
					font-size: 1.8rem;
					font-weight: 400;
					overflow: hidden;
					margin: 0 0 1.5rem;
					@include media-breakpoint-up( $bp ) {
						max-height: 6.5rem;
						margin: 0;
					}

					a {
						color: $white;
					}
				}
			}
			&__excerpt {
				display: none;
				margin: 0;
			}
			&__link {
				@include media-breakpoint-up( $bp ) {
					position: absolute;
					left: 1.5rem;
					bottom: 2rem;
					font-size: 1.4rem;
				}
				@include media-breakpoint-up( hd ) {
					font-size: 1.6rem;
				}

				&__a {
					display: inline-block;
					padding-bottom: 0.3rem;
					text-decoration: none;

					&:hover,
					&:focus,
					&:active {
						text-decoration: none;
						border-bottom: 1px solid $pink;
					}
				}
			}
		}
	}

	&__slick-controls {
		margin: 1.5rem 0 0;
		text-align: center;

		@include media-breakpoint-up( $bp ) {
			display: none;
		}
	}
}
