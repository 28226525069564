#chamfr-units-switch {
	width: auto;
	margin-left: auto;
	text-align: right;
	position: relative;

	@include media-breakpoint-down(sm) {
		width: 100%;
		top: -44px;
		margin-bottom: -24px;
	}

	span {
		&:first-child::after {
			content: " / ";
		}
	}
	a.active {
		font-weight: 700;
	}

	.tt {
		visibility: hidden;
		width: 120px;
		background-color: black;
		color: #fff;
		text-align: center;
		padding: 5px 0;
		border-radius: 6px;

		/* Position the tooltip text - see examples below! */
		position: absolute;
		z-index: 1;

		bottom: 125%;
		left: 50%;
		margin-left: -60px;
	}

	/* Show the tooltip text when you mouse over the tooltip container */
	&:hover .tt {
		visibility: visible;
	}
}

#chamfr-supplier-store #chamfr-units-switch {
	display: none;
}
