.woocommerce-order{
    .confirmation-box{
        background-color: $chamfr-white;
        border-radius: 8px;
        padding: 32px;
        margin: 0 -32px 48px;

        &.order-summary{
            padding: 24px 40px 33px;
        }
    }

    .woocommerce-notice{
        color: $chamfr-black;
        font-size: 38px;
        font-weight: 800;
        line-height: 54px;
        margin-bottom: 8px;
    }

    .woocommerce-notice-after{
        color: $chamfr-black;
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 8px;
    }

    .order-number-pdf-outer{
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down( xsm ){
            flex-wrap: wrap;
            gap: 20px;
        }

        .order-number-large{
            color: $chamfr-pink;
            font-size: 22px;
            font-weight: 800;
            line-height: 32px;
            margin-bottom: 0;
        }

        .pdf-download{
            a{
                font-size: 14px;
                line-height: 16.45px;
                display: block;
                text-decoration-line: underline;
                text-decoration-style: solid;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                &:hover{
                    text-decoration: none;
                }
            }
        }

        .pdf-icon{
            background: url(../images/download-pdf.svg) no-repeat center/contain;
            width: 16px;
            height: 20px;
            display: inline-block;
            margin-right: 13px;
            vertical-align: bottom;
        }
    }

    .woocommerce-order-overview{
        background-color: $chamfr-lightest-gray;
        border-radius: 8px;
        padding: 40px;
        margin: 32px 0;
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down( md ){
            flex-wrap: wrap;
        }

        .order-overview-box{
            flex: 1 1 100%;
            
            @include media-breakpoint-down( md ){
                display: flex;
                justify-content: space-between;
            }
            @include media-breakpoint-down( sm ){
                flex-direction: column;
            }

            @include media-breakpoint-up( lg ){
                &:not(:last-child){
                    margin-right: 48px;
                    padding-right: 48px;
                    border-right: 1px solid $chamfr-light-gray;
                }
            }
            @include media-breakpoint-down( md ){
                &:not(:last-child){
                    margin-bottom: 48px;
                    padding-bottom: 48px;
                    border-bottom: 1px solid $chamfr-light-gray;
                }
            }
        }

        .box-heading{
            color: $chamfr-black;
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
            margin-bottom: 16px;
        }

        .inner-box{
            .box-sub-heading{
                color: $chamfr-black;
                font-size: 18px;
                font-weight: 500;
                line-height: 26px;
                margin: 0;
            }
            &:not(:last-child){
                margin-bottom: 12px;
            }
        }
    }

    .order-total-text {
        margin: 0 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media-breakpoint-down( sm ){
            flex-wrap: wrap;
            gap: 20px;
        }

        .thankyou-support-text{
            font-size: 14px;
            line-height: 18px;
            font-style: italic;
            margin: 0;
            max-width: 42%;
            width: 100%;

            @include media-breakpoint-down( xl ){
                max-width: 50%;
            }
            @include media-breakpoint-down( lg ){
                max-width: 60%;
            }
            @include media-breakpoint-down( sm ){
                max-width: 100%;
            }
            a{
                font-style: normal;
                text-decoration: underline;
                text-decoration-style: solid;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                &:hover{
                    text-decoration: none;
                }
            }
        }

        .order-total{
            color: $chamfr-pink;
            font-size: 24px;
            font-weight: 800;
            line-height: 32px;
        }
    }

    .order-summary {
        .summary-table {
            width: 100%;
            border-collapse: collapse;

            th, td{
                color: $chamfr-black;
                font-size: 18px;
                font-weight: 500;
                line-height: 26px;

                @include media-breakpoint-down( md ){
                    display: inline-block;
                }
            }

            th{
                padding: 0 24px 24px;

                @include media-breakpoint-down(md){
                    display: none;
                }
            }

            thead{
                .head-border{
                    td{
                        border-top: 1px solid $chamfr-black;
                        padding-bottom: 32px;
                        @include media-breakpoint-down( md ){
                            display: none;
                        }
                    }
                }
            }
        }

        .product-body{
            border: 1px solid $chamfr-light-gray;
            border-radius: 4px;
        }

        .product-header{
            background: $chamfr-light-gray;
            border-radius: 4px;

            @include media-breakpoint-down(md){
                display: flex;
                flex-direction: column;
            }

            .product-seller{
                padding: 12px 16px;
            }
        }

        .shipping-method {
            font-size: 16px;
            background: $chamfr-white;
            padding: 15px 16px;
            text-align: center;
            line-height: 20px;
            border-radius: 4px;
        }

        .product-details{
            border-bottom: 1px solid $chamfr-gray;

            @include media-breakpoint-down(md){
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            td{
                padding: 24px 24px 16px;
                @include media-breakpoint-down(xsm){
                    padding: 24px 10px 16px;
                }
            }
            .product-info{
                display: flex;
                gap: 10px;
                align-items: flex-start;

                & > *{
                    flex: 1 1 100%;
                }
            }

            .product-image {
                max-width: 104px;
                width: 100%;
                height: 104px;
                background: $chamfr-lightest-gray;
                border-radius: 4px;
                padding: 7px;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }

            .mobile-table-header{
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 10px 15px;
                margin: 0 10px;
                background-color: $chamfr-lightest-gray;
                border-radius: 4px 4px 0 0;

                @include media-breakpoint-up(lg){
                    display: none;
                }

                p{
                    margin: 0;
                }
            }
        }

        .total-amount-border{
            td{
                border-top: 1px solid $chamfr-gray;
                @include media-breakpoint-down(md){
                    display: block;
                }
            }
        }

        .total-amount-outer{
            @include media-breakpoint-down(md){
                text-align: right;
            }

            .total-amount {
                font-size: 22px;
                font-weight: 700;
                line-height: 32px;
                text-align: right;
                color: $chamfr-black;
                padding: 16px 24px 24px;
            }
        }

        
    }
      
    .account-creation-form{
        background-color: $chamfr-deep-gray;
        padding: 34px 56px 32px;
        margin: 32px 0;
        border-radius: 8px;

        .account-creation-main-head{
            color: $chamfr-white;
            font-weight: 800;
            line-height: 24px;
            text-transform: capitalize;
            margin: 0 0 7px;
        }
        .account-creation-sub-head{
            color: $chamfr-white;
            font-size: 14px;
            line-height: 24px;
            color: $chamfr-white;
            margin: 0 0 30px;
        }

        form{
            display: flex;
            align-items: flex-end;
            column-gap: 32px;
            width: 57%;

            @include media-breakpoint-down(lg){
                width: 95%;
            }
            @include media-breakpoint-down(md){
                width: 100%;
            }
            @include media-breakpoint-down(sm){
                flex-wrap: wrap;
                row-gap: 20px;
            }

            & > *{
                flex: 1 1 auto;
                margin: 0;
            }
        }

        label{
            line-height: 16.45px;
            color: $chamfr-white;
            span{
                color: #FE0000;
                margin-left: 3px;
            }
        }
        input{
            border: 1px solid $chamfr-gray;
            background-color: $chamfr-white;
            max-width: 380px;
            width: 100%;
            padding: 15px 16px;
            border-radius: 4px;
            @include media-breakpoint-down(sm){
                max-width: 100%;
            }
        }
        button{
            max-width: 300px;
            width: 100%;
            box-shadow: none;
            @include media-breakpoint-down(sm){
                max-width: 100%;
            }
            &:hover{
                background: $chamfr-raspberry;
            }
        }
    }
}