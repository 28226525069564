.block-spots {
	.primary-spot {
		padding-bottom: calc($spacer / 2);
	}

	.secondary-spots {
		margin-left: calc($spacer / -2);
		margin-right: calc($spacer / -2);

		.spot {
			padding: calc($spacer / 2);
		}
	}

	.spot-image {
		width: 100%;
		height: auto;
		border-radius: 1rem;

		&-desktop {
			display: none;

			@include media-breakpoint-up(sm) {
				display: inline-block;
			}
		}

		&-mobile {
			display: inline-block;

			@include media-breakpoint-up(sm) {
				display: none;
			}
		}
	}

	@include media-breakpoint-down(xsm) {
		.slick {
			&-slide {
				.spot {
					opacity: 0.8;
					transform: scale(0.92);
					transition: all 300ms ease;
				}
			}
			&-center {
				.spot {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}
}
