@mixin select2-arrow() {
	position: absolute;
	width: 0;
	height: 0;
	margin-left: -6px;
	margin-top: -3px;
	border-color: $dark-pink transparent transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0 6px;
}
.form-row select {
	padding: 0 1.5rem;
}
.ms-addresses-field,
#order_shipment_account_type {
	position: relative;

	&:after {
		content: '';
		display: block !important;
		position: absolute;
		top: 2.8rem;
		bottom: 1px;
		right: 1px;
		z-index: 10;
		width: 1.5rem;
		height: 5rem;
		background: $white;
		border-radius: 0 0.5rem 0.5rem 0;
	}
	&:before {
		position: absolute;
		top: 5rem;
		right: 0.8rem;
		z-index: 11;
		width: 0;
		height: 0;
		margin-left: -6px;
		margin-top: -3px;
		border-color: $dark-pink transparent transparent transparent;
		border-style: solid;
		border-width: 6px 6px 0 6px;
	}
}
.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
	box-shadow: none;
}
.select2-container .select2-choice {
	height: 5rem;
	margin: 0;
	padding: 0 0 0 1.5rem;
	background: $white;
	border-radius: 0.5rem;
	border: 1px solid $light-gray2;
	line-height: 5rem; /* stylelint-disable-line declaration-property-unit-whitelist */
	color: $dark-gray;

	.select2-arrow {
		top: 0;
		width: 3rem;
		height: 5rem;
		background: transparent;
		border: none;

		b {
			@include select2-arrow();
			top: 50%;
			left: 50%;		
		}
	}
}

.select2-container .select2-selection--single {
	height: 5rem;
	margin: 0;
}
.select2-container--default {
	.select2-selection--single {
		border-radius: 0.5rem;
		border: 1px solid $light-gray2;

		.select2-selection__rendered {
			padding-left: 1.5rem;
			padding-right: 3rem;
			line-height: 5rem; /* stylelint-disable-line declaration-property-unit-whitelist */
			color: $dark-gray;
		}

		.select2-selection__arrow {
			top: 0;
			width: 3rem;
			height: 5rem;

			b {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 0;
				height: 0;
				margin-left: -6px;
				margin-top: -3px;
				border-color: $dark-pink transparent transparent transparent;
				border-style: solid;
				border-width: 6px 6px 0 6px;
			}
		}
	}
	.select2-results {
		color: $dark-gray;

		&__option {
			padding: 0.6rem 1.5rem;

			&--highlighted[aria-selected],
			&--highlighted[data-selected] {
				background: $dark-pink;
				color: $white;
			}
		}
	}
}

.select2-container--default.select2-container--open
	.select2-selection--single
	.select2-selection__arrow
	b {
	border-color: transparent transparent $dark-pink transparent;
	border-width: 0 6px 6px 6px;
}

.select2-selection--multiple {
	position: relative;
	height: 5rem;
	border: 1px solid $light-gray2;

	&:after {
		@include select2-arrow();
		right: 10px;
		top: 24px;
		content: '';
	}

	.select2-search {
		&__field {
			height: auto;
		}
	}
}
