.woocommerce-checkout {
	#order_fedex_delivery_speed_field,
	#order_fedex_account_number_field,
	#order_ups_account_number_field {
		display: none;
		&.field-invalid {
			color: $invalid-color;
			input {
				border-color: $invalid-color;
			}
		}
	}
	.long-loading-message {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -100px);
		background: $black;
		color: $white;
		padding: 5px;
		border-radius: 5px;
	}
	#payment
		div.payment_box
		.woocommerce-SavedPaymentMethods
		.woocommerce-SavedPaymentMethods-tokenInput {
		position: absolute;
	}
}
#billing_ship_to_the_same_address_field {
	.optional {
		float: left;
		width: 40px;
		color: transparent;
	}
}
.woocommerce-shipping-fields.same_address .shipping_address_fields {
	height: 0;
	overflow: hidden;
}

.shipment_account_details {
	margin-bottom: 3rem;
	h3 {
		margin-top: 5rem;
	}
	h4 {
		font-size: 2rem;
		margin: 0 0 1rem;
	}
}

input[type="radio"]#payment_method_stripe + label {
	display: block;
	width: 100%;
}
.woocommerce {
	.shop_table.woocommerce-checkout-review-order-table {
		border-collapse: collapse;

		thead {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}
		tbody {
			border: 2px solid #a7a7a7;

			.shipping {
				border-bottom: 2px solid #a7a7a7;
				th {
					color: $dark-pink;

					p {
						color: $text-black;
					}
				}
			}
		}

		tbody td,
		tbody th {
			border: none;
		}
		.cart_item_odd {
			background: $light-gray;
			+ .shipping {
				background: $light-gray;
			}
		}
		.cart_item_even {
			background: $light-gray3;
			+ .shipping {
				background: $light-gray3;
			}
		}
		td.product-total {
			vertical-align: top;
		}
		td.product-name dl.variation {
			font-weight: 700;
			color: $pink;

			dt.variation-SoldBy,
			dd.variation-SoldBy {
				display: none;
				padding: 2rem 0 0;
			}
		}

		td.product-name .product-quantity,
		td.product-name dl.variation dt.variation-SoldBy {
			color: $dark-pink;
		}

		td.product-name {
			.lead-time {
				color: $light-green;
				font-size: 13px;
			}
		}

		/*
		.select_shipping_method {
			position: relative;
			background: $white;
			border: 1px solid $light-gray2;
			border-radius: 0.5rem;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				right: 10px;
				z-index: 10;
				width: 0;
				height: 0;
				margin-left: -6px;
				margin-top: -3px;
				border-color: $dark-pink transparent transparent;
				border-style: solid;
				border-width: 6px 6px 0;
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 2px;
				bottom: 2px;
				right: 2px;
				width: 30px;
				background: $white;
			}

			select {
				border-color: transparent;
			}
		}
		*/
		.select_shipping_method {
			select {
				opacity: 0;
			}
			.select2-container {
				width: 100%;
			}
		}
	}

	#content {
		.coupon {
			label {
				text-align: left;
			}

			#coupon_code {
				width: 110px;
			}

			.button {
				@include media-breakpoint-down($bp-down) {
					padding: 1.558rem 1rem;
					font-size: 14px;
				}
			}
		}
	}
}

//ms_addresses_popup

/*
#ms_shipping_addresses_field {
	overflow: hidden;
	height: 0;
	margin: 0;
}
#shipping_first_name_field {
	position: relative;
}
#ms_addresses_popup {
	position: absolute;
	z-index: 100;
	overflow: hidden;
	width: 100%;
	height: 0;
	margin: 3px 0 0;

	&.on {
		height: auto;
	}
}
#ms_addresses_popup_inner {
	width: 100%;
	padding: 2rem;
	border-radius: 0.5rem;
	background: $dark-gray;
	font-size: 1.2rem;
	color: $white;

	b {
		display: block;
		font-weight: 500;
	}
	.ms_address_option {
		margin: 0 0 2rem;
		span {
			cursor: pointer;
		}
	}

	.ms_address_manage {
		padding-top: 2rem;
		border-top: 1px solid $border-gray;

		a {
			text-decoration: none;
			color: $white;
		}
	}
}
*/
#order_ups_account_number_field,
#order_ups_delivery_speed_field,
#order_fedex_account_number_field,
#order_fedex_delivery_speed_field {
	.optional {
		color: transparent;

		&:before {
			content: "*";
			color: $red;
			font-weight: 700;
		}
	}
}
#order_shipment_account_type_field {
	input[type="radio"] + label:before {
		border-radius: 50%;
	}
	input[type="radio"]:checked + label:before {
		background: #fff;
		content: "•";
		text-align: center;
		font-family: Arial, sans-serif;
		font-size: 6rem;
		line-height: 2.8rem;
		color: $pink;
	}
}

.wc-saved-payment-methods {
	padding-left: 0;
	padding-inline-start: 0;
}

.chamfr-checkout {
	display: flex;
	flex-wrap: wrap;

	.woocommerce-notices-wrapper {
		flex: 0 0 100%;
	}

	> .checkout-steps {
		flex: 0 0 70%;
		@include media-breakpoint-down(md) {
			flex: 0 0 100%;
		}
	}

	&-sidebar {
		flex: 0 0 30%;
		padding-left: $spacer;
		position: relative;

		@include media-breakpoint-down(md) {
			display: none;
		}

		.woocommerce-info {
			padding: 0;
			margin: 0;
			border: none;
			background-color: transparent;
			font-size: 14px;
			padding-left: $spacer;

			&::before {
				display: none;
			}
		}
	}

	&-order-summary {
		background-color: $white;
		padding: $spacer;
		border-radius: 16px;
		margin-bottom: $spacer;

		h4 {
			font-size: 22px;
			font-weight: 700;
			border-bottom: 1px solid $chamfr-gray;
			padding-bottom: calc($spacer / 2);
			margin-bottom: calc($spacer / 2);
		}

		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;

			li {
				display: flex;
				justify-content: space-between;
				margin-bottom: calc($spacer / 4);

				> span:first-child {
					color: $chamfr-dark-gray;
				}

				> span:last-child {
					font-weight: 700;
				}
			}
		}

		.shipping-note {
			font-size: 12px;
		}

		.total {
			border-top: 1px solid $chamfr-gray;
			margin-top: calc($spacer / 2);
			padding-top: calc($spacer / 2);
			font-size: 20px;
			font-weight: bold;
			display: flex;
			justify-content: space-between;
		}
	}

	&-items {
		background-color: $white;
		padding: $spacer;
		border-radius: 16px;
		margin-bottom: $spacer;

		h4 {
			font-size: 22px;
			font-weight: 700;
			padding-bottom: 0;
			margin-bottom: 0;
			position: relative;

			&:after {
				content: "";
				width: 24px;
				height: 14px;
				display: block;
				position: absolute;
				right: 0;
				top: 3px;
				cursor: pointer;
				background-image: url("data:image/svg+xml,%3Csvg width='24' height='14' viewBox='0 0 24 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 1.5L12 11.5L2 1.5' stroke='%23111111' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
			}

			span {
				font-weight: 400;
			}
		}

		.edit-cart {
			text-align: right;
			font-size: 12px;

			a {
				color: $black;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			font-size: 14px;
			line-height: 16.45px;
			display: none;

			li {
				margin-top: calc($spacer / 2);
				padding-top: calc($spacer / 2);
				border-top: 1px solid $chamfr-gray;
				display: flex;
				gap: calc($spacer / 2);

				.image {
					flex: 0 0 60px;

					img {
						width: 45px;
						height: auto;
					}
				}

				.description {
					flex-grow: 1;

					.name {
						font-weight: 700;
					}

					.sold-by,
					.item-number {
						margin-bottom: 3px;
						color: $chamfr-dark-gray;
					}

					.qty {
						color: $dark-pink;
					}
				}

				.subtotal {
					flex: 0 0 80px;
					font-weight: 700;
					text-align: right;
				}
			}
		}

		&.expand {
			h4 {
				&:after {
					transform: rotate(180deg);
				}
			}

			ul {
				display: block;
			}
		}
	}
}
