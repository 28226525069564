/* stylelint-disable no-descending-specificity */
.woocommerce td.product-name .wc-item-meta .wc-item-meta-label {
	display: inline;
	float: none;
	clear: none;
	margin: 0;
}
.woocommerce-order-details {
	margin: 5rem 0 0;

	&-table {
		ul.wc-item-meta {
			list-style: none;
			margin: 0;
			padding: 0;

			.wc-item-meta-label {
				display: inline;
				clear: none;
				margin: 0;
			}

			p {
				display: inline;
				margin: 0;
			}
			.contact-seller {
				display: block;
			}
			.button {
				margin-top: 2rem;
				height: 4rem;
				@include tiny-shadow();
				font-size: 1.2rem;
				line-height: 4rem;
			}
		}
		.product-total {
			text-align: right;
		}

		+ p {
			margin: 3rem 0;

			a {
				@extend .btn;
			}
		}
	}
}
.woocommerce-customer-details {
	margin-top: 5rem;

	.woocommerce-addresses {
		display: block;
		@include media-breakpoint-up( hd ) {
			display: flex;
		}
		@include media-breakpoint-only( lg ) {
			display: flex;
		}
		.woocommerce-address,
		.woocommerce-address:last-of-type {
			@include media-breakpoint-up( $bp ) {
				padding: 0 0 0 7rem;
				flex: 0 0 50%;
			}
			p {
				margin: 0;
				&.woocommerce-customer-details--phone {
					margin-top: 2rem;
				}
			}
		}
	}
}
