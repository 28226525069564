.checkout-step-addresses {
	#ms_shipping_addresses_field {
		display: none;
	}

	.saved-addresses {
		padding-bottom: 48px;

		.list {
			display: flex;
			// max-height: 500px;
			// overflow-y: scroll;
			// scrollbar-color: $chamfr-gray transparent;
			flex-wrap: wrap;

			.address {
				flex: 0 0 33%;
				padding-right: $spacer;
				padding-bottom: $spacer;

				@include media-breakpoint-down(sm) {
					flex: 0 0 100%;
					padding-right: 0;
				}

				input[type="radio"] + label {
					@include typo-label();
					width: 100%;
					height: 100%;
					position: relative;
					background-color: $chamfr-lightest-gray;
					border-radius: 8px;
					border: 2px solid $chamfr-lightest-gray;
					padding: 16px;
					margin: 0;

					&::before {
						margin: 0 0 8px 0;
					}

					.default {
						position: absolute;
						top: 22px;
						left: 56px;
						font-weight: 600;

						a {
							display: none;
							font-weight: 400;

							&.loading {
								font-size: 0;
								transform: translateY(-9px);
								@include loader-dots();
							}
						}
					}

					.contact {
						margin: 16px 0;
					}
				}

				input[type="radio"]:checked + label {
					border-color: $chamfr-pink;

					&::before {
						opacity: 1;
					}

					.default {
						position: absolute;
						top: 22px;
						left: 56px;
					}
				}

				&.default-address {
					.default {
						span {
							display: inline;
						}
						a {
							display: none;
						}
					}
				}
				&:not(.default-address) {
					.default {
						span {
							display: none;
						}
					}
					input[type="radio"]:checked + label {
						.default {
							a {
								display: inline-block;
							}
						}
					}
				}
			}
		}

		.new {
			> a {
				@include button-outlined();
			}
		}

		.error {
			color: $chamfr-red;

			p {
				margin-bottom: 16px;
			}
		}
	}
}

.chamfr-address-form {
	.has-error {
		input,
		select {
			border-color: $chamfr-red;
		}
	}

	.fields {
		display: flex;
		margin: 0 -16px 36px -16px;
		flex-wrap: wrap;

		p {
			flex: 0 0 50%;
			padding: 0 16px;

			@include media-breakpoint-down(sm) {
				flex: 0 0 100%;
			}

			abbr {
				text-decoration: none;

				&.required {
					color: $chamfr-red;
				}
			}
		}

		#shipping_country_field,
		#billing_country_field {
			flex-basis: 100%;

			> span {
				display: block;
				width: calc(50% - 16px);

				@include media-breakpoint-down(sm) {
					width: 100%;
				}
			}
		}

		#shipping_address_2_field,
		#billing_address_2_field {
			padding-top: 29px;
		}
	}

	.submit {
		@include button-primary();
		width: 300px;
		max-width: 100%;

		@include media-breakpoint-down(sm) {
			margin-bottom: 16px;
		}

		&.loading {
			font-size: 0;
			@include loader-dots();
		}
	}

	.loading {
		@include loader-dots();
	}

	.cancel {
		@include button-secondary();
		width: 300px;
		max-width: 100%;
	}

	.errors {
		color: $chamfr-red;
		list-style-type: none;
		padding: 0;
		margin: 0 0 16px;
	}
}
