// typography mixins to be used to style all the h1-h6, p etc.
@mixin h1 {
	@include headings-font();
	font-size: 24px;
	font-weight: 800;

	@include media-breakpoint-up(sm) {
		font-size: 38px;
	}
}

@mixin h2 {
	@include headings-font();
	font-size: 20px;
	font-weight: 700;

	@include media-breakpoint-up(sm) {
		font-size: 24px;
	}
}

@mixin h3 {
	@include headings-font();
	font-size: 18px;
	font-weight: 700;

	@include media-breakpoint-up(sm) {
		font-size: 20px;
	}
}

@mixin h4 {
	@include headings-font();
	font-size: 2.2rem;
	@include media-breakpoint-up(sm) {
		font-size: 2.6rem;
	}
}

@mixin h5 {
	@include headings-font();
	font-size: 1.8rem;
	@include media-breakpoint-up(sm) {
		font-size: 2.2rem;
	}
}

@mixin h6 {
	@include h5;
}
//-----------------------------------
@mixin p {
	font: 400 1.6rem/1.5 $content-font;

	@include media-breakpoint-down(xs) {
		font-size: 1.4rem;
		line-height: 1.75;
	}
}

@mixin subtext {
	font: 400 1.4rem/1.5 $content-font;
}

@mixin caption {
	font: 400 1.3rem/1.5 $content-font;
}

@mixin typo-label {
	font: 400 12px/1.3 $content-font;

	@include media-breakpoint-up(sm) {
		font-size: 14px;
	}
}
