.chamfr {
	&-service-partners {
		margin-bottom: 15rem;

		.summary {
			max-width: 64rem;
			margin-bottom: 4rem;
			//font-size: 1.8rem;
			//
			//@include media-breakpoint-down( xs ) {
			//	font-size: 1.6rem;
			//}
		}

		.select2 {
			max-width: 100%;
		}
	}

	&-service-partners-featured {
		display: flex;
		padding: 2rem 2rem 9rem;
		align-items: flex-start;

		@include media-breakpoint-down( md ) {
			padding: 0 0 3rem;
			display: block;
		}

		.logo {
			flex: 0 0 60%;
			position: relative;

			//@include media-breakpoint-down( lg ) {
			//	flex: 0 0 40%;
			//}

			&-img {
				overflow: hidden;
				border-radius: 0.5rem;
				background: radial-gradient( closest-side, #fff, #e0e0e0 );
			}

			img {
				width: 100%;
			}

			&:before {
				content: '';
				display: block;
				position: absolute;
				z-index: -1;
				bottom: -2.6rem;
				left: -2rem;
				width: 54rem;
				height: 22.5rem;
				background: url( ../images/dots.svg ) repeat left bottom;
				opacity: 0.5;
				@include media-breakpoint-down( lg ) {
					width: 32rem;
				}
				@include media-breakpoint-down( md ) {
					height: 24rem;
					width: 24rem;
				}
				@include media-breakpoint-down( xs ) {
					bottom: -1.2rem;
					left: -1rem;
					height: 12rem;
					background-size: 25%;
				}
			}
		}

		.summary {
			margin-left: 4rem;
			font-size: 1.8rem;

			@include media-breakpoint-down( xs ) {
				font-size: 1.4rem;
				margin: 3rem 0 0 0;
			}

			.website {
				@extend .btn;
			}
		}
	}

	&-drawers {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;

		@include media-breakpoint-down( md ) {
			display: block;
		}

		&-nav {
			margin-top: 2em;
			margin-right: 1rem;
			flex: 0 0 calc(30% - 1rem);

			#chamfr_drawers_filter_clear {
				display: none;
				margin-bottom: 1rem;

				&.show {
					display: block;
				}
			}

			&-filter-wrapper {
				margin-top: 2rem;

				.select2-container--default {

					.select2-selection__clear {
						display: none;
					}

					.select2-selection--multiple {
						height: auto;
						min-height: 5rem;

						.select2-selection__rendered {
							li {
								margin-top: 1rem;
								margin-left: 1rem;
							}
						}
						li.select2-selection__choice {
							line-height: inherit;
							color: #000000;
						}
					}
				}
			}

			&-searchbox {
				position: relative;

				&-field {
					width: 100%;
					max-width: 100%;
					height: auto;
					padding: 0.9rem 4rem 0.8rem 1rem;
					background-color: #fafafa;
					border-radius: 0.2rem;
					border: none;
					appearance: none;
					@include tiny-shadow();
					/* stylelint-disable-next-line function-url-quotes */
					font-size: 1.4rem;
					line-height: 1.36;
					@include media-breakpoint-up( $bp-m ) {
						width: 300px;
					}
				}

				&-submit {
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					width: 3.5rem;
					background-image: url( "data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A" );
					background-position: 1.2rem 1.9rem;
					background-size: 12px;
					background-repeat: no-repeat;
					background-color: #e11d73;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					border-top-right-radius: 0.2rem;
					border-bottom-right-radius: 0.2rem;
				}
			}
		}

		.drawer {
			flex: 0 0 70%;
		}

		.companies {
			display: flex;
			flex-wrap: wrap;

			margin: 10px -10px;

			.company {
				flex: 0 0 50%;
				display: flex;
				align-items: stretch;
				width: 50%;
				max-width: 50%;
				padding: 10px;
				color: $dark-gray;

				@include media-breakpoint-down( sm ) {
					flex: 0 0 100%;
					width: 100%;
					max-width: 100%;
				}

				> div {
					flex: 0 0 100%;
					background-color: $white;
					border-radius: 10px;
					position: relative;
					padding-bottom: 2rem;

					display: grid;
					grid-template-rows: auto 1fr;

					@include media-breakpoint-up( md ) {
						padding-bottom: 5rem;
					}
				}

				.logo {
					background-color: #e0e0e0;
					height: 13rem;
					padding: 20px;
					flex: 0 0 100%;
					border-top-left-radius: 10px;
					border-top-right-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					img {
						width: auto;
						height: auto;
						max-width: 100%;
						max-height: 100%;
					}
				}

				.details {
					padding: 2rem 3rem;

					display: flex;
					flex-direction: column;
					margin-bottom: 4.5rem;

					@include media-breakpoint-down( md ) {
						margin-bottom: 6.2rem;
					}

					@include media-breakpoint-down( sm ) {
						margin-bottom: 9rem;
					}

					.type {
						color: transparentize( $dark-gray, 0.5 );
						font-size: 1.4rem;
						letter-spacing: 0.04em;
					}

					.links {
						a {
							position: relative;
							text-decoration: none;
							padding: 1rem;
							margin-right: 1rem;
							font-size: 1.2rem;
							margin-bottom: 1rem;

							@include media-breakpoint-down( xs ) {

							}

							&:hover {

							}

							&:last-child {
								margin-right: 0;
							}

							&:before {

							}

							&.shop {
								color: $white;
								background-color: $pink;
								border: 2px solid $pink;
								box-shadow: none;
							}
							&.rfq {
								color: $pink;
								background-color: $white;
								border: 2px solid $pink;
								box-shadow: none;
							}
						}
					}
				}

				h4 {
					margin: 0.5rem 0 1.5rem;
					font-size: 2rem;
					font-weight: 400;
					@include media-breakpoint-up( sm ) {
						font-size: 2.2rem;
					}

					+ p {
						margin-top: 2rem;
					}
				}

				.details__bottom {
					overflow: hidden;
					min-height: 3.5rem;
					padding-top: 3rem;
					position: absolute;
					bottom: 2rem;

					@include media-breakpoint-up( md ) {

					}
				}
				.website {
					display: inline-block;
					float: left;
					margin-top: 0.7rem;
					line-height: 1.2;
					text-decoration: underline;
					text-underline-position: under;
					//text-underline-offset: 0.3rem;
					color: $dark-pink;

					&:after {
						content: ' \2192';
					}
					&:hover {
						text-decoration: none;
						//border-color: transparent;
					}
				}
				.qcb {
					margin: 1rem 0px;

					span {
						display: inline-block;
						margin-left: 0.7rem;

						img {
							width: auto;
							height: 24px;
							&.fda {
								height: 12px;
							}
						}
					}
				}
			}
		}
	}

	&-sp-t2 {
		@include media-breakpoint-up( md ) {
			@include make-row();
			margin-left: - calc($spacer / 2);
			margin-right: - calc($spacer / 2);
			padding: 2rem 2rem 5rem;
		}

		color: white;

		&-single {
			margin-bottom: $spacer;
			display: flex;

			@include media-breakpoint-up( md ) {
				@include make-col-ready();
				@include make-col( 6 );
				padding-left: calc($spacer / 2);
				padding-right: calc($spacer / 2);
				margin-bottom: 0;
			}

			.inner {
				color: $text-black;
				background-color: $white;
				border-radius: 1rem;
				padding: $spacer;
				display: flex;
			}
		}

		&-image {
			flex: 0 0 25%;
		}

		&-content {
			position: relative;
			padding-bottom: 5rem;

			.btn {
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}

		&-description {
			margin-bottom: $spacer;
			margin-right: $spacer;
		}
	}
}
