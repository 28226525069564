.chamfr-cart-control-links {
	display: flex;
	justify-content: end;
	font-size: 14px;

	> a {
		margin-left: 2rem;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}

		&:first-child {
			margin-left: 0;
		}
	}

	> .share-cart,
	> .download-quote,
	> .sign-in-for-quote {
		display: inline-block;
		padding-left: 24px;
		position: relative;
		color: $pink;

		&:before {
			display: inline-block;
			position: absolute;
			left: 0;
			top: 0;
			content: "";
			width: 20px;
			height: 20px;
		}
	}
	.share-cart:before {
		background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.16335 5.10742H1.09668V19.1074H15.0967V13.0408' stroke='%23E11D73' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.46606 10.6418L15.4661 4.64185' stroke='%23E11D73' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.89255 3.36911H16.6309V10.1074' stroke='%23E11D73' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
	}
	.sign-in-for-quote:before,
	.download-quote:before {
		height: 17px;
		top: 2px;
		background-image: url("data:image/svg+xml,%3Csvg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7.49512V11.9657C1 14.1748 2.79086 15.9657 5 15.9657H15C17.2091 15.9657 19 14.1748 19 11.9657V7.49512' stroke='%23E11D73' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.085 1.14209V9.61268' stroke='%23E11D73' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.765 6.43628L10.0003 11.201L5.2356 6.43628' stroke='%23E11D73' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
	}
}
