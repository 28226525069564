.soc-links {
	padding: 6rem 0 0;
	text-align: center;

	@include media-breakpoint-up( $bp ) {
		padding: 2rem 0 0;
		text-align: left;
	}
}
.social-links {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 2.5rem;

	@include media-breakpoint-up( $bp ) {
		justify-content: flex-end;
		height: 100%;
		margin-top: 0;
	}

	a {
		display: block;
		width: 6rem;
		height: 6rem;
		margin: 0 0.5rem;
		border-radius: 50%;
		border: 2px solid $dark-pink;
		font-size: 3rem;
		line-height: 5.6rem;
		text-align: center;
		color: $dark-pink;
		@include media-breakpoint-up( $bp ) {
			margin: 0 0 0 2.6rem;
		}
	}
}
