.contact {
	&__row {
		display: flex;
		flex-wrap: wrap;
		margin-right: $grid-gutter-margin;
		margin-left: $grid-gutter-margin;
	}

	&__col-narrow {
		position: relative;
		width: 100%;
		padding-right: $grid-gutter-padding;
		padding-left: $grid-gutter-padding;

		@include media-breakpoint-up( hd ) {
			flex: 0 0 20%;
			max-width: 20%;
		}
	}

	&__col-wide {
		position: relative;
		width: 100%;
		padding-right: $grid-gutter-padding;
		padding-left: $grid-gutter-padding;

		@include media-breakpoint-up( hd ) {
			flex: 0 0 60%;
			max-width: 60%;
		}
	}

	&__intro {
		@include media-breakpoint-up( hd ) {
			margin: 0 0 4rem;
		}
	}

	&__sidebar {
		margin: 4rem 0 3rem;
		padding: 3rem;
		@include gradient-light();
		border-radius: 0.5rem;
		@include medium-shadow();
		color: $black;

		@include media-breakpoint-up( sm ) {
			margin: 4rem 0 6rem;
		}

		&__link {
			margin-top: 3rem;

			a {
				text-decoration: none;
				color: $dark-pink;

				&:hover,
				&:focus,
				&:active {
					text-decoration: underline;
					color: $dark-pink;
				}
			}
		}
	}

	&__form {
		margin: 0 0 9rem;
	}
}
