$content-max-width: 70rem;

.single {
	&__container {
		position: relative;
		max-width: $content-max-width;
		margin: 5rem auto 0;

		@each $tag, $size in $font-size {
			#{$tag} {
				font-size: $size;
			}
		}

		@include media-breakpoint-down( md ) {
			@each $tag, $size in $font-size-mobile {
				#{$tag} {
					font-size: $size;
				}
			}
		}
	
		// h1 {
		// 	@include h2;
		// 	margin-bottom: 2.5rem;
		// }

		// h2,
		// h3 {
		// 	@include h4;
		// 	@include content-font();
		// 	margin: 5.5rem 0 1.75rem;
		// }
		// h4,
		// h5 {
		// 	margin-top: 3.5rem;
		// }

		ul,
		ol {
			margin: 0 0 2rem;
			padding: 0 0 0 4rem;

			li {
				margin: 0;
			}
		}
	}

	&__categories {
		margin: 0 0 0.7rem;
		font-size: 1.4rem;
		font-weight: 600;
		opacity: 0.5;

		a {
			color: inherit;
		}
	}

	&__meta {
		display: flex;
		align-items: stretch;
		margin: 0 0 2.5rem;

		&__info {
			display: flex;
			flex-flow: column;
			justify-content: center;
			padding-left: 1.5rem;
		}
		&__author {
			margin: 0 0 0.7rem;
		}
		&__date {
			opacity: 0.5;
		}
	}
	&__avatar {
		overflow: hidden;
		width: 6rem;
		height: 6rem;
		border-radius: 50%;

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__bottom {
		margin: 5rem 0 0;
		padding: 3rem 0 0;
		border-top: 1px solid $middle-gray;
	}
	&__author {
		display: flex;
		align-items: stretch;
		margin: 0 0 5.5rem;

		&__info {
			display: flex;
			flex-flow: column;
			justify-content: center;
			padding-left: 1.5rem;
		}
		&__name {
			font-size: 1.4rem;
			margin: 0 0 0.5rem;
		}
		&__bio {
			font-size: 1.4rem;
			opacity: 0.5;
		}
	}
}
