/*  set desired standard shadows */
@mixin large-shadow() {
	box-shadow: 0 20px 60px transparentize( $black, 0.75 );
}

@mixin medium-shadow() {
	box-shadow: 0 10px 25px transparentize( $black, 0.85 );
}

@mixin medium-shadow2() {
	box-shadow: 0 10px 15px transparentize( $black, 0.85 );
}

@mixin small-shadow() {
	box-shadow: 0 3px 15px transparentize( $black, 0.75 );
}

@mixin tiny-shadow() {
	box-shadow: 0 3px 6px transparentize( $black, 0.85 );
}
