/* Popup Maker plugin */
.pum-theme-351 .pum-container,
.pum-theme-lightbox .pum-container {
	padding: 6rem 3rem 4rem;
	@include gradient-light();
	border: none;
	border-radius: 1rem;
	@include medium-shadow();

	@include media-breakpoint-up( $bp ) {
		padding: 5rem 7rem;
	}
}
.pum-theme-351 .pum-content + .pum-close,
.pum-theme-lightbox .pum-content + .pum-close {
	top: 1.8rem;
	right: 1.8rem;
	border: 2px solid $dark-gray;
	border-radius: 50%;
	box-shadow: none;
	text-shadow: none;
	background-color: transparent;
	font-family: Arial, sans-serif;
	font-size: 26px;
	color: $dark-gray;
}
.pum-theme-351 .pum-title,
.pum-theme-lightbox .pum-title {
	@include h2;
	text-shadow: none;
	margin: 0 0 2rem;
}
body .pum-theme-351 .pum-container,
body .pum-theme-lightbox .pum-container {
	@media only screen and ( min-width: 641px ) {
		div.ginput_container_name span {
			padding-right: 2rem;
		}
	}
}

/* Woocommerce Quick View plugin */
.pp_overlay {
	opacity: 0.46 !important;
}
div.pp_woocommerce .pp_content_container {
	padding: 6rem 2rem;
	@include gradient-light();
	border: none;
	border-radius: 1rem;
	@include medium-shadow();
	@include media-breakpoint-up( $bp ) {
		padding: 8.5rem 3rem;
	}
}
.pp_content_container .pp_left,
.pp_content_container .pp_right {
	padding: 0;
}
.post-type-archive-product .pp_woocommerce_quick_view .pp_content,
.tax-product_cat .pp_woocommerce_quick_view .pp_content {
	overflow-x: hidden;
	overflow-y: auto;
	width: 100% !important;
	max-height: 80vh;
	@include styled-scrollbar;
	padding: 0 1rem;
}
div.pp_woocommerce .pp_close {
	top: 1.5rem;
	right: 1.5rem;
	height: 3rem;
	width: 3rem;
	background-color: $dark-gray;
	border-radius: 50%;
	border: 2px solid $dark-gray;
	transition: all ease-in-out 0.2s;
	font-size: 2.6rem !important;
	line-height: 1;
	text-shadow: none;
	color: $light-gray !important;
	&:before {
		@media only screen and ( max-width: 768px ) {
			font-size: 2.6rem !important;
		}
	}
}
.post-type-archive-product .pp_woocommerce_quick_view .pp_close,
.tax-product_cat .pp_woocommerce_quick_view .pp_close {
	display: block !important;
}
.woocommerce.quick-view.single-product {
	overflow: visible;
	padding: 0;
	@include media-breakpoint-up( sm ) {
		padding: 0 3rem;
	}

	&.single-product-with-thumb {
		padding-left: 0;
		@include media-breakpoint-up( hd ) {
			padding-left: 15rem;
		}
	}

	.chamfr-single-product__thumb {
		@include media-breakpoint-up( hd ) {
			top: 0;
			transform: none;
		}
	}
	.woocommerce-product-gallery .flex-viewport {
		box-shadow: none;
		@include media-breakpoint-up( sm ) {
			@include medium-shadow();
		}
	}
	.chamfr-single-product__header h2,
	.chamfr-single-product__details h2,
	.chamfr-single-product__summary h2 {
		margin: 0 0 3rem;
		font-size: 1.5rem;
		@include media-breakpoint-up( sm ) {
			font-size: 3rem;
		}
	}
	.chamfr-single-product__details {
		h2,
		.quick-view-detail-button {
			display: block;
			@include media-breakpoint-up( lg ) {
				display: none;
			}
		}
	}
	.chamfr-single-product__summary {
		margin-top: 4.6rem;
		&.chamfr-single-product__summary--cfrq {
			margin-top: 0px;
		}

		h2,
		.quick-view-detail-button {
			display: none;
			@include media-breakpoint-up( lg ) {
				display: block;
			}
		}
	}
	.chamfr-single-product__cart {
		margin: 0 0 3rem;
		border-radius: 10px;

		.cart-qty {
			order: -1;
		}
		#wc_bis_product_form {
			text-align: right;
		}
	}
	.contact-seller .button {
		overflow: hidden;
	}
	div.product div.images .flex-control-thumbs {
		@include media-breakpoint-down( sm ) {
			margin-left: -0.3rem;
			margin-right: -0.3rem;
		}
		li {
			@include media-breakpoint-down( sm ) {
				padding: 0.3rem;
			}
		}
	}
}
/* stylelint-disable-next-line no-descending-specificity */
.quick-view-detail-button {
	margin: 0 0 3rem;
	text-align: left;

	a {
		text-decoration: none;
	}
}
div.pp_woocommerce .pp_details {
	max-width: 100%;
}
