.wp-block-image {
	margin-top: 7rem;
	margin-bottom: 5rem;

	@include media-breakpoint-up( $bp ) {
		margin-left: -5rem;
		margin-right: -5rem;
	}

	figcaption {
		font-size: 1.6rem;
		font-style: italic;
		color: $middle-gray;
	}
}
