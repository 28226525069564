.added-to-cart {
	&-overlay {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 3000;
		background: transparentize($color: $black, $amount: 0.85);
	}

	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 20000;
	background-color: $white;
	width: 100%;
	max-width: 80rem;

	@include medium-shadow();

	.close {
		font-size: 0;
		width: 1.2rem;
		height: 1.2rem;
		display: inline-block;
		background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10.9999 10.9999' stroke='white' stroke-linecap='round'/%3E%3Cpath d='M11 1L1.00008 10.9999' stroke='white' stroke-linecap='round'/%3E%3C/svg%3E");
	}

	.heading {
		color: $white;
		background-color: $light-green;
		padding: 2rem;
		font-size: 1.6rem;
		font-weight: 700;
		line-height: 1;
	}

	.content {
		padding: 2rem;
		display: flex;
		gap: 2rem;

		@include media-breakpoint-down(md) {
			display: block;
		}
	}

	.item-image {
		img {
			max-width: 6rem;
			height: auto;
		}
	}

	.item-data {
		font-size: 1.4rem;
		color: $middle-gray;

		.name {
			font-size: 1.6rem;
			color: $black;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
	}

	.actions {
		.btn {
			text-wrap: nowrap;
			width: 100%;
			box-shadow: none;
		}
	}
}
