.chamfr-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #2b2b2b50;

	.popup-content {
		position: absolute;
		background-color: $chamfr-white;
		padding: 36px;
		width: 100%;
		max-width: 840px;
		height: 100%;
		max-height: 90vh;
		overflow-y: scroll;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.close {
			font-size: 0;
			width: 12px;
			height: 12px;
			background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10.9999 10.9999' stroke='%232B2B2B' stroke-linecap='round'/%3E%3Cpath d='M11 1L1.00008 10.9999' stroke='%232B2B2B' stroke-linecap='round'/%3E%3C/svg%3E%0A");
		}
	}
}
