@charset "UTF-8";
/* Load bootstrap with custom variables */
/* Override breakpoints as we need more for larger screens */
/* Load SCSS style theme parts */
/* Colors */
/* Fonts */
/* Other */
/* Slick slider variables  See /src/scss/partials/components/slick.scss */
/* GForms constants */
/* Slick constants */
/**
 * Function to make svg-load postcss-inlinde-svg plugin working in SCSS
 *
 * Usage:
 *   background-image: svg-load('../path/to/file.svg', '#FILLCO', '#STROKE');
 *
 * Default directory to search images: src/icons
 */
/**
 * Back compat to suppress Bootstrap deprecation warning
 */
/* Needed for multiple width's layoyt eg. standard wide container, middle, small containers.
@mixin  make-negative-margins-from-smaller-to-middle(){
  @include media-breakpoint-up(lg){
    max-width: 100vw;
    margin-left: ($middle-single-container-max-width - $smaller-single-container-max-width) / -2;
    margin-right: ($middle-single-container-max-width - $smaller-single-container-max-width) / -2;
  }
}

@mixin  make-negative-margins-from-smaller-to-large(){

  @include media-breakpoint-up(xl){
    max-width: 100vw;
    margin-left: ($large-single-container-max-width - $smaller-single-container-max-width) / -2;
    margin-right: ($large-single-container-max-width - $smaller-single-container-max-width) / -2;
  }
  @include media-breakpoint-up(hd){
    max-width: 100vw;
    margin-left: ($large-single-container-max-width-hd - $smaller-single-container-max-width) / -2;
    margin-right: ($large-single-container-max-width-hd - $smaller-single-container-max-width) / -2;
  }
}
*/
/* HTML: <div class="loader"></div> */
@keyframes ldots {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}
/*  set desired standard shadows */
/* stylelint-disable no-descending-specificity, function-comma-space-after */
html {
  font-size: 10px;
}

/* Prevent horizontal scrolling */
html,
body {
  overflow-x: hidden;
}

html.no-scroll {
  overflow: hidden;
}
@media (min-device-width: 1025px) {
  html.no-scroll {
    padding-right: 15px;
  }
}

/* Input elements */
button,
input[type=button],
input[type=submit],
input[type=reset] {
  outline: 0 !important;
  border: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}
*:hover, *:focus, *:active {
  outline: none;
}

/* Typography */
body {
  width: 100%;
  margin: 0;
  color: #000;
  background-color: #ddd;
  background-image: url(../images/bg-light.svg);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  font: 400 1.6rem/1.5 "Public Sans", sans-serif;
}
@media (max-width: 359.98px) {
  body {
    font-size: 1.4rem;
    line-height: 1.75;
  }
}
body.body-style--light {
  color: #000;
  background-color: #ddd;
  background-image: url(../images/bg-light.svg);
}
body.body-style--dark {
  color: #fff;
  background-color: #0f0f0f;
  background-image: url(../images/bg-dark.svg);
}

b,
strong {
  font-weight: 700;
}

h1 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 24px;
  font-weight: 800;
}
@media (min-width: 576px) {
  h1 {
    font-size: 38px;
  }
}

h2 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 20px;
  font-weight: 700;
}
@media (min-width: 576px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 18px;
  font-weight: 700;
}
@media (min-width: 576px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 2.2rem;
}
@media (min-width: 576px) {
  h4 {
    font-size: 2.6rem;
  }
}

h5 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
}
@media (min-width: 576px) {
  h5 {
    font-size: 2.2rem;
  }
}

h6 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
}
@media (min-width: 576px) {
  h6 {
    font-size: 2.2rem;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0 0 2rem;
}
@media (max-width: 767.98px) {
  h1,
h2,
h3,
h4,
h5 {
    margin-bottom: 1rem;
  }
}

p {
  margin: 0 0 2rem;
}
p:last-child {
  margin-bottom: 0;
}

a {
  transition: all 0.2s;
  text-decoration: none;
  color: #e11d73;
}
a:hover, a:focus, a:active {
  color: #e11d73;
}

.lead {
  font-size: 1.6rem;
}
@media (max-width: 767.98px) {
  .lead {
    font-size: 1.2rem;
  }
}

/* Typography Colors */
.t-color-white {
  color: #fff;
}

.t-color-gray {
  color: #313131;
}

/* Other */
img {
  max-width: 100%;
  height: auto;
}

.has-black-background-color {
  background-color: #000;
}

.has-black-color {
  color: #000;
}

.has-black-border-color {
  border-color: #000;
}

.has-white-background-color {
  background-color: #fff;
}

.has-white-color {
  color: #fff;
}

.has-white-border-color {
  border-color: #fff;
}

.has-dark-gray-background-color {
  background-color: #313131;
}

.has-dark-gray-color {
  color: #313131;
}

.has-dark-gray-border-color {
  border-color: #313131;
}

.has-light-gray-background-color {
  background-color: #eaeaea;
}

.has-light-gray-color {
  color: #eaeaea;
}

.has-light-gray-border-color {
  border-color: #eaeaea;
}

.has-light-gray2-background-color {
  background-color: #ddd;
}

.has-light-gray2-color {
  color: #ddd;
}

.has-light-gray2-border-color {
  border-color: #ddd;
}

.has-middle-gray-background-color {
  background-color: #6b6b6b;
}

.has-middle-gray-color {
  color: #6b6b6b;
}

.has-middle-gray-border-color {
  border-color: #6b6b6b;
}

.has-gray-background-color {
  background-color: #5b5b5b;
}

.has-gray-color {
  color: #5b5b5b;
}

.has-gray-border-color {
  border-color: #5b5b5b;
}

.has-pink-background-color {
  background-color: #e11d73;
}

.has-pink-color {
  color: #e11d73;
}

.has-pink-border-color {
  border-color: #e11d73;
}

.btn, .woocommerce-MyAccount-content .stripe-connect.light-blue, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .button, .chamfr-service-partners-featured .summary .website, .blog .sticky .post__link a,
.archive .sticky .post__link a, .woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button, .woocommerce-order-details-table + p a, body .gform_wrapper .gform_footer input.button,
body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer input[type=submit], .wp-block-button__link {
  display: inline-block;
  padding: 1.558rem 3rem;
  border-radius: 0.5rem;
  background: #e11d73;
  transition: all 0.3s ease-out;
  cursor: pointer;
  user-select: none;
  font: 600 1.6rem/1.2 "Public Sans", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.04em;
  color: #fff;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.25);
  /*
   Colored button which allows to select background and text color in tinyMCE
   just set rules like font\size\paddings\border radius if necessary.
   */
}
.btn:hover, .woocommerce-MyAccount-content .stripe-connect.light-blue:hover, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:hover, .wcv-grid .chamfr-vendor-dashboard-content .button:hover, .chamfr-service-partners-featured .summary .website:hover, .blog .sticky .post__link a:hover,
.archive .sticky .post__link a:hover, .woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover, .woocommerce-order-details-table + p a:hover, body .gform_wrapper .gform_footer input.button:hover,
body .gform_wrapper .gform_footer input[type=submit]:hover,
body .gform_wrapper .gform_page_footer input.button:hover,
body .gform_wrapper .gform_page_footer input[type=submit]:hover, .wp-block-button__link:hover, .btn:focus, .woocommerce-MyAccount-content .stripe-connect.light-blue:focus, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:focus, .wcv-grid .chamfr-vendor-dashboard-content .button:focus, .chamfr-service-partners-featured .summary .website:focus, .blog .sticky .post__link a:focus,
.archive .sticky .post__link a:focus, .woocommerce #respond input#submit:focus,
.woocommerce a.button:focus,
.woocommerce button.button:focus,
.woocommerce input.button:focus, .woocommerce-order-details-table + p a:focus, body .gform_wrapper .gform_footer input.button:focus,
body .gform_wrapper .gform_footer input[type=submit]:focus,
body .gform_wrapper .gform_page_footer input.button:focus,
body .gform_wrapper .gform_page_footer input[type=submit]:focus, .wp-block-button__link:focus, .btn:active, .woocommerce-MyAccount-content .stripe-connect.light-blue:active, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:active, .wcv-grid .chamfr-vendor-dashboard-content .button:active, .chamfr-service-partners-featured .summary .website:active, .blog .sticky .post__link a:active,
.archive .sticky .post__link a:active, .woocommerce #respond input#submit:active,
.woocommerce a.button:active,
.woocommerce button.button:active,
.woocommerce input.button:active, .woocommerce-order-details-table + p a:active, body .gform_wrapper .gform_footer input.button:active,
body .gform_wrapper .gform_footer input[type=submit]:active,
body .gform_wrapper .gform_page_footer input.button:active,
body .gform_wrapper .gform_page_footer input[type=submit]:active, .wp-block-button__link:active {
  text-decoration: none;
  color: #fff;
  background: #e11d73;
  border: none;
  outline: none;
}
.btn:disabled, .woocommerce-MyAccount-content .stripe-connect.light-blue:disabled, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:disabled, .wcv-grid .chamfr-vendor-dashboard-content .button:disabled, .chamfr-service-partners-featured .summary .website:disabled, .blog .sticky .post__link a:disabled,
.archive .sticky .post__link a:disabled, .woocommerce #respond input#submit:disabled,
.woocommerce a.button:disabled,
.woocommerce button.button:disabled,
.woocommerce input.button:disabled, .woocommerce-order-details-table + p a:disabled, body .gform_wrapper .gform_footer input.button:disabled,
body .gform_wrapper .gform_footer input[type=submit]:disabled,
body .gform_wrapper .gform_page_footer input.button:disabled,
body .gform_wrapper .gform_page_footer input[type=submit]:disabled, .wp-block-button__link:disabled {
  background: #eaeaea;
  box-shadow: none;
  cursor: default;
}
.btn:disabled:hover, .woocommerce-MyAccount-content .stripe-connect.light-blue:disabled:hover, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:disabled:hover, .wcv-grid .chamfr-vendor-dashboard-content .button:disabled:hover, .chamfr-service-partners-featured .summary .website:disabled:hover, .blog .sticky .post__link a:disabled:hover,
.archive .sticky .post__link a:disabled:hover, .woocommerce #respond input#submit:disabled:hover,
.woocommerce a.button:disabled:hover,
.woocommerce button.button:disabled:hover,
.woocommerce input.button:disabled:hover, .woocommerce-order-details-table + p a:disabled:hover, body .gform_wrapper .gform_footer input.button:disabled:hover,
body .gform_wrapper .gform_footer input[type=submit]:disabled:hover,
body .gform_wrapper .gform_page_footer input.button:disabled:hover,
body .gform_wrapper .gform_page_footer input[type=submit]:disabled:hover, .wp-block-button__link:disabled:hover, .btn:disabled:focus, .woocommerce-MyAccount-content .stripe-connect.light-blue:disabled:focus, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:disabled:focus, .wcv-grid .chamfr-vendor-dashboard-content .button:disabled:focus, .chamfr-service-partners-featured .summary .website:disabled:focus, .blog .sticky .post__link a:disabled:focus,
.archive .sticky .post__link a:disabled:focus, .woocommerce #respond input#submit:disabled:focus,
.woocommerce a.button:disabled:focus,
.woocommerce button.button:disabled:focus,
.woocommerce input.button:disabled:focus, .woocommerce-order-details-table + p a:disabled:focus, body .gform_wrapper .gform_footer input.button:disabled:focus,
body .gform_wrapper .gform_footer input[type=submit]:disabled:focus,
body .gform_wrapper .gform_page_footer input.button:disabled:focus,
body .gform_wrapper .gform_page_footer input[type=submit]:disabled:focus, .wp-block-button__link:disabled:focus {
  transform: none;
  box-shadow: none;
}
.btn-primary:disabled, .wp-block-button__link:disabled {
  background: #eaeaea;
  box-shadow: none;
  cursor: default;
}
.btn-primary:disabled:hover, .wp-block-button__link:disabled:hover, .btn-primary:disabled:focus, .wp-block-button__link:disabled:focus {
  transform: none;
  box-shadow: none;
}
.btn-secondary, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt, .woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
  background: #fff;
  color: #e11d73;
}
.btn-secondary:hover, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button:hover, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary:hover,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt:hover,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt:hover,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt:hover,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt:hover,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt:hover,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt:hover, .woocommerce #respond input#submit.alt:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover, .btn-secondary:focus, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button:focus, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary:focus,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt:focus,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt:focus,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt:focus,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt:focus,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt:focus,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt:focus, .woocommerce #respond input#submit.alt:focus,
.woocommerce a.button.alt:focus,
.woocommerce button.button.alt:focus,
.woocommerce input.button.alt:focus, .btn-secondary:active, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button:active, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary:active,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt:active,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt:active,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt:active,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt:active,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt:active,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt:active, .woocommerce #respond input#submit.alt:active,
.woocommerce a.button.alt:active,
.woocommerce button.button.alt:active,
.woocommerce input.button.alt:active {
  background: #fff;
  color: #e11d73;
}
.btn-secondary:disabled, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button:disabled, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary:disabled,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt:disabled,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt:disabled,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt:disabled,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt:disabled,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt:disabled,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt:disabled, .woocommerce #respond input#submit.alt:disabled,
.woocommerce a.button.alt:disabled,
.woocommerce button.button.alt:disabled,
.woocommerce input.button.alt:disabled {
  background: #eaeaea;
  box-shadow: none;
  cursor: default;
}
.btn-secondary:disabled:hover, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button:disabled:hover, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary:disabled:hover,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt:disabled:hover,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt:disabled:hover,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt:disabled:hover,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt:disabled:hover,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt:disabled:hover,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt:disabled:hover, .woocommerce #respond input#submit.alt:disabled:hover,
.woocommerce a.button.alt:disabled:hover,
.woocommerce button.button.alt:disabled:hover,
.woocommerce input.button.alt:disabled:hover, .btn-secondary:disabled:focus, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button:disabled:focus, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary:disabled:focus,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt:disabled:focus,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt:disabled:focus,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt:disabled:focus,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt:disabled:focus,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt:disabled:focus,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt:disabled:focus, .woocommerce #respond input#submit.alt:disabled:focus,
.woocommerce a.button.alt:disabled:focus,
.woocommerce button.button.alt:disabled:focus,
.woocommerce input.button.alt:disabled:focus {
  transform: none;
  box-shadow: none;
}
.btn-link {
  display: inline-block;
  transition: all 0.2s;
}
.btn-colored {
  position: relative;
}
.btn-colored:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  transition: all 0.3s;
}
.btn-colored:hover:after, .btn-colored:focus:after {
  background: rgba(0, 0, 0, 0.1);
}
.btn-outlined {
  background-color: transparent;
  color: #e11d73;
  border: 2px solid #e11d73;
}
.btn-outlined:hover, .btn-outlined:focus, .btn-outlined:active {
  text-decoration: none;
  color: #e11d73;
  background-color: transparent;
  border: 2px solid #e11d73;
  outline: none;
}

.wp-block-image {
  margin-top: 7rem;
  margin-bottom: 5rem;
}
@media (min-width: 992px) {
  .wp-block-image {
    margin-left: -5rem;
    margin-right: -5rem;
  }
}
.wp-block-image figcaption {
  font-size: 1.6rem;
  font-style: italic;
  color: #6b6b6b;
}

.wp-block-media-text.has-media-on-the-left .wp-block-media-text__content {
  padding-right: 0;
}
.wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
  padding-left: 0;
}
.wp-block-media-text__content {
  padding: 0;
}

.wp-block-pullquote {
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  text-align: left;
  padding: 0;
}
.wp-block-pullquote__citation, .wp-block-pullquote cite, .wp-block-pullquote footer {
  font: 400 1.8rem/1.6 "Public Sans", sans-serif;
  text-transform: none;
}

.wp-block-quote {
  border-left: none;
  margin: 0;
  padding-left: 0.5rem;
}

.chamfr-pattern-card {
  text-align: center;
  padding: 2rem;
  border-radius: 1rem;
  height: 100%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}
.chamfr-pattern-card .wp-block-image {
  margin: 0 0 2rem 0;
}
.chamfr-pattern-card .wp-block-image img {
  width: 12rem;
}
.chamfr-pattern-card h4 {
  color: #e11d73;
  text-transform: uppercase;
}
.chamfr-pattern-card-light {
  background-color: #fafafa;
  color: #000;
}
.chamfr-pattern-card-dark {
  background-color: #424142;
  color: #fff;
}

.chamfr-pattern-case-study {
  color: #fff;
  background-color: #424142;
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 2rem 0 0;
}

.chamfr-pattern-logo-gallery .wp-block-image {
  margin: 0;
}
.chamfr-pattern-logo-gallery img {
  max-width: 32rem;
  max-height: 8rem;
  width: 100%;
  height: auto;
}

body .gform_wrapper .gform_body ul.gform_fields .onboard-seller-company-form {
  margin-right: 20px;
}
body .gform_wrapper .gform_body ul.gform_fields .onboard-seller-company-form.gfmc-column50 {
  width: 55%;
}
body .gform_wrapper .gform_body ul.gform_fields .onboard-seller-company-form input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
  padding: 0.5rem;
  height: 3.6rem;
}
body .gform_wrapper .gform_body ul.gform_fields .onboard-seller-company-form li.gfield select {
  padding: 0.5rem;
  height: 3.6rem;
}
body .gform_wrapper .gform_body ul.gform_fields .onboard-seller-company-form .gfield {
  padding: 0.5rem 0;
}
body .gform_wrapper .gform_body ul.gform_fields .onboard-seller-company-form .show-password-input {
  top: 0.5em;
  right: 0.5em;
}
body .gform_wrapper .gform_body ul.gform_fields .onboard-seller-floating-checkout {
  background-color: #b7b7b7;
  padding: 20px;
  margin-top: -20px;
}
body .gform_wrapper .gform_body ul.gform_fields .onboard-seller-floating-checkout.gfmc-column50 {
  width: 40%;
}
body .gform_wrapper .gform_body ul.gform_fields .onboard-seller-floating-checkout .gfield {
  padding: 0.5rem 0;
}
body .gform_wrapper .gform_body ul.gform_fields .onboard-seller-billing-cycle-label {
  padding-bottom: 0;
}
body .gform_wrapper .gform_body ul.gform_fields .onboard-seller-billing-cycle-select {
  padding-top: 0;
}
body .gform_wrapper .gform_body ul.gform_fields .onboard-seller-billing-cycle-select .gchoice {
  margin-bottom: 0;
}

.membership-levels .wp-block-column .chamfr-seller-pricing-option {
  min-height: 300px;
  color: #fff;
  background-color: #000;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
}
.membership-levels .wp-block-column .chamfr-seller-pricing-option .caption {
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
}
.membership-levels .wp-block-column .chamfr-seller-pricing-option .price {
  font-size: 32px;
  margin-bottom: 0;
}
.membership-levels .wp-block-column .chamfr-seller-pricing-option .price em {
  font-size: 16px;
}
.membership-levels .wp-block-column .chamfr-seller-pricing-option .price-note {
  font-size: 12px;
}
.membership-levels .wp-block-column.premium {
  padding: 5px 5px 7px 5px;
  margin-top: -25px;
  border-radius: 20px;
  margin-bottom: 0px;
}
.membership-levels .wp-block-column.premium .chamfr-seller-pricing-option {
  min-height: 290px;
}
.membership-levels .wp-block-column.premium .most-popular {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  display: block;
  font-size: 14px;
}

.chamfr-seller-pricing-table thead,
.chamfr-seller-pricing-table tbody {
  border-top: 3px solid;
  border-bottom: 3px solid;
}
.chamfr-seller-pricing-table th,
.chamfr-seller-pricing-table td {
  border: none;
}
.chamfr-seller-pricing-table th {
  background: #e11d73;
  color: #fff;
  border-color: transparent;
  text-align: center;
  font-size: 120%;
}
.chamfr-seller-pricing-table tbody tr:nth-child(even) {
  background-color: #fff;
}
.chamfr-seller-pricing-table tbody tr:nth-child(odd) {
  background-color: #ddd;
}
.chamfr-seller-pricing-table tbody td {
  text-align: center;
}
.chamfr-seller-pricing-table.wide tbody tr:nth-child(even) {
  background-color: #fff;
}
.chamfr-seller-pricing-table.wide tbody tr:nth-child(odd) {
  background-color: #ddd;
}
.chamfr-seller-pricing-table.wide tbody tr.table-main-header {
  background-color: #000;
}
.chamfr-seller-pricing-table.wide tbody tr.table-main-header td {
  color: #e11d73;
  text-transform: uppercase;
  font-weight: bold;
}
.chamfr-seller-pricing-table.wide tbody tr.table-sub-header {
  background-color: #808080;
}
.chamfr-seller-pricing-table.wide tbody tr.table-sub-header td {
  color: #ffffff;
  font-weight: bold;
}
.chamfr-seller-pricing-table.wide tbody td {
  text-align: center;
  width: 10%;
}
.chamfr-seller-pricing-table.wide tbody td:nth-child(1) {
  text-align: left;
  width: 70%;
}

.block-spots .primary-spot {
  padding-bottom: 1rem;
}
.block-spots .secondary-spots {
  margin-left: -1rem;
  margin-right: -1rem;
}
.block-spots .secondary-spots .spot {
  padding: 1rem;
}
.block-spots .spot-image {
  width: 100%;
  height: auto;
  border-radius: 1rem;
}
.block-spots .spot-image-desktop {
  display: none;
}
@media (min-width: 576px) {
  .block-spots .spot-image-desktop {
    display: inline-block;
  }
}
.block-spots .spot-image-mobile {
  display: inline-block;
}
@media (min-width: 576px) {
  .block-spots .spot-image-mobile {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .block-spots .slick-slide .spot {
    opacity: 0.8;
    transform: scale(0.92);
    transition: all 300ms ease;
  }
  .block-spots .slick-center .spot {
    opacity: 1;
    transform: scale(1);
  }
}

.advantages-counter {
  position: relative;
  margin: 0 0 18rem;
  padding: 4rem 6rem;
  background: #000;
  border-radius: 0.5rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}
@media (max-width: 991.98px) {
  .advantages-counter {
    margin: 0 0 10rem;
    padding: 2rem 3rem;
  }
}
@media (min-width: 992px) {
  .advantages-counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.advantages-counter:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -2.5rem;
  right: -3rem;
  z-index: -1;
  width: 20rem;
  height: 16.5rem;
  background: url(../images/dots.svg) repeat bottom right;
  opacity: 0.5;
}
@media (min-width: 992px) {
  .advantages-counter:after {
    width: 51.9rem;
    height: 18rem;
  }
}
.advantages-counter__info {
  margin: 0 0 3.5rem;
}
@media (min-width: 992px) {
  .advantages-counter__info {
    flex: 0 1 38.6rem;
    width: 38.6rem;
    margin: 0 10rem 2rem 0;
  }
}
.advantages-counter__info h3 {
  margin: 0;
}
.advantages-counter__link {
  margin: 1.5rem 0 0;
}
.advantages-counter__link a {
  text-decoration: none;
  padding-bottom: 0.3rem;
}
.advantages-counter__link a:hover, .advantages-counter__link a:focus, .advantages-counter__link a:active {
  text-decoration: none;
  border-bottom: 1px solid #e11d73;
}
.advantages-counter__stats {
  flex: 0 2 100%;
}
@media (min-width: 768px) {
  .advantages-counter__stats {
    display: flex;
    justify-content: space-between;
  }
}
.advantages-counter .stat-item {
  margin: 0 auto;
  padding: 0 0 4.5rem;
  text-align: center;
}
@media (min-width: 768px) {
  .advantages-counter .stat-item {
    margin: 0;
    padding: 0 4rem;
  }
}
.advantages-counter .stat-item:last-of-type {
  padding: 0;
}
@media (min-width: 768px) {
  .advantages-counter .stat-item:last-of-type {
    padding: 0 0 0 4rem;
  }
}
.advantages-counter .stat-item .value {
  font-size: 5.6rem;
  font-weight: 600;
}
.advantages-counter .stat-item__description {
  font-size: 2.2rem;
}

.advantages {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000;
  padding: 0 0 6rem;
  margin: 0 0 18rem;
}
@media (max-width: 991.98px) {
  .advantages {
    margin: 0 0 10rem;
    padding: 2rem 2rem 3rem;
  }
}
@media (max-width: 991.98px) {
  .advantages {
    flex-direction: column;
  }
}
.advantages:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -2.5rem;
  right: -3rem;
  z-index: -1;
  width: 20rem;
  height: 16.5rem;
  background: url(../images/dots.svg) repeat bottom right;
  opacity: 0.5;
}
@media (min-width: 992px) {
  .advantages:after {
    width: 51.9rem;
    height: 18rem;
  }
}
.advantages__intro {
  padding: 0 0 4rem;
}
@media (min-width: 992px) {
  .advantages__intro {
    padding: 0 0 4rem;
  }
}
@media (max-width: 991.98px) {
  .advantages__intro {
    padding: 0 0 2rem;
  }
}
.advantages__intro h3 {
  text-align: center;
  padding-top: 3rem;
  margin-bottom: 0px;
}
@media (max-width: 991.98px) {
  .advantages__intro h3 {
    padding-top: 1rem;
  }
}
@media (max-width: 991.98px) {
  .advantages .col-lg-4:last-child .advantages__item {
    margin-bottom: 0;
  }
}
.advantages__item {
  margin: 0 auto 10rem;
  text-align: center;
}
@media (max-width: 991.98px) {
  .advantages__item {
    margin: 0 auto 5rem;
  }
}
@media (min-width: 992px) {
  .advantages__item {
    margin: 0;
  }
}
.advantages__item img {
  display: block;
  position: relative;
  margin: 0 auto;
  max-height: 5rem;
  object-fit: contain;
}
.advantages__item__txt {
  position: relative;
  margin: 4rem 4rem 0;
  font-size: 1.6rem;
  min-height: 10rem;
  padding-bottom: 2rem;
}
@media (max-width: 991.98px) {
  .advantages__item__txt {
    margin: 2rem 0 0;
    padding-bottom: 1rem;
  }
}
.advantages__item__author {
  color: #E11D72;
  font-size: 1.6rem;
  font-weight: bold;
}

.contact__row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.contact__col-narrow {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 1425px) {
  .contact__col-narrow {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.contact__col-wide {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 1425px) {
  .contact__col-wide {
    flex: 0 0 60%;
    max-width: 60%;
  }
}
@media (min-width: 1425px) {
  .contact__intro {
    margin: 0 0 4rem;
  }
}
.contact__sidebar {
  margin: 4rem 0 3rem;
  padding: 3rem;
  background: radial-gradient(ellipse at center, #fff 0%, #eaeaea 100%);
  border-radius: 0.5rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  color: #000;
}
@media (min-width: 576px) {
  .contact__sidebar {
    margin: 4rem 0 6rem;
  }
}
.contact__sidebar__link {
  margin-top: 3rem;
}
.contact__sidebar__link a {
  text-decoration: none;
  color: #9f325d;
}
.contact__sidebar__link a:hover, .contact__sidebar__link a:focus, .contact__sidebar__link a:active {
  text-decoration: underline;
  color: #9f325d;
}
.contact__form {
  margin: 0 0 9rem;
}

.block-features-wrapper > h3 {
  text-align: center;
  padding-bottom: 4rem;
}
@media (min-width: 992px) {
  .block-features-wrapper > h3 {
    margin: 0;
  }
}
@media (max-width: 991.98px) {
  .block-features-wrapper > h3 {
    padding-bottom: 1rem;
  }
}
.block-features-wrapper .block-features {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  background-color: #000;
  margin: 0 0 18rem;
}
@media (max-width: 991.98px) {
  .block-features-wrapper .block-features {
    margin: 0 0 10rem;
    padding: 2rem;
  }
}
@media (max-width: 991.98px) {
  .block-features-wrapper .block-features {
    flex-direction: column;
  }
}
.block-features-wrapper .block-features:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -2.5rem;
  right: -3rem;
  z-index: -1;
  width: 20rem;
  height: 16.5rem;
  background: url(../images/dots.svg) repeat bottom right;
  opacity: 0.5;
}
@media (min-width: 992px) {
  .block-features-wrapper .block-features:after {
    width: 51.9rem;
    height: 18rem;
  }
}
.block-features-wrapper .block-features-item {
  padding: 3rem;
  flex: 1 1;
}
@media (max-width: 991.98px) {
  .block-features-wrapper .block-features-item {
    padding: 1rem;
  }
}
@media (max-width: 991.98px) {
  .block-features-wrapper .block-features-item {
    padding-bottom: 3rem;
  }
  .block-features-wrapper .block-features-item:last-child {
    padding-bottom: 0.5rem;
  }
}
.block-features-wrapper .block-features-item-image {
  width: 100px;
  height: 100px;
  margin: 0 auto 3rem;
  position: relative;
}
@media (max-width: 991.98px) {
  .block-features-wrapper .block-features-item-image {
    margin: 0 auto 1rem;
  }
}
.block-features-wrapper .block-features-item-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.block-features-wrapper .block-features-item-title {
  text-align: center;
  color: #e11d73;
}
.block-features-wrapper .block-features-item-description {
  color: #fff;
}
.block-features-wrapper.chamfr-membership .block-features-item-title {
  font-size: 350%;
  font-weight: 700;
}

.home-hero {
  padding: 0 0 0;
}
@media (min-width: 992px) {
  .home-hero {
    display: flex;
    padding: 7rem 0 16rem 0;
  }
}
@media (max-width: 991.98px) {
  .home-hero {
    padding: 3rem 0 8rem 0;
  }
}
.home-hero__image {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  padding: 0 0 2rem 2rem;
}
@media (max-width: 991.98px) {
  .home-hero__image {
    padding: 0 0 1rem 1rem;
  }
}
@media (min-width: 992px) {
  .home-hero__image {
    flex: 0 0 55%;
    width: 55%;
    max-width: 55%;
  }
}
.home-hero__image:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 2rem;
  right: 0;
  left: 30%;
  background: linear-gradient(to right, rgba(34, 34, 34, 0) 0%, rgb(34, 34, 34) 100%);
  /* stylelint-disable-line */
  border-radius: 0 0.5rem 0.5rem 0;
  opacity: 0.6;
}
.home-hero__image__pattern {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -0.7rem;
  z-index: -1;
  width: 24rem;
  max-width: 75%;
  height: 15rem;
  max-height: 75%;
  background: url(../images/dots.svg) repeat bottom left;
  opacity: 0.5;
}
@media (min-width: 992px) {
  .home-hero__image__pattern {
    width: 52rem;
    height: 34rem;
  }
}
.home-hero__image__wrapper {
  overflow: hidden;
  height: 100%;
  border-radius: 0.5rem;
}
.home-hero__image img {
  display: block;
  width: 100%;
}
@media (min-width: 992px) {
  .home-hero__image img {
    height: 100%;
    object-fit: cover;
  }
}
.home-hero__text {
  position: relative;
  z-index: 2;
  flex: 0 0 100%;
  width: 100%;
  margin: -6rem 0 0;
  padding: 0 3rem;
}
@media (min-width: 992px) {
  .home-hero__text {
    flex: 0 0 45%;
    width: 45%;
    max-width: 45%;
    margin: 0;
    padding: 5rem 4rem 0 4rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 991.98px) {
  .home-hero__text p {
    margin-bottom: 0px;
  }
}
.home-hero__text h1 {
  font-size: 3.6rem;
  font-weight: 700;
  top: 1rem;
  right: 2rem;
  z-index: 2;
  margin-bottom: 2rem;
  white-space: normal;
  left: -5rem;
}
@media (min-width: 992px) {
  .home-hero__text h1 {
    position: relative;
    margin-left: -10rem;
    z-index: 2;
    margin-bottom: 2rem;
    font-size: 5.6rem;
  }
}
.home-hero__btn {
  margin-top: 3rem;
}
@media (max-width: 480px) {
  .home-hero__btn .btn, .home-hero__btn .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .home-hero__btn .stripe-connect.light-blue, .home-hero__btn .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .home-hero__btn .wcv-button, .home-hero__btn .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .home-hero__btn .button, .home-hero__btn .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .home-hero__btn .website, .home-hero__btn .blog .sticky .post__link a, .blog .sticky .post__link .home-hero__btn a,
.home-hero__btn .archive .sticky .post__link a,
.archive .sticky .post__link .home-hero__btn a, .home-hero__btn .woocommerce #respond input#submit, .woocommerce #respond .home-hero__btn input#submit,
.home-hero__btn .woocommerce a.button,
.woocommerce .home-hero__btn a.button,
.home-hero__btn .woocommerce button.button,
.woocommerce .home-hero__btn button.button,
.home-hero__btn .woocommerce input.button,
.woocommerce .home-hero__btn input.button, .home-hero__btn .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .home-hero__btn a, .home-hero__btn body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .home-hero__btn input.button,
.home-hero__btn body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .home-hero__btn input[type=submit],
.home-hero__btn body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .home-hero__btn input.button,
.home-hero__btn body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .home-hero__btn input[type=submit], .home-hero__btn .wp-block-button__link {
    height: auto;
    padding: 1rem 2rem;
    line-height: 1.2;
  }
}
@media (max-width: 991.98px) {
  .home-hero__btn {
    margin-top: 2rem;
  }
}
.home-hero__search {
  margin-left: -14rem;
  width: calc(100% + 7rem);
  margin-top: 4rem;
}
.home-hero__search form {
  position: relative;
}
.home-hero__search form #autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapperPrefix .aa-Label .aa-SubmitButton {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 7rem;
  height: 7.2rem;
  background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
  background-position: 2.2rem 2.2rem;
  background-size: 3rem;
  background-repeat: no-repeat;
  background-color: #e11d73;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
@media (max-width: 1200px) {
  .home-hero__search form #autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapperPrefix .aa-Label .aa-SubmitButton {
    width: 5rem;
    height: 5.5rem;
    background-size: 2.5rem;
    background-position: 1.4rem 1.7rem;
  }
}
.home-hero__search form #autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapper {
  width: 100%;
}
.home-hero__search form #autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapper .aa-Input {
  height: auto;
  font-size: 2.6rem;
  padding: 0.8em 3.4em 0.8em 0.8em;
  line-height: 1;
  width: 100%;
  border-radius: 0.5rem;
}
@media (max-width: 1200px) {
  .home-hero__search form #autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapper .aa-Input {
    font-size: 2rem;
  }
}
.home-hero__search input:not([type=submit]) {
  height: auto;
  font-size: 2.6rem;
  padding: 0.8em 3.4em 0.8em 0.8em;
  line-height: 1;
}
@media (max-width: 1200px) {
  .home-hero__search input:not([type=submit]) {
    font-size: 2rem;
  }
}
.home-hero__search .search-submit {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 7rem;
  background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
  background-position: 2.2rem 2.2rem;
  background-size: 3rem;
  background-repeat: no-repeat;
  background-color: #e11d73;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
@media (max-width: 1200px) {
  .home-hero__search .search-submit {
    width: 5rem;
    background-size: 2.5rem;
    background-position: 1.4rem 1.7rem;
  }
}
@media (max-width: 1424.98px) {
  .home-hero__search {
    margin-top: 10px;
    width: auto;
  }
}
@media (max-width: 1200px) {
  .home-hero__search {
    margin-top: 0px;
  }
}
@media (max-width: 992px) {
  .home-hero__search {
    margin-left: 0px;
    margin-top: 2rem;
  }
}
@media (max-width: 767px) {
  .home-hero__search {
    display: none;
  }
}

.body-style--light .home-hero__image:after {
  background: linear-gradient(to right, rgba(204, 204, 204, 0) 0%, #cccccc 100%);
  /* stylelint-disable-line */
}

.block-img-txt {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 0 8rem;
}
@media (min-width: 992px) {
  .block-img-txt {
    padding-bottom: 10rem;
  }
}
.block-img-txt .img-txt__image {
  flex: 0 0 100%;
  width: 100%;
  margin-bottom: 2rem;
}
@media (min-width: 992px) {
  .block-img-txt .img-txt__image {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding-right: 2rem;
  }
}
.block-img-txt .img-txt__text {
  flex: 0 0 100%;
  width: 100%;
}
@media (min-width: 992px) {
  .block-img-txt .img-txt__text {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    padding-left: 2rem;
  }
}
.block-img-txt .img-txt__btn {
  margin-top: 4rem;
}
.block-img-txt--right .img-txt__image {
  order: 1;
}
@media (min-width: 992px) {
  .block-img-txt--right .img-txt__image {
    order: 2;
    padding-right: 0;
    padding-left: 2rem;
  }
}
.block-img-txt--right .img-txt__text {
  order: 2;
}
@media (min-width: 992px) {
  .block-img-txt--right .img-txt__text {
    order: 1;
    padding-left: 0;
    padding-right: 2rem;
  }
}

.wp-block-column.chamfr-light {
  padding: 2rem;
  background-color: #fff;
  color: #000;
}
.wp-block-column.chamfr-dark {
  padding: 2rem;
  background-color: #000;
  color: #fff;
}
.wp-block-column.chamfr-transparent {
  padding: 2rem;
  background-color: none;
  color: #fff;
}
ul.left-align {
  text-align: left;
}

.theme-chamfr2020 .schema-faq {
  display: block;
  margin: 0 auto;
  width: 90%;
}
.theme-chamfr2020 .schema-faq .schema-faq-section {
  margin-bottom: 2rem;
}
.theme-chamfr2020 .schema-faq .schema-faq-section .schema-faq-question {
  border-bottom: 2px solid #e11d73;
  cursor: pointer;
}
.theme-chamfr2020 .schema-faq .schema-faq-section .schema-faq-question::after {
  color: #e11d73;
  content: "\f13a";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  display: inline-block;
  transition: transform 0.3s;
  position: absolute;
  right: 10%;
}
.theme-chamfr2020 .schema-faq .schema-faq-section .schema-faq-question.expanded::after {
  transform: rotate(180deg);
}
.theme-chamfr2020 .schema-faq .schema-faq-section .schema-faq-answer {
  display: none;
  margin: 2rem;
}

.page-become-a-partner h1.wp-block-heading {
  font-size: 2.6rem;
}
.page-become-a-partner h2.wp-block-heading {
  font-size: 4rem;
}
.page-become-a-partner h3.wp-block-heading {
  font-size: 3rem;
}
.page-become-a-partner h4.wp-block-heading {
  font-size: 2.5rem;
}
.page-become-a-partner h5.wp-block-heading {
  font-size: 2rem;
}
.page-become-a-partner p {
  font-size: 1.5rem;
}

/* stylelint-disable no-descending-specificity */
.latest-blog {
  padding: 0 0 16rem;
}
@media (max-width: 991.98px) {
  .latest-blog {
    padding: 0 0 8rem;
  }
}
@media (min-width: 992px) {
  .latest-blog {
    padding: 0 0 16rem 0;
    margin: 0 -3rem;
  }
}
@media (min-width: 1425px) {
  .latest-blog {
    margin: 0 4rem;
  }
}
.latest-blog > h3 {
  text-align: center;
  padding-bottom: 4rem;
}
@media (min-width: 992px) {
  .latest-blog > h3 {
    margin: 0;
  }
}
@media (max-width: 991.98px) {
  .latest-blog > h3 {
    padding-bottom: 1rem;
  }
}
@media (min-width: 992px) {
  .latest-blog__posts {
    position: relative;
    overflow: hidden;
    padding: 2rem 3rem 0rem;
  }
  .latest-blog__posts:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 1rem;
    z-index: -1;
    width: 31.9rem;
    height: 41.5rem;
    background: url(../images/dots.svg) repeat top left;
    opacity: 0.5;
  }
}
.latest-blog__posts--desktop {
  display: none;
}
@media (min-width: 992px) {
  .latest-blog__posts--desktop {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
}
.latest-blog__posts--mobile {
  display: block;
}
@media (min-width: 992px) {
  .latest-blog__posts--mobile {
    display: none;
  }
}
.latest-blog__posts__col {
  flex: 0 0 50%;
  width: 58%;
}
.latest-blog__posts__col:first-of-type {
  width: 58%;
  padding-right: 16px;
}
.latest-blog__posts__col:first-of-type .post {
  display: block;
}
@media (min-width: 992px) {
  .latest-blog__posts__col:first-of-type .post .post__img,
.latest-blog__posts__col:first-of-type .post .post__body {
    width: 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 992px) {
  .latest-blog__posts__col:first-of-type .post .post__body {
    padding: 4rem 3.5rem;
  }
}
@media (min-width: 992px) {
  .latest-blog__posts__col:first-of-type .post__txt {
    position: static;
  }
}
@media (min-width: 992px) {
  .latest-blog__posts__col:first-of-type .post .post__meta {
    font-size: 1.4rem;
    margin: 0 0 1rem;
  }
}
@media (min-width: 992px) {
  .latest-blog__posts__col:first-of-type .post .post__title h2 {
    font-family: "Public Sans", sans-serif;
    font-size: 3rem;
    font-weight: 500;
    max-height: none;
  }
}
@media (min-width: 992px) {
  .latest-blog__posts__col:first-of-type .post .post__excerpt {
    display: block;
    margin: 0 0 2.5rem;
    font-size: 1.8rem;
  }
}
@media (min-width: 992px) {
  .latest-blog__posts__col:first-of-type .post .post__link {
    position: static;
  }
}
@media (min-width: 992px) {
  .latest-blog__posts__col:last-of-type {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  .latest-blog__posts__col:last-of-type .post .post__img {
    padding: 2rem 1rem 2rem 2rem;
  }
  .latest-blog__posts__col:last-of-type .post .post__img img {
    border-radius: 0.5rem;
  }
}
.latest-blog__posts__wrapper {
  padding-bottom: 16px;
  flex: 1;
}
.latest-blog__posts__wrapper:last-of-type {
  padding: 0;
}
.latest-blog__posts.slick-slider {
  margin: 0 -5px;
  transition: opacity 0.2s;
  opacity: 0;
}
.latest-blog__posts.slick-slider.slick-initialized {
  opacity: 1;
}
.latest-blog__posts.slick-slider .slick-list {
  overflow: visible;
}
.latest-blog__posts.slick-slider .slick-slide {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.latest-blog__posts.slick-slider .slick-slide.slick-active, .latest-blog__posts.slick-slider .slick-slide.slick-current {
  opacity: 1;
}
.latest-blog__posts.slick-slider .slick-slide .injected {
  opacity: 1;
  transition: opacity 0.2s;
}
.latest-blog__posts.slick-slider .slick-slide .injected.loading {
  opacity: 0;
}
.latest-blog__posts.slick-slider .slick-slide {
  margin: 0 5px;
}
.latest-blog__posts.slick-slider .slick-slide .post {
  height: 100%;
}
.latest-blog__posts .post {
  overflow: hidden;
  background: #2b2b2b;
  border-radius: 0.5rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}
@media (min-width: 992px) {
  .latest-blog__posts .post {
    display: flex;
    align-items: stretch;
    height: 100%;
  }
}
.latest-blog__posts .post__img {
  margin: 0;
}
.latest-blog__posts .post__img img {
  display: block;
  width: 100%;
}
@media (min-width: 992px) {
  .latest-blog__posts .post__img, .latest-blog__posts .post__body {
    width: 50%;
    flex: 0 0 50%;
  }
}
.latest-blog__posts .post__body {
  padding: 2rem 3rem 2.6rem;
}
@media (min-width: 992px) {
  .latest-blog__posts .post__body {
    position: relative;
    padding: 2rem 1.5rem 5rem;
  }
}
.latest-blog__posts .post__meta {
  margin: 0 0 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #eaeaea;
}
.latest-blog__posts .post__categories, .latest-blog__posts .post__date {
  display: inline-block;
}
.latest-blog__posts .post__categories {
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .latest-blog__posts .post__categories {
    font-size: 1rem;
  }
}
@media (min-width: 1425px) {
  .latest-blog__posts .post__categories {
    font-size: 1.2rem;
  }
}
.latest-blog__posts .post__categories a {
  color: #eaeaea;
}
.latest-blog__posts .post__categories a:hover, .latest-blog__posts .post__categories a:focus, .latest-blog__posts .post__categories a:active {
  color: #eaeaea;
}
.latest-blog__posts .post__title h2 {
  font-family: "Public Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  overflow: hidden;
  margin: 0 0 1.5rem;
}
@media (min-width: 992px) {
  .latest-blog__posts .post__title h2 {
    max-height: 6.5rem;
    margin: 0;
  }
}
.latest-blog__posts .post__title h2 a {
  color: #fff;
}
.latest-blog__posts .post__excerpt {
  display: none;
  margin: 0;
}
@media (min-width: 992px) {
  .latest-blog__posts .post__link {
    position: absolute;
    left: 1.5rem;
    bottom: 2rem;
    font-size: 1.4rem;
  }
}
@media (min-width: 1425px) {
  .latest-blog__posts .post__link {
    font-size: 1.6rem;
  }
}
.latest-blog__posts .post__link__a {
  display: inline-block;
  padding-bottom: 0.3rem;
  text-decoration: none;
}
.latest-blog__posts .post__link__a:hover, .latest-blog__posts .post__link__a:focus, .latest-blog__posts .post__link__a:active {
  text-decoration: none;
  border-bottom: 1px solid #e11d73;
}
.latest-blog__slick-controls {
  margin: 1.5rem 0 0;
  text-align: center;
}
@media (min-width: 992px) {
  .latest-blog__slick-controls {
    display: none;
  }
}

.block-logos-carousel {
  margin-bottom: 5rem;
}
.block-logos-carousel .logos {
  padding: 0 3.6rem;
}
.block-logos-carousel .logo {
  display: flex;
  justify-content: center;
  padding: 0 1rem;
}
.block-logos-carousel .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
}
.block-logos-carousel .slick-prev {
  left: 0;
}
.block-logos-carousel .slick-next {
  right: 0;
}

.product-categories {
  position: relative;
  padding: 0 0 16rem;
  text-align: center;
}
@media (max-width: 991.98px) {
  .product-categories {
    padding-bottom: 8rem;
  }
}
.product-categories .btn, .product-categories .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .product-categories .stripe-connect.light-blue, .product-categories .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .product-categories .wcv-button, .product-categories .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .product-categories .button, .product-categories .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .product-categories .website, .product-categories .blog .sticky .post__link a, .blog .sticky .post__link .product-categories a,
.product-categories .archive .sticky .post__link a,
.archive .sticky .post__link .product-categories a, .product-categories .woocommerce #respond input#submit, .woocommerce #respond .product-categories input#submit,
.product-categories .woocommerce a.button,
.woocommerce .product-categories a.button,
.product-categories .woocommerce button.button,
.woocommerce .product-categories button.button,
.product-categories .woocommerce input.button,
.woocommerce .product-categories input.button, .product-categories .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .product-categories a, .product-categories body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .product-categories input.button,
.product-categories body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .product-categories input[type=submit],
.product-categories body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .product-categories input.button,
.product-categories body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .product-categories input[type=submit], .product-categories .wp-block-button__link {
  text-transform: uppercase !important;
}
.product-categories h3 {
  margin: 0 0 4rem;
  text-align: center;
}
@media (max-width: 991.98px) {
  .product-categories h3 {
    margin: 0 0 2rem;
  }
}
.product-categories__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 3rem;
}
@media (max-width: 991.98px) {
  .product-categories__list {
    padding-bottom: 1rem;
  }
}
.product-categories .product-category {
  display: flex;
  padding: 2rem 2.5rem;
  margin: 0 1rem 1rem 0;
  flex: 0 0 42%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #323132;
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  font-size: 0.8rem;
  color: #fff;
  text-decoration: none;
}
@media (max-width: 991.98px) {
  .product-categories .product-category {
    padding: 1rem 1rem;
  }
  .product-categories .product-category:nth-child(2n) {
    margin-right: 0px;
  }
}
.product-categories .product-category.empty {
  background: none;
  box-shadow: none;
  max-height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.product-categories .product-category:hover {
  color: #E11B72;
}
.product-categories .product-category:hover .product-category__title {
  border-bottom: 1px solid #e11d73;
}
.product-categories .product-category > div {
  align-items: center;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) and (max-width: 575.98px) {
  .product-categories .product-category {
    flex: 0 0 24%;
  }
}
@media (min-width: 992px) {
  .product-categories .product-category {
    padding: 2rem 2.5rem;
    flex: 0 0 23%;
  }
}
@media (min-width: 1200px) {
  .product-categories .product-category {
    font-size: 1.6rem;
  }
}
.product-categories .product-category__icon {
  height: 6rem;
  width: 6rem;
  margin: 0 0 1rem;
}
@media (min-width: 992px) {
  .product-categories .product-category__icon {
    height: 10rem;
    width: 10rem;
  }
}
.product-categories .product-category__icon > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.product-categories .product-category__title {
  font-size: 1.2rem;
  text-align: center;
}
@media (min-width: 992px) {
  .product-categories .product-category__title {
    font-size: 2rem;
  }
}
.product-categories .product-category__link {
  position: absolute;
  left: 2.5rem;
  right: 2.5rem;
  bottom: 2rem;
}
.product-categories .product-category__link a {
  text-decoration: underline;
  text-underline-position: under;
  color: #9f325d;
}
.product-categories .product-category__link a:hover, .product-categories .product-category__link a:focus, .product-categories .product-category__link a:active {
  text-decoration: underline;
  color: #9f325d;
}
.product-categories__slick-controls {
  margin: 0 0 2rem;
  text-align: right;
}
@media (min-width: 992px) {
  .product-categories__slick-controls {
    position: absolute;
    top: 5.3rem;
    right: 0;
  }
}
.product-categories__slick-controls .slick-next {
  margin-right: 0;
}
.product-categories .btn, .product-categories .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .product-categories .stripe-connect.light-blue, .product-categories .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .product-categories .wcv-button, .product-categories .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .product-categories .button, .product-categories .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .product-categories .website, .product-categories .blog .sticky .post__link a, .blog .sticky .post__link .product-categories a,
.product-categories .archive .sticky .post__link a,
.archive .sticky .post__link .product-categories a, .product-categories .woocommerce #respond input#submit, .woocommerce #respond .product-categories input#submit,
.product-categories .woocommerce a.button,
.woocommerce .product-categories a.button,
.product-categories .woocommerce button.button,
.woocommerce .product-categories button.button,
.product-categories .woocommerce input.button,
.woocommerce .product-categories input.button, .product-categories .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .product-categories a, .product-categories body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .product-categories input.button,
.product-categories body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .product-categories input[type=submit],
.product-categories body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .product-categories input.button,
.product-categories body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .product-categories input[type=submit], .product-categories .wp-block-button__link {
  text-transform: unset;
}
@media (max-width: 991.98px) {
  .product-categories .btn, .product-categories .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .product-categories .stripe-connect.light-blue, .product-categories .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .product-categories .wcv-button, .product-categories .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .product-categories .button, .product-categories .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .product-categories .website, .product-categories .blog .sticky .post__link a, .blog .sticky .post__link .product-categories a,
.product-categories .archive .sticky .post__link a,
.archive .sticky .post__link .product-categories a, .product-categories .woocommerce #respond input#submit, .woocommerce #respond .product-categories input#submit,
.product-categories .woocommerce a.button,
.woocommerce .product-categories a.button,
.product-categories .woocommerce button.button,
.woocommerce .product-categories button.button,
.product-categories .woocommerce input.button,
.woocommerce .product-categories input.button, .product-categories .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .product-categories a, .product-categories body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .product-categories input.button,
.product-categories body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .product-categories input[type=submit],
.product-categories body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .product-categories input.button,
.product-categories body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .product-categories input[type=submit], .product-categories .wp-block-button__link {
    width: 86%;
  }
}

.recently-added-products {
  position: relative;
  padding: 0 0 16rem;
  text-align: center;
}
@media (max-width: 991.98px) {
  .recently-added-products {
    padding-bottom: 8rem;
  }
}
.recently-added-products .btn, .recently-added-products .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .recently-added-products .stripe-connect.light-blue, .recently-added-products .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .recently-added-products .wcv-button, .recently-added-products .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .recently-added-products .button, .recently-added-products .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .recently-added-products .website, .recently-added-products .blog .sticky .post__link a, .blog .sticky .post__link .recently-added-products a,
.recently-added-products .archive .sticky .post__link a,
.archive .sticky .post__link .recently-added-products a, .recently-added-products .woocommerce #respond input#submit, .woocommerce #respond .recently-added-products input#submit,
.recently-added-products .woocommerce a.button,
.woocommerce .recently-added-products a.button,
.recently-added-products .woocommerce button.button,
.woocommerce .recently-added-products button.button,
.recently-added-products .woocommerce input.button,
.woocommerce .recently-added-products input.button, .recently-added-products .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .recently-added-products a, .recently-added-products body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .recently-added-products input.button,
.recently-added-products body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .recently-added-products input[type=submit],
.recently-added-products body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .recently-added-products input.button,
.recently-added-products body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .recently-added-products input[type=submit], .recently-added-products .wp-block-button__link {
  text-transform: uppercase !important;
}
.recently-added-products h3 {
  margin: 0 0 4rem;
  text-align: center;
}
@media (max-width: 991.98px) {
  .recently-added-products h3 {
    margin: 0 0 2rem;
  }
}
.recently-added-products__list {
  padding-bottom: 3rem;
}
@media (max-width: 991.98px) {
  .recently-added-products__list {
    padding-bottom: 1rem;
  }
}
.recently-added-products__list .slick-slide {
  margin: 1rem;
}
.recently-added-products__list__link:hover {
  text-decoration: none;
}
.recently-added-products__list__item {
  display: block;
  padding: 2rem 2.5rem;
  margin: 0 1rem 1rem 0;
  min-height: 14rem;
  background: #323132;
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  font-size: 0.8rem;
  color: #fff;
  text-decoration: none;
}
@media (max-width: 991.98px) {
  .recently-added-products__list__item {
    padding: 1rem 1rem;
  }
}
.recently-added-products__list__item > div {
  text-align: center;
  display: inline-block;
  margin: 0.5rem auto;
  width: 100%;
}
@media (min-width: 992px) {
  .recently-added-products__list__item {
    padding: 2rem 2.5rem;
    min-height: 23rem;
  }
}
@media (min-width: 1200px) {
  .recently-added-products__list__item {
    min-height: 20rem;
  }
}
@media (min-width: 1200px) {
  .recently-added-products__list__item {
    font-size: 1.6rem;
  }
}
.recently-added-products__list__item__count {
  height: 6rem;
  margin: 0 0 1rem;
  font-size: 5rem;
}
@media (min-width: 992px) {
  .recently-added-products__list__item__count {
    height: 8rem;
    font-size: 6rem;
  }
}
.recently-added-products__list__item__category {
  font-size: 1.2rem;
  text-align: center;
}
@media (min-width: 992px) {
  .recently-added-products__list__item__category {
    font-size: 2rem;
  }
}
:hover > .recently-added-products__list__item__category {
  color: #E11B72;
  text-decoration: underline;
  text-underline-offset: 7px;
  text-decoration-thickness: from-font;
}
.recently-added-products__slick-controls {
  margin: 0rem;
  text-align: right;
}
@media (min-width: 992px) {
  .recently-added-products__slick-controls {
    margin: 0 0 2rem;
    position: absolute;
    top: 4rem;
    right: 0;
  }
}
.recently-added-products__slick-controls .slick-next {
  margin-right: 0;
}

.seller-top-block-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: normal;
  box-sizing: border-box;
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  margin-bottom: 2rem;
  padding: 1rem 1.5rem;
}
.seller-top-block-wrapper .image-wrapper {
  flex-basis: 33.33%;
  flex-grow: 0;
  text-align: center;
}
.seller-top-block-wrapper .text-wrapper {
  flex-basis: 66.66%;
  flex-grow: 0;
  padding-left: 5%;
}
.seller-top-block-wrapper .text-wrapper .title-wrapper {
  text-align: center;
}
.seller-top-block-wrapper .text-wrapper .subtitle-wrapper {
  text-align: center;
}
.soc-links {
  padding: 6rem 0 0;
  text-align: center;
}
@media (min-width: 992px) {
  .soc-links {
    padding: 2rem 0 0;
    text-align: left;
  }
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.5rem;
}
@media (min-width: 992px) {
  .social-links {
    justify-content: flex-end;
    height: 100%;
    margin-top: 0;
  }
}
.social-links a {
  display: block;
  width: 6rem;
  height: 6rem;
  margin: 0 0.5rem;
  border-radius: 50%;
  border: 2px solid #9f325d;
  font-size: 3rem;
  line-height: 5.6rem;
  text-align: center;
  color: #9f325d;
}
@media (min-width: 992px) {
  .social-links a {
    margin: 0 0 0 2.6rem;
  }
}

.twitter-feed-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #313232;
  padding: 4rem;
  margin: 0 0 15rem;
}
@media (max-width: 991.98px) {
  .twitter-feed-block {
    flex-direction: column;
  }
}
.twitter-feed-block .twitter-timeline {
  padding-bottom: 0.3rem;
  text-decoration: none;
  display: inline-block;
  width: fit-content;
}
.twitter-feed-block .twitter-timeline:hover, .twitter-feed-block .twitter-timeline:focus, .twitter-feed-block .twitter-timeline:active {
  text-decoration: none;
  border-bottom: 1px solid #e11d73;
}
.twitter-feed-block .twitter-feed-info {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 1rem;
}
@media (max-width: 991.98px) {
  .twitter-feed-block .twitter-feed-info {
    width: 100%;
    padding-bottom: 1rem;
    padding-right: 0;
  }
}
.twitter-feed-block .twitter-feed-info .title {
  color: #fff;
}
.twitter-feed-block .twitter-feed-info .description {
  padding-bottom: 2rem;
}
.twitter-feed-block .twitter-feed-info a {
  padding-bottom: 0.3rem;
  text-decoration: none;
  display: inline-block;
  width: fit-content;
}
.twitter-feed-block .twitter-feed-info a:hover, .twitter-feed-block .twitter-feed-info a:focus, .twitter-feed-block .twitter-feed-info a:active {
  text-decoration: none;
  border-bottom: 1px solid #e11d73;
}
.twitter-feed-block .twitter-feed-iframe {
  width: 50%;
  height: 200px;
  padding-right: 0;
  overflow-y: scroll;
}
@media (max-width: 991.98px) {
  .twitter-feed-block .twitter-feed-iframe {
    width: 100%;
  }
}

#chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 360px) {
  #chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
    max-width: 360px;
  }
}
@media (min-width: 576px) {
  #chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  #chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  #chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  #chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
    max-width: 1140px;
  }
}
@media (min-width: 1425px) {
  #chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
    max-width: 1360px;
  }
}

#ais-wrapper.columns {
  flex-grow: 1;
}

/* Hits table Header */
.sortable {
  cursor: pointer;
  user-select: none;
}

.sortable .sort-arrow {
  margin-left: 5px;
  display: inline-block;
  width: 10px;
}
@media (max-width: 991.98px) {
  .sortable .sort-arrow {
    display: none;
  }
}

/* Facets */
.custom-search-box .ais-SearchBox-submit {
  display: none;
}
.custom-search-box .ais-SearchBox-reset {
  display: none;
}
.custom-search-box .ais-SearchBox {
  width: 100%;
}

.ais-ToggleRefinement-label input[type=checkbox] {
  display: none;
}
.ais-ToggleRefinement-label input[type=checkbox] + span::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid #fff;
  /* stylelint-disable-next-line */
  transition: background-color 0.2s ease-out 0.05s, border-color 0.2s ease-out 0.05s, background-image 0.1s 0.3s;
  cursor: pointer;
}
.ais-ToggleRefinement-label input[type=checkbox]:checked + span::before {
  background: #e11d73 url(../images/check-white.svg) no-repeat center;
  background-size: 12px;
  border: 1px solid #e11d73;
}

.ais-RefinementList-label input[type=checkbox] {
  display: none;
}
.ais-RefinementList-label input[type=checkbox] + span::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.6rem;
  margin-left: -2.6rem;
  margin-top: -0.3rem;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid #fff;
  /* stylelint-disable-next-line */
  transition: background-color 0.2s ease-out 0.05s, border-color 0.2s ease-out 0.05s, background-image 0.1s 0.3s;
  cursor: pointer;
}
.ais-RefinementList-searchBox {
  width: 100%;
}
.ais-RefinementList .ais-RefinementList-list {
  padding-left: 0px;
  padding-right: 0px;
}
.ais-RefinementList ul.inner {
  overflow-y: scroll;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  scrollbar-color: #ddd #2b2b2b;
  scrollbar-width: thin !important;
}

.ais-RefinementList-item--selected .ais-RefinementList-label input[type=checkbox] + span::before {
  background: #e11d73 url(../images/check-white.svg) no-repeat center;
  background-size: 12px;
  border: 1px solid #e11d73;
}

.ais-ClearRefinements {
  text-align: center;
  margin-bottom: 1rem;
}
.ais-ClearRefinements button.button {
  display: none;
}
.ais-ClearRefinements button.button.show {
  display: inline-block;
}

#chamfr-search-results .columns,
#chamfr-supplier-store .columns,
#chamfr-recently-added-products .columns {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
}
@media (max-width: 1199.98px) {
  #chamfr-search-results .columns,
#chamfr-supplier-store .columns,
#chamfr-recently-added-products .columns {
    display: block;
  }
}
#chamfr-search-results .columns #ais-facets .chamfr-filter,
#chamfr-supplier-store .columns #ais-facets .chamfr-filter,
#chamfr-recently-added-products .columns #ais-facets .chamfr-filter {
  padding: 2rem 1rem;
}
#chamfr-search-results .columns #ais-facets .chamfr-filter .caption.widgettitle,
#chamfr-supplier-store .columns #ais-facets .chamfr-filter .caption.widgettitle,
#chamfr-recently-added-products .columns #ais-facets .chamfr-filter .caption.widgettitle {
  margin-bottom: 1rem;
}
#chamfr-search-results .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-submit,
#chamfr-supplier-store .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-submit,
#chamfr-recently-added-products .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-submit {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  height: 36px;
  background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
  background-position: 12px 12px;
  background-size: 12px;
  background-repeat: no-repeat;
  background-color: #e11d73;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
#chamfr-search-results .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-submit svg,
#chamfr-supplier-store .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-submit svg,
#chamfr-recently-added-products .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-submit svg {
  display: none;
}
#chamfr-search-results .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox form,
#chamfr-supplier-store .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox form,
#chamfr-recently-added-products .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox form {
  position: relative;
}
#chamfr-search-results .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-input,
#chamfr-supplier-store .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-input,
#chamfr-recently-added-products .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-input {
  max-width: 100%;
  height: auto;
  padding: 8px 15px 9px 15px;
  background-color: #fafafa;
  border-radius: 0.5rem;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  font-size: 1.4rem;
  line-height: 1.36;
  color: #313131;
  margin: 0;
}
#chamfr-search-results .columns #facet-categories .ais-RefinementList-searchBox,
#chamfr-search-results .columns #facet-container .ais-RefinementList-searchBox,
#chamfr-supplier-store .columns #facet-categories .ais-RefinementList-searchBox,
#chamfr-supplier-store .columns #facet-container .ais-RefinementList-searchBox,
#chamfr-recently-added-products .columns #facet-categories .ais-RefinementList-searchBox,
#chamfr-recently-added-products .columns #facet-container .ais-RefinementList-searchBox {
  margin-bottom: 2rem;
}

/* RangeSlide Facet */
.slider-wrapper {
  position: relative;
  min-height: 7rem;
}
.slider-wrapper .chamfr-filter-slider-range-input-field {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  max-width: 8rem;
  font-size: 1.2rem;
  color: #5b5b5b;
}
.slider-wrapper .chamfr-filter-slider-range-input-field.to {
  left: auto;
  right: 0;
}
.slider-wrapper .chamfr-filter-slider-range-input-field .chamfr-filter-slider-range-input {
  font-size: 1.2rem;
  height: 2rem;
  padding: 0 0.5rem;
  width: 100%;
  margin: 0;
  background: #fff;
  border: 1px solid #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #313131;
  border-radius: 0.5rem;
}
.slider-wrapper .slider-container {
  margin-bottom: 1.5rem;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.slider-wrapper .slider-container .noUi-target {
  height: 1px;
  background: rgba(234, 234, 234, 0.2);
  border: none;
  box-shadow: none;
  padding: 0px 0.6rem;
}
.slider-wrapper .slider-container .noUi-target .noUi-connect {
  background: #b7b7b7;
}
.slider-wrapper .slider-container .noUi-target .noUi-handle {
  height: 1.2rem;
  width: 1.2rem;
  top: -0.5rem;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}
.slider-wrapper .slider-container .noUi-target .noUi-handle:before {
  display: none;
}
.slider-wrapper .slider-container .noUi-target .noUi-handle:after {
  display: none;
}
.slider-wrapper .slider-container .noUi-target .noUi-handle-lower {
  right: -0.6rem;
}
.slider-wrapper .slider-container .noUi-target .noUi-handle-upper {
  right: -0.6rem;
}

/* End of RangeSlider Facet */
.widget_ep-facet.hidden {
  display: none;
}

/* Search Bar */
.algolia-search-box-wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

#algolia-search-box {
  width: 100%;
  max-width: 100%;
  margin-right: auto;
}

/* End of Search Bar */
/* Stats */
#algolia-stats {
  width: 60%;
  margin-bottom: 20px;
  /* Additional margin for separation */
  padding-left: 20px;
  /* Align with ais-main */
}

/* Collapsible button styles */
/* Add space between the title and the categories */
/* Add bottom margin to the categories section */
.chamfr-filter {
  margin-bottom: 20px;
  /* Adjust as needed */
}
@media (max-width: 1199.98px) {
  .chamfr-filter #facet-container {
    display: none !important;
  }
}
@media (max-width: 1199.98px) {
  .chamfr-filter #facet-container.mobile-on {
    display: block !important;
  }
}

/* End of Collapsible Container */
/* Category List */
.ais-HierarchicalMenu {
  margin-top: 20px;
}

.chamfr-filter ul li.ais-RefinementList-item {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  clip: auto !important;
  position: relative !important;
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
  line-height: 26px;
  padding-left: 2.6rem;
}
.chamfr-filter ul li.ais-RefinementList-item div label.ais-RefinementList-label {
  margin: 0px;
}

.ais-HierarchicalMenu-label {
  color: #ccc;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  clip: auto !important;
  /* NEW */
  position: relative !important;
  /* NEW */
  flex-grow: 1;
  /* Allow label to take remaining space */
}

.ais-HierarchicalMenu-label:hover {
  color: #e11d73;
}

/* Additional Widgets */
.not-finding {
  margin-top: 20px;
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.not-finding-after-table .not-finding {
  margin-top: 0px;
  padding: 0px;
  background: none;
}

.not-finding p {
  margin: 0;
  color: #ccc;
  font-size: 14px;
}

.not-finding a {
  display: block;
  margin-top: 10px;
  background-color: #e11d73;
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.not-finding a:hover {
  background-color: #d10b65;
}

/* End of Sidebar Container */
/* Reset default list styles */
#algolia-pagination {
  position: relative;
  min-height: 120px;
}
#algolia-pagination.hidden {
  display: none;
}
#algolia-pagination .ais-Pagination {
  text-align: center;
  margin: 0;
  padding: 50px 10px 10px;
}
@media (max-width: 767.98px) {
  #algolia-pagination .ais-Pagination {
    padding: 20px 10px 10px;
  }
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list {
  list-style: none;
  border: none;
  margin: 0;
  padding: 1rem;
  border-radius: 28px;
  transition: background-color 0.1s ease, box-shadow 0.1s ease;
}
@media (max-width: 767.98px) {
  #algolia-pagination .ais-Pagination ul.ais-Pagination-list {
    padding: 0.5rem;
    border-radius: 19px;
  }
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item {
  display: inline-block;
  border: none;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--firstPage {
  display: none;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled {
  display: none;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--previousPage a {
  color: transparent;
  position: relative;
  border-color: #000;
  opacity: 1;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--previousPage a::after {
  content: "←";
  color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--previousPage a:hover {
  color: transparent;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled {
  display: none;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--nextPage a {
  color: transparent;
  position: relative;
  border-color: #000;
  opacity: 1;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--nextPage a::after {
  content: "→";
  color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--nextPage a:hover {
  color: transparent;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--lastPage {
  display: none;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item span,
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item a {
  text-decoration: none;
  min-width: 3.6rem;
  height: 3.6rem;
  margin: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767.98px) {
  #algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item span,
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item a {
    margin: 0;
    height: 2.8rem;
    min-width: 2.8rem;
  }
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item a {
  color: #000;
  opacity: 0.5;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item a:hover {
  background: #e9e6ed;
  color: #816e99;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item--selected a {
  background-color: transparent;
  color: #e11d73;
  opacity: 1;
}
#algolia-pagination .ais-Pagination.fixed {
  position: fixed;
  z-index: 1;
  pointer-events: none;
  bottom: 50px;
  margin: 0 auto;
  left: 50%;
}
#algolia-pagination .ais-Pagination.fixed ul {
  pointer-events: auto;
  background-color: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

#algolia-no-results .categories {
  padding-top: 4rem;
}
#algolia-no-results .categories-list {
  margin: 0 -1rem;
  display: flex;
  flex-wrap: wrap;
}
#algolia-no-results .category {
  flex: 0 0 33.333%;
  padding: 1rem;
}
@media (max-width: 767.98px) {
  #algolia-no-results .category {
    flex: 0 0 50%;
  }
}
@media (max-width: 359.98px) {
  #algolia-no-results .category {
    flex: 0 0 100%;
  }
}
#algolia-no-results .category > a {
  display: block;
  background: #fff;
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

@media (max-width: 767.98px) {
  .no-results .chamfr-filter {
    display: none;
  }
}
.no-results .chamfr-product-list .products {
  width: 100%;
  margin: 0px auto;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
.aw-communication-page .aw-communication-form__preference-checkbox {
  height: 15px;
  width: 15px;
  clip: auto;
}

.autocomplete-suggestion {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  cursor: pointer;
}

.autocomplete-suggestion a {
  text-decoration: none;
  color: #333;
}

/* ===== */
.aa-suggestions-category {
  font-weight: bold;
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
  background-color: #f5f5f5;
}

.aa-suggestion {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.aa-suggestion img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  margin-right: 10px;
}

.aa-suggestion strong {
  margin-right: auto;
}

.aa-suggestion span {
  margin-left: 10px;
  color: #888;
}

.aa-suggestion .add-to-cart {
  background-color: #e11d73;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
}

.aa-Panel {
  z-index: 10000;
}
.aa-Panel .aa-PanelLayout .aa-Source .aa-List .aa-Item .aa-ItemWrapper .aa-ItemContent .aa-ItemContentBody .aa-ItemContentTitle mark {
  padding: 0px;
}
.aa-Panel .aa-PanelLayout .aa-Source .aa-List .aa-Item .aa-ItemWrapper .aa-ItemContent .aa-ItemContentBody .aa-ItemContentDescription mark {
  padding: 0px;
}

#autocomplete-searchbar .aa-Autocomplete {
  color: #313131;
  margin: 0;
}
#autocomplete-searchbar .aa-Autocomplete .aa-Form {
  border-color: transparent;
  box-shadow: none;
  display: block;
  border: none;
  border-radius: 8px;
}
#autocomplete-searchbar .aa-Autocomplete .aa-Form:focus-within {
  border-color: transparent;
  box-shadow: none;
}
#autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapperPrefix {
  order: 4;
  position: absolute;
  z-index: 3;
  right: 0px;
  top: 0px;
  display: block;
  bottom: 0px;
}
#autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapperPrefix .aa-Label .aa-SubmitButton {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 105.05px;
  height: 48px;
  background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-size: 25px;
  background-repeat: no-repeat;
  background-color: #e11d73;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: all 0.3s ease-out;
}
@media (max-width: 575.98px) {
  #autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapperPrefix .aa-Label .aa-SubmitButton {
    width: 40px;
    height: 40px;
    background-size: 20px;
  }
}
#autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapperPrefix .aa-Label .aa-SubmitButton .aa-SubmitIcon {
  display: none;
}
#autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapperPrefix .aa-Label .aa-SubmitButton:hover {
  background-color: #9f325d;
}
#autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapperPrefix .aa-LoadingIndicator {
  display: none !important;
}
#autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapperPrefix .aa-LoadingIndicator .aa-LoadingIcon {
  display: none !important;
}
#autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapper {
  order: 1;
  width: 100%;
  max-width: 608px;
  display: block;
}
@media (max-width: 1199.98px) {
  #autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapper {
    max-width: 100%;
  }
}
#autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapper .aa-Input {
  width: calc(100% - 105px);
  height: auto;
  padding: 14.5px 16px;
  background-color: #FFFFFF;
  border-radius: 8px;
  border: none;
  appearance: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 18.8px;
}
@media (max-width: 575.98px) {
  #autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapper .aa-Input {
    width: calc(100% - 40px);
    padding: 10.6px 12px;
    font-size: 12px;
  }
}
#autocomplete-searchbar .aa-Autocomplete .aa-Form .aa-InputWrapperSuffix {
  display: none;
  order: 2;
}

@media (min-width: 768px) {
  .aa-PanelSections {
    column-gap: var(--aa-spacing);
    display: flex;
  }
}
.aa-PanelSection {
  padding: var(--aa-spacing-half);
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 768px) {
  .aa-PanelSection--left {
    width: 65%;
  }
  .aa-PanelSection--right {
    padding-left: var(--aa-spacing);
    padding-right: var(--aa-spacing);
    width: 35%;
    background-color: #eaeaea;
  }
}
.aa-SourceHeaderTitle {
  font-size: 1.3rem;
}
.aa-Category:hover, .aa-Post:hover, .aa-Product:hover, .aa-Supplier:hover {
  color: inherit;
  text-decoration: none;
}
.aa-Product {
  padding: 1rem;
  border-bottom: 1px solid #b7b7b7;
}
.aa-Product .aa-ItemContentTitle {
  text-overflow: inherit;
  white-space: inherit;
  overflow: inherit;
  color: #e11d73;
  font-weight: 700;
}
.aa-SoldBy {
  font-size: 1.2rem;
  color: #5b5b5b;
}
.aa-Source[data-autocomplete-source-id=products] {
  flex-grow: 1;
  padding-bottom: 4rem;
}
.aa-Source[data-autocomplete-source-id=products] .aa-SourceHeader {
  margin-left: calc(var(--aa-spacing) / -2);
  margin-right: calc(var(--aa-spacing) * -1.5);
  padding: calc(var(--aa-spacing) / 2);
  background-color: #eaeaea;
}
.aa-Source[data-autocomplete-source-id=products] .aa-SourceFooter {
  position: absolute;
  bottom: calc(var(--aa-spacing) / -2);
  left: calc(var(--aa-spacing) / -2);
  right: calc(var(--aa-spacing) * -1.5);
  text-align: right;
  padding: 1.3rem calc(var(--aa-spacing) * 2) 1.3rem;
  font-weight: 700;
}
.aa-Source[data-autocomplete-source-id=suppliers], .aa-Source[data-autocomplete-source-id=posts] {
  border-bottom: 1px solid #b7b7b7;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.aa-Source[data-autocomplete-source-id=suppliers]:last-child, .aa-Source[data-autocomplete-source-id=posts]:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.aa-Source[data-autocomplete-source-id=suppliers] .aa-Item, .aa-Source[data-autocomplete-source-id=posts] .aa-Item {
  padding: 4px calc(var(--aa-spacing) / 2);
}
.aa-Source[data-autocomplete-source-id=suppliers] .aa-ItemContentTitle, .aa-Source[data-autocomplete-source-id=posts] .aa-ItemContentTitle {
  text-overflow: inherit;
  white-space: inherit;
  overflow: inherit;
}
.aa-Source[data-autocomplete-source-id=suppliers] .aa-SourceFooter, .aa-Source[data-autocomplete-source-id=posts] .aa-SourceFooter {
  padding: calc(var(--aa-spacing) / 2) 0;
  text-align: right;
}
.aa-Source[data-autocomplete-source-id=suppliers] .aa-SourceFooter a, .aa-Source[data-autocomplete-source-id=posts] .aa-SourceFooter a {
  color: inherit;
}

.gateway-balance-qualification-data span {
  display: inline-block;
  width: 240px;
}
.gateway-balance-qualification-fields .form-row label {
  flex: 0 0 240px;
  margin-bottom: 0;
}
.gateway-balance-qualification-fields input:not([type=submit]),
.gateway-balance-qualification-fields select,
.gateway-balance-qualification-fields textarea {
  padding: 0.5rem;
  height: 3rem;
}

blockquote cite {
  text-transform: none;
}

.btn, .woocommerce-MyAccount-content .stripe-connect.light-blue, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .button, .chamfr-service-partners-featured .summary .website, .blog .sticky .post__link a,
.archive .sticky .post__link a, .woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button, .woocommerce-order-details-table + p a, body .gform_wrapper .gform_footer input.button,
body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer input[type=submit], .wp-block-button__link {
  display: inline-block;
  padding: 1.558rem 3rem;
  border-radius: 0.5rem;
  background: #e11d73;
  transition: all 0.3s ease-out;
  cursor: pointer;
  user-select: none;
  font: 600 1.6rem/1.2 "Public Sans", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.04em;
  color: #fff;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.25);
  /*
   Colored button which allows to select background and text color in tinyMCE
   just set rules like font\size\paddings\border radius if necessary.
   */
}
.btn:hover, .woocommerce-MyAccount-content .stripe-connect.light-blue:hover, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:hover, .wcv-grid .chamfr-vendor-dashboard-content .button:hover, .chamfr-service-partners-featured .summary .website:hover, .blog .sticky .post__link a:hover,
.archive .sticky .post__link a:hover, .woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover, .woocommerce-order-details-table + p a:hover, body .gform_wrapper .gform_footer input.button:hover,
body .gform_wrapper .gform_footer input[type=submit]:hover,
body .gform_wrapper .gform_page_footer input.button:hover,
body .gform_wrapper .gform_page_footer input[type=submit]:hover, .wp-block-button__link:hover, .btn:focus, .woocommerce-MyAccount-content .stripe-connect.light-blue:focus, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:focus, .wcv-grid .chamfr-vendor-dashboard-content .button:focus, .chamfr-service-partners-featured .summary .website:focus, .blog .sticky .post__link a:focus,
.archive .sticky .post__link a:focus, .woocommerce #respond input#submit:focus,
.woocommerce a.button:focus,
.woocommerce button.button:focus,
.woocommerce input.button:focus, .woocommerce-order-details-table + p a:focus, body .gform_wrapper .gform_footer input.button:focus,
body .gform_wrapper .gform_footer input[type=submit]:focus,
body .gform_wrapper .gform_page_footer input.button:focus,
body .gform_wrapper .gform_page_footer input[type=submit]:focus, .wp-block-button__link:focus, .btn:active, .woocommerce-MyAccount-content .stripe-connect.light-blue:active, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:active, .wcv-grid .chamfr-vendor-dashboard-content .button:active, .chamfr-service-partners-featured .summary .website:active, .blog .sticky .post__link a:active,
.archive .sticky .post__link a:active, .woocommerce #respond input#submit:active,
.woocommerce a.button:active,
.woocommerce button.button:active,
.woocommerce input.button:active, .woocommerce-order-details-table + p a:active, body .gform_wrapper .gform_footer input.button:active,
body .gform_wrapper .gform_footer input[type=submit]:active,
body .gform_wrapper .gform_page_footer input.button:active,
body .gform_wrapper .gform_page_footer input[type=submit]:active, .wp-block-button__link:active {
  text-decoration: none;
  color: #fff;
  background: #e11d73;
  border: none;
  outline: none;
}
.btn:disabled, .woocommerce-MyAccount-content .stripe-connect.light-blue:disabled, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:disabled, .wcv-grid .chamfr-vendor-dashboard-content .button:disabled, .chamfr-service-partners-featured .summary .website:disabled, .blog .sticky .post__link a:disabled,
.archive .sticky .post__link a:disabled, .woocommerce #respond input#submit:disabled,
.woocommerce a.button:disabled,
.woocommerce button.button:disabled,
.woocommerce input.button:disabled, .woocommerce-order-details-table + p a:disabled, body .gform_wrapper .gform_footer input.button:disabled,
body .gform_wrapper .gform_footer input[type=submit]:disabled,
body .gform_wrapper .gform_page_footer input.button:disabled,
body .gform_wrapper .gform_page_footer input[type=submit]:disabled, .wp-block-button__link:disabled {
  background: #eaeaea;
  box-shadow: none;
  cursor: default;
}
.btn:disabled:hover, .woocommerce-MyAccount-content .stripe-connect.light-blue:disabled:hover, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:disabled:hover, .wcv-grid .chamfr-vendor-dashboard-content .button:disabled:hover, .chamfr-service-partners-featured .summary .website:disabled:hover, .blog .sticky .post__link a:disabled:hover,
.archive .sticky .post__link a:disabled:hover, .woocommerce #respond input#submit:disabled:hover,
.woocommerce a.button:disabled:hover,
.woocommerce button.button:disabled:hover,
.woocommerce input.button:disabled:hover, .woocommerce-order-details-table + p a:disabled:hover, body .gform_wrapper .gform_footer input.button:disabled:hover,
body .gform_wrapper .gform_footer input[type=submit]:disabled:hover,
body .gform_wrapper .gform_page_footer input.button:disabled:hover,
body .gform_wrapper .gform_page_footer input[type=submit]:disabled:hover, .wp-block-button__link:disabled:hover, .btn:disabled:focus, .woocommerce-MyAccount-content .stripe-connect.light-blue:disabled:focus, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:disabled:focus, .wcv-grid .chamfr-vendor-dashboard-content .button:disabled:focus, .chamfr-service-partners-featured .summary .website:disabled:focus, .blog .sticky .post__link a:disabled:focus,
.archive .sticky .post__link a:disabled:focus, .woocommerce #respond input#submit:disabled:focus,
.woocommerce a.button:disabled:focus,
.woocommerce button.button:disabled:focus,
.woocommerce input.button:disabled:focus, .woocommerce-order-details-table + p a:disabled:focus, body .gform_wrapper .gform_footer input.button:disabled:focus,
body .gform_wrapper .gform_footer input[type=submit]:disabled:focus,
body .gform_wrapper .gform_page_footer input.button:disabled:focus,
body .gform_wrapper .gform_page_footer input[type=submit]:disabled:focus, .wp-block-button__link:disabled:focus {
  transform: none;
  box-shadow: none;
}
.btn-primary:disabled, .wp-block-button__link:disabled {
  background: #eaeaea;
  box-shadow: none;
  cursor: default;
}
.btn-primary:disabled:hover, .wp-block-button__link:disabled:hover, .btn-primary:disabled:focus, .wp-block-button__link:disabled:focus {
  transform: none;
  box-shadow: none;
}
.btn-secondary, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt, .woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
  background: #fff;
  color: #e11d73;
}
.btn-secondary:hover, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button:hover, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary:hover,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt:hover,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt:hover,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt:hover,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt:hover,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt:hover,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt:hover, .woocommerce #respond input#submit.alt:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover, .btn-secondary:focus, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button:focus, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary:focus,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt:focus,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt:focus,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt:focus,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt:focus,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt:focus,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt:focus, .woocommerce #respond input#submit.alt:focus,
.woocommerce a.button.alt:focus,
.woocommerce button.button.alt:focus,
.woocommerce input.button.alt:focus, .btn-secondary:active, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button:active, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary:active,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt:active,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt:active,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt:active,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt:active,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt:active,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt:active, .woocommerce #respond input#submit.alt:active,
.woocommerce a.button.alt:active,
.woocommerce button.button.alt:active,
.woocommerce input.button.alt:active {
  background: #fff;
  color: #e11d73;
}
.btn-secondary:disabled, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button:disabled, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary:disabled,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt:disabled,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt:disabled,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt:disabled,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt:disabled,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt:disabled,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt:disabled, .woocommerce #respond input#submit.alt:disabled,
.woocommerce a.button.alt:disabled,
.woocommerce button.button.alt:disabled,
.woocommerce input.button.alt:disabled {
  background: #eaeaea;
  box-shadow: none;
  cursor: default;
}
.btn-secondary:disabled:hover, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button:disabled:hover, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary:disabled:hover,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt:disabled:hover,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt:disabled:hover,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt:disabled:hover,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt:disabled:hover,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt:disabled:hover,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt:disabled:hover, .woocommerce #respond input#submit.alt:disabled:hover,
.woocommerce a.button.alt:disabled:hover,
.woocommerce button.button.alt:disabled:hover,
.woocommerce input.button.alt:disabled:hover, .btn-secondary:disabled:focus, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button:disabled:focus, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary:disabled:focus,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt:disabled:focus,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt:disabled:focus,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt:disabled:focus,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt:disabled:focus,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt:disabled:focus,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt:disabled:focus, .woocommerce #respond input#submit.alt:disabled:focus,
.woocommerce a.button.alt:disabled:focus,
.woocommerce button.button.alt:disabled:focus,
.woocommerce input.button.alt:disabled:focus {
  transform: none;
  box-shadow: none;
}
.btn-link {
  display: inline-block;
  transition: all 0.2s;
}
.btn-colored {
  position: relative;
}
.btn-colored:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  transition: all 0.3s;
}
.btn-colored:hover:after, .btn-colored:focus:after {
  background: rgba(0, 0, 0, 0.1);
}
.btn-outlined {
  background-color: transparent;
  color: #e11d73;
  border: 2px solid #e11d73;
}
.btn-outlined:hover, .btn-outlined:focus, .btn-outlined:active {
  text-decoration: none;
  color: #e11d73;
  background-color: transparent;
  border: 2px solid #e11d73;
  outline: none;
}

.chamfr-cart-control-links {
  display: flex;
  justify-content: end;
  font-size: 14px;
}
.chamfr-cart-control-links > a {
  margin-left: 2rem;
  text-decoration: underline;
}
.chamfr-cart-control-links > a:hover {
  text-decoration: none;
}
.chamfr-cart-control-links > a:first-child {
  margin-left: 0;
}
.chamfr-cart-control-links > .share-cart,
.chamfr-cart-control-links > .download-quote,
.chamfr-cart-control-links > .sign-in-for-quote {
  display: inline-block;
  padding-left: 24px;
  position: relative;
  color: #e11d73;
}
.chamfr-cart-control-links > .share-cart:before,
.chamfr-cart-control-links > .download-quote:before,
.chamfr-cart-control-links > .sign-in-for-quote:before {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 20px;
  height: 20px;
}
.chamfr-cart-control-links .share-cart:before {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.16335 5.10742H1.09668V19.1074H15.0967V13.0408' stroke='%23E11D73' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.46606 10.6418L15.4661 4.64185' stroke='%23E11D73' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.89255 3.36911H16.6309V10.1074' stroke='%23E11D73' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.chamfr-cart-control-links .sign-in-for-quote:before,
.chamfr-cart-control-links .download-quote:before {
  height: 17px;
  top: 2px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7.49512V11.9657C1 14.1748 2.79086 15.9657 5 15.9657H15C17.2091 15.9657 19 14.1748 19 11.9657V7.49512' stroke='%23E11D73' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.085 1.14209V9.61268' stroke='%23E11D73' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.765 6.43628L10.0003 11.201L5.2356 6.43628' stroke='%23E11D73' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

#cart-widget-overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.15);
}

.cart-open #cart-widget-overlay {
  display: block;
}

.cart-open .page-header .navbar-toggler {
  z-index: 350;
}

.cart-widget {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20000;
  width: 54rem;
  background: #fff;
  transition: all 0.5s ease-out;
  transform: translateX(100%);
  color: #000;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .cart-widget {
    width: 100%;
  }
}
.cart-widget .cart-header {
  padding: 2rem;
}
.cart-widget .cart-header .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
}
.cart-widget .cart-header .close {
  color: #e11d73;
  opacity: 1;
  line-height: 20px;
  cursor: pointer;
}
.cart-widget .cart-items {
  padding: 0 2rem;
  overflow-y: scroll;
}
.cart-widget .cart-items .cart-item {
  border-top: 1px solid #b4b4b4;
  padding: 2rem 0;
  display: flex;
  gap: 2rem;
}
.cart-widget .cart-items .cart-item-image {
  flex: 0 0 48px;
}
.cart-widget .cart-items .cart-item-details {
  flex-grow: 1;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}
.cart-widget .cart-items .cart-item-details-title {
  color: #e11d73;
}
.cart-widget .cart-items .cart-item-details .sold-by {
  font-size: 14px;
  font-weight: 400;
  color: #636363;
}
.cart-widget .mini-cart-form {
  padding-right: 40px;
  position: relative;
  flex: 0 0 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.cart-widget .mini-cart-form .details-qty {
  display: none;
}
.cart-widget .mini-cart-form .details-price {
  font-size: 14px;
  font-weight: 700;
}
.cart-widget .mini-cart-form .update {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #e11d73;
  border-color: #e11d73;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 12px;
}
.cart-widget .mini-cart-form .update:hover, .cart-widget .mini-cart-form .update:focus {
  text-decoration: none;
}
.cart-widget .mini-cart-form .update:hover, .cart-widget .mini-cart-form .update:focus {
  color: #9f325d;
  border-color: #9f325d;
  background-color: transparent;
}
.cart-widget .mini-cart-form .update:disabled {
  color: #b4b4b4;
  border-color: #b4b4b4;
  background-color: transparent;
}
.cart-widget .mini-cart-form .update:disabled {
  display: none;
}
.cart-widget .mini-cart-form .update.loading {
  cursor: default;
  pointer-events: none;
  opacity: 0.3;
}
.cart-widget .mini-cart-form .quantity .qty {
  font-size: 14px;
  padding: 10px;
  width: 54px;
  line-height: 1;
  height: 36px;
  text-align: center;
}
.cart-widget .mini-cart-form .remove {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  cursor: pointer;
}
.cart-widget .cart-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f6f6f6;
  padding: 2rem;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
}
.cart-widget .cart-footer .subtotal {
  font-size: 2rem;
  line-height: 1;
  font-weight: 800;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #b4b4b4;
}
.cart-widget .cart-footer .subtotal .amount {
  float: right;
}
.cart-widget .cart-footer .control-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}
.cart-widget .cart-footer .control-buttons .go-to-cart {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #e11d73;
  border-color: #e11d73;
  background-color: transparent;
  flex-grow: 1;
}
.cart-widget .cart-footer .control-buttons .go-to-cart:hover, .cart-widget .cart-footer .control-buttons .go-to-cart:focus {
  text-decoration: none;
}
.cart-widget .cart-footer .control-buttons .go-to-cart:hover, .cart-widget .cart-footer .control-buttons .go-to-cart:focus {
  color: #9f325d;
  border-color: #9f325d;
  background-color: transparent;
}
.cart-widget .cart-footer .control-buttons .go-to-cart:disabled {
  color: #b4b4b4;
  border-color: #b4b4b4;
  background-color: transparent;
}
.cart-widget .cart-footer .control-buttons .proceed-to-checkout {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  border-color: #e11d73;
  background-color: #e11d73;
  flex-grow: 1;
}
.cart-widget .cart-footer .control-buttons .proceed-to-checkout:hover, .cart-widget .cart-footer .control-buttons .proceed-to-checkout:focus {
  text-decoration: none;
}
.cart-widget .cart-footer .control-buttons .proceed-to-checkout:hover, .cart-widget .cart-footer .control-buttons .proceed-to-checkout:focus {
  color: #FFFFFF;
  background-color: #9f325d;
  border-color: #9f325d;
}
.cart-widget .cart-footer .control-buttons .proceed-to-checkout:disabled {
  color: #FFFFFF;
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}
.cart-widget .cart-footer .chamfr-cart-control-links a {
  color: #e11d73;
}

.cart-open #cart-widget-overlay,
.cart-open .cart-widget {
  transform: none;
  box-shadow: 0 0 5rem rgba(0, 0, 0, 0.15);
}

.woocommerce button.button.loading {
  padding: 2px 19px 3px 10px !important;
}
.woocommerce button.button.loading::after {
  top: 0.19em;
  right: 0.2em;
}
@media (max-width: 767.98px) {
  .woocommerce button.button.loading::after {
    top: 1.15em;
  }
}

body.woocommerce-cart #mini-cart-icon,
body.woocommerce-checkout #mini-cart-icon {
  pointer-events: none;
}

.widget_shopping_cart_content .chamfr-mini-cart-wrapper,
.widget_shopping_cart_content .mini-cart-footer {
  display: none !important;
}

.wsc_save_share_cart_btns .wsc_share_cart {
  display: none !important;
}

.chamfr-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(43, 43, 43, 0.3137254902);
}
.chamfr-popup .popup-content {
  position: absolute;
  background-color: #FFFFFF;
  padding: 36px;
  width: 100%;
  max-width: 840px;
  height: 100%;
  max-height: 90vh;
  overflow-y: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.chamfr-popup .popup-content .close {
  font-size: 0;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10.9999 10.9999' stroke='%232B2B2B' stroke-linecap='round'/%3E%3Cpath d='M11 1L1.00008 10.9999' stroke='%232B2B2B' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}

.checkout-steps h1 {
  font-size: 38px;
  font-weight: 800;
}

.checkout-step {
  border-bottom: 1px solid #b4b4b4;
  padding: 2rem 0;
}
.checkout-step-heading {
  display: flex;
}
.checkout-step-heading h4 {
  font-size: 22px;
  font-weight: 800;
  flex-grow: 1;
  margin: 0;
  color: #636363;
}
.checkout-step h5 {
  font-size: 18px;
  font-weight: 700;
}
.checkout-step-edit {
  font-size: 14px;
  display: none;
}
.checkout-step-edit a {
  color: #000;
  text-decoration: underline;
}
.checkout-step-edit a:hover {
  text-decoration: none;
}
.checkout-step-content, .checkout-step-summary {
  display: none;
  padding: 2rem;
  font-size: 14px;
}
@media (max-width: 767.98px) {
  .checkout-step-content, .checkout-step-summary {
    padding: 2rem 0;
  }
}
.checkout-step-button {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  border-color: #e11d73;
  background-color: #e11d73;
  min-width: 300px;
}
.checkout-step-button:hover, .checkout-step-button:focus {
  text-decoration: none;
}
.checkout-step-button:hover, .checkout-step-button:focus {
  color: #FFFFFF;
  background-color: #9f325d;
  border-color: #9f325d;
}
.checkout-step-button:disabled {
  color: #FFFFFF;
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}
.checkout-step-active h4 {
  color: #000;
}
.checkout-step-active .checkout-step-content {
  display: block;
}
.checkout-step-completed h4 {
  color: #e11d73;
}
.checkout-step-completed h4:after {
  content: "";
  display: inline-block;
  margin-left: 8px;
  transform: translateY(2px);
  width: 18px;
  height: 18px;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 9.90849L6.44231 15.125L16 2.875' stroke='%23E11D73' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.checkout-step-completed .checkout-step-edit {
  display: block;
}
.checkout-step-completed .checkout-step-summary {
  display: block;
}
.checkout-step input.error {
  border-color: #CA3737;
}
.checkout-step label.error {
  color: #CA3737;
}

.checkout-step-addresses #ms_shipping_addresses_field {
  display: none;
}
.checkout-step-addresses .saved-addresses {
  padding-bottom: 48px;
}
.checkout-step-addresses .saved-addresses .list {
  display: flex;
  flex-wrap: wrap;
}
.checkout-step-addresses .saved-addresses .list .address {
  flex: 0 0 33%;
  padding-right: 2rem;
  padding-bottom: 2rem;
}
@media (max-width: 767.98px) {
  .checkout-step-addresses .saved-addresses .list .address {
    flex: 0 0 100%;
    padding-right: 0;
  }
}
.checkout-step-addresses .saved-addresses .list .address input[type=radio] + label {
  font: 400 12px/1.3 "Public Sans", sans-serif;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f6f6f6;
  border-radius: 8px;
  border: 2px solid #f6f6f6;
  padding: 16px;
  margin: 0;
}
@media (min-width: 576px) {
  .checkout-step-addresses .saved-addresses .list .address input[type=radio] + label {
    font-size: 14px;
  }
}
.checkout-step-addresses .saved-addresses .list .address input[type=radio] + label::before {
  margin: 0 0 8px 0;
}
.checkout-step-addresses .saved-addresses .list .address input[type=radio] + label .default {
  position: absolute;
  top: 22px;
  left: 56px;
  font-weight: 600;
}
.checkout-step-addresses .saved-addresses .list .address input[type=radio] + label .default a {
  display: none;
  font-weight: 400;
}
.checkout-step-addresses .saved-addresses .list .address input[type=radio] + label .default a.loading {
  font-size: 0;
  transform: translateY(-9px);
  display: inline-block;
  width: 30px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #000 90%, rgba(0, 0, 0, 0)) 0/33.3333333333% 100% space;
  clip-path: inset(0 100% 0 0);
  animation: ldots 1s steps(4) infinite;
}
.checkout-step-addresses .saved-addresses .list .address input[type=radio] + label .contact {
  margin: 16px 0;
}
.checkout-step-addresses .saved-addresses .list .address input[type=radio]:checked + label {
  border-color: #e11d73;
}
.checkout-step-addresses .saved-addresses .list .address input[type=radio]:checked + label::before {
  opacity: 1;
}
.checkout-step-addresses .saved-addresses .list .address input[type=radio]:checked + label .default {
  position: absolute;
  top: 22px;
  left: 56px;
}
.checkout-step-addresses .saved-addresses .list .address.default-address .default span {
  display: inline;
}
.checkout-step-addresses .saved-addresses .list .address.default-address .default a {
  display: none;
}
.checkout-step-addresses .saved-addresses .list .address:not(.default-address) .default span {
  display: none;
}
.checkout-step-addresses .saved-addresses .list .address:not(.default-address) input[type=radio]:checked + label .default a {
  display: inline-block;
}
.checkout-step-addresses .saved-addresses .new > a {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #e11d73;
  border-color: #e11d73;
  background-color: transparent;
}
.checkout-step-addresses .saved-addresses .new > a:hover, .checkout-step-addresses .saved-addresses .new > a:focus {
  text-decoration: none;
}
.checkout-step-addresses .saved-addresses .new > a:hover, .checkout-step-addresses .saved-addresses .new > a:focus {
  color: #9f325d;
  border-color: #9f325d;
  background-color: transparent;
}
.checkout-step-addresses .saved-addresses .new > a:disabled {
  color: #b4b4b4;
  border-color: #b4b4b4;
  background-color: transparent;
}
.checkout-step-addresses .saved-addresses .error {
  color: #CA3737;
}
.checkout-step-addresses .saved-addresses .error p {
  margin-bottom: 16px;
}

.chamfr-address-form .has-error input,
.chamfr-address-form .has-error select {
  border-color: #CA3737;
}
.chamfr-address-form .fields {
  display: flex;
  margin: 0 -16px 36px -16px;
  flex-wrap: wrap;
}
.chamfr-address-form .fields p {
  flex: 0 0 50%;
  padding: 0 16px;
}
@media (max-width: 767.98px) {
  .chamfr-address-form .fields p {
    flex: 0 0 100%;
  }
}
.chamfr-address-form .fields p abbr {
  text-decoration: none;
}
.chamfr-address-form .fields p abbr.required {
  color: #CA3737;
}
.chamfr-address-form .fields #shipping_country_field,
.chamfr-address-form .fields #billing_country_field {
  flex-basis: 100%;
}
.chamfr-address-form .fields #shipping_country_field > span,
.chamfr-address-form .fields #billing_country_field > span {
  display: block;
  width: calc(50% - 16px);
}
@media (max-width: 767.98px) {
  .chamfr-address-form .fields #shipping_country_field > span,
.chamfr-address-form .fields #billing_country_field > span {
    width: 100%;
  }
}
.chamfr-address-form .fields #shipping_address_2_field,
.chamfr-address-form .fields #billing_address_2_field {
  padding-top: 29px;
}
.chamfr-address-form .submit {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  border-color: #e11d73;
  background-color: #e11d73;
  width: 300px;
  max-width: 100%;
}
.chamfr-address-form .submit:hover, .chamfr-address-form .submit:focus {
  text-decoration: none;
}
.chamfr-address-form .submit:hover, .chamfr-address-form .submit:focus {
  color: #FFFFFF;
  background-color: #9f325d;
  border-color: #9f325d;
}
.chamfr-address-form .submit:disabled {
  color: #FFFFFF;
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}
@media (max-width: 767.98px) {
  .chamfr-address-form .submit {
    margin-bottom: 16px;
  }
}
.chamfr-address-form .submit.loading {
  font-size: 0;
  display: inline-block;
  width: 30px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #000 90%, rgba(0, 0, 0, 0)) 0/33.3333333333% 100% space;
  clip-path: inset(0 100% 0 0);
  animation: ldots 1s steps(4) infinite;
}
.chamfr-address-form .loading {
  display: inline-block;
  width: 30px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #000 90%, rgba(0, 0, 0, 0)) 0/33.3333333333% 100% space;
  clip-path: inset(0 100% 0 0);
  animation: ldots 1s steps(4) infinite;
}
.chamfr-address-form .cancel {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #e11d73;
  border-color: #e11d73;
  background-color: transparent;
  width: 300px;
  max-width: 100%;
}
.chamfr-address-form .cancel:hover, .chamfr-address-form .cancel:focus {
  text-decoration: none;
}
.chamfr-address-form .cancel:hover, .chamfr-address-form .cancel:focus {
  color: #9f325d;
  border-color: #9f325d;
  background-color: transparent;
}
.chamfr-address-form .cancel:disabled {
  color: #b4b4b4;
  border-color: #b4b4b4;
  background-color: transparent;
}
.chamfr-address-form .errors {
  color: #CA3737;
  list-style-type: none;
  padding: 0;
  margin: 0 0 16px;
}

.checkout-step-sign-in form.login {
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
}
.checkout-step-sign-in form.login .form-row-first,
.checkout-step-sign-in form.login .form-row-last {
  float: none;
  padding: 0;
  width: auto;
}
.checkout-step-sign-in form.login .input-text {
  border: 1px solid #b4b4b4;
  border-radius: 4px;
}
.checkout-step-sign-in form.login .woocommerce-form-login__submit {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  border-color: #e11d73;
  background-color: #e11d73;
  box-shadow: none;
  width: 70%;
}
.checkout-step-sign-in form.login .woocommerce-form-login__submit:hover, .checkout-step-sign-in form.login .woocommerce-form-login__submit:focus {
  text-decoration: none;
}
.checkout-step-sign-in form.login .woocommerce-form-login__submit:hover, .checkout-step-sign-in form.login .woocommerce-form-login__submit:focus {
  color: #FFFFFF;
  background-color: #9f325d;
  border-color: #9f325d;
}
.checkout-step-sign-in form.login .woocommerce-form-login__submit:disabled {
  color: #FFFFFF;
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}
.checkout-step-sign-in .checkout-as-guest-button {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #e11d73;
  border-color: #e11d73;
  background-color: transparent;
}
.checkout-step-sign-in .checkout-as-guest-button:hover, .checkout-step-sign-in .checkout-as-guest-button:focus {
  text-decoration: none;
}
.checkout-step-sign-in .checkout-as-guest-button:hover, .checkout-step-sign-in .checkout-as-guest-button:focus {
  color: #9f325d;
  border-color: #9f325d;
  background-color: transparent;
}
.checkout-step-sign-in .checkout-as-guest-button:disabled {
  color: #b4b4b4;
  border-color: #b4b4b4;
  background-color: transparent;
}
@media (min-width: 576px) {
  .checkout-step-sign-in .checkout-as-guest-button {
    width: 70%;
  }
}

.checkout-step-delivery-options .package {
  display: block;
  margin-bottom: 2rem;
  border: 1px solid #b4b4b4;
  border-radius: 1rem;
  overflow: hidden;
}
.checkout-step-delivery-options .products {
  padding: 2rem;
}
.checkout-step-delivery-options .single-cart-product {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #fff;
  display: flex;
  font-size: 14px;
}
.checkout-step-delivery-options .single-cart-product:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
.checkout-step-delivery-options .single-cart-product .product-name {
  flex-grow: 1;
  padding-right: 2rem;
}
.checkout-step-delivery-options .single-cart-product .product-name .name {
  font-weight: 600;
}
.checkout-step-delivery-options .single-cart-product .product-name .quantity {
  color: #9f325d;
}
.checkout-step-delivery-options .single-cart-product .product-name .lead-time {
  color: #56b8a1;
  font-size: 13px;
}
.checkout-step-delivery-options .single-cart-product .product-subtotal {
  font-weight: 800;
  text-align: right;
}
.checkout-step-delivery-options .shipping {
  background-color: #fff;
  padding: 2rem;
  display: flex;
  font-size: 14px;
}
.checkout-step-delivery-options .shipping .shipped-by {
  flex: 0 0 50%;
  padding-right: 2rem;
  font-weight: 700;
}
.checkout-step-delivery-options .shipping .shipped-by p {
  font-weight: 400;
}
.checkout-step-delivery-options .shipping .shipping-speed {
  flex: 0 0 50%;
}
.checkout-step-delivery-options .shipping .shipping-speed .select2-container {
  width: 100%;
}

.chamfr-filter {
  background-color: #2b2b2b;
  color: #fff;
  border-radius: 10px;
  padding: 2rem 1.5rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  flex: 0 0 22.9%;
  margin-bottom: 2rem;
  font-size: 1.4rem;
}
@media (max-width: 1199.98px) {
  .chamfr-filter {
    flex: 0 0 100%;
  }
}
.chamfr-filter .widget_search {
  position: relative;
}
@media (max-width: 1200px) {
  .chamfr-filter .widget_search {
    margin: 0 2.5rem 0 2.5rem;
  }
}
.chamfr-filter .widget_search .caption {
  display: none;
}
.chamfr-filter .widget_search input[type=text] {
  border-color: #fff;
  height: auto;
  padding: 0.8rem 3.2rem 0.8rem 0.8rem;
  margin-bottom: 0;
}
.chamfr-filter .widget_search .search-submit {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3.5rem;
  background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
  background-position: 1.2rem 1.3rem;
  background-size: 1.2rem;
  background-repeat: no-repeat;
  background-color: #e11d73;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  color: transparent;
}
.chamfr-filter-actions a {
  display: inline-block;
  padding: 1rem 0;
  color: #e11d73;
  margin-left: 1em;
}
.chamfr-filter-actions a:first-child {
  margin-left: 0;
}
.chamfr-filter-actions a.filters-reset {
  color: #fff;
  margin: 1rem 2.5rem;
  display: block;
}
.chamfr-filter .filters-toggle {
  display: none;
  text-align: center;
  border-top: 1px solid rgba(234, 234, 234, 0.2);
  margin-top: 1rem;
  padding: 1rem 0;
}
.chamfr-filter .filters-toggle a {
  color: #fff;
  text-decoration: none;
  transition: all none;
  font-size: 0;
}
.chamfr-filter .filters-toggle a:before {
  font-size: 1.4rem;
  content: "Filter Results";
}
.chamfr-filter .filters-toggle a.on:before {
  content: "Close Filters";
}
@media (max-width: 1199.98px) {
  .chamfr-filter .filters-toggle {
    display: block;
  }
}
@media (max-width: 1199.98px) {
  .chamfr-filter #filters {
    display: none;
  }
}
.chamfr-filter .widget_ep-facet {
  padding: 1rem 0;
  border-top: 1px solid rgba(234, 234, 234, 0.2);
}
.chamfr-filter .widget_ep-facet .caption {
  position: relative;
  font-size: 1.6rem;
  cursor: pointer;
}
.chamfr-filter .widget_ep-facet .caption .toggle {
  display: block;
  position: absolute;
  top: 0.8rem;
  right: 0;
  width: 0;
  height: 0;
  margin-left: 0.5em;
  border-style: solid;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-color: transparent transparent transparent #e11d73;
}
.chamfr-filter .widget_ep-facet .terms.searchable {
  display: none;
  padding-top: 1rem;
}
.chamfr-filter .widget_ep-facet .terms.searchable input.facet-search {
  background-color: transparent;
  /* White Search Icon */
  /* stylelint-disable-next-line */
  background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 0.7rem 1.3rem;
  border-color: #fff;
  height: auto;
  padding: 0.8rem 0.8rem 0.8rem 3.2rem;
  color: #fff;
  margin-bottom: 0;
}
.chamfr-filter .widget_ep-facet .terms.searchable input.facet-search::placeholder {
  color: #eaeaea;
}
.chamfr-filter .widget_ep-facet .inner {
  display: none;
  margin-top: 1rem;
  max-height: 15em;
  overflow-y: auto;
  scrollbar-width: none;
  flex-direction: column;
}
.chamfr-filter .widget_ep-facet .inner.noscroll {
  max-height: none;
  overflow-y: inherit;
}
.chamfr-filter .widget_ep-facet .inner::-webkit-scrollbar {
  width: 4px;
}
.chamfr-filter .widget_ep-facet .inner::-webkit-scrollbar-track {
  border-radius: 2px;
}
.chamfr-filter .widget_ep-facet .inner::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 2px;
}
.chamfr-filter .widget_ep-facet .inner::-webkit-scrollbar-thumb:hover {
  background: #eaeaea;
}
.chamfr-filter .widget_ep-facet .inner .empty-term {
  display: none;
}
.chamfr-filter .widget_ep-facet .inner .term {
  position: relative;
  margin-top: 0.4rem;
}
.chamfr-filter .widget_ep-facet .inner .term a {
  color: #fff;
}
.chamfr-filter .widget_ep-facet .inner .term a .image {
  display: inline-block;
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
}
.chamfr-filter .widget_ep-facet .inner .term a .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.chamfr-filter .widget_ep-facet .inner .term a:hover, .chamfr-filter .widget_ep-facet .inner .term a:focus, .chamfr-filter .widget_ep-facet .inner .term a:active {
  text-decoration: none;
  color: #fff;
}
.chamfr-filter .widget_ep-facet .inner .term label {
  color: #fff;
  display: inline-block;
}
.chamfr-filter .widget_ep-facet .inner .term label:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid #fff;
  /* stylelint-disable-next-line */
  transition: background-color 0.2s ease-out 0.05s, border-color 0.2s ease-out 0.05s, background-image 0.1s 0.3s;
  cursor: pointer;
}
.chamfr-filter .widget_ep-facet .inner .term label:hover {
  text-decoration: none;
}
.chamfr-filter .widget_ep-facet .inner .term input[type=checkbox]:checked + label:before {
  background: #e11d73 url(../images/check-white.svg) no-repeat center;
  background-size: 12px;
  border: 1px solid #e11d73;
}
.chamfr-filter .widget_ep-facet .inner .term.filtered {
  display: none;
}
.chamfr-filter .widget_ep-facet .inner .term.filtered.selected {
  display: block;
}
.chamfr-filter .widget_ep-facet .inner .term.selected {
  order: -1;
}
.chamfr-filter .widget_ep-facet.open .caption .toggle, .chamfr-filter .widget_ep-facet.active .caption .toggle {
  transform: rotate(90deg);
}
.chamfr-filter .widget_ep-facet.open .terms.searchable,
.chamfr-filter .widget_ep-facet.open .inner, .chamfr-filter .widget_ep-facet.active .terms.searchable,
.chamfr-filter .widget_ep-facet.active .inner {
  display: flex;
}
.chamfr-filter .widget_ep-facet#chamfr_facet_product_cat {
  padding: 3rem 0 0.5rem;
}
.chamfr-filter .widget_ep-facet#chamfr_facet_product_cat .caption {
  padding-bottom: 1rem;
  font-size: 1.6rem;
}
.chamfr-filter .widget_ep-facet#chamfr_facet_product_cat .inner {
  padding-top: 3rem;
  border-top: 1px solid rgba(234, 234, 234, 0.2);
}
.chamfr-filter .widget_ep-facet#chamfr_facet_product_cat .inner .term {
  margin: 0 2.5rem 2.5rem;
}
.chamfr-filter .widget_ep-facet#chamfr_facet_product_cat .inner .term a {
  transition: all 0.3s ease-out;
  font-size: 1.8rem;
  text-decoration: none;
  color: #959595;
}
.chamfr-filter .widget_ep-facet#chamfr_facet_product_cat .inner .term a:hover, .chamfr-filter .widget_ep-facet#chamfr_facet_product_cat .inner .term a:focus, .chamfr-filter .widget_ep-facet#chamfr_facet_product_cat .inner .term a:active {
  color: #fff;
}
.chamfr-filter .widget_ep-facet#chamfr_facet_product_cat .inner .term a .image {
  display: none;
}
.chamfr-filter .btn, .chamfr-filter .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .chamfr-filter .stripe-connect.light-blue, .chamfr-filter .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .chamfr-filter .wcv-button, .chamfr-filter .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .chamfr-filter .button, .chamfr-filter .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .chamfr-filter .website, .chamfr-filter .blog .sticky .post__link a, .blog .sticky .post__link .chamfr-filter a,
.chamfr-filter .archive .sticky .post__link a,
.archive .sticky .post__link .chamfr-filter a, .chamfr-filter .woocommerce #respond input#submit, .woocommerce #respond .chamfr-filter input#submit,
.chamfr-filter .woocommerce a.button,
.woocommerce .chamfr-filter a.button,
.chamfr-filter .woocommerce button.button,
.woocommerce .chamfr-filter button.button,
.chamfr-filter .woocommerce input.button,
.woocommerce .chamfr-filter input.button, .chamfr-filter .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .chamfr-filter a, .chamfr-filter body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .chamfr-filter input.button,
.chamfr-filter body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .chamfr-filter input[type=submit],
.chamfr-filter body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .chamfr-filter input.button,
.chamfr-filter body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .chamfr-filter input[type=submit], .chamfr-filter .wp-block-button__link {
  height: auto;
  padding: 1.6rem 3rem;
  line-height: 1.2;
}
@media (max-width: 359.98px) {
  .chamfr-filter {
    margin-left: -15px;
    margin-right: -15px;
    border-radius: 0;
  }
}
.chamfr-filter .heading {
  position: relative;
}
@media (max-width: 991.98px) {
  .chamfr-filter .heading {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
}
.chamfr-filter .heading h6 {
  font-size: 1.6rem;
  font-weight: 400;
  margin: 0;
}
@media (max-width: 991.98px) {
  .chamfr-filter .heading h6 {
    margin-right: 2rem;
  }
}
.chamfr-filter .heading .reset {
  font-size: 1.3rem;
  position: absolute;
  right: 0;
  bottom: 0;
  line-height: 1;
  display: none;
}
@media (max-width: 991.98px) {
  .chamfr-filter .heading .reset {
    position: static;
  }
}
.chamfr-filter .heading .reset.active {
  display: block;
}
.chamfr-filter .heading .toggle {
  position: absolute;
  top: 50%;
  right: 0;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 992px) {
  .chamfr-filter .heading .toggle {
    display: none;
  }
}
.chamfr-filter .heading .toggle:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-color: transparent transparent transparent #e11d73;
  transition: transform 0.3s ease;
}
.chamfr-filter .heading .toggle.active:before {
  transform: rotate(90deg);
}
.chamfr-filter form#chamfr-filter {
  border-top: 1px solid rgba(234, 234, 234, 0.2);
  padding-top: 3rem;
  margin-top: 1rem;
}
@media (max-width: 991.98px) {
  .chamfr-filter form#chamfr-filter {
    display: none;
    padding-top: 2rem;
    margin-top: 2rem;
  }
}
.chamfr-filter ul {
  list-style-type: none;
  padding: 0 2.5rem;
  margin: 0;
  margin-bottom: 3rem;
}
.chamfr-filter ul li {
  margin: 0 0 3rem;
  font-size: 1.8rem;
  line-height: 1;
}
.chamfr-filter ul li:last-child {
  margin-bottom: 0;
}
.chamfr-filter ul li input[type=checkbox] + label {
  color: #fff;
  opacity: 0.5;
  font-size: 1.8rem;
  margin: 0;
  transition: opacity 0.3s ease-out;
  cursor: pointer;
}
.chamfr-filter ul li input[type=checkbox] + label:hover, .chamfr-filter ul li input[type=checkbox] + label:focus, .chamfr-filter ul li input[type=checkbox] + label:active {
  opacity: 1;
}
.chamfr-filter ul li input[type=checkbox] + label:before {
  display: none;
}
.chamfr-filter ul li input[type=checkbox]:checked + label {
  opacity: 1;
}
.chamfr-filter .not-finding {
  padding: 3.5rem 2.5rem 2.5rem;
  border-top: 1px solid rgba(234, 234, 234, 0.2);
}
.chamfr-filter .not-finding .btn, .chamfr-filter .not-finding .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .chamfr-filter .not-finding .stripe-connect.light-blue, .chamfr-filter .not-finding .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .chamfr-filter .not-finding .wcv-button, .chamfr-filter .not-finding .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .chamfr-filter .not-finding .button, .chamfr-filter .not-finding .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .chamfr-filter .not-finding .website, .chamfr-filter .not-finding .blog .sticky .post__link a, .blog .sticky .post__link .chamfr-filter .not-finding a,
.chamfr-filter .not-finding .archive .sticky .post__link a,
.archive .sticky .post__link .chamfr-filter .not-finding a, .chamfr-filter .not-finding .woocommerce #respond input#submit, .woocommerce #respond .chamfr-filter .not-finding input#submit,
.chamfr-filter .not-finding .woocommerce a.button,
.woocommerce .chamfr-filter .not-finding a.button,
.chamfr-filter .not-finding .woocommerce button.button,
.woocommerce .chamfr-filter .not-finding button.button,
.chamfr-filter .not-finding .woocommerce input.button,
.woocommerce .chamfr-filter .not-finding input.button, .chamfr-filter .not-finding .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .chamfr-filter .not-finding a, .chamfr-filter .not-finding body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .chamfr-filter .not-finding input.button,
.chamfr-filter .not-finding body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .chamfr-filter .not-finding input[type=submit],
.chamfr-filter .not-finding body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .chamfr-filter .not-finding input.button,
.chamfr-filter .not-finding body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .chamfr-filter .not-finding input[type=submit], .chamfr-filter .not-finding .wp-block-button__link {
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (max-width: 1424.98px) {
  .chamfr-filter .not-finding {
    padding: 3.5rem 0 0;
  }
}
@media (max-width: 991.98px) {
  .chamfr-filter .not-finding {
    display: none;
  }
}

.not-finding-after-table {
  margin: 0 -15px;
  padding: 2rem;
  background-color: #2b2b2b;
  color: #fff;
  text-align: center;
}

.floating-share,
.single__container .floating-share {
  display: none;
  position: absolute;
  top: 0;
  margin-left: -11.5rem;
}
@media (min-width: 992px) {
  .floating-share,
.single__container .floating-share {
    display: block;
  }
}
.floating-share.fixed,
.single__container .floating-share.fixed {
  position: fixed;
}
.floating-share.fixed-bottom,
.single__container .floating-share.fixed-bottom {
  position: absolute;
  top: auto;
  bottom: 0;
}
.floating-share ul,
.single__container .floating-share ul {
  list-style: none;
  margin: 4rem 0 0;
  padding: 0;
}
.floating-share ul li,
.single__container .floating-share ul li {
  display: block;
  margin: 0 0 1.5rem;
}
.floating-share ul li:last-of-type,
.single__container .floating-share ul li:last-of-type {
  margin: 0;
}
.floating-share ul li a,
.single__container .floating-share ul li a {
  display: block;
  width: 34px;
  height: 34px;
  border: 2px solid #9f325d;
  border-radius: 50%;
  line-height: 30px;
  text-decoration: none;
  text-align: center;
  color: #9f325d;
}

.main-footer {
  margin-top: 60px;
  background-color: #2b2b2b;
}

.page-footer {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  color: #fff;
  padding-bottom: 0;
  padding-top: 0;
}
@media (min-width: 360px) {
  .page-footer {
    max-width: 360px;
  }
}
@media (min-width: 576px) {
  .page-footer {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .page-footer {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .page-footer {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .page-footer {
    max-width: 1140px;
  }
}
@media (min-width: 1425px) {
  .page-footer {
    max-width: 1360px;
  }
}
.page-footer a {
  color: #fff;
}
.page-footer .top-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 55px 0 47px;
}
@media (max-width: 991.98px) {
  .page-footer .top-footer {
    flex-wrap: wrap;
    row-gap: 30px;
  }
}
@media (max-width: 575.98px) {
  .page-footer .top-footer {
    padding: 40px 0 47px;
    row-gap: 0;
  }
}
@media (min-width: 992px) {
  .page-footer .company {
    flex: 0 0 50%;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .page-footer .company {
    flex: 0 0 43%;
    width: 43%;
  }
}
@media (min-width: 1425px) {
  .page-footer .company {
    flex: 0 0 36%;
    width: 36%;
  }
}
.page-footer .company .logo {
  width: 145px;
  height: 32px;
  background-image: url(../images/logo.svg);
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 0;
  margin-bottom: 23px;
}
@media (min-width: 576px) {
  .page-footer .company .logo {
    width: 209px;
    height: 48px;
    margin-right: 4rem;
  }
}
@media (min-width: 1425px) {
  .page-footer .company .logo {
    margin-right: 57px;
  }
}
@media (max-width: 575.98px) {
  .page-footer .company .logo {
    width: 209px;
    height: 48px;
  }
}
.page-footer .company .footer-paragraph {
  margin-bottom: 30px;
}
@media (max-width: 575.98px) {
  .page-footer .company .footer-paragraph {
    margin-bottom: 23px;
  }
}
.page-footer .company .footer-paragraph p {
  font-size: 14px;
  line-height: 24px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #A9A9A9;
}
.page-footer .company .social {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
}
.page-footer .company .social li {
  margin: 0 12px 0 0;
}
.page-footer .company .social li:last-child {
  margin-right: 0;
}
.page-footer .company .social li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border: 1px solid #111111;
  font-size: 24px;
  border-radius: 50%;
  transition: all 0.3s ease-in;
  background-color: #111111;
}
.page-footer .company .social li a i {
  transition: transform 0.3s ease-in, color 0.1s ease-in;
}
.page-footer .company .social li a:hover {
  text-decoration: none;
  color: #FFFFFF;
  background-color: #e11d73;
  border-color: #e11d73;
}
.page-footer .company .social li a:hover i {
  transform: rotateY(360deg);
}
.page-footer .company .social + p {
  margin-top: 4rem;
}
@media (max-width: 1199.98px) {
  .page-footer .company .social + p {
    margin-top: 2rem;
    text-align: center;
  }
}
@media (min-width: 992px) {
  .page-footer .footer-newsletter {
    flex: 0 0 50%;
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .page-footer .footer-newsletter {
    flex: 0 0 43%;
    width: 43%;
  }
}
@media (min-width: 1425px) {
  .page-footer .footer-newsletter {
    flex: 0 0 36%;
    width: 36%;
  }
}
.page-footer .mid-footer {
  padding: 47px 0 45px;
}
@media (max-width: 575.98px) {
  .page-footer .mid-footer {
    padding: 0 0 45px;
  }
}
@media (max-width: 575.98px) {
  .page-footer .mid-footer .after-menu-item {
    margin: 0 -15px;
    padding: 1.5rem;
    border-bottom: 1px solid #636363;
  }
}
.page-footer .mid-footer .after-menu-item a {
  font-size: 21px;
  line-height: 24.68px;
  color: #e11d73;
  font-weight: 800;
}
.page-footer .mid-footer .after-menu-item a:hover {
  text-decoration: none;
}
@media (min-width: 576px) {
  .page-footer .mid-footer .after-menu-item a {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.45px;
    padding: 20px 0 10px 22px;
    position: relative;
    display: inline-block;
    color: #b4b4b4;
  }
  .page-footer .mid-footer .after-menu-item a::after {
    content: "";
    position: absolute;
    width: 0.5px;
    height: 100%;
    background-color: #A9A9A9;
    left: 0;
    top: 0;
    transition: all 0.3s ease-in;
  }
  .page-footer .mid-footer .after-menu-item a:hover {
    text-decoration: none;
  }
  .page-footer .mid-footer .after-menu-item a:hover::after {
    background-color: #e11d73;
  }
}
.page-footer .footer-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (min-width: 576px) {
  .page-footer .footer-menu .menu {
    display: flex;
  }
}
@media (max-width: 991.98px) {
  .page-footer .footer-menu .menu {
    flex-wrap: wrap;
  }
}
.page-footer .footer-menu .menu > .menu-item {
  position: relative;
  margin: 0 -15px;
  padding: 1.5rem;
  border-top: 1px solid #636363;
  /* stylelint-disable-next-line no-descending-specificity */
}
.page-footer .footer-menu .menu > .menu-item:last-of-type {
  border-bottom: 1px solid #636363;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .page-footer .footer-menu .menu > .menu-item {
    font-size: 1.2rem;
  }
}
@media (min-width: 576px) {
  .page-footer .footer-menu .menu > .menu-item {
    min-width: 10rem;
    width: 0;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    border: none;
  }
  .page-footer .footer-menu .menu > .menu-item:last-of-type {
    padding-right: 0;
    border: none;
  }
}
@media (max-width: 991.98px) and (min-width: 576px) {
  .page-footer .footer-menu .menu > .menu-item {
    width: 50%;
    margin-top: 20px;
  }
  .page-footer .footer-menu .menu > .menu-item:first-child {
    width: 100%;
  }
}
.page-footer .footer-menu .menu > .menu-item:after {
  content: "";
  position: absolute;
  top: 24px;
  right: 15px;
  transform: rotate(-90deg);
  transition: transform 0.3s ease-out;
  width: 12px;
  height: 8px;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%23fa006a' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
}
@media (min-width: 576px) {
  .page-footer .footer-menu .menu > .menu-item:after {
    display: none;
  }
}
.page-footer .footer-menu .menu > .menu-item .sub-menu {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
}
@media (min-width: 576px) {
  .page-footer .footer-menu .menu > .menu-item .sub-menu {
    max-height: none;
    margin-top: 22px;
  }
}
.page-footer .footer-menu .menu > .menu-item .sub-menu > .menu-item:first-of-type {
  margin-top: 1.6rem;
}
@media (min-width: 576px) {
  .page-footer .footer-menu .menu > .menu-item .sub-menu > .menu-item:first-of-type {
    margin-top: 0;
  }
}
.page-footer .footer-menu .menu > .menu-item .sub-menu > .menu-item a {
  padding: 10px 0 10px 22px;
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 16.45px;
}
.page-footer .footer-menu .menu > .menu-item .sub-menu > .menu-item a::after {
  content: "";
  position: absolute;
  width: 0.5px;
  height: 100%;
  background-color: #A9A9A9;
  left: 0;
  top: 0;
  transition: all 0.3s ease-in;
}
.page-footer .footer-menu .menu > .menu-item .sub-menu > .menu-item a:hover {
  text-decoration: none;
}
.page-footer .footer-menu .menu > .menu-item .sub-menu > .menu-item a:hover::after {
  background-color: #e11d73;
}
.page-footer .footer-menu .menu > .menu-item.li-open:after {
  transform: rotate(0);
}
.page-footer .footer-menu .menu > .menu-item.li-open .sub-menu {
  max-height: 100rem;
}
@media (min-width: 576px) {
  .page-footer .footer-menu .menu > .menu-item.li-open .sub-menu {
    max-height: none;
  }
}
@media (min-width: 576px) {
  .page-footer .footer-menu .menu > .menu-item.columns-1 {
    flex-grow: 1;
  }
  .page-footer .footer-menu .menu > .menu-item.columns-1 .sub-menu {
    columns: 1;
  }
  .page-footer .footer-menu .menu > .menu-item.columns-2 {
    flex-grow: 2;
  }
  .page-footer .footer-menu .menu > .menu-item.columns-2 .sub-menu {
    columns: 2;
  }
  .page-footer .footer-menu .menu > .menu-item.columns-3 {
    flex-grow: 3;
  }
  .page-footer .footer-menu .menu > .menu-item.columns-3 .sub-menu {
    columns: 3;
  }
  .page-footer .footer-menu .menu > .menu-item.columns-4 {
    flex-grow: 4;
  }
  .page-footer .footer-menu .menu > .menu-item.columns-4 .sub-menu {
    columns: 4;
  }
}
.page-footer .footer-menu .menu > .menu-item > a {
  display: block;
  color: #e11d73;
  font-size: 24px;
  font-weight: 800;
  line-height: 28.2px;
}
.page-footer .footer-menu .menu > .menu-item > a:hover, .page-footer .footer-menu .menu > .menu-item > a:focus, .page-footer .footer-menu .menu > .menu-item > a:active {
  text-decoration: none;
}
@media (min-width: 576px) {
  .page-footer .footer-menu .menu > .menu-item > a {
    display: inline;
    pointer-events: none;
  }
}
@media (max-width: 575.98px) {
  .page-footer .footer-menu .menu > .menu-item > a {
    font-size: 21px;
    line-height: 24.68px;
  }
}
@media (min-width: 576px) {
  .page-footer .footer-menu .menu > .menu-item:not(:first-child) .sub-menu a {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.15px;
  }
}

.bottom-footer {
  background-color: #111111;
}
.bottom-footer .bottomline {
  padding: 28px 0 27px;
  font-size: 1.4rem;
  text-align: center;
}
@media (min-width: 768px) {
  .bottom-footer .bottomline {
    display: flex;
    text-align: left;
    column-gap: 17px;
  }
}
@media (min-width: 576px) {
  .bottom-footer .bottomline {
    padding: 20px 0;
  }
}
.bottom-footer .bottomline .copyright {
  margin: 0 0 0.8rem;
}
@media (min-width: 768px) {
  .bottom-footer .bottomline .copyright {
    margin: 0;
  }
}
.bottom-footer .bottomline .copyright p {
  margin: 0 0 1rem;
  color: #A9A9A9;
}
.bottom-footer .bottomline .copyright p:last-child {
  margin-bottom: 0;
}
.bottom-footer .bottomline .terms ul {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
  /* stylelint-disable-next-line no-descending-specificity */
}
@media (min-width: 768px) {
  .bottom-footer .bottomline .terms ul {
    display: flex;
  }
}
.bottom-footer .bottomline .terms ul > li {
  display: inline-block;
  margin: 0 10px 0 0;
  padding: 0 10px 0 0;
  border-right: 1px solid #A9A9A9;
}
.bottom-footer .bottomline .terms ul > li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
.bottom-footer .bottomline .terms a {
  color: #A9A9A9;
}

.checkout-footer {
  background-color: #0f0f0f;
  color: #fff;
  padding: 2rem 0;
}
.checkout-footer > div {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  text-align: right;
}
@media (min-width: 360px) {
  .checkout-footer > div {
    max-width: 360px;
  }
}
@media (min-width: 576px) {
  .checkout-footer > div {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .checkout-footer > div {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .checkout-footer > div {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .checkout-footer > div {
    max-width: 1140px;
  }
}
@media (min-width: 1425px) {
  .checkout-footer > div {
    max-width: 1360px;
  }
}
.checkout-footer a {
  color: #fff;
  text-decoration: underline;
}
.checkout-footer a:hover {
  text-decoration: none;
}

body:not(.product-template-default) .pswp {
  display: none;
}

/* stylelint-disable no-descending-specificity */
input:not([type=submit]),
select,
textarea {
  width: 100%;
  height: 5rem;
  margin: 0;
  padding: 1.5rem;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: none;
  color: #313131;
  border-radius: 0.5rem;
}
input:not([type=submit])::placeholder,
select::placeholder,
textarea::placeholder {
  font-weight: 400;
  color: #343a40;
  opacity: 0.5;
}

textarea {
  height: 20rem;
}

label {
  display: block;
  margin: 0 0 0.8rem;
  font-size: 1.4rem;
  font-weight: 400;
}

.required {
  display: inline-block;
  vertical-align: baseline;
  padding-left: 0.3em;
  color: inherit;
}

input[type=radio],
input[type=checkbox] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
input[type=radio] + label,
input[type=checkbox] + label {
  display: inline-block;
  position: relative;
  font-size: 1.4rem;
  font-weight: 400;
}
input[type=radio] + label:before,
input[type=checkbox] + label:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  transition: background-color 0.2s ease-out 0.05s, border-color 0.2s ease-out 0.05s, background-image 0.1s 0.3s;
  cursor: pointer;
}
input[type=radio]:checked + label:before,
input[type=checkbox]:checked + label:before {
  background: #fff url(../images/check.svg) no-repeat center;
  border: 1px solid #ddd;
}

input[type=radio]:not(.checkbox-like) + label:before {
  border-radius: 50%;
}

input[type=radio]:not(.checkbox-like):checked + label:before {
  background: #fff url(../images/circle.svg) no-repeat center;
}

body .gform_wrapper {
  margin: 0;
  padding: 0 0 1rem;
  /* submit */
  /* errors */
}
body .gform_wrapper .gform_body {
  margin: 0;
  /* ul, li */
  /* labels */
  /* inputs */
  /* textarea */
  /* stylelint-disable */
  /* stylelint-enable */
  /* select */
  /* checkbox, radio */
  /* stylelint-disable-next-line no-descending-specificity */
  /* file */
}
body .gform_wrapper .gform_body ul.gform_fields {
  padding: 0;
  list-style: none;
}
body .gform_wrapper .gform_body ul.gform_fields.top_label div.ginput_container {
  margin: 0 !important;
}
body .gform_wrapper .gform_body ul.gform_fields .gfield {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 1rem 0;
  margin: 0 !important;
}
@media (min-width: 992px) {
  body .gform_wrapper .gform_body ul.gform_fields .gfield.gf_left_half, body .gform_wrapper .gform_body ul.gform_fields .gfield.gf_right_half {
    width: 50%;
  }
  body .gform_wrapper .gform_body ul.gform_fields .gfield.gf_left_half {
    padding-right: 1.2rem;
    padding-left: 0;
  }
  body .gform_wrapper .gform_body ul.gform_fields .gfield.gf_right_half {
    padding-left: 1.2rem;
    padding-right: 0;
  }
}
body .gform_wrapper .gform_body ul.gform_fields .gfield input[type=text],
body .gform_wrapper .gform_body ul.gform_fields .gfield input[type=email],
body .gform_wrapper .gform_body ul.gform_fields .gfield select,
body .gform_wrapper .gform_body ul.gform_fields .gfield textarea {
  padding: 1.5rem;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: none;
  color: #313131;
}
body .gform_wrapper .gform_body ul.gform_fields .gfield.gfield_html {
  margin-top: 2rem;
}
body .gform_wrapper .gform_body .top_label .gfield_label,
body .gform_wrapper .gform_body legend.gfield_label {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.1;
  margin: 0 0 0.8rem;
}
body .gform_wrapper .gform_body .field_sublabel_above .ginput_complex.ginput_container label,
body .gform_wrapper .gform_body .field_sublabel_above div[class*=gfield_time_].ginput_container label {
  /* stylelint-disable-line */
  margin: 0;
}
body .gform_wrapper .gform_body ul.gform_fields.top_label .gfield_label + .ginput_container_checkbox,
body .gform_wrapper .gform_body ul.gform_fields.top_label .gfield_label + .ginput_container_radio {
  margin-top: 1.6rem !important;
}
body .gform_wrapper .gform_body .gfield_radio li label {
  margin: 0;
}
body .gform_wrapper .gform_body .top_label .field_sublabel_above label.gfield_label-big,
body .gform_wrapper .gform_body .top_label .gfield_label_before_complex {
  margin: 1rem 0 0.8rem;
  font-size: 1.8rem;
}
body .gform_wrapper .gform_body .top_label .field_sublabel_above label.gfield_label-big + .copy_values_option_container,
body .gform_wrapper .gform_body .top_label .gfield_label_before_complex + .copy_values_option_container {
  margin-top: 2rem;
}
body .gform_wrapper .gform_body .field_sublabel_above .ginput_complex.ginput_container label {
  font-size: 1.4rem;
  letter-spacing: 0;
  margin: 0 0 0.8rem;
}
body .gform_wrapper .gform_body .gfield_required {
  display: inline-block;
  vertical-align: baseline;
  color: inherit;
}
body .gform_wrapper .gform_body .ginput_container input {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: none;
}
body .gform_wrapper .gform_body input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
  width: 100%;
  height: 5rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
}
body .gform_wrapper .gform_body .ginput_container.ginput_container_textarea textarea {
  padding: 1.5rem;
  background: #eaeaea;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
}
body .gform_wrapper .gform_body .ginput_container.ginput_container_select {
  position: relative;
}
body .gform_wrapper .gform_body .ginput_container.ginput_container_select:after {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 20px;
  height: calc( 5rem - 4px );
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%228%22%20height%3D%224%22%20viewBox%3D%220%200%208%204%22%3E%3Cpath%20d%3D%22M4%2C0%2C8%2C4H0Z%22%20transform%3D%22translate%288%204%29%20rotate%28180%29%22%20fill%3D%22%23c05f00%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: center;
}
body .gform_wrapper .gform_body ul.gform_fields li.gfield select {
  height: 5rem;
  padding: 1.5rem;
  margin: 0 !important;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  cursor: pointer;
  -webkit-appearance: none;
  line-height: 1;
}
body .gform_wrapper .gform_body .top_label li ul.gfield_checkbox,
body .gform_wrapper .gform_body .top_label li ul.gfield_radio {
  width: 100%;
  margin: 0;
}
body .gform_wrapper .gform_body ul.gfield_checkbox li,
body .gform_wrapper .gform_body ul.gfield_radio li {
  display: inline-block;
  width: 100%;
  margin: 0 0 1.6rem;
}
@media (max-width: 359.98px) {
  body .gform_wrapper .gform_body ul.gfield_checkbox li,
body .gform_wrapper .gform_body ul.gfield_radio li {
    display: block;
  }
}
body .gform_wrapper .gform_body ul.gfield_checkbox li input[type=checkbox],
body .gform_wrapper .gform_body ul.gfield_radio li input[type=radio] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
body .gform_wrapper .gform_body ul.gfield_checkbox li input[type=checkbox] + label,
body .gform_wrapper .gform_body ul.gfield_radio li input[type=radio] + label {
  display: inline-block;
  position: relative;
  font-size: 1.4rem;
  font-weight: 400;
}
body .gform_wrapper .gform_body ul.gfield_checkbox li input[type=checkbox] + label:before,
body .gform_wrapper .gform_body ul.gfield_radio li input[type=radio] + label:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  background: #fff;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  transition: background-color 0.2s ease-out 0.05s, border-color 0.2s ease-out 0.05s, background-image 0.1s 0.3s;
  cursor: pointer;
}
body .gform_wrapper .gform_body ul.gfield_checkbox li input[type=checkbox]:checked + label:before,
body .gform_wrapper .gform_body ul.gfield_radio li input[type=radio]:checked + label:before {
  background: #fff url(../images/check.svg) no-repeat center;
  border: 1px solid #ddd;
}
body .gform_wrapper .gform_body ul.gfield_radio li input[type=radio] + label:before {
  border-radius: 50%;
}
body .gform_wrapper .gform_body ul.gfield_radio li input[type=radio]:checked + label:before {
  background: #fff url(../images/circle.svg) no-repeat center;
  border: 1px solid #ddd;
}
body .gform_wrapper .gform_body .ginput_container_fileupload {
  display: inline;
  margin: 0;
  padding: 0;
}
body .gform_wrapper .gform_body .ginput_container_fileupload input[type=file] {
  display: none;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  box-shadow: none;
}
body .gform_wrapper .gform_body .input-file-message {
  margin: 1rem 0 0;
  cursor: pointer;
  display: inline-block;
}
@media (min-width: 576px) {
  body .gform_wrapper .gform_body .input-file-message {
    margin: 0 0 0 1rem;
  }
}
body .gform_wrapper .gform_body .gfield_password_strength {
  margin: 1rem 0 2rem;
  padding: 5px;
  border: none;
  box-shadow: none;
  font-size: 1.4rem;
  line-height: 1.2;
}
body .gform_wrapper .gform_footer,
body .gform_wrapper .gform_page_footer {
  margin: 2.5rem 0 0;
  padding: 0;
  text-align: left;
}
body .gform_wrapper .gform_footer input.button,
body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer input[type=submit] {
  margin: 0;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.25);
}
body .gform_wrapper .gform_footer input.button:focus, body .gform_wrapper .gform_footer input.button:active,
body .gform_wrapper .gform_footer input[type=submit]:focus,
body .gform_wrapper .gform_footer input[type=submit]:active,
body .gform_wrapper .gform_page_footer input.button:focus,
body .gform_wrapper .gform_page_footer input.button:active,
body .gform_wrapper .gform_page_footer input[type=submit]:focus,
body .gform_wrapper .gform_page_footer input[type=submit]:active {
  color: #fff;
  background: #e11d73;
  border: none;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.25);
  text-decoration: none;
  outline: none;
}
body .gform_wrapper.gform_validation_error div.validation_error {
  border: none;
  color: #e11d73;
}
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields li.gfield.gfield_error,
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
  padding-bottom: 0;
  background: transparent;
  border: none;
}
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields li.gfield.gfield_error.gfield_contains_required div.ginput_container {
  margin-top: 8px;
}
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields .gfield_error input[type=email],
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields .gfield_error input[type=number],
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields .gfield_error input[type=password],
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields .gfield_error input[type=tel],
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields .gfield_error input[type=text],
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields .gfield_error input[type=url],
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields .gfield_error select,
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields .gfield_error textarea,
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields li.gfield_error.field_sublabel_above .ginput_complex input[type=text] {
  margin: 0 !important;
}
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields li.gfield.gfield_error.gfield_contains_required label.gfield_label {
  margin-top: 0;
}
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields li.gfield_error div.ginput_complex.ginput_container label,
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields li.gfield_error ul.gfield_checkbox,
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields li.gfield_error ul.gfield_radio {
  color: #e11d73;
}
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields li.gfield_error textarea {
  border-color: #e11d73 !important;
}
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
  max-width: 100% !important;
}
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields .gfield_error .gfield_label {
  color: #e11d73;
}
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields .gfield_error .gfield_required {
  color: #e11d73;
}
body .gform_wrapper.gform_validation_error .gform_body ul.gform_fields li.gfield.gfield_error + li.gfield.gfield_error {
  margin-top: 0;
}
body .gform_wrapper.gform_validation_error .gform_body .instruction.validation_message {
  margin-top: 2px;
  color: #e11d73;
}
body .gform_wrapper.gform_validation_error .gform_body .validation_error {
  margin-bottom: 2rem;
  font-weight: 700;
  color: #e11d73;
}
body .gform_wrapper.gform_validation_error .gform_body .validation_message {
  margin: 0.5rem 0 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #e11d73;
}
body .gform_wrapper.gform_validation_error .gform_body .captcha .validation_message {
  text-align: center;
}
body .gf_placeholder::placeholder {
  font-weight: 400;
  color: #343a40;
  opacity: 0.5;
}
@media only screen and (max-width: 641px) {
  body .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=image]):not([type=file]) {
    line-height: 1.2;
    min-height: auto;
  }
  body .gform_wrapper .ginput_container span:not(.ginput_price) {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 641px) {
  body .gform_wrapper.gform_validation_error .top_label li.gfield.gfield_error.gf_left_half,
body .gform_wrapper.gform_validation_error .top_label li.gfield.gfield_error.gf_right_half {
    max-width: 50%;
    padding-right: 0;
  }
  body .gform_wrapper .ginput_container.ginput_complex {
    display: flex;
  }
  body .gform_wrapper .ginput_container.ginput_complex.ginput_container_address {
    display: block;
  }
  body .gform_wrapper .ginput_complex.ginput_container_address > span {
    display: block;
    margin: 0;
    padding: 1rem 0;
  }
  body .gf_clear {
    clear: both;
  }
  body .gform_wrapper .ginput_complex .ginput_left,
body .gform_wrapper .ginput_complex .ginput_right,
body .gform_wrapper .ginput_container_password .ginput_left,
body .gform_wrapper .ginput_container_password .ginput_right,
body .gform_wrapper .ginput_complex.ginput_container_address .ginput_left,
body .gform_wrapper .ginput_complex.ginput_container_address .ginput_right {
    display: block;
    margin: 0;
    padding: 1rem 0;
  }
  body .gform_wrapper .ginput_complex.ginput_container_address .ginput_left,
body .gform_wrapper .ginput_complex.ginput_container_address .ginput_right {
    float: left;
  }
  body .gform_wrapper .ginput_complex.ginput_container_address .ginput_right:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    clear: both;
  }
  body .gform_wrapper .ginput_complex.ginput_container_address span.ginput_right + span.ginput_left.address_zip {
    /* stylelint-disable-line */
    margin: 0;
  }
  body .gform_wrapper .ginput_complex.ginput_container_address span.ginput_right + span.ginput_left {
    /* stylelint-disable-line */
    margin: 0;
    padding-right: 1.2rem !important;
  }
  body .gform_wrapper .ginput_complex .ginput_right,
body .gform_wrapper .ginput_container_password .ginput_right,
body .gform_wrapper .ginput_complex.ginput_container_address .ginput_right {
    padding-left: 1.2rem;
  }
  body .gform_wrapper .ginput_complex .ginput_left,
body .gform_wrapper .ginput_container_password .ginput_left,
body .gform_wrapper .ginput_complex.ginput_container_address .ginput_left {
    padding-right: 1.2rem;
  }
  body div.ginput_container_name span {
    margin: 0;
    padding: 0;
  }
}

.gform_wrapper.gf_browser_ie .gform_footer input.button {
  padding: 0 3rem;
}

.chamfr-become-seller-form .gform_body .top_label .chamfr-become-seller-tell-us-field .gfield_label {
  font-size: 1.8rem;
}
.chamfr-become-seller-form .gform_body .top_label .chamfr-become-seller-tell-us-field .gfield_description {
  padding-bottom: 1rem;
}
.chamfr-become-seller-form .gform_body .top_label .chamfr-become-seller-tell-us-field .ginput_container.ginput_container_textarea textarea {
  background: #ffffff;
}
.chamfr-become-seller-form .gform_body .top_label .chamfr-become-seller-company-name-field .gfield_label {
  font-size: 1.8rem;
}
.chamfr-become-seller-form .gform_body .top_label .chamfr-become-seller-phone-field .gfield_label {
  font-size: 1.8rem;
}

.page-header .secondary {
  order: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  margin-left: auto;
  z-index: 260;
}
.page-header .secondary ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0 0 -0.3rem;
  padding: 0;
  font-size: 1.6rem;
}
@media (min-width: 576px) {
  .page-header .secondary ul {
    margin: 0;
  }
}
@media (min-width: 768px) {
  .page-header .secondary ul {
    font-size: 1.4rem;
  }
}
.page-header .secondary ul > li:not(:last-child) {
  padding-right: 29px;
  border-right: 2px solid #636363;
}
@media (max-width: 767.98px) {
  .page-header .secondary ul > li:not(:last-child) {
    padding-right: 16px;
  }
}
.page-header .secondary ul li {
  margin-right: 69px;
  display: flex;
  align-items: center;
}
.page-header .secondary ul li:last-child {
  margin-right: 0;
}
.page-header .secondary ul li.menu-item-has-children a {
  color: #fff;
}
@media (min-width: 768px) {
  .page-header .secondary ul li.menu-item-has-children a {
    color: #000;
  }
}
@media (max-width: 575.98px) {
  .page-header .secondary ul li {
    margin-right: 45px;
  }
}
.page-header .secondary ul a {
  color: #000;
  position: relative;
}
.page-header .secondary ul .minicart {
  display: inline-block;
  width: 36px;
  height: 33px;
  /* stylelint-disable-next-line function-url-quotes */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.326' height='16.252' viewBox='0 0 17.326 16.252'%3E%3Cg id='Trolley' transform='translate(0 -3)'%3E%3Cpath id='Path_90' data-name='Path 90' d='M17.259,6.391c-.144-.191.968-.076-14.056-.849L2.889,3.311A.361.361,0,0,0,2.528,3H.361a.361.361,0,1,0,0,.722H2.214c.433,3.019.137.964,1.351,9.455A1.445,1.445,0,0,0,3.973,16H14.446a.361.361,0,1,0,0-.722H3.973a.722.722,0,0,1,0-1.445H15.53a.361.361,0,0,0,.361-.285C17.454,6.084,17.4,6.579,17.259,6.391Zm-2.022,6.721H4.287l-.979-6.84,13.225.679Z' transform='translate(0 0)'/%3E%3Cpath id='Path_91' data-name='Path 91' d='M13.445,40a1.445,1.445,0,1,0,1.445,1.445A1.445,1.445,0,0,0,13.445,40Zm0,2.167a.722.722,0,1,1,.722-.722A.722.722,0,0,1,13.445,42.167Z' transform='translate(-7.666 -23.637)'/%3E%3Cpath id='Path_92' data-name='Path 92' d='M31.445,40a1.445,1.445,0,1,0,1.445,1.445A1.445,1.445,0,0,0,31.445,40Zm0,2.167a.722.722,0,1,1,.722-.722A.722.722,0,0,1,31.445,42.167Z' transform='translate(-19.165 -23.637)'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  top: -6px;
  padding-right: 4px;
  position: absolute;
  left: -41px;
}
@media (max-width: 575.98px) {
  .page-header .secondary ul .minicart {
    width: 24px;
    height: 22px;
    top: -1px;
    left: -30px;
  }
}
.page-header .secondary ul .mobile-search {
  display: inline-block;
  width: 36px;
  height: 33px;
  background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 768px) {
  .page-header .secondary ul .mobile-search {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .page-header .secondary ul .mobile-search {
    width: 24px;
    height: 22px;
  }
}
.page-header .secondary ul .mobile-search.is-active {
  background-image: url(../images/menu-close-gray.svg);
}
.page-header .secondary .secondary-toggler {
  display: none;
  position: relative;
  z-index: 2000;
  width: 38px;
  height: 37.4px;
  background: url(../images/user-menu-black.svg) no-repeat center/contain;
}
@media (max-width: 575.98px) {
  .page-header .secondary .secondary-toggler {
    width: 24px;
    height: 22px;
  }
}
.page-header .secondary .secondary-toggler.logged-in {
  display: block;
}
@media (max-width: 767.98px) {
  .page-header .secondary .secondary-toggler.logged-in {
    display: none;
  }
}
.page-header .secondary .menu {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding-top: 7.5rem;
  background: #000;
  transition: all 0.6s ease-out;
  transform: translateX(100%);
}
@media (min-width: 768px) {
  .page-header .secondary .menu {
    display: flex;
    position: relative;
    z-index: 2500;
    padding-top: 0;
    background: transparent;
    transform: translateX(0);
  }
}
.page-header .secondary .menu > .menu-item {
  width: 100%;
  padding: 1.8rem 3.5rem 0;
  margin: 0;
  border-top: 1px solid #464646;
}
.page-header .secondary .menu > .menu-item.top-login-btn a {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #e11d73;
  border-color: #e11d73;
  background-color: transparent;
}
.page-header .secondary .menu > .menu-item.top-login-btn a:hover, .page-header .secondary .menu > .menu-item.top-login-btn a:focus {
  text-decoration: none;
}
.page-header .secondary .menu > .menu-item.top-login-btn a:hover, .page-header .secondary .menu > .menu-item.top-login-btn a:focus {
  color: #9f325d;
  border-color: #9f325d;
  background-color: transparent;
}
.page-header .secondary .menu > .menu-item.top-login-btn a:disabled {
  color: #b4b4b4;
  border-color: #b4b4b4;
  background-color: transparent;
}
.page-header .secondary .menu > .menu-item.top-login-btn a:hover {
  text-decoration: none;
  background-color: #e11d73;
  color: #FFFFFF;
  border-color: #e11d73;
}
.page-header .secondary .menu > .menu-item.top-register-btn a {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  border-color: #e11d73;
  background-color: #e11d73;
}
.page-header .secondary .menu > .menu-item.top-register-btn a:hover, .page-header .secondary .menu > .menu-item.top-register-btn a:focus {
  text-decoration: none;
}
.page-header .secondary .menu > .menu-item.top-register-btn a:hover, .page-header .secondary .menu > .menu-item.top-register-btn a:focus {
  color: #FFFFFF;
  background-color: #9f325d;
  border-color: #9f325d;
}
.page-header .secondary .menu > .menu-item.top-register-btn a:disabled {
  color: #FFFFFF;
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}
.page-header .secondary .menu > .menu-item.top-register-btn a:hover {
  text-decoration: none;
  background: transparent;
  color: #e11d73;
  border-color: #e11d73;
}
.page-header .secondary .menu > .menu-item.top-login-btn a, .page-header .secondary .menu > .menu-item.top-register-btn a {
  min-width: 125px;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  transition: all 0.3s ease-in;
}
@media (min-width: 768px) {
  .page-header .secondary .menu > .menu-item {
    width: auto;
    margin-left: 7px;
    padding: 0;
    border: none;
  }
  .page-header .secondary .menu > .menu-item a span {
    display: block;
    font-weight: 800;
  }
}
.page-header .secondary .menu > .menu-item-has-children {
  transition: all 0.3s ease-out;
}
.page-header .secondary .menu > .menu-item-has-children > a {
  padding-right: 20px;
}
@media (max-width: 767.98px) {
  .page-header .secondary .menu > .menu-item-has-children > a {
    display: none;
  }
}
.page-header .secondary .menu > .menu-item-has-children > a.menu-open::after {
  transform: scaleY(-1);
}
.page-header .secondary .menu > .menu-item-has-children > a:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0.4rem;
  right: 0;
  width: 12px;
  height: 8px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
  transition: all 0.3s ease-in;
}
@media (min-width: 768px) {
  .page-header .secondary .menu > .menu-item-has-children > a:after {
    display: inline;
  }
}
.page-header .secondary .menu > .menu-item:first-child > a:after {
  display: none;
}
@media (min-width: 768px) {
  .page-header .secondary .menu > .menu-item:first-child > a:after {
    display: inline;
  }
}
.page-header .secondary .menu .sub-menu {
  display: block;
  width: calc(100% + 7rem);
  margin: 1.8rem -3.5rem 0;
  padding: 0;
}
@media (min-width: 768px) {
  .page-header .secondary .menu .sub-menu {
    position: absolute;
    z-index: 10;
    top: 4.5rem;
    left: -5rem;
    overflow: hidden;
    min-width: 300px;
    width: auto;
    max-height: 0;
    margin: 0;
    padding: 0 20px;
    background-color: #fff;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    transition: max-height 0.3s ease-out;
  }
}
@media (max-width: 991.98px) {
  .page-header .secondary .menu .sub-menu {
    min-width: 280px;
  }
}
.page-header .secondary .menu .sub-menu > .menu-item {
  padding: 1.8rem 3.5rem;
  margin: 0;
  border-top: 1px solid #464646;
}
.page-header .secondary .menu .sub-menu > .menu-item > a {
  color: #fff;
}
.page-header .secondary .menu .sub-menu > .menu-item:last-child > a {
  color: #e11d73;
}
@media (min-width: 768px) {
  .page-header .secondary .menu .sub-menu > .menu-item {
    white-space: nowrap;
    margin-bottom: 20px;
    padding: 0;
    padding-top: 20px;
    border: none;
    border-top: 1px solid #eaeaea;
  }
  .page-header .secondary .menu .sub-menu > .menu-item > a {
    color: #000;
  }
  .page-header .secondary .menu .sub-menu > .menu-item:first-child {
    padding-top: 29px;
    border: none;
  }
  .page-header .secondary .menu .sub-menu > .menu-item:last-child {
    margin-bottom: 0;
    padding-top: 47px;
    padding-bottom: 32px;
    border-top: 1px solid #eaeaea;
  }
}
.page-header .secondary.menu-open .menu {
  transform: translateX(0);
}
@media (min-width: 768px) {
  .page-header .secondary.menu-open .menu .sub-menu {
    max-height: 50rem;
  }
}

.page-header .secondary .secondary-toggler {
  background-image: url(../images/user-white.svg);
}
.page-header .secondary ul {
  /* stylelint-disable-next-line no-descending-specificity */
}
.page-header .secondary ul a {
  color: #fff;
  font-size: 16px;
  line-height: 18.8px;
}
@media (min-width: 768px) {
  .page-header .secondary ul li.menu-item-has-children a {
    color: #fff;
  }
}
.page-header .secondary ul .minicart {
  /* stylelint-disable-next-line function-url-quotes */
  background-image: url(../images/minicart-white.svg);
}
.page-header .secondary .menu {
  /* stylelint-disable-next-line no-descending-specificity */
}
.page-header .secondary .menu > .menu-item-has-children {
  /* stylelint-disable-next-line no-descending-specificity */
}
.page-header .secondary .menu > .menu-item-has-children > a:after {
  border-color: #fff transparent transparent;
}

.header-wrapper {
  background-color: #2b2b2b;
}
.header-wrapper .header-mid-area {
  padding: 22px 0 20px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1490196078);
  position: relative;
}
@media (max-width: 767.98px) {
  .header-wrapper .header-mid-area {
    padding: 20px 18px;
  }
}

.page-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  /* stylelint-disable-next-line no-descending-specificity */
}
@media (min-width: 360px) {
  .page-header {
    max-width: 360px;
  }
}
@media (min-width: 576px) {
  .page-header {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .page-header {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .page-header {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .page-header {
    max-width: 1140px;
  }
}
@media (min-width: 1425px) {
  .page-header {
    max-width: 1360px;
  }
}
@media (min-width: 1760px) {
  .page-header {
    max-width: 1680px;
  }
}
@media (max-width: 991.98px) {
  .page-header {
    max-width: none;
    padding-top: 0.8rem;
  }
}
@media (max-width: 767.98px) {
  .page-header {
    padding: 0;
  }
}
.page-header .navbar-toggler {
  order: 1;
  width: 3.4rem;
  margin: 0 1.5rem -0.3rem 0;
  padding: 0 1rem 0 0;
  background: transparent;
  cursor: pointer;
  z-index: 3500;
}
@media (min-width: 576px) {
  .page-header .navbar-toggler {
    width: 4.5rem;
    margin: 0 2.5rem 0 0;
    padding: 1rem 1rem 0 0;
  }
}
@media (min-width: 768px) {
  .page-header .navbar-toggler {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .page-header .navbar-toggler {
    width: 18px;
    margin: 0 14px 0 0;
    padding: 0;
  }
}
.page-header .navbar-toggler.mobile-navbar-toggler {
  position: absolute;
  top: 26px;
  left: 18px;
}
.page-header .navbar-toggler .icon-bar {
  display: block;
  height: 2px;
  width: 100%;
  margin: 0.7rem 0;
  background: #000;
  border-radius: 4px;
  backface-visibility: hidden;
}
@media (min-width: 576px) {
  .page-header .navbar-toggler .icon-bar {
    margin: 1rem 0;
  }
}
@media (max-width: 575.98px) {
  .page-header .navbar-toggler .icon-bar {
    margin: 3px 0;
  }
}
.page-header .logo {
  order: 0;
  width: 145px;
  height: 32px;
  background-image: url(../images/logo.svg);
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 0;
}
@media (min-width: 576px) {
  .page-header .logo {
    width: 209px;
    height: 48px;
    margin-right: 4rem;
  }
}
@media (min-width: 1425px) {
  .page-header .logo {
    margin-right: 57px;
  }
}
@media (max-width: 767.98px) {
  .page-header .logo {
    order: 1;
  }
}
.page-header .primary-menu {
  order: 5;
  width: 100%;
  flex-grow: 1;
}
.page-header .primary-menu__close {
  position: absolute;
  top: 27px;
  right: 26px;
  z-index: 100;
  width: 15px;
  height: 15px;
  background: url(../images/menu-close-gray.svg) no-repeat center/contain;
  transition: opacity 0.3s ease-out;
  opacity: 1;
}
@media (min-width: 768px) {
  .page-header .primary-menu__close {
    display: none;
  }
}
@media (min-width: 576px) {
  .page-header .primary-menu__close {
    top: 38px;
    width: 22px;
    height: 22px;
  }
}
.page-header .primary-menu__close.close-hide {
  opacity: 0;
}
.page-header .header-search {
  position: relative;
  flex-grow: 1;
  margin-right: 62px;
  max-width: 608px;
  overflow: hidden;
}
@media (max-width: 1199.98px) {
  .page-header .header-search {
    padding-top: 2rem;
    order: 10;
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
}
@media (max-width: 767.98px) {
  .page-header .header-search {
    display: none;
  }
}
.page-header .header-search__field {
  width: 100%;
  max-width: 100%;
  height: auto;
  padding: 0.9rem 4rem 0.8rem 1rem;
  background-color: #fafafa;
  border-radius: 0.2rem;
  border: none;
  appearance: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  /* stylelint-disable-next-line function-url-quotes */
  font-size: 1.4rem;
  line-height: 1.36;
}
@media (min-width: 768px) {
  .page-header .header-search__field {
    width: 300px;
  }
}
.page-header .header-search__submit {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3.5rem;
  background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
  background-position: 1.2rem 1.3rem;
  background-size: 12px;
  background-repeat: no-repeat;
  background-color: #e11d73;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.page-header.menu-on .navbar-toggler {
  z-index: 350;
}

.page-header .navbar-toggler .icon-bar {
  background: #fff;
}

.checkout-header {
  padding: 2rem 15px;
}
.checkout-header .continue-shopping a {
  color: #fff;
  text-decoration: underline;
}
.checkout-header .continue-shopping a:hover {
  text-decoration: none;
}

.iframe > .header-wrapper, .iframe > .page-footer, .iframe .wcv-header-container, .iframe .wcv-store-grid {
  display: none !important;
}

.helper-cta {
  position: relative;
  margin: 0 2rem 5rem;
  padding: 5rem 2rem;
  background-color: #2b2b2b;
  border-radius: 10px;
  color: #fff;
}
@media (min-width: 576px) {
  .helper-cta {
    margin: 0 0 5rem;
  }
}
@media (min-width: 768px) {
  .helper-cta {
    margin: 0 0 6rem;
    padding: 5rem;
  }
}
.helper-cta:before, .helper-cta:after {
  content: "";
  width: 20rem;
  height: 16.5rem;
  background: url(../images/dots.svg) repeat top left;
  opacity: 0.5;
  position: absolute;
  z-index: -1;
}
@media (min-width: 992px) {
  .helper-cta:before, .helper-cta:after {
    width: 52rem;
    height: 18rem;
  }
}
.helper-cta:before {
  top: -1.8rem;
  left: -2rem;
}
.helper-cta:after {
  right: -3rem;
  bottom: -2.5rem;
  background-position: bottom right;
}
.helper-cta .content {
  max-width: 62rem;
  margin: 0 auto;
  text-align: center;
}

.primary-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
  background: #2b2b2b;
  transition: all 0.6s ease-out;
  transform: translateX(-100%);
}
@media (min-width: 768px) {
  .primary-menu {
    position: static;
    z-index: 250;
    background: transparent;
    transform: translateX(0);
  }
}
@media (max-width: 767.98px) {
  .primary-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
  }
}
.menu-on .primary-menu {
  transform: translateX(0);
}
.primary-menu .side-account-menu {
  display: block;
  padding: 13px 17px;
  margin-top: 20px;
}
@media (min-width: 768px) {
  .primary-menu .side-account-menu {
    display: none;
  }
}
.primary-menu .side-account-menu.user-logged-in {
  background: #e11d73;
  padding: 20px 17px;
}
.primary-menu .side-account-menu.user-logged-in .menu {
  margin: 0;
}
.primary-menu .side-account-menu.user-logged-in .menu > li > a {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 18.8px;
  font-weight: 800;
  display: block;
  position: relative;
  text-decoration: none;
}
.primary-menu .side-account-menu.user-logged-in .menu > li > a.is-active::after {
  transform: rotate(0deg);
}
.primary-menu .side-account-menu.user-logged-in .menu > li > a::after {
  content: "";
  width: 12px;
  height: 8px;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
  transform: rotate(-90deg);
  position: absolute;
  right: 0;
  top: 3px;
}
.primary-menu .side-account-menu.user-logged-in .sub-menu {
  display: none;
}
.primary-menu .side-account-menu.user-logged-in .sub-menu.is-open {
  display: flex;
  flex-direction: column;
  list-style: none;
  background: #fff;
  margin: 10px 0 0;
  padding: 10px;
}
.primary-menu .side-account-menu.user-logged-in .sub-menu li:not(:first-child) a {
  border-top: 1px solid #e1e1e1;
}
.primary-menu .side-account-menu.user-logged-in .sub-menu li:last-child a {
  padding: 20px 0 10px;
  color: #e11d73;
}
.primary-menu .side-account-menu.user-logged-in .sub-menu a {
  padding: 1rem 0;
  display: block;
  color: #111111;
  font-weight: 600;
}
.primary-menu .side-account-menu .my-account-head {
  color: #FFFFFF;
  font-size: 16px;
  line-height: 18.8px;
  margin: 0;
}
.primary-menu .side-account-menu .menu {
  margin: 11px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  column-gap: 16px;
}
.primary-menu .side-account-menu .menu li {
  flex: 1 1 100%;
}
.primary-menu .side-account-menu .menu .top-login-btn a {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #e11d73;
  border-color: #e11d73;
  background-color: transparent;
}
.primary-menu .side-account-menu .menu .top-login-btn a:hover, .primary-menu .side-account-menu .menu .top-login-btn a:focus {
  text-decoration: none;
}
.primary-menu .side-account-menu .menu .top-login-btn a:hover, .primary-menu .side-account-menu .menu .top-login-btn a:focus {
  color: #9f325d;
  border-color: #9f325d;
  background-color: transparent;
}
.primary-menu .side-account-menu .menu .top-login-btn a:disabled {
  color: #b4b4b4;
  border-color: #b4b4b4;
  background-color: transparent;
}
.primary-menu .side-account-menu .menu .top-register-btn a {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  border-color: #e11d73;
  background-color: #e11d73;
}
.primary-menu .side-account-menu .menu .top-register-btn a:hover, .primary-menu .side-account-menu .menu .top-register-btn a:focus {
  text-decoration: none;
}
.primary-menu .side-account-menu .menu .top-register-btn a:hover, .primary-menu .side-account-menu .menu .top-register-btn a:focus {
  color: #FFFFFF;
  background-color: #9f325d;
  border-color: #9f325d;
}
.primary-menu .side-account-menu .menu .top-register-btn a:disabled {
  color: #FFFFFF;
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}
.primary-menu .side-account-menu .menu .top-login-btn a, .primary-menu .side-account-menu .menu .top-register-btn a {
  width: 100%;
  padding: 16px 24px;
  border-radius: 8px;
  text-align: center;
  line-height: 18.8px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.chamfr-mega-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
@media (min-width: 768px) {
  .chamfr-mega-menu {
    display: flex;
    justify-content: flex-start;
  }
}
@media (max-width: 767.98px) {
  .chamfr-mega-menu {
    margin-top: 95px;
  }
}
@media (max-width: 575.98px) {
  .chamfr-mega-menu {
    margin-top: 65px;
  }
}
.chamfr-mega-menu > .menu-item {
  margin-right: 5rem;
}
.chamfr-mega-menu > .menu-item > a {
  color: #fff;
}
.chamfr-mega-menu > .menu-item.menu-item-has-children > a::after {
  width: 12px;
  height: 8px;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
  transition: all 0.3s ease;
  content: "";
  margin-left: 0.4rem;
}
.chamfr-mega-menu > .menu-item:last-child {
  margin-right: 0;
}
.chamfr-mega-menu > .menu-item > a {
  display: inline-block;
  padding: 1rem 0;
}
.chamfr-mega-menu > .menu-item > .sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  padding: 4rem;
  padding-bottom: 16rem;
  margin: 0;
  background-color: #fff;
  color: #2b2b2b;
  display: none;
  flex-wrap: wrap;
  line-height: 2.4rem;
}
.chamfr-mega-menu > .menu-item > .sub-menu .menu-title {
  flex-basis: 100%;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.chamfr-mega-menu > .menu-item > .sub-menu .menu-title > a {
  color: inherit;
}
.chamfr-mega-menu > .menu-item > .sub-menu .menu-footer {
  order: 1;
  flex-basis: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4rem;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
}
@media (max-width: 767.98px) {
  .chamfr-mega-menu > .menu-item > .sub-menu .menu-footer {
    display: none;
  }
}
.chamfr-mega-menu > .menu-item > .sub-menu .menu-footer > div:first-child {
  margin-right: 4rem;
}
.chamfr-mega-menu > .menu-item > .sub-menu .menu-footer-title {
  font-size: 2.4rem;
  font-weight: 800;
  margin-bottom: 1rem;
}
.chamfr-mega-menu > .menu-item > .sub-menu .menu-columns {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
}
.chamfr-mega-menu > .menu-item > .sub-menu .menu-columns.scrollable {
  max-height: 50vh;
  overflow-y: scroll;
}
.chamfr-mega-menu > .menu-item > .sub-menu .menu-columns .menu-col {
  flex-grow: 1;
  flex-basis: 25%;
}
@media (max-width: 991.98px) {
  .chamfr-mega-menu > .menu-item > .sub-menu .menu-columns .menu-col {
    flex-basis: 33.333%;
  }
}
@media (max-width: 767.98px) {
  .chamfr-mega-menu > .menu-item > .sub-menu .menu-columns .menu-col {
    flex-basis: 100%;
  }
}
.chamfr-mega-menu > .menu-item > .sub-menu > .menu-item,
.chamfr-mega-menu > .menu-item > .sub-menu .menu-col > .menu-item {
  font-weight: 600;
  margin-bottom: 1rem;
}
.chamfr-mega-menu > .menu-item > .sub-menu > .menu-item > a,
.chamfr-mega-menu > .menu-item > .sub-menu .menu-col > .menu-item > a {
  color: #2b2b2b;
}
.chamfr-mega-menu > .menu-item > .sub-menu > .menu-item > a:hover,
.chamfr-mega-menu > .menu-item > .sub-menu .menu-col > .menu-item > a:hover {
  text-decoration: none;
  color: #e11d73;
}
.chamfr-mega-menu > .menu-item > .sub-menu > .menu-item.menu-item-has-children > a::after,
.chamfr-mega-menu > .menu-item > .sub-menu .menu-col > .menu-item.menu-item-has-children > a::after {
  width: 12px;
  height: 7px;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%238A8A8A' stroke-linecap='round'/%3E%3C/svg%3E");
  position: relative;
  top: -1px;
  transition: all 0.3s ease;
  content: "";
  margin-left: 0.4rem;
}
.chamfr-mega-menu > .menu-item > .sub-menu > .menu-item > .sub-menu,
.chamfr-mega-menu > .menu-item > .sub-menu .menu-col > .menu-item > .sub-menu {
  list-style: none;
  padding: 0 0 0 2rem;
  margin: 0;
  font-size: 1.4rem;
  font-weight: 400;
  display: none;
}
@media (max-width: 767.98px) {
  .chamfr-mega-menu > .menu-item > .sub-menu > .menu-item > .sub-menu > .menu-item,
.chamfr-mega-menu > .menu-item > .sub-menu .menu-col > .menu-item > .sub-menu > .menu-item {
    margin: 0.6rem 0;
  }
}
.chamfr-mega-menu > .menu-item > .sub-menu > .menu-item > .sub-menu > .menu-item > a,
.chamfr-mega-menu > .menu-item > .sub-menu .menu-col > .menu-item > .sub-menu > .menu-item > a {
  color: #2b2b2b;
}
.chamfr-mega-menu > .menu-item > .sub-menu > .menu-item > .sub-menu > .menu-item.see-all,
.chamfr-mega-menu > .menu-item > .sub-menu .menu-col > .menu-item > .sub-menu > .menu-item.see-all {
  font-weight: 700;
}
.chamfr-mega-menu > .menu-item > .sub-menu > .menu-item.menu-open > a,
.chamfr-mega-menu > .menu-item > .sub-menu .menu-col > .menu-item.menu-open > a {
  color: #e11d73;
}
.chamfr-mega-menu > .menu-item > .sub-menu > .menu-item.menu-open > a::after,
.chamfr-mega-menu > .menu-item > .sub-menu .menu-col > .menu-item.menu-open > a::after {
  transform: scaleY(-1);
}
.chamfr-mega-menu > .menu-item > .sub-menu > .menu-item.menu-open > .sub-menu,
.chamfr-mega-menu > .menu-item > .sub-menu .menu-col > .menu-item.menu-open > .sub-menu {
  display: block;
}
.chamfr-mega-menu > .menu-item.menu-open > a::after {
  transform: scaleY(-1);
}
.chamfr-mega-menu > .menu-item.menu-open > .sub-menu {
  display: flex;
}
@media (max-width: 767.98px) {
  .chamfr-mega-menu > .menu-item {
    margin: 0 2rem;
    max-height: calc(100vh - 50px);
  }
  .chamfr-mega-menu > .menu-item:last-child {
    margin-right: 2rem;
  }
  .chamfr-mega-menu > .menu-item > a {
    display: block;
    border-bottom: 1px solid rgba(99, 99, 99, 0.5);
  }
  .chamfr-mega-menu > .menu-item:first-child > a {
    border-top: 1px solid rgba(99, 99, 99, 0.5);
  }
  .chamfr-mega-menu > .menu-item.menu-open > a {
    border-bottom: none;
  }
  .chamfr-mega-menu > .menu-item > .sub-menu {
    position: relative;
    width: auto;
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 2rem;
    max-height: calc(100vh - 95px);
    overflow-y: scroll;
    max-height: calc(100dvh - 95px);
  }
  .chamfr-mega-menu > .menu-item > .sub-menu > .menu-item {
    margin-bottom: 1rem;
  }
}

#chamfr-nothing-found .search-again {
  flex: 0 0 100%;
  margin-bottom: 9rem;
  padding: 3rem;
  background-color: #2b2b2b;
  border-radius: 10px;
  margin: 0 0 2rem;
  display: block;
}
@media (max-width: 767px) {
  #chamfr-nothing-found .search-again {
    display: none;
  }
}
#chamfr-nothing-found .search-again form {
  max-width: 1016px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
#chamfr-nothing-found .search-again form:before {
  position: absolute;
  content: "";
  left: -1.3em;
  bottom: -1.7em;
  height: 120%;
  width: 80%;
  background: url(../images/dots.svg) repeat bottom left;
  opacity: 0.7;
  z-index: -1;
}
#chamfr-nothing-found .search-again input[type=text] {
  font-size: 2.6rem;
  padding: 0.8em 3.4em 0.8em 0.8em;
  line-height: 1;
  height: auto;
}
#chamfr-nothing-found .search-again input[type=text]::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
#chamfr-nothing-found .search-again .search-submit {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 7rem;
  /* stylelint-disable-next-line function-url-quotes */
  background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
  background-position: 2.2rem 2.2rem;
  background-size: 3rem;
  background-repeat: no-repeat;
  background-color: #e11d73;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

/* stylelint-disable no-descending-specificity */
.woocommerce td.product-name .wc-item-meta .wc-item-meta-label {
  display: inline;
  float: none;
  clear: none;
  margin: 0;
}

.woocommerce-order-details {
  margin: 5rem 0 0;
}
.woocommerce-order-details-table ul.wc-item-meta {
  list-style: none;
  margin: 0;
  padding: 0;
}
.woocommerce-order-details-table ul.wc-item-meta .wc-item-meta-label {
  display: inline;
  clear: none;
  margin: 0;
}
.woocommerce-order-details-table ul.wc-item-meta p {
  display: inline;
  margin: 0;
}
.woocommerce-order-details-table ul.wc-item-meta .contact-seller {
  display: block;
}
.woocommerce-order-details-table ul.wc-item-meta .button {
  margin-top: 2rem;
  height: 4rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  font-size: 1.2rem;
  line-height: 4rem;
}
.woocommerce-order-details-table .product-total {
  text-align: right;
}
.woocommerce-order-details-table + p {
  margin: 3rem 0;
}
.woocommerce-customer-details {
  margin-top: 5rem;
}
.woocommerce-customer-details .woocommerce-addresses {
  display: block;
}
@media (min-width: 1425px) {
  .woocommerce-customer-details .woocommerce-addresses {
    display: flex;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .woocommerce-customer-details .woocommerce-addresses {
    display: flex;
  }
}
@media (min-width: 992px) {
  .woocommerce-customer-details .woocommerce-addresses .woocommerce-address,
.woocommerce-customer-details .woocommerce-addresses .woocommerce-address:last-of-type {
    padding: 0 0 0 7rem;
    flex: 0 0 50%;
  }
}
.woocommerce-customer-details .woocommerce-addresses .woocommerce-address p,
.woocommerce-customer-details .woocommerce-addresses .woocommerce-address:last-of-type p {
  margin: 0;
}
.woocommerce-customer-details .woocommerce-addresses .woocommerce-address p.woocommerce-customer-details--phone,
.woocommerce-customer-details .woocommerce-addresses .woocommerce-address:last-of-type p.woocommerce-customer-details--phone {
  margin-top: 2rem;
}

/* Popup Maker plugin */
.pum-theme-351 .pum-container,
.pum-theme-lightbox .pum-container {
  padding: 6rem 3rem 4rem;
  background: radial-gradient(ellipse at center, #fff 0%, #eaeaea 100%);
  border: none;
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}
@media (min-width: 992px) {
  .pum-theme-351 .pum-container,
.pum-theme-lightbox .pum-container {
    padding: 5rem 7rem;
  }
}

.pum-theme-351 .pum-content + .pum-close,
.pum-theme-lightbox .pum-content + .pum-close {
  top: 1.8rem;
  right: 1.8rem;
  border: 2px solid #313131;
  border-radius: 50%;
  box-shadow: none;
  text-shadow: none;
  background-color: transparent;
  font-family: Arial, sans-serif;
  font-size: 26px;
  color: #313131;
}

.pum-theme-351 .pum-title,
.pum-theme-lightbox .pum-title {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 20px;
  font-weight: 700;
  text-shadow: none;
  margin: 0 0 2rem;
}
@media (min-width: 576px) {
  .pum-theme-351 .pum-title,
.pum-theme-lightbox .pum-title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 641px) {
  body .pum-theme-351 .pum-container div.ginput_container_name span,
body .pum-theme-lightbox .pum-container div.ginput_container_name span {
    padding-right: 2rem;
  }
}

/* Woocommerce Quick View plugin */
.pp_overlay {
  opacity: 0.46 !important;
}

div.pp_woocommerce .pp_content_container {
  padding: 6rem 2rem;
  background: radial-gradient(ellipse at center, #fff 0%, #eaeaea 100%);
  border: none;
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}
@media (min-width: 992px) {
  div.pp_woocommerce .pp_content_container {
    padding: 8.5rem 3rem;
  }
}

.pp_content_container .pp_left,
.pp_content_container .pp_right {
  padding: 0;
}

.post-type-archive-product .pp_woocommerce_quick_view .pp_content,
.tax-product_cat .pp_woocommerce_quick_view .pp_content {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100% !important;
  max-height: 80vh;
  /* Track */
  /* Handle */
  /* hovered state can be different */
  /* If Current browser's window lost focus, e.g. user has gone to another window. */
  padding: 0 1rem;
}
.post-type-archive-product .pp_woocommerce_quick_view .pp_content::-webkit-scrollbar,
.tax-product_cat .pp_woocommerce_quick_view .pp_content::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
.post-type-archive-product .pp_woocommerce_quick_view .pp_content::-webkit-scrollbar-track,
.tax-product_cat .pp_woocommerce_quick_view .pp_content::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
}
.post-type-archive-product .pp_woocommerce_quick_view .pp_content::-webkit-scrollbar-thumb,
.tax-product_cat .pp_woocommerce_quick_view .pp_content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #dddddd;
  transition: all 0.2s;
}
.post-type-archive-product .pp_woocommerce_quick_view .pp_content:hover::-webkit-scrollbar-thumb,
.tax-product_cat .pp_woocommerce_quick_view .pp_content:hover::-webkit-scrollbar-thumb {
  border-radius: 8px;
  transition: all 0.2s;
  background-color: #dddddd;
}
.post-type-archive-product .pp_woocommerce_quick_view .pp_content::-webkit-scrollbar-thumb:window-inactive,
.tax-product_cat .pp_woocommerce_quick_view .pp_content::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(221, 221, 221, 0.8);
}

div.pp_woocommerce .pp_close {
  top: 1.5rem;
  right: 1.5rem;
  height: 3rem;
  width: 3rem;
  background-color: #313131;
  border-radius: 50%;
  border: 2px solid #313131;
  transition: all ease-in-out 0.2s;
  font-size: 2.6rem !important;
  line-height: 1;
  text-shadow: none;
  color: #eaeaea !important;
}
@media only screen and (max-width: 768px) {
  div.pp_woocommerce .pp_close:before {
    font-size: 2.6rem !important;
  }
}

.post-type-archive-product .pp_woocommerce_quick_view .pp_close,
.tax-product_cat .pp_woocommerce_quick_view .pp_close {
  display: block !important;
}

.woocommerce.quick-view.single-product {
  overflow: visible;
  padding: 0;
}
@media (min-width: 576px) {
  .woocommerce.quick-view.single-product {
    padding: 0 3rem;
  }
}
.woocommerce.quick-view.single-product.single-product-with-thumb {
  padding-left: 0;
}
@media (min-width: 1425px) {
  .woocommerce.quick-view.single-product.single-product-with-thumb {
    padding-left: 15rem;
  }
}
@media (min-width: 1425px) {
  .woocommerce.quick-view.single-product .chamfr-single-product__thumb {
    top: 0;
    transform: none;
  }
}
.woocommerce.quick-view.single-product .woocommerce-product-gallery .flex-viewport {
  box-shadow: none;
}
@media (min-width: 576px) {
  .woocommerce.quick-view.single-product .woocommerce-product-gallery .flex-viewport {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  }
}
.woocommerce.quick-view.single-product .chamfr-single-product__header h2,
.woocommerce.quick-view.single-product .chamfr-single-product__details h2,
.woocommerce.quick-view.single-product .chamfr-single-product__summary h2 {
  margin: 0 0 3rem;
  font-size: 1.5rem;
}
@media (min-width: 576px) {
  .woocommerce.quick-view.single-product .chamfr-single-product__header h2,
.woocommerce.quick-view.single-product .chamfr-single-product__details h2,
.woocommerce.quick-view.single-product .chamfr-single-product__summary h2 {
    font-size: 3rem;
  }
}
.woocommerce.quick-view.single-product .chamfr-single-product__details h2,
.woocommerce.quick-view.single-product .chamfr-single-product__details .quick-view-detail-button {
  display: block;
}
@media (min-width: 992px) {
  .woocommerce.quick-view.single-product .chamfr-single-product__details h2,
.woocommerce.quick-view.single-product .chamfr-single-product__details .quick-view-detail-button {
    display: none;
  }
}
.woocommerce.quick-view.single-product .chamfr-single-product__summary {
  margin-top: 4.6rem;
}
.woocommerce.quick-view.single-product .chamfr-single-product__summary.chamfr-single-product__summary--cfrq {
  margin-top: 0px;
}
.woocommerce.quick-view.single-product .chamfr-single-product__summary h2,
.woocommerce.quick-view.single-product .chamfr-single-product__summary .quick-view-detail-button {
  display: none;
}
@media (min-width: 992px) {
  .woocommerce.quick-view.single-product .chamfr-single-product__summary h2,
.woocommerce.quick-view.single-product .chamfr-single-product__summary .quick-view-detail-button {
    display: block;
  }
}
.woocommerce.quick-view.single-product .chamfr-single-product__cart {
  margin: 0 0 3rem;
  border-radius: 10px;
}
.woocommerce.quick-view.single-product .chamfr-single-product__cart .cart-qty {
  order: -1;
}
.woocommerce.quick-view.single-product .chamfr-single-product__cart #wc_bis_product_form {
  text-align: right;
}
.woocommerce.quick-view.single-product .contact-seller .button {
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .woocommerce.quick-view.single-product div.product div.images .flex-control-thumbs {
    margin-left: -0.3rem;
    margin-right: -0.3rem;
  }
}
@media (max-width: 767.98px) {
  .woocommerce.quick-view.single-product div.product div.images .flex-control-thumbs li {
    padding: 0.3rem;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.quick-view-detail-button {
  margin: 0 0 3rem;
  text-align: left;
}
.quick-view-detail-button a {
  text-decoration: none;
}

div.pp_woocommerce .pp_details {
  max-width: 100%;
}

/* stylelint-disable no-descending-specificity */
.related {
  padding: 5rem 0;
}
@media (min-width: 992px) {
  .related {
    padding: 10rem 0;
  }
}
.related h3 {
  margin: 0 0 2rem;
  text-align: center;
}
.related .post {
  overflow: hidden;
  height: 100%;
  background: #fff;
  border-radius: 0.5rem;
}
.related .post__img {
  height: 18rem;
  margin: 0;
}
.related .post__img__wrapper {
  height: 100%;
}
.related .post__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.related .post__body {
  position: relative;
  height: calc(100% - 18rem);
  padding: 2rem 2.5rem 5.5rem;
}
.related .post__meta {
  margin: 0 0 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #959595;
}
.related .post__categories, .related .post__date {
  display: inline-block;
}
.related .post__categories {
  text-transform: uppercase;
}
.related .post__categories a {
  color: #959595;
}
.related .post__categories a:hover, .related .post__categories a:focus, .related .post__categories a:active {
  color: #959595;
}
.related .post__title h2 {
  font-family: "Public Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  margin: 0;
}
.related .post__title h2 a {
  color: #313131;
}
.related .post__excerpt {
  margin: 0;
  font-size: 1.4;
}
.related .post__link {
  position: absolute;
  left: 2.5rem;
  right: 2.5rem;
  bottom: 2rem;
}
.related .post__link__a {
  display: inline-block;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #e11d73;
  text-decoration: none;
}
.related .post__link__a:hover, .related .post__link__a:focus, .related .post__link__a:active {
  text-decoration: none;
}

.slick-related {
  margin: 0 -11px;
}
.slick-related div[class^=col-] {
  padding: 0 11px;
}
.slick-related.slick-slider {
  margin: 0 -5px;
  transition: opacity 0.2s;
  opacity: 0;
}
.slick-related.slick-slider.slick-initialized {
  opacity: 1;
}
.slick-related.slick-slider .slick-list {
  overflow: visible;
}
.slick-related.slick-slider .slick-slide {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.slick-related.slick-slider .slick-slide.slick-active, .slick-related.slick-slider .slick-slide.slick-current {
  opacity: 1;
}
.slick-related.slick-slider .slick-slide .injected {
  opacity: 1;
  transition: opacity 0.2s;
}
.slick-related.slick-slider .slick-slide .injected.loading {
  opacity: 0;
}
.slick-related.slick-slider div[class^=col-] {
  height: 100%;
  padding: 0 5px;
}
.slick-related__controls {
  margin: 1.5rem 0 0;
  text-align: center;
}
@media (min-width: 768px) {
  .slick-related__controls {
    display: none;
  }
}

.rp4wp-related-posts {
  display: none;
}

.chamfr-shipping-accounts .error {
  color: #CA3737;
}
.chamfr-shipping-accounts .error > div {
  padding: 2rem 0;
}

.chamfr-shipping-account-number {
  position: relative;
  color: #111111;
  max-width: 424px;
  padding: 16px 0 32px 40px;
}
.chamfr-shipping-account-number .not-selected {
  padding: 16px;
  display: none;
}
.chamfr-shipping-account-number .new-account-form {
  display: none;
}
.chamfr-shipping-account-number .new-account-form a {
  color: #e11d73;
  text-decoration: none;
}
.chamfr-shipping-account-number .new-account-form a:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10.9999 10.9999' stroke='%23E11D73' stroke-linecap='round'/%3E%3Cpath d='M11 1L1.00008 10.9999' stroke='%23E11D73' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}
.chamfr-shipping-account-number .new-account-form > div {
  display: flex;
  gap: 4px;
}
.chamfr-shipping-account-number .new-account-form .save-number {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #e11d73;
  border-color: #e11d73;
  background-color: transparent;
}
.chamfr-shipping-account-number .new-account-form .save-number:hover, .chamfr-shipping-account-number .new-account-form .save-number:focus {
  text-decoration: none;
}
.chamfr-shipping-account-number .new-account-form .save-number:hover, .chamfr-shipping-account-number .new-account-form .save-number:focus {
  color: #9f325d;
  border-color: #9f325d;
  background-color: transparent;
}
.chamfr-shipping-account-number .new-account-form .save-number:disabled {
  color: #b4b4b4;
  border-color: #b4b4b4;
  background-color: transparent;
}
.chamfr-shipping-account-number .options {
  position: absolute;
  display: none;
  background-color: #FFFFFF;
  border: 1px solid #b4b4b4;
  z-index: 10;
  width: 100%;
}
.chamfr-shipping-account-number .options > div {
  padding: 16px;
}
.chamfr-shipping-account-number .options a {
  color: #111111;
}
.chamfr-shipping-account-number .options a:hover {
  color: #e11d73;
}
.chamfr-shipping-account-number .options .new-account a {
  text-decoration: underline;
}
.chamfr-shipping-account-number .options .saved-account {
  display: flex;
}
.chamfr-shipping-account-number .options .saved-account span {
  font-weight: 700;
  cursor: pointer;
}
.chamfr-shipping-account-number .options .saved-account .delete {
  display: none;
}
.chamfr-shipping-account-number .action {
  border: 1px solid #b4b4b4;
  border-radius: 4px;
  background-color: #FFFFFF;
  position: relative;
}
.chamfr-shipping-account-number .action:after {
  content: "";
  position: absolute;
  right: 16px;
  top: calc(50% - 4px);
  display: block;
  width: 14px;
  height: 8px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1.5L7 6.5L2 1.5' stroke='%23B4B4B4' stroke-width='1.5' stroke-linecap='square'/%3E%3C/svg%3E%0A");
}
.chamfr-shipping-account-number .action .not-selected {
  display: block;
}
.chamfr-shipping-account-number .action .saved-account {
  display: flex;
  justify-content: space-between;
  padding: 16px 40px 16px 16px;
}
.chamfr-shipping-account-number .action .saved-account span {
  font-weight: 700;
}
.chamfr-shipping-account-number .action .saved-account .delete {
  display: inline;
  text-decoration: underline;
  color: #b4b4b4;
}
.chamfr-shipping-account-number .action .saved-account .delete:hover {
  text-decoration: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  transform: translateZ(0);
}

.slick-slide {
  min-height: 1px;
  height: inherit;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.slick-slide > div {
  height: 100%;
  width: 100%;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: flex;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218.044%22%20height%3D%2218.044%22%20viewBox%3D%220%200%2018.044%2018.044%22%3E%3Cpath%20id%3D%22Arrow%22%20d%3D%22M0%2C0%2C9.517%2C9.517M10.345%2C3.4v6.94H3.156%22%20transform%3D%22translate%2816.044%209.022%29%20rotate%28135%29%22%20fill%3D%22none%22%20stroke%3D%22%23000%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E");
}

.slick-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218.044%22%20height%3D%2218.044%22%20viewBox%3D%220%200%2018.044%2018.044%22%3E%3Cpath%20id%3D%22Arrow%22%20d%3D%22M0%2C0%2C9.517%2C9.517M10.345%2C3.4v6.94H3.156%22%20transform%3D%22translate%282%209.022%29%20rotate%28-45%29%22%20fill%3D%22none%22%20stroke%3D%22%23000%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E");
}

.slick-prev,
.slick-next {
  display: inline-block;
  height: 36px;
  width: 36px;
  margin: 0 8px;
  padding: 0;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #000;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background-color: transparent;
}
.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0.25;
}

.body-style--dark .slick-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218.044%22%20height%3D%2218.044%22%20viewBox%3D%220%200%2018.044%2018.044%22%3E%3Cpath%20id%3D%22Arrow%22%20d%3D%22M0%2C0%2C9.517%2C9.517M10.345%2C3.4v6.94H3.156%22%20transform%3D%22translate%2816.044%209.022%29%20rotate%28135%29%22%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E");
}
.body-style--dark .slick-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218.044%22%20height%3D%2218.044%22%20viewBox%3D%220%200%2018.044%2018.044%22%3E%3Cpath%20id%3D%22Arrow%22%20d%3D%22M0%2C0%2C9.517%2C9.517M10.345%2C3.4v6.94H3.156%22%20transform%3D%22translate%282%209.022%29%20rotate%28-45%29%22%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E");
}
.body-style--dark .slick-prev,
.body-style--dark .slick-next {
  border-color: #fff;
}

/* Dots */
.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
@media (min-width: 992px) {
  .slick-dots li {
    height: 15px;
    width: 15px;
  }
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 10px;
  width: 10px;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
@media (min-width: 992px) {
  .slick-dots li button {
    height: 15px;
    width: 15px;
  }
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: " ";
  height: 10px;
  width: 10px;
  line-height: 20px;
  height: 20px;
  width: 20px;
  border: solid 2px;
  text-align: center;
  color: #000;
  opacity: 1;
}
@media (min-width: 992px) {
  .slick-dots li button:before {
    height: 15px;
    width: 15px;
    line-height: 15px;
  }
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  background: rgba(0, 0, 0, 0.75);
}
.slick-dots li.slick-active button:before {
  background: #000;
  color: #000;
  opacity: 0.75;
}

.overflow-slider-with-all-visible {
  transition: opacity 0.2s;
  opacity: 0;
}
.overflow-slider-with-all-visible.slick-initialized {
  opacity: 1;
}
.overflow-slider-with-all-visible .slick-list {
  overflow: visible;
}
.overflow-slider-with-all-visible .slick-slide {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.overflow-slider-with-all-visible .slick-slide.slick-active, .overflow-slider-with-all-visible .slick-slide.slick-current {
  opacity: 1;
}
.overflow-slider-with-all-visible .slick-slide .injected {
  opacity: 1;
  transition: opacity 0.2s;
}
.overflow-slider-with-all-visible .slick-slide .injected.loading {
  opacity: 0;
}

.overflow-slider-with-fade {
  transition: opacity 0.2s;
  opacity: 0;
}
.overflow-slider-with-fade.slick-initialized {
  opacity: 1;
}
.overflow-slider-with-fade .slick-list {
  overflow: visible;
}
.overflow-slider-with-fade .slick-slide {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.overflow-slider-with-fade .slick-slide.slick-active, .overflow-slider-with-fade .slick-slide.slick-current {
  opacity: 1;
}
.overflow-slider-with-fade .slick-slide.slick-active + .slick-slide, .overflow-slider-with-fade .slick-slide.slick-current + .slick-slide {
  opacity: 1;
}
.overflow-slider-with-fade .slick-slide .injected {
  opacity: 1;
  transition: opacity 0.2s;
}
.overflow-slider-with-fade .slick-slide .injected.loading {
  opacity: 0;
}

.overflow-slider-fade-single {
  transition: opacity 0.2s;
  opacity: 0;
}
.overflow-slider-fade-single.slick-initialized {
  opacity: 1;
}
.overflow-slider-fade-single .slick-list {
  overflow: visible;
}
.overflow-slider-fade-single .slick-slide {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.overflow-slider-fade-single .slick-slide.slick-active, .overflow-slider-fade-single .slick-slide.slick-current {
  opacity: 1;
}
.overflow-slider-fade-single .slick-slide .injected {
  opacity: 1;
  transition: opacity 0.2s;
}
.overflow-slider-fade-single .slick-slide .injected.loading {
  opacity: 0;
}

.sticky-nav .header-wrapper {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
}

.subscribe {
  position: relative;
  color: #fff;
}
@media (max-width: 575.98px) {
  .subscribe {
    padding: 76px 0 47px;
  }
}
.subscribe h3 {
  font-size: 38px;
  font-weight: 800;
  line-height: 48px;
  margin-bottom: 16px;
}
@media (max-width: 575.98px) {
  .subscribe h3 {
    font-size: 29px;
  }
}
.subscribe h3 + p {
  font-size: 16px;
  font-weight: 800;
  line-height: 18.8px;
  margin-bottom: 32px;
}
@media (max-width: 575.98px) {
  .subscribe h3 + p {
    font-size: 14px;
    line-height: 16.45px;
  }
}
.subscribe #mc_embed_shell {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 2.5rem 0 0;
}
.subscribe #mc_embed_signup {
  width: 100%;
}
.subscribe #mc_embed_signup form {
  margin: 0;
}
.subscribe #mc_embed_signup .mc-field-group {
  padding: 0;
  width: 100%;
}
@media (min-width: 576px) {
  .subscribe #mc_embed_signup .mc-field-group {
    margin-right: 1rem;
  }
}
.subscribe #mc_embed_signup .mc-field-group input {
  background: #111111;
  width: 100%;
  height: 52px;
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid #111111;
  text-align: left;
  color: #FFFFFF;
  text-indent: inherit;
  transition: all 0.2s ease-in;
}
.subscribe #mc_embed_signup .mc-field-group input::placeholder {
  color: #b4b4b4;
  opacity: 1;
}
.subscribe #mc_embed_signup .mc-field-group input:focus {
  border-color: #e11d73;
}
.subscribe #mc_embed_signup .button {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  border-color: #e11d73;
  background-color: #e11d73;
  box-shadow: none;
  border-radius: 8px;
  min-width: 160px;
  width: 100%;
  height: 51px;
}
.subscribe #mc_embed_signup .button:hover, .subscribe #mc_embed_signup .button:focus {
  text-decoration: none;
}
.subscribe #mc_embed_signup .button:hover, .subscribe #mc_embed_signup .button:focus {
  color: #FFFFFF;
  background-color: #9f325d;
  border-color: #9f325d;
}
.subscribe #mc_embed_signup .button:disabled {
  color: #FFFFFF;
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}
.subscribe #mc_embed_signup .button:hover {
  background: #9f325d;
}
.subscribe #mc_embed_signup div.mce_inline_error {
  font-size: 1.4rem;
  font-weight: 500;
  color: #e11d73;
  height: 2rem;
  margin: 0.5rem 0 0;
  padding: 0;
  background: transparent;
}
.subscribe #mc_embed_signup #mce-success-response, .subscribe #mc_embed_signup #mce-error-response {
  color: #e11d73;
  font: 400 1.6rem/1.5 "Open Sans", sans-serif;
}
.subscribe .mailchimp-form-outer {
  text-align: left;
  max-width: 100%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .subscribe .mailchimp-form-outer {
    display: flex;
  }
}
@media (min-width: 1200px) {
  .subscribe #mc_embed_shell {
    margin: 0;
  }
}
@media (max-width: 767px) {
  .subscribe #mc_embed_signup .button {
    margin-top: 2rem;
  }
}

#chamfr-units-switch {
  width: auto;
  margin-left: auto;
  text-align: right;
  position: relative;
  /* Show the tooltip text when you mouse over the tooltip container */
}
@media (max-width: 767.98px) {
  #chamfr-units-switch {
    width: 100%;
    top: -44px;
    margin-bottom: -24px;
  }
}
#chamfr-units-switch span:first-child::after {
  content: " / ";
}
#chamfr-units-switch a.active {
  font-weight: 700;
}
#chamfr-units-switch .tt {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}
#chamfr-units-switch:hover .tt {
  visibility: visible;
}

#chamfr-supplier-store #chamfr-units-switch {
  display: none;
}

.woocommerce-breadcrumb {
  flex: 0 0 100%;
}

#breadcrumb {
  color: #000;
  opacity: 0.5;
  font-size: 80%;
  padding-bottom: 2rem;
}
#breadcrumb a {
  color: #000;
}

/* Multiple shipping addresses*/
.woocommerce-account .woocommerce-MyAccount-content .woocommerce-address-form h1 {
  text-align: left;
  white-space: nowrap;
}
@media (min-width: 576px) {
  .woocommerce-account .woocommerce-MyAccount-content .woocommerce-address-form h1 {
    padding-right: 15rem;
  }
}

.woocommerce a.remove.delete-address-button {
  width: auto;
  height: auto;
  margin-top: 4rem;
  border-radius: 0;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
  color: #9f325d !important;
}
@media (min-width: 576px) {
  .woocommerce a.remove.delete-address-button {
    margin-top: 1rem;
  }
}
.woocommerce a.remove.delete-address-button:hover {
  background: transparent;
}
.woocommerce a.remove.delete-address-button:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 24px;
  margin-right: 1rem;
  border-radius: 50%;
  background: url(../images/del.svg) center no-repeat;
}

.woocommerce .wc-shipping-multiple-addresses.shop_table {
  margin-top: 1rem;
  /* stylelint-disable-next-line no-descending-specificity */
}
.woocommerce .wc-shipping-multiple-addresses.shop_table th,
.woocommerce .wc-shipping-multiple-addresses.shop_table td {
  /* stylelint-disable-line */
}
@media (max-width: 359.98px) {
  .woocommerce .wc-shipping-multiple-addresses.shop_table th,
.woocommerce .wc-shipping-multiple-addresses.shop_table td {
    padding: 9px 5px;
    font-size: 1rem;
  }
}
@media (max-width: 359.98px) {
  .woocommerce .wc-shipping-multiple-addresses.shop_table input,
.woocommerce .wc-shipping-multiple-addresses.shop_table select {
    height: 4rem;
    padding: 0.5rem;
  }
}
@media (max-width: 359.98px) {
  .woocommerce .wc-shipping-multiple-addresses.shop_table .quantity .qty {
    width: 6rem;
  }
}
@media (max-width: 767.98px) {
  .woocommerce .wc-shipping-multiple-addresses.shop_table .button {
    height: 3rem;
    padding: 0 1rem;
    font-size: 1rem;
    line-height: 3rem;
    /* stylelint-disable-line */
  }
}
@media (max-width: 359.98px) {
  .woocommerce .wc-shipping-multiple-addresses.shop_table .button {
    height: 2rem;
    font-size: 0.9rem;
    line-height: 2rem;
    /* stylelint-disable-line */
  }
}

.woocommerce .update-shipping-addresses,
.woocommerce .set-shipping-addresses {
  margin-bottom: 1rem;
}
.woocommerce .update-shipping-addresses .button,
.woocommerce .set-shipping-addresses .button {
  /* stylelint-disable-line */
}
@media (max-width: 359.98px) {
  .woocommerce .update-shipping-addresses .button,
.woocommerce .set-shipping-addresses .button {
    height: 3rem;
    padding: 0 2rem;
    font-size: 1.2rem;
    line-height: 3rem;
    /* stylelint-disable-line */
  }
}

.woocommerce .update-shipping-addresses .button {
  /* stylelint-disable-line */
  margin-right: 1rem;
}

/* checkout page */
#wcms_message {
  display: none;
}

#shipping_addresses .no_shipping_address,
#shipping_addresses .ship_address {
  float: none;
  width: 100%;
  margin: 3rem 0;
  padding: 2rem;
  background-color: #eee;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-weight: 400;
  line-height: 1.5em;
}
#shipping_addresses .no_shipping_address em,
#shipping_addresses .ship_address em {
  display: block;
  margin-bottom: 2rem;
}
#shipping_addresses .no_shipping_address ul,
#shipping_addresses .ship_address ul {
  list-style: none;
  margin: 0 0 2rem;
  padding: 0;
}
#shipping_addresses .no_shipping_address select,
#shipping_addresses .ship_address select {
  max-width: 30rem;
}

#ship-to-multiple {
  display: none;
}

tr.multi_shipping div.ship_address small.data {
  display: none;
}

/* Page: /checkout/shipping-addresses/ */
.address-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.address-container .address-block {
  float: none;
  width: 25rem;
  min-height: 170px;
  margin: 0;
  padding: 0 15px calc( 30px + 6rem );
}
.address-container .address-block .buttons {
  left: 15px;
  right: 15px;
  bottom: 30px;
  width: auto;
  text-align: left;
}
.address-container .address-block .buttons .button {
  width: 9.6rem;
  height: 4rem;
  padding: 0 1rem;
  line-height: 4rem;
}

#ms_shipping_addresses_field:after {
  height: 4rem;
}

.wcms-address-form label {
  display: block;
}
.wcms-address-form .woocommerce-input-wrapper {
  width: 100%;
}

.woocommerce a.btn-import-billing,
.woocommerce a.btn-import-shipping {
  margin-bottom: 1rem;
}

.woocommerce-MyAccount-navigation ul,
.wcv-navigation ul.menu.horizontal {
  list-style-type: none;
  margin: 0 -0.5rem 3rem;
  padding: 0;
  text-align: center;
  /*@include media-breakpoint-up( $bp ) {
  	display: flex;
  	justify-content: space-between;
  	margin: 0 0 3.5rem;
  }*/
}
@media (min-width: 992px) {
  .woocommerce-MyAccount-navigation ul,
.wcv-navigation ul.menu.horizontal {
    text-align: left;
  }
}
.woocommerce-MyAccount-navigation ul li,
.wcv-navigation ul.menu.horizontal li {
  display: inline-block;
  vertical-align: top;
  float: none;
  width: 17rem;
  padding: 0 0.5rem 1.5rem;
  /*@include media-breakpoint-up( $bp ) {
  	display: block;
  	flex: 0 0 auto;
  	width: auto;
  	margin-right: 2.4rem;
  	padding: 0;
  }*/
}
.woocommerce-MyAccount-navigation ul li a,
.wcv-navigation ul.menu.horizontal li a {
  display: inline-block;
  height: 4.2rem;
  font-size: 1.4rem;
  line-height: 1;
  padding: 1.3rem 3.6rem;
  border-radius: 5px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #eaeaea;
  border: 1px solid #eaeaea;
  opacity: 0.5;
  display: block;
  width: 100%;
  padding: 1.3rem 1rem;
  white-space: nowrap;
  text-align: center;
  /*@include media-breakpoint-up( $bp ) {
  	display: inline-block;
  	padding: 1.3rem 2rem;
  }*/
}
.woocommerce-MyAccount-navigation ul li a:hover,
.wcv-navigation ul.menu.horizontal li a:hover {
  color: #fff;
  border-color: #fff;
  text-decoration: none;
  opacity: 1;
}
@media (min-width: 1200px) {
  .woocommerce-MyAccount-navigation ul li a,
.wcv-navigation ul.menu.horizontal li a {
    padding: 1.3rem 3rem;
  }
}
.woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--payment-methods a,
.wcv-navigation ul.menu.horizontal li.woocommerce-MyAccount-navigation-link--payment-methods a {
  font-size: 0;
}
@media (min-width: 1200px) {
  .woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--payment-methods a,
.wcv-navigation ul.menu.horizontal li.woocommerce-MyAccount-navigation-link--payment-methods a {
    font-size: 1.4rem;
  }
}
.woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--payment-methods a:before,
.wcv-navigation ul.menu.horizontal li.woocommerce-MyAccount-navigation-link--payment-methods a:before {
  content: "payment";
  font-size: 1.4rem;
}
@media (min-width: 1200px) {
  .woocommerce-MyAccount-navigation ul li.woocommerce-MyAccount-navigation-link--payment-methods a:before,
.wcv-navigation ul.menu.horizontal li.woocommerce-MyAccount-navigation-link--payment-methods a:before {
    display: none;
  }
}
.woocommerce-MyAccount-navigation ul li.is-active a, .woocommerce-MyAccount-navigation ul li.active a,
.wcv-navigation ul.menu.horizontal li.is-active a,
.wcv-navigation ul.menu.horizontal li.active a {
  opacity: 1;
}
.woocommerce-MyAccount-navigation ul li:last-child,
.wcv-navigation ul.menu.horizontal li:last-child {
  /*@include media-breakpoint-up( $bp ) {
  	flex-grow: 1;
  	margin-right: 0;
  	text-align: right;

  	a {
  		width: auto;
  	}
  }*/
}
.woocommerce-MyAccount-navigation ul li:last-child a,
.wcv-navigation ul.menu.horizontal li:last-child a {
  opacity: 1;
  border-color: #e11d73;
  background-color: #e11d73;
}

@media (min-width: 992px) {
  .woocommerce-MyAccount-navigation ul {
    display: flex;
    justify-content: space-between;
    margin: 0 0 3.5rem;
  }
  .woocommerce-MyAccount-navigation ul li {
    display: block;
    flex: 0 0 auto;
    width: auto;
    margin-right: 2.4rem;
    padding: 0;
  }
  .woocommerce-MyAccount-navigation ul li:last-child {
    flex-grow: 1;
    margin-right: 0;
    text-align: right;
  }
  .woocommerce-MyAccount-navigation ul li:last-child a {
    width: auto;
  }
  .woocommerce-MyAccount-navigation ul li a {
    display: inline-block;
    padding: 1.3rem 2rem;
  }
}
@media (min-width: 1200px) {
  .woocommerce-MyAccount-navigation ul li a {
    padding: 1.3rem 3.6rem;
  }
}

.wcv-dashboard-navigation ul {
  list-style-type: none;
  margin: 0 -0.5rem 3rem;
  padding: 0;
  text-align: center;
}
@media (min-width: 992px) {
  .wcv-dashboard-navigation ul {
    display: flex;
    margin: 0 0 3.5rem;
  }
}
.wcv-dashboard-navigation ul li {
  display: inline-block;
  vertical-align: top;
  width: 17rem;
  padding: 0 0.5rem 1.5rem;
}
@media (min-width: 992px) {
  .wcv-dashboard-navigation ul li {
    display: block;
    flex: 0 0 auto;
    width: auto;
    margin-right: 2.6rem;
    padding: 0;
  }
  .wcv-dashboard-navigation ul li:last-child {
    margin-right: 0;
  }
}
.wcv-dashboard-navigation ul li a {
  display: inline-block;
  height: 4.2rem;
  font-size: 1.4rem;
  line-height: 1;
  padding: 1.3rem 3.6rem;
  border-radius: 5px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #eaeaea;
  border: 1px solid #eaeaea;
  opacity: 0.5;
  display: block;
  width: 100%;
  padding: 1.3rem 1rem;
  white-space: nowrap;
  text-align: center;
}
.wcv-dashboard-navigation ul li a:hover {
  color: #fff;
  border-color: #fff;
  text-decoration: none;
  opacity: 1;
}
@media (min-width: 992px) {
  .wcv-dashboard-navigation ul li a {
    padding: 1.3rem 2rem;
  }
}
@media (min-width: 1200px) {
  .wcv-dashboard-navigation ul li a {
    padding: 1.3rem 3.6rem;
  }
}

.woocommerce nav.woocommerce-pagination {
  text-align: center;
  margin: 0;
  padding: 5rem 1rem 1rem;
}
@media (max-width: 767.98px) {
  .woocommerce nav.woocommerce-pagination {
    padding: 2rem 1rem 1rem;
  }
}
.woocommerce nav.woocommerce-pagination ul {
  border: none;
  margin: 0;
  padding: 1rem;
  border-radius: 28px;
  transition: background-color 0.1s ease, box-shadow 0.1s ease;
}
@media (max-width: 767.98px) {
  .woocommerce nav.woocommerce-pagination ul {
    padding: 0.5rem;
    border-radius: 19px;
  }
}
.woocommerce nav.woocommerce-pagination ul li {
  border: none;
}
.woocommerce nav.woocommerce-pagination ul li span,
.woocommerce nav.woocommerce-pagination ul li a {
  min-width: 3.6rem;
  height: 3.6rem;
  margin: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767.98px) {
  .woocommerce nav.woocommerce-pagination ul li span,
.woocommerce nav.woocommerce-pagination ul li a {
    margin: 0;
    height: 2.8rem;
    min-width: 2.8rem;
  }
}
.woocommerce nav.woocommerce-pagination ul li span.current {
  background-color: transparent;
  color: #e11d73;
}
.woocommerce nav.woocommerce-pagination ul li a {
  color: #000;
  opacity: 0.5;
}
.woocommerce nav.woocommerce-pagination ul li a.next, .woocommerce nav.woocommerce-pagination ul li a.prev {
  border-color: #000;
  opacity: 1;
}
.woocommerce nav.woocommerce-pagination.fixed {
  position: fixed;
  z-index: 1;
  pointer-events: none;
}
.woocommerce nav.woocommerce-pagination.fixed ul {
  pointer-events: auto;
  background-color: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.form-row select {
  padding: 0 1.5rem;
}

.ms-addresses-field,
#order_shipment_account_type {
  position: relative;
}
.ms-addresses-field:after,
#order_shipment_account_type:after {
  content: "";
  display: block !important;
  position: absolute;
  top: 2.8rem;
  bottom: 1px;
  right: 1px;
  z-index: 10;
  width: 1.5rem;
  height: 5rem;
  background: #fff;
  border-radius: 0 0.5rem 0.5rem 0;
}
.ms-addresses-field:before,
#order_shipment_account_type:before {
  position: absolute;
  top: 5rem;
  right: 0.8rem;
  z-index: 11;
  width: 0;
  height: 0;
  margin-left: -6px;
  margin-top: -3px;
  border-color: #9f325d transparent transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0 6px;
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  box-shadow: none;
}

.select2-container .select2-choice {
  height: 5rem;
  margin: 0;
  padding: 0 0 0 1.5rem;
  background: #fff;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  line-height: 5rem;
  /* stylelint-disable-line declaration-property-unit-whitelist */
  color: #313131;
}
.select2-container .select2-choice .select2-arrow {
  top: 0;
  width: 3rem;
  height: 5rem;
  background: transparent;
  border: none;
}
.select2-container .select2-choice .select2-arrow b {
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -6px;
  margin-top: -3px;
  border-color: #9f325d transparent transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  top: 50%;
  left: 50%;
}

.select2-container .select2-selection--single {
  height: 5rem;
  margin: 0;
}

.select2-container--default .select2-selection--single {
  border-radius: 0.5rem;
  border: 1px solid #ddd;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding-left: 1.5rem;
  padding-right: 3rem;
  line-height: 5rem;
  /* stylelint-disable-line declaration-property-unit-whitelist */
  color: #313131;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 0;
  width: 3rem;
  height: 5rem;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -6px;
  margin-top: -3px;
  border-color: #9f325d transparent transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0 6px;
}
.select2-container--default .select2-results {
  color: #313131;
}
.select2-container--default .select2-results__option {
  padding: 0.6rem 1.5rem;
}
.select2-container--default .select2-results__option--highlighted[aria-selected], .select2-container--default .select2-results__option--highlighted[data-selected] {
  background: #9f325d;
  color: #fff;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #9f325d transparent;
  border-width: 0 6px 6px 6px;
}

.select2-selection--multiple {
  position: relative;
  height: 5rem;
  border: 1px solid #ddd;
}
.select2-selection--multiple:after {
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -6px;
  margin-top: -3px;
  border-color: #9f325d transparent transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  right: 10px;
  top: 24px;
  content: "";
}
.select2-selection--multiple .select2-search__field {
  height: auto;
}

/* stylelint-disable no-descending-specificity */
.woocommerce-table {
  width: 100%;
}
.woocommerce-table thead tr,
.woocommerce-table tbody tr {
  border-bottom: 1px solid #b7b7b7;
}
.woocommerce-table thead tr th,
.woocommerce-table tbody tr th {
  padding: 0 1rem 1rem;
  font-size: 1.8rem;
  font-weight: 600;
}
.woocommerce-table thead tr th:first-of-type,
.woocommerce-table tbody tr th:first-of-type {
  padding-left: 0;
}
.woocommerce-table thead tr th:last-of-type,
.woocommerce-table tbody tr th:last-of-type {
  padding-right: 0;
}
.woocommerce-table thead tr td,
.woocommerce-table tbody tr td {
  padding: 2.5rem 1rem;
}
.woocommerce-table thead tr td:first-of-type,
.woocommerce-table tbody tr td:first-of-type {
  padding-left: 0;
}
.woocommerce-table thead tr td:last-of-type,
.woocommerce-table tbody tr td:last-of-type {
  padding-right: 0;
}
.woocommerce-table tfoot tr {
  border: none;
}
.woocommerce-table tfoot tr th,
.woocommerce-table tfoot tr td {
  padding: 0.5rem 1rem;
}
.woocommerce-table tfoot tr th:first-of-type,
.woocommerce-table tfoot tr td:first-of-type {
  padding-left: 0;
}
.woocommerce-table tfoot tr th:last-of-type,
.woocommerce-table tfoot tr td:last-of-type {
  padding-right: 0;
}
.woocommerce-table tfoot tr th {
  font-size: 1.8rem;
  font-weight: 600;
}
.woocommerce-table tfoot tr td {
  text-align: right;
}
.woocommerce-table tfoot tr:first-of-type th,
.woocommerce-table tfoot tr:first-of-type td {
  padding-top: 3rem;
}
.woocommerce-table-responsive thead {
  display: none;
}
@media (min-width: 992px) {
  .woocommerce-table-responsive thead {
    display: table-header-group;
  }
}
.woocommerce-table-responsive tbody tr:first-of-type td {
  padding-top: 0;
}
@media (min-width: 992px) {
  .woocommerce-table-responsive tbody tr:first-of-type td {
    padding-top: 2.5rem;
  }
}
.woocommerce-table-responsive tbody tr {
  display: block;
}
@media (min-width: 992px) {
  .woocommerce-table-responsive tbody tr {
    display: table-row;
  }
}
.woocommerce-table-responsive tbody tr td {
  display: block;
  padding: 0 0 1rem;
}
.woocommerce-table-responsive tbody tr td:first-of-type {
  padding: 3rem 0 1rem;
}
.woocommerce-table-responsive tbody tr td:last-of-type {
  padding: 2rem 0;
}
@media (min-width: 992px) {
  .woocommerce-table-responsive tbody tr td {
    display: table-cell;
    padding: 2.5rem 1rem;
  }
  .woocommerce-table-responsive tbody tr td:first-of-type {
    padding: 2.5rem 1rem 2.5rem 0;
  }
  .woocommerce-table-responsive tbody tr td:last-of-type {
    padding: 2.5rem 0 2.5rem 1rem;
  }
}

.woocommerce table.shop_attributes {
  border-top: 1px solid #b7b7b7;
}
.woocommerce table.shop_attributes tr {
  border-bottom: 1px solid #b7b7b7;
}
.woocommerce table.shop_attributes th {
  display: block;
  width: auto;
  padding: 12px 0 3px;
  border: none;
  opacity: 0.5;
  font-weight: 400;
}
@media (min-width: 576px) {
  .woocommerce table.shop_attributes th {
    display: table-cell;
    padding: 12px 12px 12px 0;
  }
}
.woocommerce table.shop_attributes td {
  display: block;
  padding: 3px 0 12px;
  border: none;
  font-style: normal;
}
@media (min-width: 576px) {
  .woocommerce table.shop_attributes td {
    display: table-cell;
    padding: 12px 0;
  }
}
.woocommerce table.shop_attributes td p {
  padding: 0;
}
.woocommerce table.shop_attributes tr:nth-child(even) td,
.woocommerce table.shop_attributes tr:nth-child(even) th {
  background-color: transparent;
}

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button,
#add_payment_method .wc-proceed-to-checkout a.checkout-button {
  padding: 1.558rem 3rem;
  font-size: 1.6rem;
}

.woocommerce #respond input#submit.disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce #respond input#submit:disabled[disabled],
.woocommerce a.button.disabled,
.woocommerce a.button:disabled,
.woocommerce a.button:disabled[disabled],
.woocommerce button.button.disabled,
.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce input.button.disabled,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled] {
  padding: 1.558rem 3rem;
}

.checkout .form-row,
#order_review, .form-row {
  display: block;
}

.woocommerce form .form-row {
  padding: 0;
  margin: 0 0 2rem;
}
.woocommerce form .form-row .woocommerce-input-wrapper .field-hint {
  display: inline;
  background: none;
  color: #000;
  border-radius: 0;
  padding: 1em;
  margin: 0.5em 0 0;
  font-size: 1.2rem;
}

.woocommerce form .form-row label {
  line-height: 1.2;
}

.woocommerce-form__label-for-checkbox span:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  transition: background-color 0.2s ease-out 0.05s, border-color 0.2s ease-out 0.05s, background-image 0.1s 0.3s;
  cursor: pointer;
}

.woocommerce-form__label-for-checkbox input:checked + span:before {
  background: #fff url(../images/check.svg) no-repeat center;
  border: 1px solid #ddd;
}

.woocommerce form .form-row-first,
.woocommerce form .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last {
  float: none;
  width: 100%;
}
@media (min-width: 768px) {
  .woocommerce form .form-row-first,
.woocommerce form .form-row-last,
.woocommerce-page form .form-row-first,
.woocommerce-page form .form-row-last {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .woocommerce form .form-row-first,
.woocommerce-page form .form-row-first {
    float: left;
    padding-right: 1.2rem;
  }
}

@media (min-width: 768px) {
  .woocommerce form .form-row-last,
.woocommerce-page form .form-row-last {
    float: right;
    padding-left: 1.2rem;
  }
}

@media (min-width: 768px) {
  .form-row-last[style="display: none"] + .form-row-first {
    float: right;
    padding-left: 1.2rem;
    padding-right: 0;
  }
}

#ship-to-different-address {
  margin: 0 0 2rem;
}

#customer_details {
  margin-bottom: 5rem;
}

.woocommerce-shipping-fields {
  margin: 5rem 0 0;
}
@media (min-width: 992px) {
  .woocommerce-shipping-fields {
    margin: 0;
  }
}

.woocommerce ul.order_details {
  margin: 0 0 5rem;
  padding: 0;
}
.woocommerce ul.order_details li {
  padding-top: 2em;
}
.woocommerce ul.order_details li:last-child {
  margin-right: 0;
  padding-right: 0;
}
@media (max-width: 991.98px) {
  .woocommerce ul.order_details li {
    border-right: none;
  }
}

.woocommerce-thankyou-order-received,
.woocommerce-thankyou-order-failed {
  margin: 0 0 5rem;
}

.woocommerce-pagination {
  margin: 5rem 0 0;
}

.woocommerce .quantity .qty {
  width: 8rem;
}

.product-price ins {
  text-decoration: none;
}

.woocommerce table.shop_table td del {
  margin-right: 0.8rem;
}

.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select {
  border-color: #ddd;
}

.woocommerce-error,
.woocommerce-info,
.woocommerce-message {
  list-style: none outside;
  position: relative;
  width: auto;
  margin: 0 0 2em !important;
  padding: 1em 2em 1em 3.5em;
  background-color: #fafafa;
  border-top: 3px solid #e11d73;
  color: #313131;
  word-wrap: break-word;
}

.woocommerce-error:before,
.woocommerce-info:before,
.woocommerce-message:before {
  content: "\e028";
  display: inline-block;
  position: absolute;
  top: 1em;
  left: 1.5em;
  font-family: WooCommerce;
  /* stylelint-disable-line */
  color: #e11d73;
}

.woocommerce-info {
  border-top-color: #313131;
}
.woocommerce-info:before {
  color: #313131;
}

.woocommerce-message {
  border-top-color: #313131;
}
.woocommerce-message:before {
  content: "\e015";
  color: #313131;
}

.woocommerce .woocommerce-form-login .woocommerce-form-login__rememberme {
  display: block;
  width: 100%;
  margin: 2rem 0 3rem;
}

.woocommerce-ResetPassword.lost_reset_password .password-input,
.woocommerce-ResetPassword.lost_reset_password label {
  width: 100%;
}

.woocommerce-billing-fields {
  margin-bottom: 5rem;
}

.wc-stripe-elements-field,
.wc-stripe-iban-element-field {
  padding: 15px;
}

.woocommerce #payment #place_order,
.woocommerce-page #payment #place_order,
.woocommerce-page #payment .btn-secondary,
.woocommerce-page #payment .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button,
.wcv-grid .chamfr-vendor-dashboard-content .wcv-search .woocommerce-page #payment .wcv-button,
.woocommerce-page #payment .wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce .woocommerce-page #payment a.wcv-button.button.alt,
.woocommerce-page #payment .woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-page #payment a.wcv-button.button.alt,
.woocommerce-page #payment .wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce .woocommerce-page #payment button.wcv-button.button.alt,
.woocommerce-page #payment .woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-page #payment button.wcv-button.button.alt,
.woocommerce-page #payment .wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce .woocommerce-page #payment input.wcv-button.button.alt,
.woocommerce-page #payment .woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-page #payment input.wcv-button.button.alt,
.woocommerce-page #payment .woocommerce #respond input#submit.alt,
.woocommerce #respond .woocommerce-page #payment input#submit.alt,
.woocommerce-page #payment .woocommerce a.button.alt,
.woocommerce .woocommerce-page #payment a.button.alt,
.woocommerce-page #payment .woocommerce button.button.alt,
.woocommerce .woocommerce-page #payment button.button.alt,
.woocommerce-page #payment .woocommerce input.button.alt,
.woocommerce .woocommerce-page #payment input.button.alt {
  float: none;
  padding: 1.558rem 7rem;
}

.woocommerce-privacy-policy-text {
  margin: 3rem 0 0;
  font-size: 1.4rem;
}

.added-to-cart {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20000;
  background-color: #fff;
  width: 100%;
  max-width: 80rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}
.added-to-cart-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.15);
}
.added-to-cart .close {
  font-size: 0;
  width: 1.2rem;
  height: 1.2rem;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10.9999 10.9999' stroke='white' stroke-linecap='round'/%3E%3Cpath d='M11 1L1.00008 10.9999' stroke='white' stroke-linecap='round'/%3E%3C/svg%3E");
}
.added-to-cart .heading {
  color: #fff;
  background-color: #56b8a1;
  padding: 2rem;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1;
}
.added-to-cart .content {
  padding: 2rem;
  display: flex;
  gap: 2rem;
}
@media (max-width: 991.98px) {
  .added-to-cart .content {
    display: block;
  }
}
.added-to-cart .item-image img {
  max-width: 6rem;
  height: auto;
}
.added-to-cart .item-data {
  font-size: 1.4rem;
  color: #6b6b6b;
}
.added-to-cart .item-data .name {
  font-size: 1.6rem;
  color: #000;
}
.added-to-cart .item-data ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.added-to-cart .actions .btn, .added-to-cart .actions .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .added-to-cart .actions .stripe-connect.light-blue, .added-to-cart .actions .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .added-to-cart .actions .wcv-button, .added-to-cart .actions .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .added-to-cart .actions .button, .added-to-cart .actions .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .added-to-cart .actions .website, .added-to-cart .actions .blog .sticky .post__link a, .blog .sticky .post__link .added-to-cart .actions a,
.added-to-cart .actions .archive .sticky .post__link a,
.archive .sticky .post__link .added-to-cart .actions a, .added-to-cart .actions .wp-block-button__link, .added-to-cart .actions body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .added-to-cart .actions input.button,
.added-to-cart .actions body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .added-to-cart .actions input[type=submit],
.added-to-cart .actions body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .added-to-cart .actions input.button,
.added-to-cart .actions body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .added-to-cart .actions input[type=submit], .added-to-cart .actions .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .added-to-cart .actions a, .added-to-cart .actions .woocommerce #respond input#submit, .woocommerce #respond .added-to-cart .actions input#submit,
.added-to-cart .actions .woocommerce a.button,
.woocommerce .added-to-cart .actions a.button,
.added-to-cart .actions .woocommerce button.button,
.woocommerce .added-to-cart .actions button.button,
.added-to-cart .actions .woocommerce input.button,
.woocommerce .added-to-cart .actions input.button {
  text-wrap: nowrap;
  width: 100%;
  box-shadow: none;
}

.my-account-popup {
  display: none;
  position: absolute;
  top: 60px;
  right: 26.5rem;
  max-width: 433px;
  width: 100%;
  background: #FFFFFF;
  z-index: 9999;
  padding: 5px 3px 8px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
@media (max-width: 767.98px) {
  .my-account-popup {
    position: fixed;
    top: 0;
    right: 0;
    overflow: scroll;
    height: 100%;
  }
}
.my-account-popup.open-my-account {
  display: block;
}
.my-account-popup .popup-close-btn {
  position: absolute;
  top: 12px;
  right: 15px;
  z-index: 100;
  width: 15px;
  height: 15px;
  background: url(../images/menu-close-gray.svg) no-repeat center/contain;
  transition: opacity 0.3s ease-out;
  opacity: 1;
}
@media (min-width: 768px) {
  .my-account-popup .popup-close-btn {
    opacity: 0;
  }
}
.my-account-popup .login-register {
  padding: 0;
}
.my-account-popup .form-top-area {
  padding: 24px;
}
.my-account-popup .beta-message-before-login {
  margin: 0 0 35px;
}
.my-account-popup .beta-message-before-login p {
  color: #2b2b2b;
  font-size: 16px;
  font-weight: 800;
  line-height: 18.8px;
}
.my-account-popup .beta-message-before-login p a {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.my-account-popup .beta-message-before-login p a:hover {
  text-decoration: none;
}
.my-account-popup .woocommerce-form {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}
.my-account-popup .woocommerce-form .woocommerce-form-group {
  margin: 0 0 32px;
  position: relative;
}
.my-account-popup .woocommerce-form .woocommerce-form-group .show-password-input {
  top: auto;
  right: 25px;
  bottom: 14px;
}
.my-account-popup .woocommerce-form .woocommerce-form-group .show-password-input.display-password::after {
  color: #636363;
}
.my-account-popup .woocommerce-form .woocommerce-form-group .show-password-input::after {
  color: #111111;
  display: block;
  width: 24px;
  height: 19px;
  text-align: right;
}
.my-account-popup .woocommerce-form label {
  color: #111111;
  font-size: 14px;
  line-height: 16.45px;
}
.my-account-popup .woocommerce-form label .required {
  color: #CA3737;
}
.my-account-popup .woocommerce-form .input-text {
  border: 1px solid #b4b4b4;
  padding: 15px 16px;
  border-radius: 4px;
  transition: all 0.3s ease-in;
}
.my-account-popup .woocommerce-form .input-text:focus {
  border-color: #e11d73;
}
.my-account-popup .woocommerce-form .input-checkbox {
  border: 1px solid #b4b4b4;
  margin-right: 8px;
}
.my-account-popup .woocommerce-form .btn, .my-account-popup .woocommerce-form .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .my-account-popup .woocommerce-form .stripe-connect.light-blue, .my-account-popup .woocommerce-form .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .woocommerce-form .wcv-button, .my-account-popup .woocommerce-form .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .woocommerce-form .button, .my-account-popup .woocommerce-form .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .my-account-popup .woocommerce-form .website, .my-account-popup .woocommerce-form .blog .sticky .post__link a, .blog .sticky .post__link .my-account-popup .woocommerce-form a,
.my-account-popup .woocommerce-form .archive .sticky .post__link a,
.archive .sticky .post__link .my-account-popup .woocommerce-form a, .my-account-popup .woocommerce-form .wp-block-button__link, .my-account-popup .woocommerce-form body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .my-account-popup .woocommerce-form input.button,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .my-account-popup .woocommerce-form input[type=submit],
.my-account-popup .woocommerce-form body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .my-account-popup .woocommerce-form input.button,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .my-account-popup .woocommerce-form input[type=submit], .my-account-popup .woocommerce-form .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .my-account-popup .woocommerce-form a, .my-account-popup .woocommerce-form .woocommerce #respond input#submit, .woocommerce #respond .my-account-popup .woocommerce-form input#submit,
.my-account-popup .woocommerce-form .woocommerce a.button,
.woocommerce .my-account-popup .woocommerce-form a.button,
.my-account-popup .woocommerce-form .woocommerce button.button,
.woocommerce .my-account-popup .woocommerce-form button.button,
.my-account-popup .woocommerce-form .woocommerce input.button,
.woocommerce .my-account-popup .woocommerce-form input.button {
  max-width: 300px;
  width: 100%;
  border-radius: 8px !important;
  box-shadow: none;
  letter-spacing: 0;
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  border-color: #e11d73;
  background-color: #e11d73;
}
.my-account-popup .woocommerce-form .btn:hover, .my-account-popup .woocommerce-form .woocommerce-MyAccount-content .stripe-connect.light-blue:hover, .woocommerce-MyAccount-content .my-account-popup .woocommerce-form .stripe-connect.light-blue:hover, .my-account-popup .woocommerce-form .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:hover, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .woocommerce-form .wcv-button:hover, .my-account-popup .woocommerce-form .wcv-grid .chamfr-vendor-dashboard-content .button:hover, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .woocommerce-form .button:hover, .my-account-popup .woocommerce-form .chamfr-service-partners-featured .summary .website:hover, .chamfr-service-partners-featured .summary .my-account-popup .woocommerce-form .website:hover, .my-account-popup .woocommerce-form .blog .sticky .post__link a:hover, .blog .sticky .post__link .my-account-popup .woocommerce-form a:hover,
.my-account-popup .woocommerce-form .archive .sticky .post__link a:hover,
.archive .sticky .post__link .my-account-popup .woocommerce-form a:hover, .my-account-popup .woocommerce-form .wp-block-button__link:hover, .my-account-popup .woocommerce-form body .gform_wrapper .gform_footer input.button:hover, body .gform_wrapper .gform_footer .my-account-popup .woocommerce-form input.button:hover,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_footer input[type=submit]:hover,
body .gform_wrapper .gform_footer .my-account-popup .woocommerce-form input[type=submit]:hover,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_page_footer input.button:hover,
body .gform_wrapper .gform_page_footer .my-account-popup .woocommerce-form input.button:hover,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_page_footer input[type=submit]:hover,
body .gform_wrapper .gform_page_footer .my-account-popup .woocommerce-form input[type=submit]:hover, .my-account-popup .woocommerce-form .woocommerce-order-details-table + p a:hover, .woocommerce-order-details-table + p .my-account-popup .woocommerce-form a:hover, .my-account-popup .woocommerce-form .woocommerce #respond input#submit:hover, .woocommerce #respond .my-account-popup .woocommerce-form input#submit:hover,
.my-account-popup .woocommerce-form .woocommerce a.button:hover,
.woocommerce .my-account-popup .woocommerce-form a.button:hover,
.my-account-popup .woocommerce-form .woocommerce button.button:hover,
.woocommerce .my-account-popup .woocommerce-form button.button:hover,
.my-account-popup .woocommerce-form .woocommerce input.button:hover,
.woocommerce .my-account-popup .woocommerce-form input.button:hover, .my-account-popup .woocommerce-form .btn:focus, .my-account-popup .woocommerce-form .woocommerce-MyAccount-content .stripe-connect.light-blue:focus, .woocommerce-MyAccount-content .my-account-popup .woocommerce-form .stripe-connect.light-blue:focus, .my-account-popup .woocommerce-form .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:focus, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .woocommerce-form .wcv-button:focus, .my-account-popup .woocommerce-form .wcv-grid .chamfr-vendor-dashboard-content .button:focus, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .woocommerce-form .button:focus, .my-account-popup .woocommerce-form .chamfr-service-partners-featured .summary .website:focus, .chamfr-service-partners-featured .summary .my-account-popup .woocommerce-form .website:focus, .my-account-popup .woocommerce-form .blog .sticky .post__link a:focus, .blog .sticky .post__link .my-account-popup .woocommerce-form a:focus,
.my-account-popup .woocommerce-form .archive .sticky .post__link a:focus,
.archive .sticky .post__link .my-account-popup .woocommerce-form a:focus, .my-account-popup .woocommerce-form .wp-block-button__link:focus, .my-account-popup .woocommerce-form body .gform_wrapper .gform_footer input.button:focus, body .gform_wrapper .gform_footer .my-account-popup .woocommerce-form input.button:focus,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_footer input[type=submit]:focus,
body .gform_wrapper .gform_footer .my-account-popup .woocommerce-form input[type=submit]:focus,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_page_footer input.button:focus,
body .gform_wrapper .gform_page_footer .my-account-popup .woocommerce-form input.button:focus,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_page_footer input[type=submit]:focus,
body .gform_wrapper .gform_page_footer .my-account-popup .woocommerce-form input[type=submit]:focus, .my-account-popup .woocommerce-form .woocommerce-order-details-table + p a:focus, .woocommerce-order-details-table + p .my-account-popup .woocommerce-form a:focus, .my-account-popup .woocommerce-form .woocommerce #respond input#submit:focus, .woocommerce #respond .my-account-popup .woocommerce-form input#submit:focus,
.my-account-popup .woocommerce-form .woocommerce a.button:focus,
.woocommerce .my-account-popup .woocommerce-form a.button:focus,
.my-account-popup .woocommerce-form .woocommerce button.button:focus,
.woocommerce .my-account-popup .woocommerce-form button.button:focus,
.my-account-popup .woocommerce-form .woocommerce input.button:focus,
.woocommerce .my-account-popup .woocommerce-form input.button:focus {
  text-decoration: none;
}
.my-account-popup .woocommerce-form .btn:hover, .my-account-popup .woocommerce-form .woocommerce-MyAccount-content .stripe-connect.light-blue:hover, .woocommerce-MyAccount-content .my-account-popup .woocommerce-form .stripe-connect.light-blue:hover, .my-account-popup .woocommerce-form .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:hover, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .woocommerce-form .wcv-button:hover, .my-account-popup .woocommerce-form .wcv-grid .chamfr-vendor-dashboard-content .button:hover, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .woocommerce-form .button:hover, .my-account-popup .woocommerce-form .chamfr-service-partners-featured .summary .website:hover, .chamfr-service-partners-featured .summary .my-account-popup .woocommerce-form .website:hover, .my-account-popup .woocommerce-form .blog .sticky .post__link a:hover, .blog .sticky .post__link .my-account-popup .woocommerce-form a:hover,
.my-account-popup .woocommerce-form .archive .sticky .post__link a:hover,
.archive .sticky .post__link .my-account-popup .woocommerce-form a:hover, .my-account-popup .woocommerce-form .wp-block-button__link:hover, .my-account-popup .woocommerce-form body .gform_wrapper .gform_footer input.button:hover, body .gform_wrapper .gform_footer .my-account-popup .woocommerce-form input.button:hover,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_footer input[type=submit]:hover,
body .gform_wrapper .gform_footer .my-account-popup .woocommerce-form input[type=submit]:hover,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_page_footer input.button:hover,
body .gform_wrapper .gform_page_footer .my-account-popup .woocommerce-form input.button:hover,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_page_footer input[type=submit]:hover,
body .gform_wrapper .gform_page_footer .my-account-popup .woocommerce-form input[type=submit]:hover, .my-account-popup .woocommerce-form .woocommerce-order-details-table + p a:hover, .woocommerce-order-details-table + p .my-account-popup .woocommerce-form a:hover, .my-account-popup .woocommerce-form .woocommerce #respond input#submit:hover, .woocommerce #respond .my-account-popup .woocommerce-form input#submit:hover,
.my-account-popup .woocommerce-form .woocommerce a.button:hover,
.woocommerce .my-account-popup .woocommerce-form a.button:hover,
.my-account-popup .woocommerce-form .woocommerce button.button:hover,
.woocommerce .my-account-popup .woocommerce-form button.button:hover,
.my-account-popup .woocommerce-form .woocommerce input.button:hover,
.woocommerce .my-account-popup .woocommerce-form input.button:hover, .my-account-popup .woocommerce-form .btn:focus, .my-account-popup .woocommerce-form .woocommerce-MyAccount-content .stripe-connect.light-blue:focus, .woocommerce-MyAccount-content .my-account-popup .woocommerce-form .stripe-connect.light-blue:focus, .my-account-popup .woocommerce-form .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:focus, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .woocommerce-form .wcv-button:focus, .my-account-popup .woocommerce-form .wcv-grid .chamfr-vendor-dashboard-content .button:focus, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .woocommerce-form .button:focus, .my-account-popup .woocommerce-form .chamfr-service-partners-featured .summary .website:focus, .chamfr-service-partners-featured .summary .my-account-popup .woocommerce-form .website:focus, .my-account-popup .woocommerce-form .blog .sticky .post__link a:focus, .blog .sticky .post__link .my-account-popup .woocommerce-form a:focus,
.my-account-popup .woocommerce-form .archive .sticky .post__link a:focus,
.archive .sticky .post__link .my-account-popup .woocommerce-form a:focus, .my-account-popup .woocommerce-form .wp-block-button__link:focus, .my-account-popup .woocommerce-form body .gform_wrapper .gform_footer input.button:focus, body .gform_wrapper .gform_footer .my-account-popup .woocommerce-form input.button:focus,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_footer input[type=submit]:focus,
body .gform_wrapper .gform_footer .my-account-popup .woocommerce-form input[type=submit]:focus,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_page_footer input.button:focus,
body .gform_wrapper .gform_page_footer .my-account-popup .woocommerce-form input.button:focus,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_page_footer input[type=submit]:focus,
body .gform_wrapper .gform_page_footer .my-account-popup .woocommerce-form input[type=submit]:focus, .my-account-popup .woocommerce-form .woocommerce-order-details-table + p a:focus, .woocommerce-order-details-table + p .my-account-popup .woocommerce-form a:focus, .my-account-popup .woocommerce-form .woocommerce #respond input#submit:focus, .woocommerce #respond .my-account-popup .woocommerce-form input#submit:focus,
.my-account-popup .woocommerce-form .woocommerce a.button:focus,
.woocommerce .my-account-popup .woocommerce-form a.button:focus,
.my-account-popup .woocommerce-form .woocommerce button.button:focus,
.woocommerce .my-account-popup .woocommerce-form button.button:focus,
.my-account-popup .woocommerce-form .woocommerce input.button:focus,
.woocommerce .my-account-popup .woocommerce-form input.button:focus {
  color: #FFFFFF;
  background-color: #9f325d;
  border-color: #9f325d;
}
.my-account-popup .woocommerce-form .btn:disabled, .my-account-popup .woocommerce-form .woocommerce-MyAccount-content .stripe-connect.light-blue:disabled, .woocommerce-MyAccount-content .my-account-popup .woocommerce-form .stripe-connect.light-blue:disabled, .my-account-popup .woocommerce-form .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:disabled, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .woocommerce-form .wcv-button:disabled, .my-account-popup .woocommerce-form .wcv-grid .chamfr-vendor-dashboard-content .button:disabled, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .woocommerce-form .button:disabled, .my-account-popup .woocommerce-form .chamfr-service-partners-featured .summary .website:disabled, .chamfr-service-partners-featured .summary .my-account-popup .woocommerce-form .website:disabled, .my-account-popup .woocommerce-form .blog .sticky .post__link a:disabled, .blog .sticky .post__link .my-account-popup .woocommerce-form a:disabled,
.my-account-popup .woocommerce-form .archive .sticky .post__link a:disabled,
.archive .sticky .post__link .my-account-popup .woocommerce-form a:disabled, .my-account-popup .woocommerce-form .wp-block-button__link:disabled, .my-account-popup .woocommerce-form body .gform_wrapper .gform_footer input.button:disabled, body .gform_wrapper .gform_footer .my-account-popup .woocommerce-form input.button:disabled,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_footer input[type=submit]:disabled,
body .gform_wrapper .gform_footer .my-account-popup .woocommerce-form input[type=submit]:disabled,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_page_footer input.button:disabled,
body .gform_wrapper .gform_page_footer .my-account-popup .woocommerce-form input.button:disabled,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_page_footer input[type=submit]:disabled,
body .gform_wrapper .gform_page_footer .my-account-popup .woocommerce-form input[type=submit]:disabled, .my-account-popup .woocommerce-form .woocommerce-order-details-table + p a:disabled, .woocommerce-order-details-table + p .my-account-popup .woocommerce-form a:disabled, .my-account-popup .woocommerce-form .woocommerce #respond input#submit:disabled, .woocommerce #respond .my-account-popup .woocommerce-form input#submit:disabled,
.my-account-popup .woocommerce-form .woocommerce a.button:disabled,
.woocommerce .my-account-popup .woocommerce-form a.button:disabled,
.my-account-popup .woocommerce-form .woocommerce button.button:disabled,
.woocommerce .my-account-popup .woocommerce-form button.button:disabled,
.my-account-popup .woocommerce-form .woocommerce input.button:disabled,
.woocommerce .my-account-popup .woocommerce-form input.button:disabled {
  color: #FFFFFF;
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}
.my-account-popup .woocommerce-form .btn:hover, .my-account-popup .woocommerce-form .woocommerce-MyAccount-content .stripe-connect.light-blue:hover, .woocommerce-MyAccount-content .my-account-popup .woocommerce-form .stripe-connect.light-blue:hover, .my-account-popup .woocommerce-form .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:hover, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .woocommerce-form .wcv-button:hover, .my-account-popup .woocommerce-form .wcv-grid .chamfr-vendor-dashboard-content .button:hover, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .woocommerce-form .button:hover, .my-account-popup .woocommerce-form .chamfr-service-partners-featured .summary .website:hover, .chamfr-service-partners-featured .summary .my-account-popup .woocommerce-form .website:hover, .my-account-popup .woocommerce-form .blog .sticky .post__link a:hover, .blog .sticky .post__link .my-account-popup .woocommerce-form a:hover,
.my-account-popup .woocommerce-form .archive .sticky .post__link a:hover,
.archive .sticky .post__link .my-account-popup .woocommerce-form a:hover, .my-account-popup .woocommerce-form .wp-block-button__link:hover, .my-account-popup .woocommerce-form body .gform_wrapper .gform_footer input.button:hover, body .gform_wrapper .gform_footer .my-account-popup .woocommerce-form input.button:hover,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_footer input[type=submit]:hover,
body .gform_wrapper .gform_footer .my-account-popup .woocommerce-form input[type=submit]:hover,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_page_footer input.button:hover,
body .gform_wrapper .gform_page_footer .my-account-popup .woocommerce-form input.button:hover,
.my-account-popup .woocommerce-form body .gform_wrapper .gform_page_footer input[type=submit]:hover,
body .gform_wrapper .gform_page_footer .my-account-popup .woocommerce-form input[type=submit]:hover, .my-account-popup .woocommerce-form .woocommerce-order-details-table + p a:hover, .woocommerce-order-details-table + p .my-account-popup .woocommerce-form a:hover, .my-account-popup .woocommerce-form .woocommerce #respond input#submit:hover, .woocommerce #respond .my-account-popup .woocommerce-form input#submit:hover,
.my-account-popup .woocommerce-form .woocommerce a.button:hover,
.woocommerce .my-account-popup .woocommerce-form a.button:hover,
.my-account-popup .woocommerce-form .woocommerce button.button:hover,
.woocommerce .my-account-popup .woocommerce-form button.button:hover,
.my-account-popup .woocommerce-form .woocommerce input.button:hover,
.woocommerce .my-account-popup .woocommerce-form input.button:hover {
  background: #FFFFFF;
  color: #e11d73;
  border-color: #e11d73;
}
.my-account-popup .woocommerce-form .woocommerce-lost_password a {
  font-size: 14px;
  line-height: 16.45px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.my-account-popup .woocommerce-form .woocommerce-lost_password a:hover {
  text-decoration: none;
}
.my-account-popup .beta-message-after-login {
  margin: 0;
  background-color: #f6f6f6;
  border-radius: 0 0 8px 8px;
  padding: 42px;
  text-align: center;
}
.my-account-popup .beta-message-after-login h3 {
  color: #111111;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 24px;
}
.my-account-popup .beta-message-after-login .btn, .my-account-popup .beta-message-after-login .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .my-account-popup .beta-message-after-login .stripe-connect.light-blue, .my-account-popup .beta-message-after-login .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .beta-message-after-login .wcv-button, .my-account-popup .beta-message-after-login .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .beta-message-after-login .button, .my-account-popup .beta-message-after-login .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .my-account-popup .beta-message-after-login .website, .my-account-popup .beta-message-after-login .blog .sticky .post__link a, .blog .sticky .post__link .my-account-popup .beta-message-after-login a,
.my-account-popup .beta-message-after-login .archive .sticky .post__link a,
.archive .sticky .post__link .my-account-popup .beta-message-after-login a, .my-account-popup .beta-message-after-login .wp-block-button__link, .my-account-popup .beta-message-after-login body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .my-account-popup .beta-message-after-login input.button,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .my-account-popup .beta-message-after-login input[type=submit],
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .my-account-popup .beta-message-after-login input.button,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .my-account-popup .beta-message-after-login input[type=submit], .my-account-popup .beta-message-after-login .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .my-account-popup .beta-message-after-login a, .my-account-popup .beta-message-after-login .woocommerce #respond input#submit, .woocommerce #respond .my-account-popup .beta-message-after-login input#submit,
.my-account-popup .beta-message-after-login .woocommerce a.button,
.woocommerce .my-account-popup .beta-message-after-login a.button,
.my-account-popup .beta-message-after-login .woocommerce button.button,
.woocommerce .my-account-popup .beta-message-after-login button.button,
.my-account-popup .beta-message-after-login .woocommerce input.button,
.woocommerce .my-account-popup .beta-message-after-login input.button {
  max-width: 258px;
  width: 100%;
  border-radius: 8px !important;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  box-shadow: none;
  letter-spacing: 0;
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #e11d73;
  border-color: #e11d73;
  background-color: transparent;
}
.my-account-popup .beta-message-after-login .btn:hover, .my-account-popup .beta-message-after-login .woocommerce-MyAccount-content .stripe-connect.light-blue:hover, .woocommerce-MyAccount-content .my-account-popup .beta-message-after-login .stripe-connect.light-blue:hover, .my-account-popup .beta-message-after-login .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:hover, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .beta-message-after-login .wcv-button:hover, .my-account-popup .beta-message-after-login .wcv-grid .chamfr-vendor-dashboard-content .button:hover, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .beta-message-after-login .button:hover, .my-account-popup .beta-message-after-login .chamfr-service-partners-featured .summary .website:hover, .chamfr-service-partners-featured .summary .my-account-popup .beta-message-after-login .website:hover, .my-account-popup .beta-message-after-login .blog .sticky .post__link a:hover, .blog .sticky .post__link .my-account-popup .beta-message-after-login a:hover,
.my-account-popup .beta-message-after-login .archive .sticky .post__link a:hover,
.archive .sticky .post__link .my-account-popup .beta-message-after-login a:hover, .my-account-popup .beta-message-after-login .wp-block-button__link:hover, .my-account-popup .beta-message-after-login body .gform_wrapper .gform_footer input.button:hover, body .gform_wrapper .gform_footer .my-account-popup .beta-message-after-login input.button:hover,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_footer input[type=submit]:hover,
body .gform_wrapper .gform_footer .my-account-popup .beta-message-after-login input[type=submit]:hover,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_page_footer input.button:hover,
body .gform_wrapper .gform_page_footer .my-account-popup .beta-message-after-login input.button:hover,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_page_footer input[type=submit]:hover,
body .gform_wrapper .gform_page_footer .my-account-popup .beta-message-after-login input[type=submit]:hover, .my-account-popup .beta-message-after-login .woocommerce-order-details-table + p a:hover, .woocommerce-order-details-table + p .my-account-popup .beta-message-after-login a:hover, .my-account-popup .beta-message-after-login .woocommerce #respond input#submit:hover, .woocommerce #respond .my-account-popup .beta-message-after-login input#submit:hover,
.my-account-popup .beta-message-after-login .woocommerce a.button:hover,
.woocommerce .my-account-popup .beta-message-after-login a.button:hover,
.my-account-popup .beta-message-after-login .woocommerce button.button:hover,
.woocommerce .my-account-popup .beta-message-after-login button.button:hover,
.my-account-popup .beta-message-after-login .woocommerce input.button:hover,
.woocommerce .my-account-popup .beta-message-after-login input.button:hover, .my-account-popup .beta-message-after-login .btn:focus, .my-account-popup .beta-message-after-login .woocommerce-MyAccount-content .stripe-connect.light-blue:focus, .woocommerce-MyAccount-content .my-account-popup .beta-message-after-login .stripe-connect.light-blue:focus, .my-account-popup .beta-message-after-login .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:focus, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .beta-message-after-login .wcv-button:focus, .my-account-popup .beta-message-after-login .wcv-grid .chamfr-vendor-dashboard-content .button:focus, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .beta-message-after-login .button:focus, .my-account-popup .beta-message-after-login .chamfr-service-partners-featured .summary .website:focus, .chamfr-service-partners-featured .summary .my-account-popup .beta-message-after-login .website:focus, .my-account-popup .beta-message-after-login .blog .sticky .post__link a:focus, .blog .sticky .post__link .my-account-popup .beta-message-after-login a:focus,
.my-account-popup .beta-message-after-login .archive .sticky .post__link a:focus,
.archive .sticky .post__link .my-account-popup .beta-message-after-login a:focus, .my-account-popup .beta-message-after-login .wp-block-button__link:focus, .my-account-popup .beta-message-after-login body .gform_wrapper .gform_footer input.button:focus, body .gform_wrapper .gform_footer .my-account-popup .beta-message-after-login input.button:focus,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_footer input[type=submit]:focus,
body .gform_wrapper .gform_footer .my-account-popup .beta-message-after-login input[type=submit]:focus,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_page_footer input.button:focus,
body .gform_wrapper .gform_page_footer .my-account-popup .beta-message-after-login input.button:focus,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_page_footer input[type=submit]:focus,
body .gform_wrapper .gform_page_footer .my-account-popup .beta-message-after-login input[type=submit]:focus, .my-account-popup .beta-message-after-login .woocommerce-order-details-table + p a:focus, .woocommerce-order-details-table + p .my-account-popup .beta-message-after-login a:focus, .my-account-popup .beta-message-after-login .woocommerce #respond input#submit:focus, .woocommerce #respond .my-account-popup .beta-message-after-login input#submit:focus,
.my-account-popup .beta-message-after-login .woocommerce a.button:focus,
.woocommerce .my-account-popup .beta-message-after-login a.button:focus,
.my-account-popup .beta-message-after-login .woocommerce button.button:focus,
.woocommerce .my-account-popup .beta-message-after-login button.button:focus,
.my-account-popup .beta-message-after-login .woocommerce input.button:focus,
.woocommerce .my-account-popup .beta-message-after-login input.button:focus {
  text-decoration: none;
}
.my-account-popup .beta-message-after-login .btn:hover, .my-account-popup .beta-message-after-login .woocommerce-MyAccount-content .stripe-connect.light-blue:hover, .woocommerce-MyAccount-content .my-account-popup .beta-message-after-login .stripe-connect.light-blue:hover, .my-account-popup .beta-message-after-login .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:hover, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .beta-message-after-login .wcv-button:hover, .my-account-popup .beta-message-after-login .wcv-grid .chamfr-vendor-dashboard-content .button:hover, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .beta-message-after-login .button:hover, .my-account-popup .beta-message-after-login .chamfr-service-partners-featured .summary .website:hover, .chamfr-service-partners-featured .summary .my-account-popup .beta-message-after-login .website:hover, .my-account-popup .beta-message-after-login .blog .sticky .post__link a:hover, .blog .sticky .post__link .my-account-popup .beta-message-after-login a:hover,
.my-account-popup .beta-message-after-login .archive .sticky .post__link a:hover,
.archive .sticky .post__link .my-account-popup .beta-message-after-login a:hover, .my-account-popup .beta-message-after-login .wp-block-button__link:hover, .my-account-popup .beta-message-after-login body .gform_wrapper .gform_footer input.button:hover, body .gform_wrapper .gform_footer .my-account-popup .beta-message-after-login input.button:hover,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_footer input[type=submit]:hover,
body .gform_wrapper .gform_footer .my-account-popup .beta-message-after-login input[type=submit]:hover,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_page_footer input.button:hover,
body .gform_wrapper .gform_page_footer .my-account-popup .beta-message-after-login input.button:hover,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_page_footer input[type=submit]:hover,
body .gform_wrapper .gform_page_footer .my-account-popup .beta-message-after-login input[type=submit]:hover, .my-account-popup .beta-message-after-login .woocommerce-order-details-table + p a:hover, .woocommerce-order-details-table + p .my-account-popup .beta-message-after-login a:hover, .my-account-popup .beta-message-after-login .woocommerce #respond input#submit:hover, .woocommerce #respond .my-account-popup .beta-message-after-login input#submit:hover,
.my-account-popup .beta-message-after-login .woocommerce a.button:hover,
.woocommerce .my-account-popup .beta-message-after-login a.button:hover,
.my-account-popup .beta-message-after-login .woocommerce button.button:hover,
.woocommerce .my-account-popup .beta-message-after-login button.button:hover,
.my-account-popup .beta-message-after-login .woocommerce input.button:hover,
.woocommerce .my-account-popup .beta-message-after-login input.button:hover, .my-account-popup .beta-message-after-login .btn:focus, .my-account-popup .beta-message-after-login .woocommerce-MyAccount-content .stripe-connect.light-blue:focus, .woocommerce-MyAccount-content .my-account-popup .beta-message-after-login .stripe-connect.light-blue:focus, .my-account-popup .beta-message-after-login .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:focus, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .beta-message-after-login .wcv-button:focus, .my-account-popup .beta-message-after-login .wcv-grid .chamfr-vendor-dashboard-content .button:focus, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .beta-message-after-login .button:focus, .my-account-popup .beta-message-after-login .chamfr-service-partners-featured .summary .website:focus, .chamfr-service-partners-featured .summary .my-account-popup .beta-message-after-login .website:focus, .my-account-popup .beta-message-after-login .blog .sticky .post__link a:focus, .blog .sticky .post__link .my-account-popup .beta-message-after-login a:focus,
.my-account-popup .beta-message-after-login .archive .sticky .post__link a:focus,
.archive .sticky .post__link .my-account-popup .beta-message-after-login a:focus, .my-account-popup .beta-message-after-login .wp-block-button__link:focus, .my-account-popup .beta-message-after-login body .gform_wrapper .gform_footer input.button:focus, body .gform_wrapper .gform_footer .my-account-popup .beta-message-after-login input.button:focus,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_footer input[type=submit]:focus,
body .gform_wrapper .gform_footer .my-account-popup .beta-message-after-login input[type=submit]:focus,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_page_footer input.button:focus,
body .gform_wrapper .gform_page_footer .my-account-popup .beta-message-after-login input.button:focus,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_page_footer input[type=submit]:focus,
body .gform_wrapper .gform_page_footer .my-account-popup .beta-message-after-login input[type=submit]:focus, .my-account-popup .beta-message-after-login .woocommerce-order-details-table + p a:focus, .woocommerce-order-details-table + p .my-account-popup .beta-message-after-login a:focus, .my-account-popup .beta-message-after-login .woocommerce #respond input#submit:focus, .woocommerce #respond .my-account-popup .beta-message-after-login input#submit:focus,
.my-account-popup .beta-message-after-login .woocommerce a.button:focus,
.woocommerce .my-account-popup .beta-message-after-login a.button:focus,
.my-account-popup .beta-message-after-login .woocommerce button.button:focus,
.woocommerce .my-account-popup .beta-message-after-login button.button:focus,
.my-account-popup .beta-message-after-login .woocommerce input.button:focus,
.woocommerce .my-account-popup .beta-message-after-login input.button:focus {
  color: #9f325d;
  border-color: #9f325d;
  background-color: transparent;
}
.my-account-popup .beta-message-after-login .btn:disabled, .my-account-popup .beta-message-after-login .woocommerce-MyAccount-content .stripe-connect.light-blue:disabled, .woocommerce-MyAccount-content .my-account-popup .beta-message-after-login .stripe-connect.light-blue:disabled, .my-account-popup .beta-message-after-login .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:disabled, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .beta-message-after-login .wcv-button:disabled, .my-account-popup .beta-message-after-login .wcv-grid .chamfr-vendor-dashboard-content .button:disabled, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .beta-message-after-login .button:disabled, .my-account-popup .beta-message-after-login .chamfr-service-partners-featured .summary .website:disabled, .chamfr-service-partners-featured .summary .my-account-popup .beta-message-after-login .website:disabled, .my-account-popup .beta-message-after-login .blog .sticky .post__link a:disabled, .blog .sticky .post__link .my-account-popup .beta-message-after-login a:disabled,
.my-account-popup .beta-message-after-login .archive .sticky .post__link a:disabled,
.archive .sticky .post__link .my-account-popup .beta-message-after-login a:disabled, .my-account-popup .beta-message-after-login .wp-block-button__link:disabled, .my-account-popup .beta-message-after-login body .gform_wrapper .gform_footer input.button:disabled, body .gform_wrapper .gform_footer .my-account-popup .beta-message-after-login input.button:disabled,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_footer input[type=submit]:disabled,
body .gform_wrapper .gform_footer .my-account-popup .beta-message-after-login input[type=submit]:disabled,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_page_footer input.button:disabled,
body .gform_wrapper .gform_page_footer .my-account-popup .beta-message-after-login input.button:disabled,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_page_footer input[type=submit]:disabled,
body .gform_wrapper .gform_page_footer .my-account-popup .beta-message-after-login input[type=submit]:disabled, .my-account-popup .beta-message-after-login .woocommerce-order-details-table + p a:disabled, .woocommerce-order-details-table + p .my-account-popup .beta-message-after-login a:disabled, .my-account-popup .beta-message-after-login .woocommerce #respond input#submit:disabled, .woocommerce #respond .my-account-popup .beta-message-after-login input#submit:disabled,
.my-account-popup .beta-message-after-login .woocommerce a.button:disabled,
.woocommerce .my-account-popup .beta-message-after-login a.button:disabled,
.my-account-popup .beta-message-after-login .woocommerce button.button:disabled,
.woocommerce .my-account-popup .beta-message-after-login button.button:disabled,
.my-account-popup .beta-message-after-login .woocommerce input.button:disabled,
.woocommerce .my-account-popup .beta-message-after-login input.button:disabled {
  color: #b4b4b4;
  border-color: #b4b4b4;
  background-color: transparent;
}
.my-account-popup .beta-message-after-login .btn:hover, .my-account-popup .beta-message-after-login .woocommerce-MyAccount-content .stripe-connect.light-blue:hover, .woocommerce-MyAccount-content .my-account-popup .beta-message-after-login .stripe-connect.light-blue:hover, .my-account-popup .beta-message-after-login .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:hover, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .beta-message-after-login .wcv-button:hover, .my-account-popup .beta-message-after-login .wcv-grid .chamfr-vendor-dashboard-content .button:hover, .wcv-grid .chamfr-vendor-dashboard-content .my-account-popup .beta-message-after-login .button:hover, .my-account-popup .beta-message-after-login .chamfr-service-partners-featured .summary .website:hover, .chamfr-service-partners-featured .summary .my-account-popup .beta-message-after-login .website:hover, .my-account-popup .beta-message-after-login .blog .sticky .post__link a:hover, .blog .sticky .post__link .my-account-popup .beta-message-after-login a:hover,
.my-account-popup .beta-message-after-login .archive .sticky .post__link a:hover,
.archive .sticky .post__link .my-account-popup .beta-message-after-login a:hover, .my-account-popup .beta-message-after-login .wp-block-button__link:hover, .my-account-popup .beta-message-after-login body .gform_wrapper .gform_footer input.button:hover, body .gform_wrapper .gform_footer .my-account-popup .beta-message-after-login input.button:hover,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_footer input[type=submit]:hover,
body .gform_wrapper .gform_footer .my-account-popup .beta-message-after-login input[type=submit]:hover,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_page_footer input.button:hover,
body .gform_wrapper .gform_page_footer .my-account-popup .beta-message-after-login input.button:hover,
.my-account-popup .beta-message-after-login body .gform_wrapper .gform_page_footer input[type=submit]:hover,
body .gform_wrapper .gform_page_footer .my-account-popup .beta-message-after-login input[type=submit]:hover, .my-account-popup .beta-message-after-login .woocommerce-order-details-table + p a:hover, .woocommerce-order-details-table + p .my-account-popup .beta-message-after-login a:hover, .my-account-popup .beta-message-after-login .woocommerce #respond input#submit:hover, .woocommerce #respond .my-account-popup .beta-message-after-login input#submit:hover,
.my-account-popup .beta-message-after-login .woocommerce a.button:hover,
.woocommerce .my-account-popup .beta-message-after-login a.button:hover,
.my-account-popup .beta-message-after-login .woocommerce button.button:hover,
.woocommerce .my-account-popup .beta-message-after-login button.button:hover,
.my-account-popup .beta-message-after-login .woocommerce input.button:hover,
.woocommerce .my-account-popup .beta-message-after-login input.button:hover {
  background-color: #e11d73;
  color: #FFFFFF;
  border-color: #e11d73;
}

.error-404 {
  min-height: 50vh;
  padding: 5rem 0;
  text-align: center;
}
@media (min-width: 992px) {
  .error-404 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
}
.error-404__logo {
  width: 100%;
  max-width: 100%;
  margin: 0 0 4rem;
}
@media (min-width: 992px) {
  .error-404__logo {
    flex: 0 0 49rem;
    width: 49rem;
  }
}
.error-404__txt {
  flex: 1 1 auto;
}
.error-404__heading {
  margin: 0 0 4rem;
  font-size: 4.5rem;
}
.error-404__copy {
  margin: 0 0 4rem;
  font-size: 3.2rem;
}
.error-404__back-link {
  margin: 0;
}

.page.page-about .site {
  padding: 5rem 0 0;
}
@media (min-width: 992px) {
  .page.page-about .site {
    padding: 14rem 0 0;
  }
}

.woocommerce-orders .woocommerce-pagination {
  text-align: center;
}

.chamfr-shop-all-components {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 2rem;
  padding-bottom: 10rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
@media (min-width: 360px) {
  .chamfr-shop-all-components {
    max-width: 360px;
  }
}
@media (min-width: 576px) {
  .chamfr-shop-all-components {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .chamfr-shop-all-components {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .chamfr-shop-all-components {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .chamfr-shop-all-components {
    max-width: 1140px;
  }
}
@media (min-width: 1425px) {
  .chamfr-shop-all-components {
    max-width: 1360px;
  }
}
@media (min-width: 1760px) {
  .chamfr-shop-all-components {
    max-width: 1680px;
  }
}
.chamfr-shop-all-components .search {
  flex: 0 0 100%;
  margin-bottom: 9rem;
}
@media (max-width: 767px) {
  .chamfr-shop-all-components .search {
    display: none;
  }
}
.chamfr-shop-all-components .search form {
  max-width: 1016px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.chamfr-shop-all-components .search form:before {
  position: absolute;
  content: "";
  left: -1.3em;
  bottom: -1.7em;
  height: 120%;
  width: 80%;
  background: url(../images/dots.svg) repeat bottom left;
  opacity: 0.7;
  z-index: -1;
}
.chamfr-shop-all-components .search input[type=text] {
  font-size: 2.6rem;
  padding: 0.8em 3.4em 0.8em 0.8em;
  line-height: 1;
  height: auto;
}
.chamfr-shop-all-components .search input[type=text]::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.chamfr-shop-all-components .search .search-submit {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 7rem;
  /* stylelint-disable-next-line function-url-quotes */
  background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
  background-position: 2.2rem 2.2rem;
  background-size: 3rem;
  background-repeat: no-repeat;
  background-color: #e11d73;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.chamfr-shop-all-components .search form:before {
  height: 90px;
  width: 585px;
}
.chamfr-shop-all-components .chamfr-filter {
  flex: 0 0 18%;
  margin-bottom: 2rem;
}
.chamfr-shop-all-categories {
  flex: 0 0 82%;
  display: flex;
  margin: -1rem;
  margin-left: 1rem;
  flex-wrap: wrap;
}
.chamfr-shop-all-categories .category {
  flex: 0 0 33.333%;
  padding: 1rem;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}
.chamfr-shop-all-categories .category .heading {
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 2.5rem;
  display: flex;
  align-items: flex-start;
  flex-grow: 0;
}
.chamfr-shop-all-categories .category .heading .image {
  flex: 0 0 6rem;
  margin-right: 1rem;
}
.chamfr-shop-all-categories .category .heading .image img {
  width: 6rem;
  height: 6rem;
  object-fit: contain;
}
.chamfr-shop-all-categories .category .heading .summary h5 {
  font-weight: 400;
  margin: 0 1rem 0 0;
  line-height: 1;
}
.chamfr-shop-all-categories .category .heading .summary .products {
  font-size: 1.4rem;
  opacity: 0.5;
}
.chamfr-shop-all-categories .category .heading .summary .description {
  margin-top: 1rem;
  flex: 0 0 100%;
}
.chamfr-shop-all-categories .category .links {
  padding: 2.2rem 2.5rem 5rem;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #eaeaea;
  flex-grow: 1;
}
.chamfr-shop-all-categories .category .links .columns {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 -1rem;
  padding-bottom: 4rem;
}
.chamfr-shop-all-categories .category .links .columns a {
  color: #313131;
}
.chamfr-shop-all-categories .category .links .columns .column {
  font-size: 1.6rem;
  line-height: 1.375;
  min-width: 35%;
  margin: 0 1rem;
}
.chamfr-shop-all-categories .category .links .columns .column > div {
  margin-bottom: 1rem;
}
.chamfr-shop-all-categories .category .links .columns .column > div:last-child {
  margin-bottom: 0;
}
.chamfr-shop-all-categories .category .links .all {
  display: inline-block;
  position: absolute;
  bottom: 2.5rem;
  left: 2.5rem;
  height: 2.8rem;
  border-bottom: 1px solid #e11d73;
}
.chamfr-shop-all-categories .category .links .all:hover {
  text-decoration: none;
}

@media (max-width: 1759px) {
  .chamfr-shop-all-components .search form {
    max-width: 732px;
  }
  .chamfr-shop-all-components .chamfr-filter {
    flex: 0 0 21%;
  }
  .chamfr-shop-all-categories {
    flex: 0 0 79%;
  }
}
@media (max-width: 1439px) {
  .chamfr-shop-all-components .chamfr-filter {
    flex: 0 0 25%;
  }
  .chamfr-shop-all-categories {
    flex: 0 0 75%;
  }
  .chamfr-shop-all-categories .category {
    flex: 0 0 50%;
  }
}
@media (max-width: 1424.98px) {
  .chamfr-shop-all-components .chamfr-filter {
    flex: 0 0 30%;
  }
  .chamfr-shop-all-categories {
    flex: 0 0 70%;
  }
}
@media (max-width: 991.98px) {
  .chamfr-shop-all-components {
    display: block;
  }
  .chamfr-shop-all-categories {
    margin-left: -1rem;
  }
  .chamfr-shop-all-categories .category {
    flex: 0 0 100%;
  }
}
@media (max-width: 359.98px) {
  .chamfr-shop-all-components .search {
    margin-bottom: 3rem;
  }
  .chamfr-shop-all-components .search form:before {
    position: absolute;
    content: "";
    left: -0.5em;
    bottom: -0.7em;
    height: 85%;
    background: url(../images/dots.svg) repeat top left;
    background-size: 25%;
  }
  .chamfr-shop-all-components .search input[type=text] {
    font-size: 1.4rem;
    padding: 1em 1em 1em 3em;
    background-position: 1.1em 1.2em;
  }
  .chamfr-shop-all-categories .category .links .columns {
    display: block;
  }
  .chamfr-shop-all-categories .category .links .columns .column {
    margin-bottom: 1rem;
  }
}
/* stylelint-disable no-descending-specificity */
.blog .site,
.archive .site {
  padding: 3rem 0 0;
}
@media (min-width: 992px) {
  .blog .site,
.archive .site {
    padding: 4rem 0;
  }
}
@media (max-width: 359.98px) {
  .blog .site,
.archive .site {
    padding: 0;
  }
}
.blog .post,
.archive .post {
  margin: 4rem 0 0;
  padding: 0 0 4rem;
  border-bottom: 1px solid #959595;
}
@media (min-width: 992px) {
  .blog .post,
.archive .post {
    display: flex;
    align-items: stretch;
  }
}
.blog .post__img,
.archive .post__img {
  margin: 0 0 2.36rem;
}
@media (min-width: 992px) {
  .blog .post__img,
.archive .post__img {
    width: 46%;
    flex: 0 0 46%;
    margin: 0;
  }
}
.blog .post__img__wrapper,
.archive .post__img__wrapper {
  overflow: hidden;
  border-radius: 0.5rem;
}
.blog .post__img img,
.archive .post__img img {
  display: block;
  width: 100%;
}
@media (min-width: 992px) {
  .blog .post__img img,
.archive .post__img img {
    height: 100%;
    object-fit: cover;
  }
}
@media (min-width: 992px) {
  .blog .post__body,
.archive .post__body {
    padding-left: 4rem;
  }
}
.blog .post__meta,
.archive .post__meta {
  margin: 0 0 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: #959595;
}
@media (min-width: 576px) {
  .blog .post__meta,
.archive .post__meta {
    font-size: 1.4rem;
    margin: 0 0 0.75rem;
  }
}
.blog .post__categories, .blog .post__date,
.archive .post__categories,
.archive .post__date {
  display: inline-block;
}
.blog .post__categories,
.archive .post__categories {
  text-transform: uppercase;
}
.blog .post__categories a,
.archive .post__categories a {
  color: #959595;
}
.blog .post__categories a:hover, .blog .post__categories a:focus, .blog .post__categories a:active,
.archive .post__categories a:hover,
.archive .post__categories a:focus,
.archive .post__categories a:active {
  color: #959595;
}
.blog .post__title h2,
.archive .post__title h2 {
  font-family: "Public Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  margin: 0 0 1rem;
}
@media (min-width: 576px) {
  .blog .post__title h2,
.archive .post__title h2 {
    font-size: 2.2rem;
    font-weight: 500;
  }
}
.blog .post__title h2 a,
.archive .post__title h2 a {
  color: inherit;
}
.blog .post__excerpt,
.archive .post__excerpt {
  margin: 0 0 3.5rem;
  font-size: 1.4;
}
@media (min-width: 576px) {
  .blog .post__excerpt,
.archive .post__excerpt {
    font-size: 1.6rem;
  }
}
.blog .post__link__a,
.archive .post__link__a {
  display: inline-block;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid #e11d73;
  text-decoration: none;
}
.blog .post__link__a:hover, .blog .post__link__a:focus, .blog .post__link__a:active,
.archive .post__link__a:hover,
.archive .post__link__a:focus,
.archive .post__link__a:active {
  text-decoration: none;
}
.blog .subscribe,
.archive .subscribe {
  margin: 4rem -15px 0;
  padding: 5.5rem 4rem;
  background: #000;
  border-radius: 0;
}
@media (min-width: 576px) {
  .blog .subscribe,
.archive .subscribe {
    margin: 4rem 0 0;
    border-radius: 0.5rem;
  }
}
.blog .subscribe:before, .blog .subscribe:after,
.archive .subscribe:before,
.archive .subscribe:after {
  display: none;
}
.blog .subscribe + .post,
.archive .subscribe + .post {
  padding-top: 4rem;
  border-top: 1px solid #959595;
}
.blog .sticky,
.archive .sticky {
  margin-bottom: 4rem;
}
.blog .sticky .post,
.archive .sticky .post {
  border: none;
}
.blog .sticky .post__img,
.archive .sticky .post__img {
  position: relative;
  margin: 0 0 3rem 2rem;
}
@media (min-width: 576px) {
  .blog .sticky .post__img,
.archive .sticky .post__img {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .blog .sticky .post__img,
.archive .sticky .post__img {
    width: calc(100% - 415.8px);
    flex: 0 0 calc(100% - 415.8px);
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .blog .sticky .post__img,
.archive .sticky .post__img {
    width: calc(100% - 496.8px);
    flex: 0 0 calc(100% - 496.8px);
  }
}
@media (min-width: 1425px) {
  .blog .sticky .post__img,
.archive .sticky .post__img {
    width: calc(100% - 595.8px);
    flex: 0 0 calc(100% - 595.8px);
  }
}
.blog .sticky .post__img:before,
.archive .sticky .post__img:before {
  content: "";
  display: block;
  position: absolute;
  top: -1.8rem;
  left: -2rem;
  z-index: -1;
  width: 20rem;
  height: 16.5rem;
  background: url(../images/dots.svg) repeat top left;
  opacity: 0.7;
}
@media (min-width: 992px) {
  .blog .sticky .post__img:before,
.archive .sticky .post__img:before {
    width: 58.7rem;
    height: 30.8rem;
  }
}
@media (min-width: 992px) {
  .blog .sticky .post__body,
.archive .sticky .post__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 415.8px;
    flex: 0 0 415.8px;
  }
}
@media (min-width: 1200px) {
  .blog .sticky .post__body,
.archive .sticky .post__body {
    width: 496.8px;
    flex: 0 0 496.8px;
  }
}
@media (min-width: 1425px) {
  .blog .sticky .post__body,
.archive .sticky .post__body {
    width: 595.8px;
    flex: 0 0 595.8px;
  }
}
.blog .sticky .post__title h2,
.archive .sticky .post__title h2 {
  font-family: "Public Sans", sans-serif;
  font-size: 2.4rem;
  font-weight: 600;
  margin: 0 0 1rem;
}
@media (min-width: 576px) {
  .blog .sticky .post__title h2,
.archive .sticky .post__title h2 {
    font-size: 3.6rem;
    font-weight: 500;
  }
}
.blog .sticky .post__excerpt,
.archive .sticky .post__excerpt {
  font-size: 1.6rem;
}
@media (min-width: 576px) {
  .blog .sticky .post__excerpt,
.archive .sticky .post__excerpt {
    font-size: 1.8rem;
  }
}
.blog .sticky .post__link a:hover, .blog .sticky .post__link a:focus, .blog .sticky .post__link a:active,
.archive .sticky .post__link a:hover,
.archive .sticky .post__link a:focus,
.archive .sticky .post__link a:active {
  text-decoration: none;
}
.blog .categogy-list,
.archive .categogy-list {
  list-style: none;
  margin: 1rem 0 0;
  padding: 0;
  text-align: center;
}
@media (min-width: 992px) {
  .blog .categogy-list,
.archive .categogy-list {
    margin: 4rem 0 0;
    text-align: left;
  }
}
.blog .categogy-list li,
.archive .categogy-list li {
  display: inline-block;
  margin: 1em;
  padding: 0;
}
@media (min-width: 992px) {
  .blog .categogy-list li,
.archive .categogy-list li {
    display: block;
    margin: 0 0 2.5rem;
  }
}
.blog .categogy-list li a,
.archive .categogy-list li a {
  font-size: 1.8rem;
  text-decoration: none;
  color: #959595;
  transition: all 0.3s ease-out;
}
.blog .categogy-list li a:hover, .blog .categogy-list li a:focus, .blog .categogy-list li a:active,
.archive .categogy-list li a:hover,
.archive .categogy-list li a:focus,
.archive .categogy-list li a:active {
  color: #fff;
}
.blog .categogy-list li.current-cat a,
.archive .categogy-list li.current-cat a {
  color: #fff;
}
.blog .more-posts,
.archive .more-posts {
  margin: 4rem 0 0;
  text-align: center;
}

.blog .categogy-list li.cat-item-all a {
  color: #fff;
}

.chamfr-cart {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (max-width: 1199.98px) {
  .chamfr-cart {
    display: block;
  }
}
.chamfr-cart-header {
  flex: 0 0 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 575.98px) {
  .chamfr-cart-header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
}
.chamfr-cart-content {
  flex: 0 0 65%;
}
.chamfr-cart-collaterals {
  flex: 0 0 30%;
}
.chamfr-cart-collaterals-header {
  line-height: 14px;
  margin-top: 31px;
  font-size: 14px;
  margin-bottom: 2rem;
  padding-right: 2rem;
  text-align: right;
}
.chamfr-cart-collaterals-header a {
  text-decoration: underline;
}
.chamfr-cart-collaterals-header a:hover {
  text-decoration: none;
}
.chamfr-cart-collaterals .cart-collaterals .cart_totals {
  float: none;
  width: 100%;
  background-color: #FFFFFF;
  padding: 24px;
  border-radius: 16px;
}
.chamfr-cart-collaterals .cart-collaterals .cart_totals h2 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 20px;
  font-weight: 700;
}
@media (min-width: 576px) {
  .chamfr-cart-collaterals .cart-collaterals .cart_totals h2 {
    font-size: 24px;
  }
}
.chamfr-cart-collaterals .cart-collaterals .cart_totals table {
  border: none;
}
.chamfr-cart-collaterals .cart-collaterals .cart_totals table th,
.chamfr-cart-collaterals .cart-collaterals .cart_totals table td {
  width: auto;
}
.chamfr-cart-collaterals .cart-collaterals .cart_totals table th {
  font-weight: 400;
}
.chamfr-cart-collaterals .cart-collaterals .cart_totals table td {
  text-align: right;
  font-weight: 400;
}
.chamfr-cart-collaterals .cart-collaterals .cart_totals table tr.order-total th,
.chamfr-cart-collaterals .cart-collaterals .cart_totals table tr.order-total td {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 18px;
  font-weight: 700;
  font-weight: 800;
}
@media (min-width: 576px) {
  .chamfr-cart-collaterals .cart-collaterals .cart_totals table tr.order-total th,
.chamfr-cart-collaterals .cart-collaterals .cart_totals table tr.order-total td {
    font-size: 20px;
  }
}
.chamfr-cart-collaterals .cart-collaterals .cart_totals .wc-proceed-to-checkout .checkout-button {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  border-color: #e11d73;
  background-color: #e11d73;
  box-shadow: none;
  display: block;
  margin: 0 8px;
  width: auto;
}
.chamfr-cart-collaterals .cart-collaterals .cart_totals .wc-proceed-to-checkout .checkout-button:hover, .chamfr-cart-collaterals .cart-collaterals .cart_totals .wc-proceed-to-checkout .checkout-button:focus {
  text-decoration: none;
}
.chamfr-cart-collaterals .cart-collaterals .cart_totals .wc-proceed-to-checkout .checkout-button:hover, .chamfr-cart-collaterals .cart-collaterals .cart_totals .wc-proceed-to-checkout .checkout-button:focus {
  color: #FFFFFF;
  background-color: #9f325d;
  border-color: #9f325d;
}
.chamfr-cart-collaterals .cart-collaterals .cart_totals .wc-proceed-to-checkout .checkout-button:disabled {
  color: #FFFFFF;
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}
.chamfr-cart-actions {
  display: flex;
  flex-wrap: wrap;
}
.chamfr-cart-actions .left,
.chamfr-cart-actions .right {
  flex: 0 0 50%;
}
.chamfr-cart-actions .right {
  text-align: right;
}
.chamfr-cart-actions .clear-cart {
  text-decoration: underline;
  position: relative;
}
.chamfr-cart-actions .clear-cart:before {
  content: "";
  position: relative;
  transform: translateY(4px);
  display: inline-block;
  margin-right: 4px;
  width: 20px;
  height: 19px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.06885 3.06897L5.13781 17.5517H14.862L17.1378 3.06897' stroke='%23E11D73' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.82756 15.483L6.58618 6.17261' stroke='%23E11D73' stroke-linecap='round'/%3E%3Cpath d='M13.207 6.17244L11.9656 15.4828' stroke='%23E11D73' stroke-linecap='round'/%3E%3Cpath d='M9.68945 15.483V6.17261' stroke='%23E11D73' stroke-linecap='round'/%3E%3Cpath d='M1 2.86194H19' stroke='%23E11D73' stroke-linecap='round'/%3E%3Cpath d='M7 2.86207V1H13V2.86207' stroke='%23E11D73' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.chamfr-cart table.cart {
  border: none;
}
@media (max-width: 575.98px) {
  .chamfr-cart table.cart thead {
    display: block;
  }
  .chamfr-cart table.cart tbody > tr {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }
}
.chamfr-cart table.cart th {
  background-color: #d8d8d8;
}
@media (max-width: 575.98px) {
  .chamfr-cart table.cart th {
    width: 100%;
  }
}
.chamfr-cart table.cart th:first-child {
  border-top-left-radius: 8px;
}
.chamfr-cart table.cart th:last-child {
  border-top-right-radius: 8px;
}
.chamfr-cart table.cart td {
  background-color: #f6f6f6;
}
.chamfr-cart table.cart tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}
.chamfr-cart table.cart tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}
@media (max-width: 575.98px) {
  .chamfr-cart table.cart tr:last-child td:first-child {
    border-bottom-left-radius: 0;
  }
  .chamfr-cart table.cart tr:last-child td:last-child {
    border-radius: 0 0 8px 8px;
  }
}
.chamfr-cart table.cart img {
  width: 42px;
}
.chamfr-cart table.cart td.product-name {
  font-weight: 600;
  line-height: 1.5;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.chamfr-cart table.cart td.product-name dl.variation {
  font: 400 12px/1.3 "Public Sans", sans-serif;
}
@media (min-width: 576px) {
  .chamfr-cart table.cart td.product-name dl.variation {
    font-size: 14px;
  }
}
.chamfr-cart table.cart td.product-name dl.variation dt {
  font-weight: 300;
  line-height: 1.5;
}
.chamfr-cart table.cart td.product-name dl.variation dd {
  font-weight: 500;
  line-height: 1.5;
}
.chamfr-cart table.cart .product-price,
.chamfr-cart table.cart .product-subtotal {
  text-align: right;
  font-weight: 700;
}
@media (max-width: 575.98px) {
  .chamfr-cart table.cart .product-price,
.chamfr-cart table.cart .product-subtotal {
    text-align: left;
  }
}
.chamfr-cart table.cart a.remove {
  display: inline-block;
  width: 24px;
  height: 24px;
  font-size: 0;
  line-height: 0;
  transform: translateY(-10px);
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10.9999 10.9999' stroke='%23111111' stroke-linecap='round'/%3E%3Cpath d='M11 1L1.00008 10.9999' stroke='%23111111' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
}
.chamfr-cart table.cart a.remove:hover {
  background-color: rgba(170, 0, 0, 0.2);
}
.chamfr-cart-update-button {
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #e11d73;
  border-color: #e11d73;
  background-color: transparent;
}
.chamfr-cart-update-button:hover, .chamfr-cart-update-button:focus {
  text-decoration: none;
}
.chamfr-cart-update-button:hover, .chamfr-cart-update-button:focus {
  color: #9f325d;
  border-color: #9f325d;
  background-color: transparent;
}
.chamfr-cart-update-button:disabled {
  color: #b4b4b4;
  border-color: #b4b4b4;
  background-color: transparent;
}
.chamfr-cart .coupon {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
}
.chamfr-cart .coupon-link {
  padding: 2rem 2rem 0;
}
.chamfr-cart .coupon input {
  flex-basis: 45%;
  flex-grow: 1;
}
.chamfr-cart .coupon .coupon-error-notice {
  flex: 0 0 100%;
}
.chamfr-cart .coupon input.button {
  box-shadow: none;
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #e11d73;
  border-color: #e11d73;
  background-color: transparent;
}
.chamfr-cart .coupon input.button:hover, .chamfr-cart .coupon input.button:focus {
  text-decoration: none;
}
.chamfr-cart .coupon input.button:hover, .chamfr-cart .coupon input.button:focus {
  color: #9f325d;
  border-color: #9f325d;
  background-color: transparent;
}
.chamfr-cart .coupon input.button:disabled {
  color: #b4b4b4;
  border-color: #b4b4b4;
  background-color: transparent;
}

.woocommerce-checkout #order_fedex_delivery_speed_field,
.woocommerce-checkout #order_fedex_account_number_field,
.woocommerce-checkout #order_ups_account_number_field {
  display: none;
}
.woocommerce-checkout #order_fedex_delivery_speed_field.field-invalid,
.woocommerce-checkout #order_fedex_account_number_field.field-invalid,
.woocommerce-checkout #order_ups_account_number_field.field-invalid {
  color: #a00;
}
.woocommerce-checkout #order_fedex_delivery_speed_field.field-invalid input,
.woocommerce-checkout #order_fedex_account_number_field.field-invalid input,
.woocommerce-checkout #order_ups_account_number_field.field-invalid input {
  border-color: #a00;
}
.woocommerce-checkout .long-loading-message {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -100px);
  background: #000;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}
.woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput {
  position: absolute;
}

#billing_ship_to_the_same_address_field .optional {
  float: left;
  width: 40px;
  color: transparent;
}

.woocommerce-shipping-fields.same_address .shipping_address_fields {
  height: 0;
  overflow: hidden;
}

.shipment_account_details {
  margin-bottom: 3rem;
}
.shipment_account_details h3 {
  margin-top: 5rem;
}
.shipment_account_details h4 {
  font-size: 2rem;
  margin: 0 0 1rem;
}

input[type=radio]#payment_method_stripe + label {
  display: block;
  width: 100%;
}

.woocommerce .shop_table.woocommerce-checkout-review-order-table {
  border-collapse: collapse;
  /*
  .select_shipping_method {
  	position: relative;
  	background: $white;
  	border: 1px solid $light-gray2;
  	border-radius: 0.5rem;

  	&:before {
  		content: '';
  		display: block;
  		position: absolute;
  		top: 50%;
  		right: 10px;
  		z-index: 10;
  		width: 0;
  		height: 0;
  		margin-left: -6px;
  		margin-top: -3px;
  		border-color: $dark-pink transparent transparent;
  		border-style: solid;
  		border-width: 6px 6px 0;
  	}
  	&:after {
  		content: '';
  		display: block;
  		position: absolute;
  		top: 2px;
  		bottom: 2px;
  		right: 2px;
  		width: 30px;
  		background: $white;
  	}

  	select {
  		border-color: transparent;
  	}
  }
  */
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table thead {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table tbody {
  border: 2px solid #a7a7a7;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table tbody .shipping {
  border-bottom: 2px solid #a7a7a7;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table tbody .shipping th {
  color: #9f325d;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table tbody .shipping th p {
  color: #000;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table tbody td,
.woocommerce .shop_table.woocommerce-checkout-review-order-table tbody th {
  border: none;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table .cart_item_odd {
  background: #eaeaea;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table .cart_item_odd + .shipping {
  background: #eaeaea;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table .cart_item_even {
  background: #f3f3f3;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table .cart_item_even + .shipping {
  background: #f3f3f3;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table td.product-total {
  vertical-align: top;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table td.product-name dl.variation {
  font-weight: 700;
  color: #e11d73;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table td.product-name dl.variation dt.variation-SoldBy,
.woocommerce .shop_table.woocommerce-checkout-review-order-table td.product-name dl.variation dd.variation-SoldBy {
  display: none;
  padding: 2rem 0 0;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table td.product-name .product-quantity,
.woocommerce .shop_table.woocommerce-checkout-review-order-table td.product-name dl.variation dt.variation-SoldBy {
  color: #9f325d;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table td.product-name .lead-time {
  color: #56b8a1;
  font-size: 13px;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table .select_shipping_method select {
  opacity: 0;
}
.woocommerce .shop_table.woocommerce-checkout-review-order-table .select_shipping_method .select2-container {
  width: 100%;
}
.woocommerce #content .coupon label {
  text-align: left;
}
.woocommerce #content .coupon #coupon_code {
  width: 110px;
}
@media (max-width: 991.98px) {
  .woocommerce #content .coupon .button {
    padding: 1.558rem 1rem;
    font-size: 14px;
  }
}

/*
#ms_shipping_addresses_field {
	overflow: hidden;
	height: 0;
	margin: 0;
}
#shipping_first_name_field {
	position: relative;
}
#ms_addresses_popup {
	position: absolute;
	z-index: 100;
	overflow: hidden;
	width: 100%;
	height: 0;
	margin: 3px 0 0;

	&.on {
		height: auto;
	}
}
#ms_addresses_popup_inner {
	width: 100%;
	padding: 2rem;
	border-radius: 0.5rem;
	background: $dark-gray;
	font-size: 1.2rem;
	color: $white;

	b {
		display: block;
		font-weight: 500;
	}
	.ms_address_option {
		margin: 0 0 2rem;
		span {
			cursor: pointer;
		}
	}

	.ms_address_manage {
		padding-top: 2rem;
		border-top: 1px solid $border-gray;

		a {
			text-decoration: none;
			color: $white;
		}
	}
}
*/
#order_ups_account_number_field .optional,
#order_ups_delivery_speed_field .optional,
#order_fedex_account_number_field .optional,
#order_fedex_delivery_speed_field .optional {
  color: transparent;
}
#order_ups_account_number_field .optional:before,
#order_ups_delivery_speed_field .optional:before,
#order_fedex_account_number_field .optional:before,
#order_fedex_delivery_speed_field .optional:before {
  content: "*";
  color: #f00;
  font-weight: 700;
}

#order_shipment_account_type_field input[type=radio] + label:before {
  border-radius: 50%;
}
#order_shipment_account_type_field input[type=radio]:checked + label:before {
  background: #fff;
  content: "•";
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 6rem;
  line-height: 2.8rem;
  color: #e11d73;
}

.wc-saved-payment-methods {
  padding-left: 0;
  padding-inline-start: 0;
}

.chamfr-checkout {
  display: flex;
  flex-wrap: wrap;
}
.chamfr-checkout .woocommerce-notices-wrapper {
  flex: 0 0 100%;
}
.chamfr-checkout > .checkout-steps {
  flex: 0 0 70%;
}
@media (max-width: 991.98px) {
  .chamfr-checkout > .checkout-steps {
    flex: 0 0 100%;
  }
}
.chamfr-checkout-sidebar {
  flex: 0 0 30%;
  padding-left: 2rem;
  position: relative;
}
@media (max-width: 991.98px) {
  .chamfr-checkout-sidebar {
    display: none;
  }
}
.chamfr-checkout-sidebar .woocommerce-info {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  font-size: 14px;
  padding-left: 2rem;
}
.chamfr-checkout-sidebar .woocommerce-info::before {
  display: none;
}
.chamfr-checkout-order-summary {
  background-color: #fff;
  padding: 2rem;
  border-radius: 16px;
  margin-bottom: 2rem;
}
.chamfr-checkout-order-summary h4 {
  font-size: 22px;
  font-weight: 700;
  border-bottom: 1px solid #b4b4b4;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.chamfr-checkout-order-summary ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.chamfr-checkout-order-summary ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.chamfr-checkout-order-summary ul li > span:first-child {
  color: #636363;
}
.chamfr-checkout-order-summary ul li > span:last-child {
  font-weight: 700;
}
.chamfr-checkout-order-summary .shipping-note {
  font-size: 12px;
}
.chamfr-checkout-order-summary .total {
  border-top: 1px solid #b4b4b4;
  margin-top: 1rem;
  padding-top: 1rem;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.chamfr-checkout-items {
  background-color: #fff;
  padding: 2rem;
  border-radius: 16px;
  margin-bottom: 2rem;
}
.chamfr-checkout-items h4 {
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 0;
  margin-bottom: 0;
  position: relative;
}
.chamfr-checkout-items h4:after {
  content: "";
  width: 24px;
  height: 14px;
  display: block;
  position: absolute;
  right: 0;
  top: 3px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='14' viewBox='0 0 24 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 1.5L12 11.5L2 1.5' stroke='%23111111' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}
.chamfr-checkout-items h4 span {
  font-weight: 400;
}
.chamfr-checkout-items .edit-cart {
  text-align: right;
  font-size: 12px;
}
.chamfr-checkout-items .edit-cart a {
  color: #000;
  text-decoration: underline;
}
.chamfr-checkout-items .edit-cart a:hover {
  text-decoration: none;
}
.chamfr-checkout-items ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 16.45px;
  display: none;
}
.chamfr-checkout-items ul li {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #b4b4b4;
  display: flex;
  gap: 1rem;
}
.chamfr-checkout-items ul li .image {
  flex: 0 0 60px;
}
.chamfr-checkout-items ul li .image img {
  width: 45px;
  height: auto;
}
.chamfr-checkout-items ul li .description {
  flex-grow: 1;
}
.chamfr-checkout-items ul li .description .name {
  font-weight: 700;
}
.chamfr-checkout-items ul li .description .sold-by,
.chamfr-checkout-items ul li .description .item-number {
  margin-bottom: 3px;
  color: #636363;
}
.chamfr-checkout-items ul li .description .qty {
  color: #9f325d;
}
.chamfr-checkout-items ul li .subtotal {
  flex: 0 0 80px;
  font-weight: 700;
  text-align: right;
}
.chamfr-checkout-items.expand h4:after {
  transform: rotate(180deg);
}
.chamfr-checkout-items.expand ul {
  display: block;
}

.page.page-contact .site {
  padding: 6rem 0 0;
}
@media (min-width: 992px) {
  .page.page-contact .site {
    padding: 10rem 0 0;
  }
}

body.page-contact .gform_wrapper .gform_body .top_label .gfield_label_before_complex {
  margin: 0 0 0.8rem;
  font-size: 1.4rem;
}

.page-customer-registration .container {
  padding-bottom: 5rem;
}
.page-customer-registration .container .gform_wrapper form {
  margin-top: 5rem;
}
@media (min-width: 768px) {
  .page-customer-registration .gform_wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
  }
}

.sc_fs_faq.sc_card {
  box-shadow: none;
  border-radius: 0;
  background: inherit;
  padding: 0;
  margin: 2rem auto;
}
.sc_fs_faq.sc_card h2 {
  font-size: 1em;
}

.home .subscribe {
  margin: 0;
  background: transparent;
  color: inherit;
}
.home .subscribe:before, .home .subscribe:after {
  display: none;
}

.login-register {
  padding-top: 7rem;
}
.login-register > div {
  padding-bottom: 5rem;
}
.login-register .woocommerce-form-group {
  margin: 0 0 3rem;
}
.login-register .input-checkbox {
  display: inline-block;
  vertical-align: middle;
  position: static;
  width: 3rem;
  height: 3rem;
  margin-right: 1.5rem;
  appearance: none;
}
.login-register .input-checkbox:checked {
  background-image: url(../images/check.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.login-register label[for=apply_for_vendor] + br {
  display: none;
}
.login-register .woocommerce-form p {
  margin: 0 0 3rem;
}

.beta-message-before-login {
  margin: 0 0 5rem;
}

.beta-message-after-login {
  margin: 5rem 0;
}
.beta-message-after-login p {
  margin: 3rem 0 0;
}

/* stylelint-disable no-descending-specificity */
.woocommerce-hi {
  margin: 0 0 5rem;
  font-size: 1.8rem;
}
@media (max-width: 359.98px) {
  .woocommerce-hi {
    display: none;
  }
}
.woocommerce-hi__name {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 18px;
  font-weight: 700;
  font-weight: 600;
  display: inline-block;
  margin: 0 2rem 0 0;
}
@media (min-width: 576px) {
  .woocommerce-hi__name {
    font-size: 20px;
  }
}

.woocommerce-account .woocommerce-MyAccount-navigation {
  float: none;
  width: auto;
}

.woocommerce-account .woocommerce-MyAccount-content,
.chamfr-vendor-dashboard-content {
  float: none;
  width: auto;
  margin: 0 -15px;
  padding: 4rem 2rem;
  background: radial-gradient(ellipse at center, #fff 0%, #eaeaea 100%);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  color: #000;
}
@media (min-width: 768px) {
  .woocommerce-account .woocommerce-MyAccount-content,
.chamfr-vendor-dashboard-content {
    margin: 0;
    padding: 5rem 6rem;
    border-radius: 1rem;
  }
}
@media (min-width: 992px) {
  .woocommerce-account .woocommerce-MyAccount-content,
.chamfr-vendor-dashboard-content {
    padding: 8rem 14rem 9rem;
  }
}
.woocommerce-account .woocommerce-MyAccount-content h1,
.chamfr-vendor-dashboard-content h1 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 4rem;
  text-align: center;
}
@media (min-width: 576px) {
  .woocommerce-account .woocommerce-MyAccount-content h1,
.chamfr-vendor-dashboard-content h1 {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .woocommerce-account .woocommerce-MyAccount-content h1,
.chamfr-vendor-dashboard-content h1 {
    text-align: left;
  }
}
.woocommerce-account .woocommerce-MyAccount-content .gform_wrapper .gsection,
.chamfr-vendor-dashboard-content .gform_wrapper .gsection {
  padding: 4rem 0 1.5rem !important;
  border: none;
}
@media (min-width: 768px) {
  .woocommerce-account .woocommerce-MyAccount-content .gform_wrapper .gsection,
.chamfr-vendor-dashboard-content .gform_wrapper .gsection {
    padding: 8rem 0 1.5rem !important;
  }
}
.woocommerce-account .woocommerce-MyAccount-content .gform_wrapper .gsection h2,
.chamfr-vendor-dashboard-content .gform_wrapper .gsection h2 {
  font-family: "Public Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
}
@media (min-width: 576px) {
  .woocommerce-account .woocommerce-MyAccount-content .gform_wrapper .gsection h2,
.chamfr-vendor-dashboard-content .gform_wrapper .gsection h2 {
    font-size: 1.8rem;
  }
}
@media (min-width: 576px) {
  .woocommerce-account .woocommerce-MyAccount-content .gform_wrapper .gfield_radio li,
.chamfr-vendor-dashboard-content .gform_wrapper .gfield_radio li {
    display: inline-block !important;
    width: 8rem !important;
    margin: 0 6rem 0 0 !important;
  }
  .woocommerce-account .woocommerce-MyAccount-content .gform_wrapper .gfield_radio li:last-of-type,
.chamfr-vendor-dashboard-content .gform_wrapper .gfield_radio li:last-of-type {
    margin: 0 !important;
  }
}
.woocommerce-account .woocommerce-MyAccount-content .gform_wrapper .gform_body .gform_fields li:first-of-type,
.chamfr-vendor-dashboard-content .gform_wrapper .gform_body .gform_fields li:first-of-type {
  padding-top: 0 !important;
}
.woocommerce-account .woocommerce-MyAccount-content .gfield_label,
.woocommerce-account .woocommerce-MyAccount-content .ginput_complex.ginput_container label,
.chamfr-vendor-dashboard-content .gfield_label,
.chamfr-vendor-dashboard-content .ginput_complex.ginput_container label {
  font-size: 1.6rem !important;
}
.woocommerce-account .woocommerce-MyAccount-content .gfield_label.gfield_label-big,
.chamfr-vendor-dashboard-content .gfield_label.gfield_label-big {
  margin: 2rem 0 1.7rem !important;
  font-size: 1.6rem !important;
}
.woocommerce-account .woocommerce-MyAccount-content .gform_wrapper li.hidden_label input,
.chamfr-vendor-dashboard-content .gform_wrapper li.hidden_label input {
  margin: 0;
}
.woocommerce-account .woocommerce-MyAccount-content .gform_wrapper .gsection_description,
.chamfr-vendor-dashboard-content .gform_wrapper .gsection_description {
  font-size: 1.4rem;
  margin: 1rem 0 !important;
  padding: 0 !important;
}
.woocommerce-account .woocommerce-MyAccount-content .gform_wrapper .gfield_password_strength,
.chamfr-vendor-dashboard-content .gform_wrapper .gfield_password_strength {
  background: #cdcdcd;
}
.woocommerce-account .woocommerce-MyAccount-content .gform_footer,
.chamfr-vendor-dashboard-content .gform_footer {
  margin-top: 4rem !important;
}

@media (min-width: 768px) {
  .chamfr-vendor-dashboard-content {
    padding: 4rem 3rem;
  }
}
@media (min-width: 992px) {
  .chamfr-vendor-dashboard-content {
    padding: 6rem 5rem 7rem;
  }
}
@media (min-width: 1200px) {
  .chamfr-vendor-dashboard-content {
    padding: 8rem 7rem;
  }
}
@media (min-width: 1425px) {
  .chamfr-vendor-dashboard-content {
    padding: 8rem 14rem 9rem;
  }
}

/* Account - Address */
.woocommerce .woocommerce-customer-details address {
  width: auto;
  margin-bottom: 5rem;
  padding: 0;
  border: none;
  border-radius: 0;
}
.woocommerce .woocommerce-customer-details .woocommerce-customer-details--phone:before,
.woocommerce .woocommerce-customer-details .woocommerce-customer-details--email:before {
  line-height: 2.2;
}

.woocommerce-addresses {
  margin: 5rem 0 0;
}
@media (min-width: 576px) {
  .woocommerce-addresses {
    display: flex;
    flex-wrap: wrap;
    margin: 5rem -15px 0;
  }
}
.woocommerce-addresses__info {
  text-align: center;
}
@media (min-width: 992px) {
  .woocommerce-addresses__info {
    text-align: left;
  }
}
.woocommerce-addresses .woocommerce-address {
  padding: 0 0 4rem;
}
@media (min-width: 576px) {
  .woocommerce-addresses .woocommerce-address {
    flex: 0 0 50%;
    width: 50%;
    padding: 0 15px 4rem;
  }
}
@media (min-width: 1425px) {
  .woocommerce-addresses .woocommerce-address {
    flex: 0 0 25%;
    width: 25%;
  }
}
.woocommerce-addresses .woocommerce-address__title {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0 0 2.5rem;
}
.woocommerce-addresses .woocommerce-address address {
  margin: 0 0 2.5rem;
  line-height: 2;
}
@media (min-width: 992px) {
  .woocommerce-addresses .woocommerce-address address {
    margin: 0 0 3rem !important;
  }
}
.woocommerce-addresses .woocommerce-address .btn-edit:before {
  content: "\e603";
  display: inline-block;
  vertical-align: middle;
  width: 2.8rem;
  height: 2.8rem;
  margin-left: 1rem;
  border: 0.2rem solid #9f325d;
  border-radius: 50%;
  font-family: WooCommerce;
  /* stylelint-disable-line */
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: center;
  color: #9f325d;
}

/* Account - Edit Address */
.woocommerce-address-form .woocommerce-address__title {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0 0 2.5rem;
}
@media (min-width: 576px) {
  .woocommerce-address-form .woocommerce-address__title {
    font-size: 1.8rem;
  }
}

.woocommerce-address-fields:after {
  content: "";
  display: block;
  height: 1px;
  clear: both;
}
.woocommerce-address-fields .form-row {
  display: block;
  margin: 0 0 3rem;
}
@media (min-width: 992px) {
  .woocommerce-address-fields .form-row {
    width: 50%;
    margin: 0 0 4rem;
  }
}
@media (min-width: 992px) {
  .woocommerce-address-fields .form-row-first {
    float: left;
    padding-right: 0.8rem;
  }
}
@media (min-width: 992px) {
  .woocommerce-address-fields .form-row-last {
    float: right;
    padding-left: 0.8rem;
  }
}
.woocommerce-address-fields .form-row-wide {
  float: none;
  clear: both;
}
@media (min-width: 992px) {
  .woocommerce-address-fields .form-row-wide {
    padding-right: 0.8rem;
  }
}
.woocommerce-address-fields label {
  display: block;
  margin: 0 0 0.8rem;
  font-size: 1.6rem;
  font-weight: 400;
}
.woocommerce-address-fields label abbr {
  text-decoration: none;
  cursor: default;
}
.woocommerce-address-fields .woocommerce-input-wrapper {
  display: block;
}
.woocommerce-address-fields__submit {
  margin-top: 2rem;
}
@media (min-width: 576px) {
  .woocommerce-address-fields__submit {
    margin-top: 3rem;
  }
}
.woocommerce-address-fields__submit .btn, .woocommerce-address-fields__submit .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .woocommerce-address-fields__submit .stripe-connect.light-blue, .woocommerce-address-fields__submit .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-address-fields__submit .wcv-button, .woocommerce-address-fields__submit .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-address-fields__submit .button, .woocommerce-address-fields__submit .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .woocommerce-address-fields__submit .website, .woocommerce-address-fields__submit .wp-block-button__link, .woocommerce-address-fields__submit body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .woocommerce-address-fields__submit input.button,
.woocommerce-address-fields__submit body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .woocommerce-address-fields__submit input[type=submit],
.woocommerce-address-fields__submit body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .woocommerce-address-fields__submit input.button,
.woocommerce-address-fields__submit body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .woocommerce-address-fields__submit input[type=submit], .woocommerce-address-fields__submit .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .woocommerce-address-fields__submit a, .woocommerce-address-fields__submit .woocommerce #respond input#submit, .woocommerce #respond .woocommerce-address-fields__submit input#submit,
.woocommerce-address-fields__submit .woocommerce a.button,
.woocommerce .woocommerce-address-fields__submit a.button,
.woocommerce-address-fields__submit .woocommerce button.button,
.woocommerce .woocommerce-address-fields__submit button.button,
.woocommerce-address-fields__submit .woocommerce input.button,
.woocommerce .woocommerce-address-fields__submit input.button, .woocommerce-address-fields__submit .blog .sticky .post__link a, .blog .sticky .post__link .woocommerce-address-fields__submit a,
.woocommerce-address-fields__submit .archive .sticky .post__link a,
.archive .sticky .post__link .woocommerce-address-fields__submit a {
  margin-right: 1.6rem;
}
.woocommerce-address-fields__submit .btn-secondary, .woocommerce-address-fields__submit .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .woocommerce-address-fields__submit .wcv-button, .woocommerce-address-fields__submit .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-address-fields__submit .wcv-button.btn-secondary, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-address-fields__submit .wcv-search .wcv-button,
.woocommerce-address-fields__submit .wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce .woocommerce-address-fields__submit a.wcv-button.button.alt,
.woocommerce-address-fields__submit .woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-address-fields__submit a.wcv-button.button.alt,
.woocommerce-address-fields__submit .wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce .woocommerce-address-fields__submit button.wcv-button.button.alt,
.woocommerce-address-fields__submit .woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-address-fields__submit button.wcv-button.button.alt,
.woocommerce-address-fields__submit .wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce .woocommerce-address-fields__submit input.wcv-button.button.alt,
.woocommerce-address-fields__submit .woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-address-fields__submit input.wcv-button.button.alt, .woocommerce-address-fields__submit .woocommerce #respond input#submit.alt, .woocommerce #respond .woocommerce-address-fields__submit input#submit.alt,
.woocommerce-address-fields__submit .woocommerce a.button.alt,
.woocommerce .woocommerce-address-fields__submit a.button.alt,
.woocommerce-address-fields__submit .woocommerce button.button.alt,
.woocommerce .woocommerce-address-fields__submit button.button.alt,
.woocommerce-address-fields__submit .woocommerce input.button.alt,
.woocommerce .woocommerce-address-fields__submit input.button.alt {
  margin-right: 0;
}

/* Account - Orders */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .woocommerce-orders-table {
    width: calc(100% + 16rem);
    margin: 0 -8rem;
  }
}
.woocommerce-orders-table tbody tr:first-of-type td {
  padding-top: 0;
}
@media (min-width: 992px) {
  .woocommerce-orders-table tbody tr:first-of-type td {
    padding-top: 2.5rem;
  }
}
.woocommerce-orders-table tr td b {
  font-weight: 600;
}
@media (min-width: 992px) {
  .woocommerce-orders-table tr td b {
    display: none;
  }
}
.woocommerce-orders-table__cell-order-number {
  font-size: 1.8rem;
  font-weight: 600;
}
@media (min-width: 992px) {
  .woocommerce-orders-table__cell-order-number {
    font-size: 1.6rem;
    font-weight: 400;
  }
}
.woocommerce-orders-table__cell-order-actions > div {
  margin-bottom: 1rem;
}
.woocommerce-orders-table__cell-order-actions > div:last-child {
  margin-bottom: 0;
}
.woocommerce-orders-table .ced_my_account_reorder {
  color: #e11d73;
}
.woocommerce-orders-table .ced_my_account_reorder:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 22px;
  margin-right: 0.6rem;
  background: url(../images/cart.svg) no-repeat center;
}
.woocommerce-orders-table .chamfr_invoice {
  margin-top: 2rem;
  color: #e11d73;
}
.woocommerce-orders-table .chamfr_invoice:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 22px;
  margin-right: 0.6rem;
  background: url(../images/invoice.svg) no-repeat center;
}
.woocommerce-orders .woocommerce-orders-message__btn {
  margin: 3rem 0 0;
}

/* Account - Payment */
#add_payment_method #payment {
  background: transparent;
}

#add_payment_method #payment ul.payment_methods {
  padding: 0;
  border: none;
  margin: 0;
  list-style: none;
}

#add_payment_method #payment div.payment_box {
  margin: 0;
  padding: 0;
  background: transparent;
  font-size: 1.4rem;
  color: #000;
}
#add_payment_method #payment div.payment_box:before {
  display: none;
}

.woocommerce-payment-methods-info {
  max-width: 64.5rem;
  margin: -2.5rem 0 5rem;
  text-align: center;
}
@media (min-width: 992px) {
  .woocommerce-payment-methods-info {
    text-align: left;
  }
}
.woocommerce-payment-methods-table tr td b {
  font-weight: 600;
}
@media (min-width: 992px) {
  .woocommerce-payment-methods-table tr td b {
    display: none;
  }
}
.woocommerce-payment-methods-table__cell-order-number {
  font-size: 1.8rem;
  font-weight: 600;
}
@media (min-width: 992px) {
  .woocommerce-payment-methods-table__cell-order-number {
    font-size: 1.6rem;
    font-weight: 400;
  }
}
.woocommerce-payment-methods-table .delete {
  color: #9f325d;
}
.woocommerce-payment-methods-table .delete:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 23px;
  margin-right: 0.6rem;
  background: url(../images/del.svg) no-repeat center;
}
.woocommerce-payment-methods-btn {
  margin: 7rem 0 0;
}
.woocommerce-payment-methods ul.payment_methods {
  list-style: none;
  margin: 0;
  padding: 0;
}
.woocommerce-payment-methods ul.payment_methods label {
  display: block;
}
.woocommerce-payment-methods .wc-stripe-elements-field,
.woocommerce-payment-methods .wc-stripe-iban-element-field {
  margin: 5px 0;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  outline: 0;
}
.woocommerce-payment-methods #wc-stripe-cc-form label {
  font-size: 1.6rem;
}
.woocommerce-payment-methods-submit {
  display: flex;
  justify-content: space-between;
  margin: 4rem 0 0;
}
@media (min-width: 992px) {
  .woocommerce-payment-methods-submit {
    display: block;
    margin: 6rem 0 0;
  }
}
.woocommerce-payment-methods-submit .btn, .woocommerce-payment-methods-submit .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .woocommerce-payment-methods-submit .stripe-connect.light-blue, .woocommerce-payment-methods-submit .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-payment-methods-submit .wcv-button, .woocommerce-payment-methods-submit .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-payment-methods-submit .button, .woocommerce-payment-methods-submit .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .woocommerce-payment-methods-submit .website, .woocommerce-payment-methods-submit .wp-block-button__link, .woocommerce-payment-methods-submit body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .woocommerce-payment-methods-submit input.button,
.woocommerce-payment-methods-submit body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .woocommerce-payment-methods-submit input[type=submit],
.woocommerce-payment-methods-submit body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .woocommerce-payment-methods-submit input.button,
.woocommerce-payment-methods-submit body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .woocommerce-payment-methods-submit input[type=submit], .woocommerce-payment-methods-submit .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .woocommerce-payment-methods-submit a, .woocommerce-payment-methods-submit .woocommerce #respond input#submit, .woocommerce #respond .woocommerce-payment-methods-submit input#submit,
.woocommerce-payment-methods-submit .woocommerce a.button,
.woocommerce .woocommerce-payment-methods-submit a.button,
.woocommerce-payment-methods-submit .woocommerce button.button,
.woocommerce .woocommerce-payment-methods-submit button.button,
.woocommerce-payment-methods-submit .woocommerce input.button,
.woocommerce .woocommerce-payment-methods-submit input.button, .woocommerce-payment-methods-submit .blog .sticky .post__link a, .blog .sticky .post__link .woocommerce-payment-methods-submit a,
.woocommerce-payment-methods-submit .archive .sticky .post__link a,
.archive .sticky .post__link .woocommerce-payment-methods-submit a {
  padding: 0 1rem;
  margin-top: 1rem;
  margin-right: 0.8rem;
  font-size: 1.4rem;
}
@media (min-width: 370px) {
  .woocommerce-payment-methods-submit .btn, .woocommerce-payment-methods-submit .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .woocommerce-payment-methods-submit .stripe-connect.light-blue, .woocommerce-payment-methods-submit .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-payment-methods-submit .wcv-button, .woocommerce-payment-methods-submit .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-payment-methods-submit .button, .woocommerce-payment-methods-submit .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .woocommerce-payment-methods-submit .website, .woocommerce-payment-methods-submit .wp-block-button__link, .woocommerce-payment-methods-submit body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .woocommerce-payment-methods-submit input.button,
.woocommerce-payment-methods-submit body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .woocommerce-payment-methods-submit input[type=submit],
.woocommerce-payment-methods-submit body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .woocommerce-payment-methods-submit input.button,
.woocommerce-payment-methods-submit body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .woocommerce-payment-methods-submit input[type=submit], .woocommerce-payment-methods-submit .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .woocommerce-payment-methods-submit a, .woocommerce-payment-methods-submit .woocommerce #respond input#submit, .woocommerce #respond .woocommerce-payment-methods-submit input#submit,
.woocommerce-payment-methods-submit .woocommerce a.button,
.woocommerce .woocommerce-payment-methods-submit a.button,
.woocommerce-payment-methods-submit .woocommerce button.button,
.woocommerce .woocommerce-payment-methods-submit button.button,
.woocommerce-payment-methods-submit .woocommerce input.button,
.woocommerce .woocommerce-payment-methods-submit input.button, .woocommerce-payment-methods-submit .blog .sticky .post__link a, .blog .sticky .post__link .woocommerce-payment-methods-submit a,
.woocommerce-payment-methods-submit .archive .sticky .post__link a,
.archive .sticky .post__link .woocommerce-payment-methods-submit a {
    padding: 0 2rem;
  }
}
@media (min-width: 576px) {
  .woocommerce-payment-methods-submit .btn, .woocommerce-payment-methods-submit .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .woocommerce-payment-methods-submit .stripe-connect.light-blue, .woocommerce-payment-methods-submit .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-payment-methods-submit .wcv-button, .woocommerce-payment-methods-submit .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-payment-methods-submit .button, .woocommerce-payment-methods-submit .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .woocommerce-payment-methods-submit .website, .woocommerce-payment-methods-submit .wp-block-button__link, .woocommerce-payment-methods-submit body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .woocommerce-payment-methods-submit input.button,
.woocommerce-payment-methods-submit body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .woocommerce-payment-methods-submit input[type=submit],
.woocommerce-payment-methods-submit body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .woocommerce-payment-methods-submit input.button,
.woocommerce-payment-methods-submit body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .woocommerce-payment-methods-submit input[type=submit], .woocommerce-payment-methods-submit .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .woocommerce-payment-methods-submit a, .woocommerce-payment-methods-submit .woocommerce #respond input#submit, .woocommerce #respond .woocommerce-payment-methods-submit input#submit,
.woocommerce-payment-methods-submit .woocommerce a.button,
.woocommerce .woocommerce-payment-methods-submit a.button,
.woocommerce-payment-methods-submit .woocommerce button.button,
.woocommerce .woocommerce-payment-methods-submit button.button,
.woocommerce-payment-methods-submit .woocommerce input.button,
.woocommerce .woocommerce-payment-methods-submit input.button, .woocommerce-payment-methods-submit .blog .sticky .post__link a, .blog .sticky .post__link .woocommerce-payment-methods-submit a,
.woocommerce-payment-methods-submit .archive .sticky .post__link a,
.archive .sticky .post__link .woocommerce-payment-methods-submit a {
    padding: 0 3rem;
    font-size: 1.6rem;
  }
}
.woocommerce-payment-methods-submit .btn-secondary, .woocommerce-payment-methods-submit .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .woocommerce-payment-methods-submit .wcv-button, .woocommerce-payment-methods-submit .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-payment-methods-submit .wcv-button.btn-secondary, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-payment-methods-submit .wcv-search .wcv-button,
.woocommerce-payment-methods-submit .wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce .woocommerce-payment-methods-submit a.wcv-button.button.alt,
.woocommerce-payment-methods-submit .woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-payment-methods-submit a.wcv-button.button.alt,
.woocommerce-payment-methods-submit .wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce .woocommerce-payment-methods-submit button.wcv-button.button.alt,
.woocommerce-payment-methods-submit .woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-payment-methods-submit button.wcv-button.button.alt,
.woocommerce-payment-methods-submit .wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce .woocommerce-payment-methods-submit input.wcv-button.button.alt,
.woocommerce-payment-methods-submit .woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-payment-methods-submit input.wcv-button.button.alt, .woocommerce-payment-methods-submit .woocommerce #respond input#submit.alt, .woocommerce #respond .woocommerce-payment-methods-submit input#submit.alt,
.woocommerce-payment-methods-submit .woocommerce a.button.alt,
.woocommerce .woocommerce-payment-methods-submit a.button.alt,
.woocommerce-payment-methods-submit .woocommerce button.button.alt,
.woocommerce .woocommerce-payment-methods-submit button.button.alt,
.woocommerce-payment-methods-submit .woocommerce input.button.alt,
.woocommerce .woocommerce-payment-methods-submit input.button.alt {
  margin-right: 0;
}

#add_payment_method #payment ul.payment_methods li img.stripe-icon,
.woocommerce-checkout #payment ul.payment_methods li img.stripe-icon {
  max-width: 40px;
  margin: 0;
  padding: 3px 3px 0 0 !important;
}
@media (min-width: 768px) {
  #add_payment_method #payment ul.payment_methods li img.stripe-icon,
.woocommerce-checkout #payment ul.payment_methods li img.stripe-icon {
    padding: 0 3px 0 0 !important;
  }
}

.stripe-source-errors ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.page .site {
  padding: 4rem 0 0;
}
@media (min-width: 992px) {
  .page .site {
    padding: 7rem 0 0;
  }
}

.password-protect {
  min-height: 100vh;
}
.password-protect-form {
  padding: 2rem 0;
}

#chamfr-search-results .chamfr-drawers .companies,
#chamfr-recently-added-products .chamfr-drawers .companies {
  padding-left: 0;
  margin-top: -10px;
  background-color: #fff;
  border-radius: 10px;
}
#chamfr-search-results .chamfr-drawers .companies .company,
#chamfr-recently-added-products .chamfr-drawers .companies .company {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0;
}
#chamfr-search-results .chamfr-drawers .companies .company:first-child,
#chamfr-recently-added-products .chamfr-drawers .companies .company:first-child {
  border-top: none;
}
@media (min-width: 768px) {
  #chamfr-search-results .chamfr-drawers .companies .company .details,
#chamfr-recently-added-products .chamfr-drawers .companies .company .details {
    display: flex;
    flex-wrap: wrap;
  }
  #chamfr-search-results .chamfr-drawers .companies .company .details .col,
#chamfr-recently-added-products .chamfr-drawers .companies .company .details .col {
    flex: 0 0 50%;
  }
}
#chamfr-search-results .chamfr-drawers .showmore,
#chamfr-recently-added-products .chamfr-drawers .showmore {
  padding: 20px 45px;
}
#chamfr-search-results .chamfr-drawers .all-results,
#chamfr-recently-added-products .chamfr-drawers .all-results {
  margin-top: 1.5rem;
}
#chamfr-search-results .all-results,
#chamfr-recently-added-products .all-results {
  margin-top: 3rem;
  margin-bottom: 6rem;
}
#chamfr-search-results .search-blog .showmore,
#chamfr-recently-added-products .search-blog .showmore {
  padding: 20px 45px;
}
#chamfr-search-results .search-blog .blog-posts,
#chamfr-recently-added-products .search-blog .blog-posts {
  padding-left: 0;
  margin-top: -10px;
  list-style: none;
  background-color: #fff;
  border-radius: 10px;
}
#chamfr-search-results .search-blog .blog-posts .blog-post,
#chamfr-recently-added-products .search-blog .blog-posts .blog-post {
  list-style: none;
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
#chamfr-search-results .search-blog .blog-posts .blog-post:first-child,
#chamfr-recently-added-products .search-blog .blog-posts .blog-post:first-child {
  border-top: none;
}
#chamfr-search-results .search-blog .blog-posts .blog-post .details,
#chamfr-recently-added-products .search-blog .blog-posts .blog-post .details {
  display: flex;
  flex-direction: row;
  padding: 20px 30px;
}
@media (max-width: 767.98px) {
  #chamfr-search-results .search-blog .blog-posts .blog-post .details,
#chamfr-recently-added-products .search-blog .blog-posts .blog-post .details {
    display: block;
  }
}
#chamfr-search-results .search-blog .blog-posts .blog-post .details .type,
#chamfr-recently-added-products .search-blog .blog-posts .blog-post .details .type {
  color: rgba(49, 49, 49, 0.5);
  font-size: 1.4rem;
  letter-spacing: 0.04em;
}
#chamfr-search-results .search-blog .blog-posts .blog-post .details h4,
#chamfr-recently-added-products .search-blog .blog-posts .blog-post .details h4 {
  font-size: 2.2rem;
  margin: 0.5rem 0 1.5rem;
  font-weight: 400;
}
#chamfr-search-results .search-blog .blog-posts .blog-post .details p,
#chamfr-recently-added-products .search-blog .blog-posts .blog-post .details p {
  color: #313131;
}
#chamfr-search-results .search-blog .blog-posts .blog-post .details a,
#chamfr-recently-added-products .search-blog .blog-posts .blog-post .details a {
  display: inline-block;
  padding-bottom: 0.3rem;
  text-decoration: none;
  border-bottom: 1px solid transparent;
}
#chamfr-search-results .search-blog .blog-posts .blog-post .details a:hover, #chamfr-search-results .search-blog .blog-posts .blog-post .details a:focus, #chamfr-search-results .search-blog .blog-posts .blog-post .details a:active,
#chamfr-recently-added-products .search-blog .blog-posts .blog-post .details a:hover,
#chamfr-recently-added-products .search-blog .blog-posts .blog-post .details a:focus,
#chamfr-recently-added-products .search-blog .blog-posts .blog-post .details a:active {
  text-decoration: none;
  border-bottom: 1px solid #e11d73;
}
@media (min-width: 768px) {
  #chamfr-search-results .search-blog .blog-posts .blog-post .details,
#chamfr-recently-added-products .search-blog .blog-posts .blog-post .details {
    display: flex;
    flex-wrap: wrap;
  }
  #chamfr-search-results .search-blog .blog-posts .blog-post .details .col,
#chamfr-recently-added-products .search-blog .blog-posts .blog-post .details .col {
    flex: 0 0 50%;
  }
}

.chamfr-service-partners {
  margin-bottom: 15rem;
}
.chamfr-service-partners .summary {
  max-width: 64rem;
  margin-bottom: 4rem;
}
.chamfr-service-partners .select2 {
  max-width: 100%;
}
.chamfr-service-partners-featured {
  display: flex;
  padding: 2rem 2rem 9rem;
  align-items: flex-start;
}
@media (max-width: 991.98px) {
  .chamfr-service-partners-featured {
    padding: 0 0 3rem;
    display: block;
  }
}
.chamfr-service-partners-featured .logo {
  flex: 0 0 60%;
  position: relative;
}
.chamfr-service-partners-featured .logo-img {
  overflow: hidden;
  border-radius: 0.5rem;
  background: radial-gradient(closest-side, #fff, #e0e0e0);
}
.chamfr-service-partners-featured .logo img {
  width: 100%;
}
.chamfr-service-partners-featured .logo:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  bottom: -2.6rem;
  left: -2rem;
  width: 54rem;
  height: 22.5rem;
  background: url(../images/dots.svg) repeat left bottom;
  opacity: 0.5;
}
@media (max-width: 1199.98px) {
  .chamfr-service-partners-featured .logo:before {
    width: 32rem;
  }
}
@media (max-width: 991.98px) {
  .chamfr-service-partners-featured .logo:before {
    height: 24rem;
    width: 24rem;
  }
}
@media (max-width: 359.98px) {
  .chamfr-service-partners-featured .logo:before {
    bottom: -1.2rem;
    left: -1rem;
    height: 12rem;
    background-size: 25%;
  }
}
.chamfr-service-partners-featured .summary {
  margin-left: 4rem;
  font-size: 1.8rem;
}
@media (max-width: 359.98px) {
  .chamfr-service-partners-featured .summary {
    font-size: 1.4rem;
    margin: 3rem 0 0 0;
  }
}
.chamfr-drawers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 991.98px) {
  .chamfr-drawers {
    display: block;
  }
}
.chamfr-drawers-nav {
  margin-top: 2em;
  margin-right: 1rem;
  flex: 0 0 calc(30% - 1rem);
}
.chamfr-drawers-nav #chamfr_drawers_filter_clear {
  display: none;
  margin-bottom: 1rem;
}
.chamfr-drawers-nav #chamfr_drawers_filter_clear.show {
  display: block;
}
.chamfr-drawers-nav-filter-wrapper {
  margin-top: 2rem;
}
.chamfr-drawers-nav-filter-wrapper .select2-container--default .select2-selection__clear {
  display: none;
}
.chamfr-drawers-nav-filter-wrapper .select2-container--default .select2-selection--multiple {
  height: auto;
  min-height: 5rem;
}
.chamfr-drawers-nav-filter-wrapper .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  margin-top: 1rem;
  margin-left: 1rem;
}
.chamfr-drawers-nav-filter-wrapper .select2-container--default .select2-selection--multiple li.select2-selection__choice {
  line-height: inherit;
  color: #000000;
}
.chamfr-drawers-nav-searchbox {
  position: relative;
}
.chamfr-drawers-nav-searchbox-field {
  width: 100%;
  max-width: 100%;
  height: auto;
  padding: 0.9rem 4rem 0.8rem 1rem;
  background-color: #fafafa;
  border-radius: 0.2rem;
  border: none;
  appearance: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  /* stylelint-disable-next-line function-url-quotes */
  font-size: 1.4rem;
  line-height: 1.36;
}
@media (min-width: 768px) {
  .chamfr-drawers-nav-searchbox-field {
    width: 300px;
  }
}
.chamfr-drawers-nav-searchbox-submit {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3.5rem;
  background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
  background-position: 1.2rem 1.9rem;
  background-size: 12px;
  background-repeat: no-repeat;
  background-color: #e11d73;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.chamfr-drawers .drawer {
  flex: 0 0 70%;
}
.chamfr-drawers .companies {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px;
}
.chamfr-drawers .companies .company {
  flex: 0 0 50%;
  display: flex;
  align-items: stretch;
  width: 50%;
  max-width: 50%;
  padding: 10px;
  color: #313131;
}
@media (max-width: 767.98px) {
  .chamfr-drawers .companies .company {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}
.chamfr-drawers .companies .company > div {
  flex: 0 0 100%;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  padding-bottom: 2rem;
  display: grid;
  grid-template-rows: auto 1fr;
}
@media (min-width: 768px) {
  .chamfr-drawers .companies .company > div {
    padding-bottom: 5rem;
  }
}
.chamfr-drawers .companies .company .logo {
  background-color: #e0e0e0;
  height: 13rem;
  padding: 20px;
  flex: 0 0 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chamfr-drawers .companies .company .logo img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.chamfr-drawers .companies .company .details {
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 4.5rem;
}
@media (max-width: 991.98px) {
  .chamfr-drawers .companies .company .details {
    margin-bottom: 6.2rem;
  }
}
@media (max-width: 767.98px) {
  .chamfr-drawers .companies .company .details {
    margin-bottom: 9rem;
  }
}
.chamfr-drawers .companies .company .details .type {
  color: rgba(49, 49, 49, 0.5);
  font-size: 1.4rem;
  letter-spacing: 0.04em;
}
.chamfr-drawers .companies .company .details .links a {
  position: relative;
  text-decoration: none;
  padding: 1rem;
  margin-right: 1rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.chamfr-drawers .companies .company .details .links a:last-child {
  margin-right: 0;
}
.chamfr-drawers .companies .company .details .links a.shop {
  color: #fff;
  background-color: #e11d73;
  border: 2px solid #e11d73;
  box-shadow: none;
}
.chamfr-drawers .companies .company .details .links a.rfq {
  color: #e11d73;
  background-color: #fff;
  border: 2px solid #e11d73;
  box-shadow: none;
}
.chamfr-drawers .companies .company h4 {
  margin: 0.5rem 0 1.5rem;
  font-size: 2rem;
  font-weight: 400;
}
@media (min-width: 576px) {
  .chamfr-drawers .companies .company h4 {
    font-size: 2.2rem;
  }
}
.chamfr-drawers .companies .company h4 + p {
  margin-top: 2rem;
}
.chamfr-drawers .companies .company .details__bottom {
  overflow: hidden;
  min-height: 3.5rem;
  padding-top: 3rem;
  position: absolute;
  bottom: 2rem;
}
.chamfr-drawers .companies .company .website {
  display: inline-block;
  float: left;
  margin-top: 0.7rem;
  line-height: 1.2;
  text-decoration: underline;
  text-underline-position: under;
  color: #9f325d;
}
.chamfr-drawers .companies .company .website:after {
  content: " →";
}
.chamfr-drawers .companies .company .website:hover {
  text-decoration: none;
}
.chamfr-drawers .companies .company .qcb {
  margin: 1rem 0px;
}
.chamfr-drawers .companies .company .qcb span {
  display: inline-block;
  margin-left: 0.7rem;
}
.chamfr-drawers .companies .company .qcb span img {
  width: auto;
  height: 24px;
}
.chamfr-drawers .companies .company .qcb span img.fda {
  height: 12px;
}
.chamfr-sp-t2 {
  color: white;
}
@media (min-width: 768px) {
  .chamfr-sp-t2 {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 2rem 2rem 5rem;
  }
}
.chamfr-sp-t2-single {
  margin-bottom: 2rem;
  display: flex;
}
@media (min-width: 768px) {
  .chamfr-sp-t2-single {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0;
  }
}
.chamfr-sp-t2-single .inner {
  color: #000;
  background-color: #fff;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
}
.chamfr-sp-t2-image {
  flex: 0 0 25%;
}
.chamfr-sp-t2-content {
  position: relative;
  padding-bottom: 5rem;
}
.chamfr-sp-t2-content .btn, .chamfr-sp-t2-content .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .chamfr-sp-t2-content .stripe-connect.light-blue, .chamfr-sp-t2-content .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .chamfr-sp-t2-content .wcv-button, .chamfr-sp-t2-content .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .chamfr-sp-t2-content .button, .chamfr-sp-t2-content .wp-block-button__link, .chamfr-sp-t2-content body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .chamfr-sp-t2-content input.button,
.chamfr-sp-t2-content body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .chamfr-sp-t2-content input[type=submit],
.chamfr-sp-t2-content body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .chamfr-sp-t2-content input.button,
.chamfr-sp-t2-content body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .chamfr-sp-t2-content input[type=submit], .chamfr-sp-t2-content .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .chamfr-sp-t2-content a, .chamfr-sp-t2-content .woocommerce #respond input#submit, .woocommerce #respond .chamfr-sp-t2-content input#submit,
.chamfr-sp-t2-content .woocommerce a.button,
.woocommerce .chamfr-sp-t2-content a.button,
.chamfr-sp-t2-content .woocommerce button.button,
.woocommerce .chamfr-sp-t2-content button.button,
.chamfr-sp-t2-content .woocommerce input.button,
.woocommerce .chamfr-sp-t2-content input.button, .chamfr-sp-t2-content .blog .sticky .post__link a, .blog .sticky .post__link .chamfr-sp-t2-content a,
.chamfr-sp-t2-content .archive .sticky .post__link a,
.archive .sticky .post__link .chamfr-sp-t2-content a, .chamfr-sp-t2-content .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .chamfr-sp-t2-content .website {
  position: absolute;
  bottom: 0;
  left: 0;
}
.chamfr-sp-t2-description {
  margin-bottom: 2rem;
  margin-right: 2rem;
}

.contact-seller-form {
  display: none;
}

.chamfr-single-product {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  padding: 5rem 15px 4rem;
}
@media (min-width: 360px) {
  .chamfr-single-product {
    max-width: 360px;
  }
}
@media (min-width: 576px) {
  .chamfr-single-product {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .chamfr-single-product {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .chamfr-single-product {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .chamfr-single-product {
    max-width: 1140px;
  }
}
@media (min-width: 1425px) {
  .chamfr-single-product {
    max-width: 1360px;
  }
}
@media (min-width: 768px) {
  .chamfr-single-product {
    padding: 9rem 15px 5rem;
  }
}
@media (min-width: 1425px) {
  .chamfr-single-product {
    padding: 9rem 165px 5rem;
  }
}
.chamfr-single-product h1 {
  margin: 0 0 3rem;
  font-size: 1.5rem;
}
@media (min-width: 576px) {
  .chamfr-single-product h1 {
    font-size: 3rem;
  }
}
.chamfr-single-product__header {
  display: flex;
  align-items: center;
  margin: 0 0 3rem;
}
@media (min-width: 576px) {
  .chamfr-single-product__header {
    margin: 0 0 5rem;
  }
}
@media (min-width: 1425px) {
  .chamfr-single-product__header {
    display: block;
    position: relative;
    margin: 0 0 3rem;
  }
}
.chamfr-single-product__header h1 {
  margin: 0;
}
.chamfr-single-product__thumb {
  flex: 0 0 6rem;
  width: 6rem;
  height: 6rem;
  margin-right: 1rem;
}
@media (min-width: 576px) {
  .chamfr-single-product__thumb {
    flex: 0 0 12rem;
    width: 12rem;
    height: 12rem;
    margin-right: 3rem;
  }
}
@media (min-width: 1425px) {
  .chamfr-single-product__thumb {
    position: absolute;
    top: 50%;
    left: -150px;
    margin: 0;
    transform: translateY(-50%);
  }
}
.chamfr-single-product__thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.chamfr-single-product__content {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
@media (min-width: 992px) {
  .chamfr-single-product__content {
    display: block;
    overflow: hidden;
  }
}
.chamfr-single-product__description {
  order: 2;
  float: left;
  position: relative;
  margin: 0 auto;
}
.chamfr-single-product__description::before {
  content: "";
  display: block;
  clear: both;
}
@media (min-width: 992px) {
  .chamfr-single-product__description {
    float: left;
    width: 100%;
  }
}
.chamfr-single-product__description.wide {
  margin-top: 3rem;
}
@media (min-width: 992px) {
  .chamfr-single-product__description.wide {
    width: 100%;
    min-height: 0;
    margin-top: 0;
  }
}
@media (max-width: 1199.98px) {
  .chamfr-single-product__description iframe {
    width: 100%;
  }
}
.chamfr-single-product__details {
  order: 2;
  margin-top: 4.6rem;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 992px) {
  .chamfr-single-product__details {
    float: left;
    width: 60%;
    min-height: 750px;
  }
}
.chamfr-single-product__details.wide {
  margin-top: 3rem;
}
@media (min-width: 992px) {
  .chamfr-single-product__details.wide {
    width: 100%;
    min-height: 0;
    margin-top: 0;
  }
}
.chamfr-single-product__details.wide .woocommerce-product-attributes-item--attribute_pa_datasheet .woocommerce-product-attributes-item__value {
  max-width: 300px;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .chamfr-single-product__details.wide .woocommerce-product-attributes-item--attribute_pa_datasheet .woocommerce-product-attributes-item__value {
    max-width: 414px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .chamfr-single-product__details.wide .woocommerce-product-attributes-item--attribute_pa_datasheet .woocommerce-product-attributes-item__value {
    max-width: 593px;
  }
}
@media (max-width: 991.98px) {
  .chamfr-single-product__details.wide .woocommerce-product-attributes-item--attribute_pa_datasheet .woocommerce-product-attributes-item__value a {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
  }
  .chamfr-single-product__details.wide .woocommerce-product-attributes-item--attribute_pa_datasheet .woocommerce-product-attributes-item__value a:before {
    content: url("../images/pdf_file_icon.svg");
    margin-right: 5px;
    height: 22px;
    width: 20px;
    display: block;
    float: left;
  }
}
.chamfr-single-product__summary {
  order: 3;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 992px) {
  .chamfr-single-product__summary {
    float: left;
    width: 40%;
  }
}
.chamfr-single-product__summary--cart {
  order: 1;
}
@media (min-width: 992px) {
  .chamfr-single-product__summary--cart {
    float: right;
    margin-top: 4.6rem;
  }
}
.chamfr-single-product__summary--cfrq {
  order: 1;
}
@media (min-width: 992px) {
  .chamfr-single-product__summary--cfrq {
    float: right;
  }
}
.chamfr-single-product__meta {
  padding: 2.5rem;
  border: 1px solid #b7b7b7;
  border-radius: 10px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.chamfr-single-product__meta .wcvendors_ships_from > br {
  display: none;
}
.chamfr-single-product__cart {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 -15px 3rem;
  padding: 2.5rem;
  background-color: #fff;
  border-radius: 0;
}
@media (min-width: 576px) {
  .chamfr-single-product__cart {
    margin: 0 0 3rem;
    border-radius: 10px;
  }
}
@media (min-width: 360px) {
  .chamfr-single-product__cart {
    justify-content: center;
  }
}
.chamfr-single-product__cart #wc_bis_product_form, .chamfr-single-product__cart #wc_bis_already_registered {
  width: 49%;
  margin: 0;
  text-align: center;
}
.chamfr-single-product__cart #wc_bis_product_form #wc_bis_email, .chamfr-single-product__cart #wc_bis_already_registered #wc_bis_email {
  padding: 3px 8px;
  height: auto;
}
.chamfr-single-product__cart #wc_bis_product_form .button, .chamfr-single-product__cart #wc_bis_already_registered .button {
  padding: 8px 8px;
}
.chamfr-single-product__cart #wc_bis_product_form #wc_bis_send_form, .chamfr-single-product__cart #wc_bis_already_registered #wc_bis_send_form {
  margin-bottom: 0px;
}
.chamfr-single-product__cart #wc_bis_product_form .woocommerce-info, .chamfr-single-product__cart #wc_bis_already_registered .woocommerce-info {
  border: none;
  margin: 0;
  padding: 0;
  background-color: inherit;
  color: inherit;
  word-wrap: inherit;
}
.chamfr-single-product__cart #wc_bis_product_form .woocommerce-info::before, .chamfr-single-product__cart #wc_bis_already_registered .woocommerce-info::before {
  display: none;
}
.chamfr-single-product__cart--mobile {
  margin: 0 -15px 6rem;
  border-radius: 0;
}
@media (min-width: 576px) {
  .chamfr-single-product__cart--mobile {
    display: none;
    margin: 0;
  }
}
.chamfr-single-product__cart .cart-qty {
  width: 49%;
  text-align: center;
  margin: 0 auto;
}
.chamfr-single-product__cart .cart-qty p {
  margin: 0;
  opacity: 0.5;
}
.chamfr-single-product__cart .cart-qty p.qty-label {
  font-size: 2.5rem;
}
.chamfr-single-product__cart .cart-qty .price {
  flex: 0 0 100%;
  font-size: 2.2rem;
  opacity: 1;
}
.chamfr-single-product__cart form.cart {
  flex: 0 0 40%;
}
.chamfr-single-product__cart form.cart div.quantity {
  float: none;
}
.chamfr-single-product__cart form.cart .button {
  padding: 8px 8px;
  height: auto;
  line-height: 1;
}
.chamfr-single-product__cart .lead-time {
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex: 0 0 100%;
  text-align: center;
}
.chamfr-single-product__cart .lead-time p {
  display: block;
  margin: 0 auto;
  color: #56b8a1;
  font-size: 13px;
}
.chamfr-single-product__cfrq {
  display: block;
  margin: 0 -15px 3rem;
  padding: 2.5rem;
  background-color: #fff;
  border-radius: 0;
}
@media (min-width: 576px) {
  .chamfr-single-product__cfrq {
    margin: 0 0 2rem;
    border-radius: 10px;
  }
}
.chamfr-single-product__cfrq h5 {
  margin: 0 0 1rem;
}
.chamfr-single-product__cfrq-text {
  flex: 0 0 100%;
}
.chamfr-single-product__cfrq-btn {
  text-align: right;
  margin-top: 1rem;
}
.chamfr-single-product__cfrq-btn .btn, .chamfr-single-product__cfrq-btn .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .chamfr-single-product__cfrq-btn .stripe-connect.light-blue, .chamfr-single-product__cfrq-btn .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .chamfr-single-product__cfrq-btn .wcv-button, .chamfr-single-product__cfrq-btn .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .chamfr-single-product__cfrq-btn .button, .chamfr-single-product__cfrq-btn .wp-block-button__link, .chamfr-single-product__cfrq-btn body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .chamfr-single-product__cfrq-btn input.button,
.chamfr-single-product__cfrq-btn body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .chamfr-single-product__cfrq-btn input[type=submit],
.chamfr-single-product__cfrq-btn body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .chamfr-single-product__cfrq-btn input.button,
.chamfr-single-product__cfrq-btn body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .chamfr-single-product__cfrq-btn input[type=submit], .chamfr-single-product__cfrq-btn .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .chamfr-single-product__cfrq-btn a, .chamfr-single-product__cfrq-btn .woocommerce #respond input#submit, .woocommerce #respond .chamfr-single-product__cfrq-btn input#submit,
.chamfr-single-product__cfrq-btn .woocommerce a.button,
.woocommerce .chamfr-single-product__cfrq-btn a.button,
.chamfr-single-product__cfrq-btn .woocommerce button.button,
.woocommerce .chamfr-single-product__cfrq-btn button.button,
.chamfr-single-product__cfrq-btn .woocommerce input.button,
.woocommerce .chamfr-single-product__cfrq-btn input.button, .chamfr-single-product__cfrq-btn .blog .sticky .post__link a, .blog .sticky .post__link .chamfr-single-product__cfrq-btn a,
.chamfr-single-product__cfrq-btn .archive .sticky .post__link a,
.archive .sticky .post__link .chamfr-single-product__cfrq-btn a, .chamfr-single-product__cfrq-btn .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .chamfr-single-product__cfrq-btn .website {
  padding: 8px 8px;
}
.chamfr-single-product__cfrq--mobile {
  margin: 0 -15px 6rem;
  border-radius: 0;
}
@media (min-width: 576px) {
  .chamfr-single-product__cfrq--mobile {
    display: none;
    margin: 0;
  }
}
.chamfr-single-product__related {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 1rem;
  padding-bottom: 8rem;
}
@media (min-width: 360px) {
  .chamfr-single-product__related {
    max-width: 360px;
  }
}
@media (min-width: 576px) {
  .chamfr-single-product__related {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .chamfr-single-product__related {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .chamfr-single-product__related {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .chamfr-single-product__related {
    max-width: 1140px;
  }
}
@media (min-width: 1425px) {
  .chamfr-single-product__related {
    max-width: 1360px;
  }
}
@media (min-width: 768px) {
  .chamfr-single-product__related {
    padding-bottom: 12rem;
  }
}
@media (min-width: 992px) {
  .chamfr-single-product__related {
    padding-top: 5rem;
  }
}
.chamfr-single-product__related.product-categories h3 {
  margin: 0 0 2rem;
}
@media (min-width: 576px) {
  .chamfr-single-product__related.product-categories h3 {
    margin: 0 0 3rem;
  }
}
.chamfr-single-product__related ul {
  list-style-type: none;
  margin: 0;
  padding: 2rem 0 0;
}
@media (min-width: 768px) {
  .chamfr-single-product__related ul {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-items: stretch;
  }
}
@media (min-width: 1200px) {
  .chamfr-single-product__related ul {
    padding: 5rem 5% 0;
  }
}
.chamfr-single-product__related ul li {
  width: 100%;
  padding: 0 5px;
}
@media (min-width: 576px) {
  .chamfr-single-product__related ul li {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .chamfr-single-product__related ul li {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    width: 33.333%;
    padding-bottom: 30px;
  }
}
.chamfr-single-product__related ul.slick-slider {
  margin: 0 -5px 3.5rem;
}
.chamfr-single-product__related ul .slick-slide {
  opacity: 0.5;
}
.chamfr-single-product__related ul .slick-slide.slick-active {
  opacity: 1;
}
.chamfr-single-product__related ul .slick-list {
  overflow: visible;
}
.chamfr-single-product__related .slick-related-cat__controls {
  margin: 0 -8px;
  text-align: right;
}
@media (min-width: 768px) {
  .chamfr-single-product__related .slick-related-cat__controls {
    display: none;
  }
}

.woocommerce div.product form.cart {
  margin: 0;
}

.woocommerce div.product p.price,
.woocommerce div.product span.price,
.woocommerce div.product .stock {
  color: #000;
}

.woocommerce div.product p.stock {
  font-size: inherit;
}

.woocommerce div.product form.cart div.quantity {
  float: none;
  margin: 0 0 4px;
}
.woocommerce div.product form.cart div.quantity input {
  width: 100%;
  text-align: left;
  padding: 3px 8px;
  height: auto;
  border-radius: 2px;
}

.tiered-pricing-wrapper {
  padding: 1.5rem;
  margin: 1.5rem;
  background: #f6f6f6;
  border-radius: 8px;
  color: #636363;
}
.tiered-pricing-wrapper h3 {
  font-size: 1.8rem;
  text-align: center;
  font-weight: bold;
  margin: 0px;
}
.tiered-pricing-wrapper table.tiered-pricing-table {
  border: none;
  width: 50%;
  margin: 0 auto;
  border-radius: 0px;
  border-collapse: collapse;
}
.tiered-pricing-wrapper table.tiered-pricing-table thead tr th {
  text-align: left;
  padding: 1rem 0px 0px 0px;
}
.tiered-pricing-wrapper table.tiered-pricing-table thead tr th.tiered-pricing-header {
  padding-left: 1rem;
}
.tiered-pricing-wrapper table.tiered-pricing-table thead tr th span {
  font-size: 1.4rem;
  border-bottom: 1px solid #636363;
}
.tiered-pricing-wrapper table.tiered-pricing-table tr {
  background-color: transparent;
}
.tiered-pricing-wrapper table.tiered-pricing-table tr.tiered-pricing--active {
  background-color: transparent !important;
}
.tiered-pricing-wrapper table.tiered-pricing-table tr.tiered-pricing--active td {
  background-color: transparent !important;
  color: #636363;
  border: none;
  padding: 1px;
}
.tiered-pricing-wrapper table.tiered-pricing-table tr td {
  background-color: transparent !important;
  color: #636363;
  border: none;
  padding: 1px;
}
.tiered-pricing-wrapper table.tiered-pricing-table tr td.volume-quantity {
  text-align: left;
}
.tiered-pricing-wrapper table.tiered-pricing-table tr td.volume-price {
  text-align: left;
  padding-left: 1rem;
}
.tiered-pricing-wrapper table.tiered-pricing-table tr td.volume-price .amount {
  background-color: transparent !important;
  color: #636363;
  transition: none;
}
.tiered-pricing-wrapper .note {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 0.5rem;
}

.woocommerce div.product form.cart .button {
  float: none;
  width: 100%;
}

.woocommerce div.product div.images,
.woocommerce-page div.product div.images,
.woocommerce #content div.product div.images,
.woocommerce-page #content div.product div.images {
  float: none;
  width: auto;
}

.woocommerce-product-gallery .flex-viewport {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
}
.woocommerce-product-gallery__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.woocommerce div.product div.images .woocommerce-product-gallery__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.woocommerce-product-gallery__trigger {
  display: none;
}

.woocommerce div.product div.images .flex-control-thumbs {
  margin-top: 2rem;
  margin-left: -1rem;
  margin-right: -1rem;
}
.woocommerce div.product div.images .flex-control-thumbs li {
  padding: 1rem;
}
.woocommerce div.product div.images .flex-control-thumbs li img {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #fff;
}
.woocommerce div.product div.images .flex-control-thumbs li img.flex-active {
  border-color: #9f325d;
}

.woocommerce-product-gallery.woocommerce-product-gallery--with-images.images {
  position: relative;
}

.download-pic {
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 200;
  width: 3rem;
  height: 3rem;
  background: #fff url(../images/download.svg) no-repeat center;
  background-size: 80%;
  cursor: pointer;
}

/* Price Tiers*/
.woocommerce table.shop_table.price-rules-table {
  margin: 0 0 3rem;
  border: none;
  border-collapse: collapse;
  border-top: 1px solid #b7b7b7;
  border-radius: 0;
}
.woocommerce table.shop_table.price-rules-table tr {
  background: transparent;
  border-bottom: 1px solid #b7b7b7;
}
.woocommerce table.shop_table.price-rules-table tr.price-rule-active {
  border: none;
}
.woocommerce table.shop_table.price-rules-table th,
.woocommerce table.shop_table.price-rules-table td {
  padding: 9px 12px;
  border: none;
  font-weight: 400;
  line-height: 1.5;
}
.woocommerce table.shop_table.price-rules-table th {
  font-weight: 500;
}

@media print {
  .single-product .page-header,
.single-product .page-footer,
.single-product form.cart,
.single-product .contact-seller,
.single-product .chamfr-single-product__related .btn,
.single-product .chamfr-single-product__related .woocommerce-MyAccount-content .stripe-connect.light-blue,
.woocommerce-MyAccount-content .single-product .chamfr-single-product__related .stripe-connect.light-blue,
.single-product .chamfr-single-product__related .wcv-grid .chamfr-vendor-dashboard-content .wcv-button,
.wcv-grid .chamfr-vendor-dashboard-content .single-product .chamfr-single-product__related .wcv-button,
.single-product .chamfr-single-product__related .wcv-grid .chamfr-vendor-dashboard-content .button,
.wcv-grid .chamfr-vendor-dashboard-content .single-product .chamfr-single-product__related .button,
.single-product .chamfr-single-product__related .wp-block-button__link,
.single-product .chamfr-single-product__related body .gform_wrapper .gform_footer input.button,
body .gform_wrapper .gform_footer .single-product .chamfr-single-product__related input.button,
.single-product .chamfr-single-product__related body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .single-product .chamfr-single-product__related input[type=submit],
.single-product .chamfr-single-product__related body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .single-product .chamfr-single-product__related input.button,
.single-product .chamfr-single-product__related body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .single-product .chamfr-single-product__related input[type=submit],
.single-product .chamfr-single-product__related .woocommerce-order-details-table + p a,
.woocommerce-order-details-table + p .single-product .chamfr-single-product__related a,
.single-product .chamfr-single-product__related .woocommerce #respond input#submit,
.woocommerce #respond .single-product .chamfr-single-product__related input#submit,
.single-product .chamfr-single-product__related .woocommerce a.button,
.woocommerce .single-product .chamfr-single-product__related a.button,
.single-product .chamfr-single-product__related .woocommerce button.button,
.woocommerce .single-product .chamfr-single-product__related button.button,
.single-product .chamfr-single-product__related .woocommerce input.button,
.woocommerce .single-product .chamfr-single-product__related input.button,
.single-product .chamfr-single-product__related .blog .sticky .post__link a,
.blog .sticky .post__link .single-product .chamfr-single-product__related a,
.single-product .chamfr-single-product__related .archive .sticky .post__link a,
.archive .sticky .post__link .single-product .chamfr-single-product__related a,
.single-product .chamfr-single-product__related .chamfr-service-partners-featured .summary .website,
.chamfr-service-partners-featured .summary .single-product .chamfr-single-product__related .website,
.single-product .product-category__link,
.single-product .helper-cta,
.single-product .download-pic {
    display: none;
  }
  .single-product .chamfr-single-product {
    padding: 2rem;
  }
  .single-product .price-rule-active td {
    background-color: transparent !important;
    font-weight: 700 !important;
    color: #000 !important;
  }
  .single-product .price-rule-active .amount {
    color: #000 !important;
  }
  .single-product .flex-control-thumbs {
    page-break-inside: avoid;
  }
  .single-product .flex-control-thumbs li {
    page-break-inside: avoid;
    page-break-before: avoid;
    page-break-after: avoid;
  }
  .single-product .chamfr-single-product__meta,
.single-product .chamfr-single-product__cart,
.single-product .chamfr-single-product__related {
    page-break-inside: avoid;
  }
}
.single__container {
  position: relative;
  max-width: 70rem;
  margin: 5rem auto 0;
}
.single__container h1 {
  font-size: 4.6rem;
}
.single__container h2 {
  font-size: 3.8rem;
}
.single__container h3 {
  font-size: 3.2rem;
}
.single__container h4 {
  font-size: 2.4rem;
}
.single__container h5 {
  font-size: 2rem;
}
.single__container h6 {
  font-size: 1.8rem;
}
.single__container p {
  font-size: 1.6rem;
}
@media (max-width: 991.98px) {
  .single__container h1 {
    font-size: 3.6rem;
  }
  .single__container h2 {
    font-size: 3rem;
  }
  .single__container h3 {
    font-size: 2.6rem;
  }
  .single__container h4 {
    font-size: 2.2rem;
  }
  .single__container h5 {
    font-size: 1.8rem;
  }
  .single__container h6 {
    font-size: 1.6rem;
  }
  .single__container p {
    font-size: 1.4rem;
  }
}
.single__container ul,
.single__container ol {
  margin: 0 0 2rem;
  padding: 0 0 0 4rem;
}
.single__container ul li,
.single__container ol li {
  margin: 0;
}
.single__categories {
  margin: 0 0 0.7rem;
  font-size: 1.4rem;
  font-weight: 600;
  opacity: 0.5;
}
.single__categories a {
  color: inherit;
}
.single__meta {
  display: flex;
  align-items: stretch;
  margin: 0 0 2.5rem;
}
.single__meta__info {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-left: 1.5rem;
}
.single__meta__author {
  margin: 0 0 0.7rem;
}
.single__meta__date {
  opacity: 0.5;
}
.single__avatar {
  overflow: hidden;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
}
.single__avatar img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.single__bottom {
  margin: 5rem 0 0;
  padding: 3rem 0 0;
  border-top: 1px solid #6b6b6b;
}
.single__author {
  display: flex;
  align-items: stretch;
  margin: 0 0 5.5rem;
}
.single__author__info {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-left: 1.5rem;
}
.single__author__name {
  font-size: 1.4rem;
  margin: 0 0 0.5rem;
}
.single__author__bio {
  font-size: 1.4rem;
  opacity: 0.5;
}

.thank-you {
  margin: 0 0 10rem;
}
@media (min-width: 992px) {
  .thank-you {
    margin: 3rem 0 18rem;
  }
}
@media (min-width: 1425px) {
  .thank-you {
    margin: 3rem 0 26rem;
  }
}
.thank-you__buttons {
  margin-top: 2rem;
  text-align: center;
}
@media (min-width: 576px) {
  .thank-you__buttons {
    text-align: inherit;
  }
}
@media (min-width: 992px) {
  .thank-you__buttons {
    margin-top: 5rem;
  }
}
.thank-you__buttons .btn, .thank-you__buttons .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .thank-you__buttons .stripe-connect.light-blue, .thank-you__buttons .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .thank-you__buttons .wcv-button, .thank-you__buttons .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .thank-you__buttons .button, .thank-you__buttons .wp-block-button__link, .thank-you__buttons body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .thank-you__buttons input.button,
.thank-you__buttons body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .thank-you__buttons input[type=submit],
.thank-you__buttons body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .thank-you__buttons input.button,
.thank-you__buttons body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .thank-you__buttons input[type=submit], .thank-you__buttons .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .thank-you__buttons a, .thank-you__buttons .woocommerce #respond input#submit, .woocommerce #respond .thank-you__buttons input#submit,
.thank-you__buttons .woocommerce a.button,
.woocommerce .thank-you__buttons a.button,
.thank-you__buttons .woocommerce button.button,
.woocommerce .thank-you__buttons button.button,
.thank-you__buttons .woocommerce input.button,
.woocommerce .thank-you__buttons input.button, .thank-you__buttons .blog .sticky .post__link a, .blog .sticky .post__link .thank-you__buttons a,
.thank-you__buttons .archive .sticky .post__link a,
.archive .sticky .post__link .thank-you__buttons a, .thank-you__buttons .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .thank-you__buttons .website {
  width: 22.5rem;
  margin-bottom: 1.5rem;
}
@media (min-width: 576px) {
  .thank-you__buttons .btn, .thank-you__buttons .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .thank-you__buttons .stripe-connect.light-blue, .thank-you__buttons .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .thank-you__buttons .wcv-button, .thank-you__buttons .wcv-grid .chamfr-vendor-dashboard-content .button, .wcv-grid .chamfr-vendor-dashboard-content .thank-you__buttons .button, .thank-you__buttons .wp-block-button__link, .thank-you__buttons body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .thank-you__buttons input.button,
.thank-you__buttons body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .thank-you__buttons input[type=submit],
.thank-you__buttons body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .thank-you__buttons input.button,
.thank-you__buttons body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .thank-you__buttons input[type=submit], .thank-you__buttons .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .thank-you__buttons a, .thank-you__buttons .woocommerce #respond input#submit, .woocommerce #respond .thank-you__buttons input#submit,
.thank-you__buttons .woocommerce a.button,
.woocommerce .thank-you__buttons a.button,
.thank-you__buttons .woocommerce button.button,
.woocommerce .thank-you__buttons button.button,
.thank-you__buttons .woocommerce input.button,
.woocommerce .thank-you__buttons input.button, .thank-you__buttons .blog .sticky .post__link a, .blog .sticky .post__link .thank-you__buttons a,
.thank-you__buttons .archive .sticky .post__link a,
.archive .sticky .post__link .thank-you__buttons a, .thank-you__buttons .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .thank-you__buttons .website {
    width: auto;
    margin-right: 1.6rem;
  }
  .thank-you__buttons .btn:last-child, .thank-you__buttons .woocommerce-MyAccount-content .stripe-connect.light-blue:last-child, .woocommerce-MyAccount-content .thank-you__buttons .stripe-connect.light-blue:last-child, .thank-you__buttons .wcv-grid .chamfr-vendor-dashboard-content .wcv-button:last-child, .wcv-grid .chamfr-vendor-dashboard-content .thank-you__buttons .wcv-button:last-child, .thank-you__buttons .wcv-grid .chamfr-vendor-dashboard-content .button:last-child, .wcv-grid .chamfr-vendor-dashboard-content .thank-you__buttons .button:last-child, .thank-you__buttons .wp-block-button__link:last-child, .thank-you__buttons body .gform_wrapper .gform_footer input.button:last-child, body .gform_wrapper .gform_footer .thank-you__buttons input.button:last-child,
.thank-you__buttons body .gform_wrapper .gform_footer input[type=submit]:last-child,
body .gform_wrapper .gform_footer .thank-you__buttons input[type=submit]:last-child,
.thank-you__buttons body .gform_wrapper .gform_page_footer input.button:last-child,
body .gform_wrapper .gform_page_footer .thank-you__buttons input.button:last-child,
.thank-you__buttons body .gform_wrapper .gform_page_footer input[type=submit]:last-child,
body .gform_wrapper .gform_page_footer .thank-you__buttons input[type=submit]:last-child, .thank-you__buttons .woocommerce-order-details-table + p a:last-child, .woocommerce-order-details-table + p .thank-you__buttons a:last-child, .thank-you__buttons .woocommerce #respond input#submit:last-child, .woocommerce #respond .thank-you__buttons input#submit:last-child,
.thank-you__buttons .woocommerce a.button:last-child,
.woocommerce .thank-you__buttons a.button:last-child,
.thank-you__buttons .woocommerce button.button:last-child,
.woocommerce .thank-you__buttons button.button:last-child,
.thank-you__buttons .woocommerce input.button:last-child,
.woocommerce .thank-you__buttons input.button:last-child, .thank-you__buttons .blog .sticky .post__link a:last-child, .blog .sticky .post__link .thank-you__buttons a:last-child,
.thank-you__buttons .archive .sticky .post__link a:last-child,
.archive .sticky .post__link .thank-you__buttons a:last-child, .thank-you__buttons .chamfr-service-partners-featured .summary .website:last-child, .chamfr-service-partners-featured .summary .thank-you__buttons .website:last-child {
    margin-right: 0;
  }
}

body.woocommerce-order-received:not(.logged-in) .ced_my_account_reorder {
  display: none;
}

body.vendor-shop .wcv-header-container {
  border-radius: 1rem 1rem 0rem 0rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}
body.vendor-shop .wcv-header-container .wcv-store-header {
  background: radial-gradient(circle, rgb(255, 255, 255) 0%, rgb(221, 221, 221) 52%);
}
body.vendor-shop .wcv-header-container .wcv-store-header #banner-wrap .wcv-store-banner:not([width="1"]) {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: none;
  min-height: 0;
}
body.vendor-shop .wcv-header-container .wcv-store-header #banner-wrap #inner-element {
  background: transparent;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
}
@media screen and (max-width: 767px) {
  body.vendor-shop .wcv-header-container .wcv-store-header #banner-wrap #inner-element {
    width: 100%;
    left: 0;
    right: 0;
    max-width: 100%;
    padding: 0px 10px;
  }
}
body.vendor-shop .wcv-header-container .wcv-store-header #banner-wrap #inner-element .store-brand .store-icon {
  width: auto;
  border-radius: unset;
}
@media screen and (max-width: 767px) {
  body.vendor-shop .wcv-header-container .wcv-store-header #banner-wrap #inner-element .store-brand {
    width: 100%;
    padding: 0 !important;
  }
  body.vendor-shop .wcv-header-container .wcv-store-header #banner-wrap #inner-element .store-brand .store-icon {
    margin: 2rem auto 0;
    display: block;
    float: none;
    height: auto;
  }
}
body.vendor-shop .wcv-header-container .wcv-store-header #banner-wrap #inner-element .store-info {
  padding-left: 8%;
}
body.vendor-shop .wcv-header-container .wcv-store-header #banner-wrap #inner-element .store-info h3 {
  text-align: left;
}
body.vendor-shop .wcv-header-container .wcv-store-header #banner-wrap #inner-element .store-info p {
  text-align: left;
}
@media screen and (max-width: 767px) {
  body.vendor-shop .wcv-header-container .wcv-store-header #banner-wrap #inner-element .store-info {
    width: 100%;
    padding: 0;
  }
  body.vendor-shop .wcv-header-container .wcv-store-header #banner-wrap #inner-element .store-info p {
    padding-bottom: 10px;
  }
}
body.vendor-shop .wcv-store-address-container {
  border-radius: 0rem 0rem 1rem 1rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  body.vendor-shop .wcv-store-address-container .store-address {
    text-align: center;
    padding: 0;
  }
}
body.vendor-shop .wcv-store-address-container .store-phone a {
  display: flex;
  align-items: center;
  justify-content: center;
}
body.vendor-shop .wcv-store-address-container .store-phone a .wcv-icon {
  transform: rotate(90deg);
  width: 10px;
  margin-right: 0.5rem;
}

.wcv-header-container #banner-wrap img.wcv-store-banner[width="1"] {
  width: 1px !important;
  height: 1px !important;
  min-height: 0 !important;
}

.wcv-header-container #banner-wrap img.wcv-store-banner:not([width="1"]) {
  width: 100%;
  height: 100%;
}

.columns #ais-facets {
  width: 22.9%;
}
@media (max-width: 1199.98px) {
  .columns #ais-facets {
    width: 100%;
  }
}
.columns #ais-facets .chamfr-vendor-adv-wrapper {
  text-align: center;
  margin-bottom: 3rem;
}
.columns #ais-facets .chamfr-vendor-adv-wrapper .chamfr-vendor-adv {
  margin-top: 3rem;
  border: none;
  width: 100%;
  display: block;
  min-height: 100px;
}
@media screen and (max-width: 767px) {
  .columns #ais-facets .chamfr-vendor-adv-wrapper .chamfr-vendor-adv {
    min-height: 100px;
  }
}

.chamfr-vendor-adv-bottom-wrapper {
  width: 100%;
  margin-bottom: 1rem;
}
.chamfr-vendor-adv-bottom-wrapper .chamfr-vendor-adv {
  margin-top: 3rem;
  border: none;
  width: 100%;
  display: block;
  min-height: 100px;
  text-align: center;
}

#chamfr-vendors-dashboard-analytics .chamfr-analytics-section {
  margin: 3rem 0;
  padding: 2rem;
  border-radius: 10px;
  -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.25);
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_header h4 {
  margin: 0;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_numbers {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
@media screen and (max-width: 767px) {
  #chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_numbers {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_numbers_item {
  background-color: #eaeaea;
  flex-basis: 0;
  flex-grow: 1;
  min-height: 12rem;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  padding: 2rem;
  text-align: center;
  margin: 0 2rem;
}
.chamfr-vendor-product #chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_numbers_item {
  cursor: pointer;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_numbers_item:hover {
  background-color: #ffffff;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_numbers_item.loading {
  position: relative;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_numbers_item.loading:before {
  z-index: 2;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  background-size: 200% 100%;
  background-position: -100% 0;
  animation: chamfr-analytics-loading 1.5s infinite;
}
@media screen and (max-width: 767px) {
  #chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_numbers_item {
    margin: 1rem 0;
  }
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_numbers_item h5 {
  margin: 0;
  font-size: 16px;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_numbers_item p {
  margin-top: 1rem;
  font-size: 40px;
  font-weight: bold;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_tables_item, #chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_oos_tables_item {
  min-height: 12rem;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  padding: 1rem 0;
  text-align: center;
  margin: 1rem 0;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_tables_item.loading, #chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_oos_tables_item.loading {
  position: relative;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_tables_item.loading:before, #chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_oos_tables_item.loading:before {
  z-index: 2;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
  background-size: 200% 100%;
  background-position: -100% 0;
  animation: chamfr-analytics-loading 1.5s infinite;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_tables_item h5, #chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_oos_tables_item h5 {
  position: relative;
  margin: 0.5rem 1rem;
  text-align: left;
  font-size: 18px;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_tables_item table, #chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_oos_tables_item table {
  position: relative;
  z-index: 1;
  font-size: 14px;
  border-bottom: 1px solid #808080;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_tables_item table thead, #chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_oos_tables_item table thead {
  background-color: #eaeaea;
  border-top: 1px solid #808080;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_tables_item table thead tr th, #chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_oos_tables_item table thead tr th {
  font-weight: bold;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_tables_item table thead tr th:first-child, #chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_oos_tables_item table thead tr th:first-child {
  width: 70%;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_tables_item table thead tr th:last-child, #chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_oos_tables_item table thead tr th:last-child {
  width: 15%;
}
#chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_tables_item table tr:hover, #chamfr-vendors-dashboard-analytics .chamfr-analytics-section_wrapper_oos_tables_item table tr:hover {
  background-color: #e0e0e0;
}

@keyframes chamfr-analytics-loading {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.wcv-grid .chamfr-vendor-dashboard-content .button {
  color: #fff !important;
}
.wcv-grid .chamfr-vendor-dashboard-content .btn, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-MyAccount-content .stripe-connect.light-blue, .woocommerce-MyAccount-content .wcv-grid .chamfr-vendor-dashboard-content .stripe-connect.light-blue, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .wp-block-button__link, .wcv-grid .chamfr-vendor-dashboard-content body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .wcv-grid .chamfr-vendor-dashboard-content input.button,
.wcv-grid .chamfr-vendor-dashboard-content body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .wcv-grid .chamfr-vendor-dashboard-content input[type=submit],
.wcv-grid .chamfr-vendor-dashboard-content body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .wcv-grid .chamfr-vendor-dashboard-content input.button,
.wcv-grid .chamfr-vendor-dashboard-content body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .wcv-grid .chamfr-vendor-dashboard-content input[type=submit], .wcv-grid .chamfr-vendor-dashboard-content .woocommerce-order-details-table + p a, .woocommerce-order-details-table + p .wcv-grid .chamfr-vendor-dashboard-content a, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce #respond input#submit, .woocommerce #respond .wcv-grid .chamfr-vendor-dashboard-content input#submit,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.button,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.button,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.button,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.button,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.button,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.button, .wcv-grid .chamfr-vendor-dashboard-content .blog .sticky .post__link a, .blog .sticky .post__link .wcv-grid .chamfr-vendor-dashboard-content a,
.wcv-grid .chamfr-vendor-dashboard-content .archive .sticky .post__link a,
.archive .sticky .post__link .wcv-grid .chamfr-vendor-dashboard-content a, .wcv-grid .chamfr-vendor-dashboard-content .chamfr-service-partners-featured .summary .website, .chamfr-service-partners-featured .summary .wcv-grid .chamfr-vendor-dashboard-content .website, .wcv-grid .chamfr-vendor-dashboard-content .button {
  text-decoration: none;
  color: #fff;
}
.wcv-grid .chamfr-vendor-dashboard-content .btn-secondary, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce #respond input#submit.alt, .woocommerce #respond .wcv-grid .chamfr-vendor-dashboard-content input#submit.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.button.alt {
  color: #e11d73;
}
.wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .woocommerce #respond input.wcv-button#submit.alt, .woocommerce #respond .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button#submit.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt {
  color: #e11d73 !important;
}
.wcv-grid .chamfr-vendor-dashboard-content .wcv-search {
  overflow: hidden;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 767.98px) {
  .wcv-grid .chamfr-vendor-dashboard-content .all-40 {
    width: 100%;
  }
}
.wcv-grid .chamfr-vendor-dashboard-content .wcvendors-table-recent_product img.size-50x50 {
  max-width: 50px;
}

.chamfr-vendor-product .search-title-pagination:last-of-type .wcv_search_results, .chamfr-vendor-order .search-title-pagination:last-of-type .wcv_search_results {
  display: none;
}

.wcv-usage-stats {
  margin-bottom: 3rem;
}

.woocommerce-MyAccount-content .stripe-connect.light-blue {
  height: auto;
  padding: 1.6rem 3rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}
.woocommerce-MyAccount-content .stripe-connect.light-blue span {
  font: 600 1.6rem/5rem "Public Sans", sans-serif;
  line-height: 1.2;
  text-shadow: none;
  color: #fff;
  display: inline;
  height: auto;
  padding: 0;
  background: transparent;
  box-shadow: none;
}
.woocommerce-MyAccount-content .stripe-connect.light-blue span:before {
  display: none;
}

/* WC Vendors overrides */
.wcv-header-container {
  flex: 0 0 100%;
}
.wcv-navigation ul.menu.black {
  background-color: transparent;
}
.wcv-navigation ul.menu.black li.active {
  background-color: transparent;
}
.wcv-navigation ul.menu.horizontal > li a {
  display: inline-block;
}
.wcv-navigation ul.menu.horizontal > li:first-child a, .wcv-navigation ul.menu.horizontal > li:last-child a {
  border-radius: 5px;
}
.wcv_dashboard_datepicker .all-33 .control-group {
  padding-left: 2em;
}
@media (max-width: 359.98px) {
  .wcv_dashboard_datepicker .all-33 {
    width: 100%;
  }
  .wcv_dashboard_datepicker .all-33 .control-group {
    padding-left: 0;
  }
}
@media (max-width: 767.98px) {
  .wcv_dashboard_datepicker .tiny-50,
.wcv_dashboard_datepicker .tiny-100 {
    width: 100%;
  }
}
.wcv-search-form input:not([type=submit]),
.wcv-search-form select,
.wcv-search-form textarea {
  width: auto;
}
.wcv-search-form .control.append-button {
  display: flex;
}
.wcv-search-form .control.append-button > span {
  display: block;
  flex: 1 1 100%;
  margin: 0 2rem 0 0;
}
.wcv-search-form .control.append-button > span input {
  width: 100%;
}
.wcv-form input[type=checkbox],
.wcv-form input[type=radio] {
  display: none !important;
}
.wcv-form-exclude .tiny-50 + .control-group .wcv-cols-group.wcv-horizontal-gutters {
  margin-left: 0;
}
.wcv-order-header .align-right {
  padding-top: 10px;
}
.wcv-modal > .modal-header {
  display: block;
  position: relative;
}
.wcv-modal > .modal-header > .modal-close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  background: #9f325d;
  opacity: 1;
  text-shadow: none;
}
.wcv-modal > .modal-header > .modal-close:before {
  content: "✕";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
  line-height: 1;
  color: #fff;
}
.wcv-modal > .modal-header > .modal-close .wcv-icon {
  display: none;
}
.wcv-modal > .modal-header h3 {
  margin-bottom: 1rem;
}
.wcv-modal > .modal-header > .modal-print {
  color: #9f325d;
}
.wcv-modal > .modal-footer,
.wcv-modal > .modal-header,
.wcv-modal > footer,
.wcv-modal > header {
  padding: 1rem 2rem 2rem;
}
@media (min-width: 768px) {
  .wcv-modal > .modal-footer,
.wcv-modal > .modal-header,
.wcv-modal > footer,
.wcv-modal > header {
    padding: 1rem 3rem 3rem;
  }
}
.wcv-modal > .modal-body {
  padding: 2rem 2rem 4rem;
}
@media (min-width: 768px) {
  .wcv-modal > .modal-body {
    padding: 3rem 3rem 5rem;
  }
}
.wcv-modal .wcv-order-customer-details.wcv-cols-group.wcv-horizontal-gutters {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  margin: 0;
}
.wcv-modal .wcv-order-customer-details.wcv-cols-group.wcv-horizontal-gutters + .wcv-cols-group.wcv-horizontal-gutters {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  margin: 0 !important;
}
.wcv-modal .wcv-order-customer-details.wcv-cols-group.wcv-horizontal-gutters + .wcv-cols-group.wcv-horizontal-gutters .all-100 {
  padding: 0 !important;
}

.wcv-grid input.btn, .wcv-grid input.wp-block-button__link, .wcv-grid body .gform_wrapper .gform_footer input.button, body .gform_wrapper .gform_footer .wcv-grid input.button,
.wcv-grid body .gform_wrapper .gform_footer input[type=submit],
body .gform_wrapper .gform_footer .wcv-grid input[type=submit],
.wcv-grid body .gform_wrapper .gform_page_footer input.button,
body .gform_wrapper .gform_page_footer .wcv-grid input.button,
.wcv-grid body .gform_wrapper .gform_page_footer input[type=submit],
body .gform_wrapper .gform_page_footer .wcv-grid input[type=submit], .wcv-grid .woocommerce #respond input#submit, .woocommerce #respond .wcv-grid input#submit,
.wcv-grid .woocommerce input.button,
.woocommerce .wcv-grid input.button, .wcv-grid .chamfr-service-partners-featured .summary input.website, .chamfr-service-partners-featured .summary .wcv-grid input.website, .wcv-grid .chamfr-vendor-dashboard-content input.button, .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search input.wcv-button, .wcv-grid .woocommerce-MyAccount-content input.stripe-connect.light-blue, .woocommerce-MyAccount-content .wcv-grid input.stripe-connect.light-blue {
  color: #fff;
}

.wcv_dashboard_table_header.wcv-order-header .align-right {
  padding-top: 25px;
}
.wcv_dashboard_table_header.wcv-order-header .align-right br {
  display: none;
}
.wcv_dashboard_table_header .wcv-form .control-group {
  line-height: 1.2;
  margin-bottom: 2rem;
}
.wcv_dashboard_table_header .wcv-form label {
  height: 17px;
}
.wcv_dashboard_table_header .all-20 {
  width: 100%;
}
@media (min-width: 1425px) {
  .wcv_dashboard_table_header .all-20 {
    width: 25%;
    padding-left: 2rem;
  }
}
.wcv_dashboard_table_header .all-20.align-right {
  text-align: left;
}
@media (min-width: 1425px) {
  .wcv_dashboard_table_header .all-20.align-right {
    text-align: right;
  }
}
.wcv_dashboard_table_header .all-80 {
  width: 100%;
}
@media (min-width: 1425px) {
  .wcv_dashboard_table_header .all-80 {
    width: 75%;
  }
}
@media (max-width: 767.98px) {
  .wcv_dashboard_table_header .tiny-100 {
    width: 100%;
  }
}
.wcv_dashboard_table_header .btn-secondary, .wcv_dashboard_table_header .woocommerce #respond input#submit.alt, .woocommerce #respond .wcv_dashboard_table_header input#submit.alt,
.wcv_dashboard_table_header .woocommerce a.button.alt,
.woocommerce .wcv_dashboard_table_header a.button.alt,
.wcv_dashboard_table_header .woocommerce button.button.alt,
.woocommerce .wcv_dashboard_table_header button.button.alt,
.wcv_dashboard_table_header .woocommerce input.button.alt,
.woocommerce .wcv_dashboard_table_header input.button.alt, .wcv_dashboard_table_header .wcv-grid .chamfr-vendor-dashboard-content .wcv-button.btn-secondary, .wcv-grid .chamfr-vendor-dashboard-content .wcv_dashboard_table_header .wcv-button.btn-secondary,
.wcv_dashboard_table_header .wcv-grid .chamfr-vendor-dashboard-content .woocommerce a.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce .wcv_dashboard_table_header a.wcv-button.button.alt,
.wcv_dashboard_table_header .woocommerce .wcv-grid .chamfr-vendor-dashboard-content a.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content .wcv_dashboard_table_header a.wcv-button.button.alt,
.wcv_dashboard_table_header .wcv-grid .chamfr-vendor-dashboard-content .woocommerce button.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce .wcv_dashboard_table_header button.wcv-button.button.alt,
.wcv_dashboard_table_header .woocommerce .wcv-grid .chamfr-vendor-dashboard-content button.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content .wcv_dashboard_table_header button.wcv-button.button.alt,
.wcv_dashboard_table_header .wcv-grid .chamfr-vendor-dashboard-content .woocommerce input.wcv-button.button.alt,
.wcv-grid .chamfr-vendor-dashboard-content .woocommerce .wcv_dashboard_table_header input.wcv-button.button.alt,
.wcv_dashboard_table_header .woocommerce .wcv-grid .chamfr-vendor-dashboard-content input.wcv-button.button.alt,
.woocommerce .wcv-grid .chamfr-vendor-dashboard-content .wcv_dashboard_table_header input.wcv-button.button.alt, .wcv_dashboard_table_header .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv-button, .wcv-grid .chamfr-vendor-dashboard-content .wcv-search .wcv_dashboard_table_header .wcv-button {
  border: none !important;
}
.wcv_dashboard_table_header + .wcv-cols-group.wcv-horizontal-gutters {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  margin: 0 !important;
  padding-top: 3rem;
}
.wcv_dashboard_table_header + .wcv-cols-group.wcv-horizontal-gutters .all-100 {
  padding: 0 !important;
}

table.wcv-table {
  background-color: transparent;
}
table.wcv-table tr th {
  font-weight: 500;
}

.wcv-grid h1,
.wcv-grid h2,
.wcv-grid h3,
.wcv-grid h4,
.wcv-grid h5,
.wcv-grid h6 {
  font-weight: 500;
  line-height: 1.2;
  color: #313131;
  margin: 3rem 0 2rem;
}
@media (max-width: 767.98px) {
  .wcv-grid h1,
.wcv-grid h2,
.wcv-grid h3,
.wcv-grid h4,
.wcv-grid h5,
.wcv-grid h6 {
    margin-bottom: 1rem;
  }
}

.wcv-grid h1:first-child,
.wcv-grid h2:first-child,
.wcv-grid h3:first-child,
.wcv-grid h4:first-child,
.wcv-grid h5:first-child,
.wcv-grid h6:first-child,
.wcv-grid p:first-child {
  margin-top: 3rem;
}

.wcv-grid h1 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 24px;
  font-weight: 800;
}
@media (min-width: 576px) {
  .wcv-grid h1 {
    font-size: 38px;
  }
}

.wcv-grid h2 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 20px;
  font-weight: 700;
}
@media (min-width: 576px) {
  .wcv-grid h2 {
    font-size: 24px;
  }
}

.wcv-grid h3 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 18px;
  font-weight: 700;
}
@media (min-width: 576px) {
  .wcv-grid h3 {
    font-size: 20px;
  }
}

.wcv-grid h4 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 2.2rem;
}
@media (min-width: 576px) {
  .wcv-grid h4 {
    font-size: 2.6rem;
  }
}

.wcv-grid h5 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
}
@media (min-width: 576px) {
  .wcv-grid h5 {
    font-size: 2.2rem;
  }
}

.wcv-grid h6 {
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
}
@media (min-width: 576px) {
  .wcv-grid h6 {
    font-size: 2.2rem;
  }
}

.wcv-grid a:hover {
  color: #e11d73;
}

.wcv-form input[type=color],
.wcv-form input[type=date],
.wcv-form input[type=datetime-local],
.wcv-form input[type=datetime],
.wcv-form input[type=email],
.wcv-form input[type=month],
.wcv-form input[type=number],
.wcv-form input[type=password],
.wcv-form input[type=search],
.wcv-form input[type=tel],
.wcv-form input[type=text],
.wcv-form input[type=time],
.wcv-form input[type=url],
.wcv-form input[type=week],
.wcv-form select,
.wcv-form textarea {
  padding: 1.5rem;
}

@media screen and (min-width: 1261px) {
  .wcv-cols-group.wcv-horizontal-gutters {
    margin-left: -2rem;
  }

  .wcv-cols-group.wcv-horizontal-gutters > [class*=all-],
.wcv-cols-group.wcv-horizontal-gutters > [class*=xlarge-] {
    padding-left: 2rem;
  }
}
@media screen and (max-width: 1260px) and (min-width: 961px) {
  .wcv-cols-group.wcv-horizontal-gutters {
    margin-left: -2rem;
  }

  .wcv-cols-group.wcv-horizontal-gutters > [class*=all-],
.wcv-cols-group.wcv-horizontal-gutters > [class*=xlarge-] {
    padding-left: 2rem;
  }
}
.wcv-form .control-group .inline li label {
  margin-right: 0;
}

.wcv-form .control-group input[type=checkbox] + label,
.wcv-form .control-group input[type=radio] + label {
  margin: 0;
  padding: 0;
}

.wcv-tabs .tabs-nav li a {
  display: block;
  padding: 1.3rem 2rem;
  border: 1px solid #959595;
  border-radius: 5px;
  opacity: 0.5;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  color: #959595;
}
.wcv-tabs .tabs-nav li.active a {
  background: transparent;
  border: 1px solid #959595;
  opacity: 1;
  text-decoration: none;
  color: #959595;
  cursor: default;
}
.wcv-tabs.top > .tabs-nav {
  border-bottom: none;
}
.wcv-tabs.top > .tabs-nav li {
  display: inline-block;
  float: none;
}
.wcv-tabs.top > .tabs-nav li a {
  margin: 0 1rem 1rem 0;
  border-radius: 5px;
}
.wcv-tabs.top > .tabs-nav li.active a {
  margin: 0 1rem 1rem 0;
  border: 1px solid #959595;
}

.media-modal-content .attachments-browser .media-toolbar {
  height: 90px;
}
.media-modal-content .wp-core-ui .button-primary {
  font: 600 1.6rem/5rem "Public Sans", sans-serif;
  text-transform: uppercase;
  color: #fff !important;
  background: #e11d73 !important;
  border-color: #e11d73 !important;
  box-shadow: none !important;
  text-shadow: none !important;
}
.media-modal-content .wp-core-ui .button-primary-disabled,
.media-modal-content .wp-core-ui .button-primary.disabled,
.media-modal-content .wp-core-ui .button-primary:disabled,
.media-modal-content .wp-core-ui .button-primary[disabled] {
  color: #eaeaea !important;
  background: #9f325d !important;
  border-color: #9f325d !important;
  box-shadow: none !important;
  text-shadow: none !important;
}
.media-modal-content .wp-core-ui .button,
.media-modal-content .wp-core-ui .button-secondary {
  font: 600 1.6rem/5rem "Public Sans", sans-serif;
  text-transform: uppercase;
  color: #e11d73;
  border: 2px solid #e11d73;
  background: transparent;
  vertical-align: top;
}
.media-modal-content .wp-core-ui .button:hover, .media-modal-content .wp-core-ui .button:focus, .media-modal-content .wp-core-ui .button:active,
.media-modal-content .wp-core-ui .button-secondary:hover,
.media-modal-content .wp-core-ui .button-secondary:focus,
.media-modal-content .wp-core-ui .button-secondary:active {
  color: #e11d73;
  border: 2px solid #e11d73;
  background: transparent;
}
.media-modal-content .media-frame-toolbar .media-toolbar {
  background: #fff;
}

.wcv-tabs .tabs-content {
  margin-bottom: 2rem;
}

.wcv-store-msg {
  background: #9f325d;
}

.wcv-grid .chamfr-vendor-product .all-50, .wcv-grid .chamfr-vendor-order .all-50 {
  float: none;
  width: 100%;
  margin: 0 0 2rem;
}
@media (min-width: 1200px) {
  .wcv-grid .chamfr-vendor-product .all-50, .wcv-grid .chamfr-vendor-order .all-50 {
    float: left;
    width: 50%;
  }
}
.wcv-grid .chamfr-vendor-product h4, .wcv-grid .chamfr-vendor-order h4 {
  font-size: 2rem;
  margin: 0 0 1rem;
}
.wcv-grid .chamfr-vendor-product .wcv_search_results, .wcv-grid .chamfr-vendor-order .wcv_search_results {
  font-style: normal;
  font-family: "Public Sans", sans-serif;
  font-weight: 500;
  font-size: 2.2rem;
}
@media (min-width: 576px) {
  .wcv-grid .chamfr-vendor-product .wcv_search_results, .wcv-grid .chamfr-vendor-order .wcv_search_results {
    font-size: 2.6rem;
  }
}

.wcvendors-table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  margin: 3rem 0;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.25);
}
.wcvendors-table-wrapper .wcvendors-table {
  border-collapse: collapse;
  width: 100%;
  min-width: 50rem;
  margin: 0;
  font-size: 1.4rem;
}
.wcvendors-table-wrapper .wcvendors-table-product tr th:first-child,
.wcvendors-table-wrapper .wcvendors-table-product tr td:first-child {
  display: none;
}
@media (min-width: 992px) {
  .wcvendors-table-wrapper .wcvendors-table-product tr th:first-child,
.wcvendors-table-wrapper .wcvendors-table-product tr td:first-child {
    display: table-cell;
  }
}
.wcvendors-table-wrapper .wcvendors-table-product img {
  display: none;
}
@media (min-width: 992px) {
  .wcvendors-table-wrapper .wcvendors-table-product img {
    display: block;
  }
}
.wcvendors-table-wrapper .wcvendors-table-order tr td:nth-of-type(3),
.wcvendors-table-wrapper .wcvendors-table-order tr th:nth-of-type(3) {
  min-width: 200px;
}
.wcvendors-table-wrapper .wcvendors-table-order tr td:last-of-type,
.wcvendors-table-wrapper .wcvendors-table-order tr th:last-of-type {
  min-width: 150px;
}
.wcvendors-table-wrapper .wcvendors-table-order br + strong {
  display: block;
  margin-top: 1rem;
}
.wcvendors-table-wrapper .wcvendors-table-order br + strong + br {
  display: none;
}
.wcvendors-table-wrapper .wcvendors-table thead {
  background-color: #eaeaea;
}
.wcvendors-table-wrapper .wcvendors-table thead > tr > th:first-child {
  border-top-left-radius: 10px;
}
.wcvendors-table-wrapper .wcvendors-table thead > tr > th:last-child {
  border-top-right-radius: 10px;
}
.wcvendors-table-wrapper .wcvendors-table tbody {
  background-color: #fff;
}
.wcvendors-table-wrapper .wcvendors-table tr th,
.wcvendors-table-wrapper .wcvendors-table tr td {
  padding: 15px 8px;
  border-bottom: 1px solid #eaeaea;
}
.wcvendors-table-wrapper .wcvendors-table tr th {
  font-weight: 700;
}

.wcv-form p.tip {
  line-height: 1.2;
}

.wcv-product-type {
  display: none;
  margin: 0 0 3rem;
}
.wcv-product-type .wcv-cols-group.wcv-horizontal-gutters {
  margin: 0;
}
@media (min-width: 768px) {
  .wcv-product-type .wcv-cols-group.wcv-horizontal-gutters {
    margin: 0 0 0 -2rem;
  }
}
.wcv-product-type .all-50.small-100 {
  float: none;
  width: 100%;
  padding: 0 0 2rem;
}
@media (min-width: 768px) {
  .wcv-product-type .all-50.small-100 {
    float: left;
    width: 50%;
    padding: 0 0 0 2rem;
  }
}

.wcv-product-shipping .wcv-cols-group.wcv-horizontal-gutters {
  margin: 0;
}
@media (min-width: 768px) {
  .wcv-product-shipping .wcv-cols-group.wcv-horizontal-gutters {
    margin: 0 0 0 -2rem;
  }
}
.wcv-product-shipping .wcv-cols-group.wcv-horizontal-gutters .all-33 {
  float: none;
  width: 100%;
  padding: 0 0 2rem;
}
@media (min-width: 768px) {
  .wcv-product-shipping .wcv-cols-group.wcv-horizontal-gutters .all-33 {
    float: left;
    width: 33.3333%;
    padding: 0 0 0 2rem;
  }
}

.wcv_product_attributes .wcv-column-group.wcv-horizontal-gutters {
  margin: 0;
  border: none !important;
}
@media (min-width: 768px) {
  .wcv_product_attributes .wcv-column-group.wcv-horizontal-gutters {
    margin: 0 0 0 -2rem;
  }
}
.wcv_product_attributes .wcv-column-group.wcv-horizontal-gutters .all-100 {
  display: flex;
}
.wcv_product_attributes .wcv-column-group.wcv-horizontal-gutters .all-100.align-right {
  display: block;
}
.wcv_product_attributes .wcv-column-group.wcv-horizontal-gutters .all-100 .button {
  margin-left: 2rem;
}
.wcv_product_attributes .wcv-column-group.wcv-horizontal-gutters .all-80 {
  float: none;
  width: 100%;
  padding: 0;
}
@media (min-width: 768px) {
  .wcv_product_attributes .wcv-column-group.wcv-horizontal-gutters .all-80 {
    float: left;
    width: 80%;
    padding: 0 0 0 2rem;
  }
}
.wcv_product_attributes .wcv-column-group.wcv-horizontal-gutters .all-20 {
  display: none;
}
@media (min-width: 768px) {
  .wcv_product_attributes .wcv-column-group.wcv-horizontal-gutters .all-20 {
    display: block;
  }
}
.wcv_product_attributes .wcv-column-group.wcv-horizontal-gutters .all-70 {
  float: none;
  width: 100%;
  padding: 0;
}
@media (min-width: 768px) {
  .wcv_product_attributes .wcv-column-group.wcv-horizontal-gutters .all-70 {
    float: left;
    width: 70%;
    padding: 0 0 0 2rem;
  }
}
.wcv_product_attributes .wcv-column-group.wcv-horizontal-gutters .all-30 {
  float: none;
  width: 100%;
  padding: 0;
}
@media (min-width: 768px) {
  .wcv_product_attributes .wcv-column-group.wcv-horizontal-gutters .all-30 {
    float: left;
    width: 30%;
    padding: 0 0 0 2rem;
  }
}
.wcv_product_attributes .product_attributes {
  clear: both;
}
.wcv_product_attributes .select2 {
  margin-bottom: 2rem;
}

.woocommerce button.button.select_all_attributes,
.woocommerce button.button.select_no_attributes,
#product_save_button,
#draft_button {
  width: 100%;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .woocommerce button.button.select_all_attributes,
.woocommerce button.button.select_no_attributes,
#product_save_button,
#draft_button {
    width: auto;
  }
}

#wcv-tiered-pricing-active + label + #wcv-pricing-tiers {
  display: none;
}

#wcv-tiered-pricing-active:checked + label + #wcv-pricing-tiers {
  display: block;
}

.wcv-pricing-tier {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 2rem;
}
.wcv-pricing-tier input {
  margin-right: 2rem;
}
.wcv-pricing-tier span.wcv-pricing-tier-remove {
  content: "";
  cursor: pointer;
  padding: 1.2rem 0.5rem;
  font-family: "Font Awesome 5 Free";
  /* stylelint-disable-line */
}
.wcv-pricing-tier span.wcv-pricing-tier-remove:before {
  content: "\f057";
}

.wcv-grid table .row-actions.row-actions-product, .wcv-grid table .row-actions.row-actions-order {
  opacity: 1;
}
.wcv-grid table .row-actions.row-actions-product a, .wcv-grid table .row-actions.row-actions-order a {
  color: #e11d73;
}
.wcv-grid table .row-actions.row-actions-product a:hover, .wcv-grid table .row-actions.row-actions-product a:focus, .wcv-grid table .row-actions.row-actions-product a:active, .wcv-grid table .row-actions.row-actions-order a:hover, .wcv-grid table .row-actions.row-actions-order a:focus, .wcv-grid table .row-actions.row-actions-order a:active {
  text-decoration: underline;
  color: #e11d73;
}

@media (min-width: 576px) {
  .products_chart {
    display: flex;
    align-items: center;
  }
}
.products_chart_pie {
  flex: 0 0 25rem;
  width: 25rem;
}
.products_chart_legend {
  margin: 3rem 0 0;
  font-size: 1.2rem;
}
@media (min-width: 576px) {
  .products_chart_legend {
    flex: 0 1 calc(100% - 25rem);
    width: calc(100% - 25rem);
    margin: 0;
    padding-left: 3rem;
  }
}
.products_chart_legend_item {
  margin: 0 0 0.5rem;
}
.products_chart_legend_item span {
  display: inline-block;
  vertical-align: middle;
  width: 1.2rem;
  height: 1.2rem;
}

#orders_chart,
#products_chart {
  padding: 0;
}

.chamfr-vendor-product nav.woocommerce-pagination ul li a.next,
.chamfr-vendor-product nav.woocommerce-pagination ul li a.prev,
.woocommerce .chamfr-vendor-product nav.woocommerce-pagination ul li a.next,
.woocommerce .chamfr-vendor-product nav.woocommerce-pagination ul li a.prev,
.chamfr-vendor-order nav.woocommerce-pagination ul li a.next,
.chamfr-vendor-order nav.woocommerce-pagination ul li a.prev,
.woocommerce .chamfr-vendor-order nav.woocommerce-pagination ul li a.next,
.woocommerce .chamfr-vendor-order nav.woocommerce-pagination ul li a.prev {
  border: none;
}
.chamfr-vendor-product .wcvendors-table-wrapper + .wcv-search,
.woocommerce .chamfr-vendor-product .wcvendors-table-wrapper + .wcv-search,
.chamfr-vendor-order .wcvendors-table-wrapper + .wcv-search,
.woocommerce .chamfr-vendor-order .wcvendors-table-wrapper + .wcv-search {
  display: none;
}

.wcv-submit-btn {
  position: relative;
}

.wcv-submit-btn-disable.on {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 767.98px) {
  .wcvendors-table-order .wcv-modal #order_line_items {
    display: initial;
  }

  .wcvendors-table-order td.total {
    display: table-cell;
  }
}
.woocommerce-order .confirmation-box {
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 32px;
  margin: 0 -32px 48px;
}
.woocommerce-order .confirmation-box.order-summary {
  padding: 24px 40px 33px;
}
.woocommerce-order .woocommerce-notice {
  color: #111111;
  font-size: 38px;
  font-weight: 800;
  line-height: 54px;
  margin-bottom: 8px;
}
.woocommerce-order .woocommerce-notice-after {
  color: #111111;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 8px;
}
.woocommerce-order .order-number-pdf-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 575.98px) {
  .woocommerce-order .order-number-pdf-outer {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.woocommerce-order .order-number-pdf-outer .order-number-large {
  color: #e11d73;
  font-size: 22px;
  font-weight: 800;
  line-height: 32px;
  margin-bottom: 0;
}
.woocommerce-order .order-number-pdf-outer .pdf-download a {
  font-size: 14px;
  line-height: 16.45px;
  display: block;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.woocommerce-order .order-number-pdf-outer .pdf-download a:hover {
  text-decoration: none;
}
.woocommerce-order .order-number-pdf-outer .pdf-icon {
  background: url(../images/download-pdf.svg) no-repeat center/contain;
  width: 16px;
  height: 20px;
  display: inline-block;
  margin-right: 13px;
  vertical-align: bottom;
}
.woocommerce-order .woocommerce-order-overview {
  background-color: #f6f6f6;
  border-radius: 8px;
  padding: 40px;
  margin: 32px 0;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 991.98px) {
  .woocommerce-order .woocommerce-order-overview {
    flex-wrap: wrap;
  }
}
.woocommerce-order .woocommerce-order-overview .order-overview-box {
  flex: 1 1 100%;
}
@media (max-width: 991.98px) {
  .woocommerce-order .woocommerce-order-overview .order-overview-box {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 767.98px) {
  .woocommerce-order .woocommerce-order-overview .order-overview-box {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  .woocommerce-order .woocommerce-order-overview .order-overview-box:not(:last-child) {
    margin-right: 48px;
    padding-right: 48px;
    border-right: 1px solid #e1e1e1;
  }
}
@media (max-width: 991.98px) {
  .woocommerce-order .woocommerce-order-overview .order-overview-box:not(:last-child) {
    margin-bottom: 48px;
    padding-bottom: 48px;
    border-bottom: 1px solid #e1e1e1;
  }
}
.woocommerce-order .woocommerce-order-overview .box-heading {
  color: #111111;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;
}
.woocommerce-order .woocommerce-order-overview .inner-box .box-sub-heading {
  color: #111111;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  margin: 0;
}
.woocommerce-order .woocommerce-order-overview .inner-box:not(:last-child) {
  margin-bottom: 12px;
}
.woocommerce-order .order-total-text {
  margin: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767.98px) {
  .woocommerce-order .order-total-text {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.woocommerce-order .order-total-text .thankyou-support-text {
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  margin: 0;
  max-width: 42%;
  width: 100%;
}
@media (max-width: 1424.98px) {
  .woocommerce-order .order-total-text .thankyou-support-text {
    max-width: 50%;
  }
}
@media (max-width: 1199.98px) {
  .woocommerce-order .order-total-text .thankyou-support-text {
    max-width: 60%;
  }
}
@media (max-width: 767.98px) {
  .woocommerce-order .order-total-text .thankyou-support-text {
    max-width: 100%;
  }
}
.woocommerce-order .order-total-text .thankyou-support-text a {
  font-style: normal;
  text-decoration: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.woocommerce-order .order-total-text .thankyou-support-text a:hover {
  text-decoration: none;
}
.woocommerce-order .order-total-text .order-total {
  color: #e11d73;
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
}
.woocommerce-order .order-summary .summary-table {
  width: 100%;
  border-collapse: collapse;
}
.woocommerce-order .order-summary .summary-table th, .woocommerce-order .order-summary .summary-table td {
  color: #111111;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}
@media (max-width: 991.98px) {
  .woocommerce-order .order-summary .summary-table th, .woocommerce-order .order-summary .summary-table td {
    display: inline-block;
  }
}
.woocommerce-order .order-summary .summary-table th {
  padding: 0 24px 24px;
}
@media (max-width: 991.98px) {
  .woocommerce-order .order-summary .summary-table th {
    display: none;
  }
}
.woocommerce-order .order-summary .summary-table thead .head-border td {
  border-top: 1px solid #111111;
  padding-bottom: 32px;
}
@media (max-width: 991.98px) {
  .woocommerce-order .order-summary .summary-table thead .head-border td {
    display: none;
  }
}
.woocommerce-order .order-summary .product-body {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}
.woocommerce-order .order-summary .product-header {
  background: #e1e1e1;
  border-radius: 4px;
}
@media (max-width: 991.98px) {
  .woocommerce-order .order-summary .product-header {
    display: flex;
    flex-direction: column;
  }
}
.woocommerce-order .order-summary .product-header .product-seller {
  padding: 12px 16px;
}
.woocommerce-order .order-summary .shipping-method {
  font-size: 16px;
  background: #FFFFFF;
  padding: 15px 16px;
  text-align: center;
  line-height: 20px;
  border-radius: 4px;
}
.woocommerce-order .order-summary .product-details {
  border-bottom: 1px solid #b4b4b4;
}
@media (max-width: 991.98px) {
  .woocommerce-order .order-summary .product-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.woocommerce-order .order-summary .product-details td {
  padding: 24px 24px 16px;
}
@media (max-width: 575.98px) {
  .woocommerce-order .order-summary .product-details td {
    padding: 24px 10px 16px;
  }
}
.woocommerce-order .order-summary .product-details .product-info {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}
.woocommerce-order .order-summary .product-details .product-info > * {
  flex: 1 1 100%;
}
.woocommerce-order .order-summary .product-details .product-image {
  max-width: 104px;
  width: 100%;
  height: 104px;
  background: #f6f6f6;
  border-radius: 4px;
  padding: 7px;
}
.woocommerce-order .order-summary .product-details .product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.woocommerce-order .order-summary .product-details .mobile-table-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  margin: 0 10px;
  background-color: #f6f6f6;
  border-radius: 4px 4px 0 0;
}
@media (min-width: 992px) {
  .woocommerce-order .order-summary .product-details .mobile-table-header {
    display: none;
  }
}
.woocommerce-order .order-summary .product-details .mobile-table-header p {
  margin: 0;
}
.woocommerce-order .order-summary .total-amount-border td {
  border-top: 1px solid #b4b4b4;
}
@media (max-width: 991.98px) {
  .woocommerce-order .order-summary .total-amount-border td {
    display: block;
  }
}
@media (max-width: 991.98px) {
  .woocommerce-order .order-summary .total-amount-outer {
    text-align: right;
  }
}
.woocommerce-order .order-summary .total-amount-outer .total-amount {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  text-align: right;
  color: #111111;
  padding: 16px 24px 24px;
}
.woocommerce-order .account-creation-form {
  background-color: #2b2b2b;
  padding: 34px 56px 32px;
  margin: 32px 0;
  border-radius: 8px;
}
.woocommerce-order .account-creation-form .account-creation-main-head {
  color: #FFFFFF;
  font-weight: 800;
  line-height: 24px;
  text-transform: capitalize;
  margin: 0 0 7px;
}
.woocommerce-order .account-creation-form .account-creation-sub-head {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  margin: 0 0 30px;
}
.woocommerce-order .account-creation-form form {
  display: flex;
  align-items: flex-end;
  column-gap: 32px;
  width: 57%;
}
@media (max-width: 1199.98px) {
  .woocommerce-order .account-creation-form form {
    width: 95%;
  }
}
@media (max-width: 991.98px) {
  .woocommerce-order .account-creation-form form {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .woocommerce-order .account-creation-form form {
    flex-wrap: wrap;
    row-gap: 20px;
  }
}
.woocommerce-order .account-creation-form form > * {
  flex: 1 1 auto;
  margin: 0;
}
.woocommerce-order .account-creation-form label {
  line-height: 16.45px;
  color: #FFFFFF;
}
.woocommerce-order .account-creation-form label span {
  color: #FE0000;
  margin-left: 3px;
}
.woocommerce-order .account-creation-form input {
  border: 1px solid #b4b4b4;
  background-color: #FFFFFF;
  max-width: 380px;
  width: 100%;
  padding: 15px 16px;
  border-radius: 4px;
}
@media (max-width: 767.98px) {
  .woocommerce-order .account-creation-form input {
    max-width: 100%;
  }
}
.woocommerce-order .account-creation-form button {
  max-width: 300px;
  width: 100%;
  box-shadow: none;
}
@media (max-width: 767.98px) {
  .woocommerce-order .account-creation-form button {
    max-width: 100%;
  }
}
.woocommerce-order .account-creation-form button:hover {
  background: #9f325d;
}

.woocommerce-order.order-status .order-status-form {
  max-width: 450px;
  margin: 32px auto;
}
.woocommerce-order.order-status .order-status-form form label {
  color: #111111;
  font-size: 14px;
  line-height: 16.45px;
}
.woocommerce-order.order-status .order-status-form form input {
  border: 1px solid #b4b4b4;
  padding: 15px 16px;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: all 0.3s ease-in;
}
.woocommerce-order.order-status .order-status-form form input:focus {
  border-color: #e11d73;
}
.woocommerce-order.order-status .order-status-btn {
  width: 100%;
  border-radius: 8px !important;
  box-shadow: none;
  letter-spacing: 0;
  display: inline-block;
  border-radius: 0.5rem;
  color: #343a40;
  background-color: #5b5b5b;
  border: 2px solid #5b5b5b;
  text-transform: uppercase;
  font-size: 16px;
  padding: 15px 20px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  border-color: #e11d73;
  background-color: #e11d73;
  transition: all 0.3s ease-in;
}
.woocommerce-order.order-status .order-status-btn:hover, .woocommerce-order.order-status .order-status-btn:focus {
  text-decoration: none;
}
.woocommerce-order.order-status .order-status-btn:hover, .woocommerce-order.order-status .order-status-btn:focus {
  color: #FFFFFF;
  background-color: #9f325d;
  border-color: #9f325d;
}
.woocommerce-order.order-status .order-status-btn:disabled {
  color: #FFFFFF;
  background-color: #e1e1e1;
  border-color: #e1e1e1;
}
.woocommerce-order.order-status .order-status-btn:hover {
  background: #FFFFFF;
  color: #e11d73;
  border-color: #e11d73;
}
.woocommerce-order.order-status .order-status-btn.back-button {
  display: inline;
}
.woocommerce-order.order-status .order-status-details #breadcrumb {
  display: none;
}

.print-order-details {
  background: #fff;
  color: #000;
}
.print-order-details .modal-close,
.print-order-details .modal-print {
  display: none;
}
.print-order-details h3 {
  font-size: 24px;
}
.print-order-details h4 {
  font-size: 20px;
}
.print-order-details .all-50 {
  display: inline-block;
  width: 49%;
  padding-left: 0;
  padding-right: 50px;
}
.print-order-details .wcv-order-table {
  margin-bottom: 1em;
}
.print-order-details .wcv-order-table #order_line_items tr td {
  border-bottom: 1px solid #eaeaea;
}
.print-order-details .wcv-order-table th,
.print-order-details .wcv-order-table td {
  padding: 5px;
}
.print-order-details .wcv-order-table th {
  font-weight: 700;
  background-color: #eaeaea;
  text-align: right;
}
.print-order-details .wcv-order-table th:first-child {
  text-align: left;
}
.print-order-details .wcv-order-table td {
  vertical-align: top;
}
.print-order-details .wcv-order-table .wcv-order-thumb {
  width: 64px;
}
.print-order-details .wcv-order-table .wcv-order-thumb img {
  width: 100%;
  height: auto;
}
.print-order-details .wcv-order-table .item_cost,
.print-order-details .wcv-order-table .quantity,
.print-order-details .wcv-order-table .line_cost {
  text-align: right;
}
.print-order-details .wcv-order-totals-label,
.print-order-details .wcv-order-totals .total {
  text-align: right;
}

@media print {
  .page-header {
    display: none;
  }

  .print-page-button {
    display: none;
  }

  .subscribe {
    display: none;
  }

  .page-footer {
    display: none;
  }
}
.container {
  position: relative;
}

.print-page-button {
  cursor: pointer;
  background: none;
  position: absolute;
  right: 0px;
  width: 50px;
  height: 50px;
  top: 0px;
}

#chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 360px) {
  #chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
    max-width: 360px;
  }
}
@media (min-width: 576px) {
  #chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  #chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  #chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  #chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
    max-width: 1140px;
  }
}
@media (min-width: 1425px) {
  #chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
    max-width: 1360px;
  }
}

#ais-wrapper.columns {
  flex-grow: 1;
}

/* Hits table Header */
.sortable {
  cursor: pointer;
  user-select: none;
}

.sortable .sort-arrow {
  margin-left: 5px;
  display: inline-block;
  width: 10px;
}
@media (max-width: 991.98px) {
  .sortable .sort-arrow {
    display: none;
  }
}

/* Facets */
.custom-search-box .ais-SearchBox-submit {
  display: none;
}
.custom-search-box .ais-SearchBox-reset {
  display: none;
}
.custom-search-box .ais-SearchBox {
  width: 100%;
}

.ais-ToggleRefinement-label input[type=checkbox] {
  display: none;
}
.ais-ToggleRefinement-label input[type=checkbox] + span::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid #fff;
  /* stylelint-disable-next-line */
  transition: background-color 0.2s ease-out 0.05s, border-color 0.2s ease-out 0.05s, background-image 0.1s 0.3s;
  cursor: pointer;
}
.ais-ToggleRefinement-label input[type=checkbox]:checked + span::before {
  background: #e11d73 url(../images/check-white.svg) no-repeat center;
  background-size: 12px;
  border: 1px solid #e11d73;
}

.ais-RefinementList-label input[type=checkbox] {
  display: none;
}
.ais-RefinementList-label input[type=checkbox] + span::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.6rem;
  margin-left: -2.6rem;
  margin-top: -0.3rem;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid #fff;
  /* stylelint-disable-next-line */
  transition: background-color 0.2s ease-out 0.05s, border-color 0.2s ease-out 0.05s, background-image 0.1s 0.3s;
  cursor: pointer;
}
.ais-RefinementList-searchBox {
  width: 100%;
}
.ais-RefinementList .ais-RefinementList-list {
  padding-left: 0px;
  padding-right: 0px;
}
.ais-RefinementList ul.inner {
  overflow-y: scroll;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  scrollbar-color: #ddd #2b2b2b;
  scrollbar-width: thin !important;
}

.ais-RefinementList-item--selected .ais-RefinementList-label input[type=checkbox] + span::before {
  background: #e11d73 url(../images/check-white.svg) no-repeat center;
  background-size: 12px;
  border: 1px solid #e11d73;
}

.ais-ClearRefinements {
  text-align: center;
  margin-bottom: 1rem;
}
.ais-ClearRefinements button.button {
  display: none;
}
.ais-ClearRefinements button.button.show {
  display: inline-block;
}

#chamfr-search-results .columns,
#chamfr-supplier-store .columns,
#chamfr-recently-added-products .columns {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
}
@media (max-width: 1199.98px) {
  #chamfr-search-results .columns,
#chamfr-supplier-store .columns,
#chamfr-recently-added-products .columns {
    display: block;
  }
}
#chamfr-search-results .columns #ais-facets .chamfr-filter,
#chamfr-supplier-store .columns #ais-facets .chamfr-filter,
#chamfr-recently-added-products .columns #ais-facets .chamfr-filter {
  padding: 2rem 1rem;
}
#chamfr-search-results .columns #ais-facets .chamfr-filter .caption.widgettitle,
#chamfr-supplier-store .columns #ais-facets .chamfr-filter .caption.widgettitle,
#chamfr-recently-added-products .columns #ais-facets .chamfr-filter .caption.widgettitle {
  margin-bottom: 1rem;
}
#chamfr-search-results .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-submit,
#chamfr-supplier-store .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-submit,
#chamfr-recently-added-products .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-submit {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  height: 36px;
  background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
  background-position: 12px 12px;
  background-size: 12px;
  background-repeat: no-repeat;
  background-color: #e11d73;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
#chamfr-search-results .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-submit svg,
#chamfr-supplier-store .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-submit svg,
#chamfr-recently-added-products .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-submit svg {
  display: none;
}
#chamfr-search-results .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox form,
#chamfr-supplier-store .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox form,
#chamfr-recently-added-products .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox form {
  position: relative;
}
#chamfr-search-results .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-input,
#chamfr-supplier-store .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-input,
#chamfr-recently-added-products .columns #ais-facets .chamfr-filter .custom-search-box .ais-SearchBox-input {
  max-width: 100%;
  height: auto;
  padding: 8px 15px 9px 15px;
  background-color: #fafafa;
  border-radius: 0.5rem;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  font-size: 1.4rem;
  line-height: 1.36;
  color: #313131;
  margin: 0;
}
#chamfr-search-results .columns #facet-categories .ais-RefinementList-searchBox,
#chamfr-search-results .columns #facet-container .ais-RefinementList-searchBox,
#chamfr-supplier-store .columns #facet-categories .ais-RefinementList-searchBox,
#chamfr-supplier-store .columns #facet-container .ais-RefinementList-searchBox,
#chamfr-recently-added-products .columns #facet-categories .ais-RefinementList-searchBox,
#chamfr-recently-added-products .columns #facet-container .ais-RefinementList-searchBox {
  margin-bottom: 2rem;
}

/* RangeSlide Facet */
.slider-wrapper {
  position: relative;
  min-height: 7rem;
}
.slider-wrapper .chamfr-filter-slider-range-input-field {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  max-width: 8rem;
  font-size: 1.2rem;
  color: #5b5b5b;
}
.slider-wrapper .chamfr-filter-slider-range-input-field.to {
  left: auto;
  right: 0;
}
.slider-wrapper .chamfr-filter-slider-range-input-field .chamfr-filter-slider-range-input {
  font-size: 1.2rem;
  height: 2rem;
  padding: 0 0.5rem;
  width: 100%;
  margin: 0;
  background: #fff;
  border: 1px solid #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #313131;
  border-radius: 0.5rem;
}
.slider-wrapper .slider-container {
  margin-bottom: 1.5rem;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.slider-wrapper .slider-container .noUi-target {
  height: 1px;
  background: rgba(234, 234, 234, 0.2);
  border: none;
  box-shadow: none;
  padding: 0px 0.6rem;
}
.slider-wrapper .slider-container .noUi-target .noUi-connect {
  background: #b7b7b7;
}
.slider-wrapper .slider-container .noUi-target .noUi-handle {
  height: 1.2rem;
  width: 1.2rem;
  top: -0.5rem;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}
.slider-wrapper .slider-container .noUi-target .noUi-handle:before {
  display: none;
}
.slider-wrapper .slider-container .noUi-target .noUi-handle:after {
  display: none;
}
.slider-wrapper .slider-container .noUi-target .noUi-handle-lower {
  right: -0.6rem;
}
.slider-wrapper .slider-container .noUi-target .noUi-handle-upper {
  right: -0.6rem;
}

/* End of RangeSlider Facet */
.widget_ep-facet.hidden {
  display: none;
}

/* Search Bar */
.algolia-search-box-wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

#algolia-search-box {
  width: 100%;
  max-width: 100%;
  margin-right: auto;
}

/* End of Search Bar */
/* Stats */
#algolia-stats {
  width: 60%;
  margin-bottom: 20px;
  /* Additional margin for separation */
  padding-left: 20px;
  /* Align with ais-main */
}

/* Collapsible button styles */
/* Add space between the title and the categories */
/* Add bottom margin to the categories section */
.chamfr-filter {
  margin-bottom: 20px;
  /* Adjust as needed */
}
@media (max-width: 1199.98px) {
  .chamfr-filter #facet-container {
    display: none !important;
  }
}
@media (max-width: 1199.98px) {
  .chamfr-filter #facet-container.mobile-on {
    display: block !important;
  }
}

/* End of Collapsible Container */
/* Category List */
.ais-HierarchicalMenu {
  margin-top: 20px;
}

.chamfr-filter ul li.ais-RefinementList-item {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  clip: auto !important;
  position: relative !important;
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
  line-height: 26px;
  padding-left: 2.6rem;
}
.chamfr-filter ul li.ais-RefinementList-item div label.ais-RefinementList-label {
  margin: 0px;
}

.ais-HierarchicalMenu-label {
  color: #ccc;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  clip: auto !important;
  /* NEW */
  position: relative !important;
  /* NEW */
  flex-grow: 1;
  /* Allow label to take remaining space */
}

.ais-HierarchicalMenu-label:hover {
  color: #e11d73;
}

/* Additional Widgets */
.not-finding {
  margin-top: 20px;
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.not-finding-after-table .not-finding {
  margin-top: 0px;
  padding: 0px;
  background: none;
}

.not-finding p {
  margin: 0;
  color: #ccc;
  font-size: 14px;
}

.not-finding a {
  display: block;
  margin-top: 10px;
  background-color: #e11d73;
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.not-finding a:hover {
  background-color: #d10b65;
}

/* End of Sidebar Container */
/* Reset default list styles */
#algolia-pagination {
  position: relative;
  min-height: 120px;
}
#algolia-pagination.hidden {
  display: none;
}
#algolia-pagination .ais-Pagination {
  text-align: center;
  margin: 0;
  padding: 50px 10px 10px;
}
@media (max-width: 767.98px) {
  #algolia-pagination .ais-Pagination {
    padding: 20px 10px 10px;
  }
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list {
  list-style: none;
  border: none;
  margin: 0;
  padding: 1rem;
  border-radius: 28px;
  transition: background-color 0.1s ease, box-shadow 0.1s ease;
}
@media (max-width: 767.98px) {
  #algolia-pagination .ais-Pagination ul.ais-Pagination-list {
    padding: 0.5rem;
    border-radius: 19px;
  }
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item {
  display: inline-block;
  border: none;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--firstPage {
  display: none;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--previousPage.ais-Pagination-item--disabled {
  display: none;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--previousPage a {
  color: transparent;
  position: relative;
  border-color: #000;
  opacity: 1;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--previousPage a::after {
  content: "←";
  color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--previousPage a:hover {
  color: transparent;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--nextPage.ais-Pagination-item--disabled {
  display: none;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--nextPage a {
  color: transparent;
  position: relative;
  border-color: #000;
  opacity: 1;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--nextPage a::after {
  content: "→";
  color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--nextPage a:hover {
  color: transparent;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item.ais-Pagination-item--lastPage {
  display: none;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item span,
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item a {
  text-decoration: none;
  min-width: 3.6rem;
  height: 3.6rem;
  margin: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767.98px) {
  #algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item span,
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item a {
    margin: 0;
    height: 2.8rem;
    min-width: 2.8rem;
  }
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item a {
  color: #000;
  opacity: 0.5;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item a:hover {
  background: #e9e6ed;
  color: #816e99;
}
#algolia-pagination .ais-Pagination ul.ais-Pagination-list li.ais-Pagination-item--selected a {
  background-color: transparent;
  color: #e11d73;
  opacity: 1;
}
#algolia-pagination .ais-Pagination.fixed {
  position: fixed;
  z-index: 1;
  pointer-events: none;
  bottom: 50px;
  margin: 0 auto;
  left: 50%;
}
#algolia-pagination .ais-Pagination.fixed ul {
  pointer-events: auto;
  background-color: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

#algolia-no-results .categories {
  padding-top: 4rem;
}
#algolia-no-results .categories-list {
  margin: 0 -1rem;
  display: flex;
  flex-wrap: wrap;
}
#algolia-no-results .category {
  flex: 0 0 33.333%;
  padding: 1rem;
}
@media (max-width: 767.98px) {
  #algolia-no-results .category {
    flex: 0 0 50%;
  }
}
@media (max-width: 359.98px) {
  #algolia-no-results .category {
    flex: 0 0 100%;
  }
}
#algolia-no-results .category > a {
  display: block;
  background: #fff;
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

@media (max-width: 767.98px) {
  .no-results .chamfr-filter {
    display: none;
  }
}
.no-results .chamfr-product-list .products {
  width: 100%;
  margin: 0px auto;
}