.wp-block-media-text {
	&.has-media-on-the-left {
		.wp-block-media-text__content {
			padding-right: 0;
		}
	}
	&.has-media-on-the-right {
		.wp-block-media-text__content {
			padding-left: 0;
		}
	}

	@include media-breakpoint-down("600px") {
		&__content {
			padding: 0;
		}
	}
}
