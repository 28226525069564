.advantages {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: space-between;
	background-color: #000;
	padding: 0 0 6rem;
	margin: 0 0 18rem;

	@include media-breakpoint-down($bp-m) {
		margin: 0 0 10rem;
		padding: 2rem 2rem 3rem;
	}

	@include media-breakpoint-down( md ) {
		flex-direction: column;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: -2.5rem;
		right: -3rem;
		z-index: -1;
		width: 20rem;
		height: 16.5rem;
		background: url( ../images/dots.svg ) repeat bottom right;
		opacity: 0.5;

		@include media-breakpoint-up( $bp ) {
			width: 51.9rem;
			height: 18rem;
		}
	}

	&__intro {
		padding: 0 0 4rem;
		@include media-breakpoint-up( $bp ) {
			padding: 0 0 4rem;
		}

		@include media-breakpoint-down($bp-m) {
			padding: 0 0 2rem;
		}

		h3 {
			text-align: center;
			padding-top: 3rem;
			margin-bottom: 0px;

			@include media-breakpoint-down($bp-m) {
				padding-top: 1rem;
			}
		}
	}

	.col-lg-4 {
		@include media-breakpoint-down($bp-m) {
			&:last-child {
				.advantages__item {
					margin-bottom: 0;
				}
			}
		}
	}

	&__item {
		margin: 0 auto 10rem;
		text-align: center;
		@include media-breakpoint-down($bp-m) {
			margin: 0 auto 5rem;
		}

		@include media-breakpoint-up( $bp ) {
			margin: 0;
		}

		img {
			display: block;
			position: relative;
			margin: 0 auto;
			max-height: 5rem;
			object-fit: contain;
		}

		&__txt {
			position: relative;
			margin: 4rem 4rem 0;
			font-size: 1.6rem;
			min-height: 10rem;
			padding-bottom: 2rem;
			@include media-breakpoint-down($bp-m) {
				margin: 2rem 0 0;
				padding-bottom: 1rem;
			}
		}

		&__author {
			color: #E11D72;
			font-size: 1.6rem;
			font-weight: bold;
		}
	}
}
