.home-hero {
	padding: 0 0 0;
	@include media-breakpoint-up( $bp ) {
		display: flex;
		padding: 7rem 0 16rem 0;
	}
	@include media-breakpoint-down($bp-m) {
		padding: 3rem 0 8rem 0;
	}

	&__image {
		position: relative;
		flex: 0 0 100%;
		width: 100%;
		padding: 0 0 2rem 2rem;
		@include media-breakpoint-down($bp-m) {
			padding: 0 0 1rem 1rem;
		}

		@include media-breakpoint-up( $bp ) {
			flex: 0 0 55%;
			width: 55%;
			max-width: 55%;
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 2rem;
			right: 0;
			left: 30%;
			background: linear-gradient( to right, rgba(34,34,34,0) 0%,rgba(34,34,34,1) 100% );/* stylelint-disable-line */
			border-radius: 0 0.5rem 0.5rem 0;
			opacity: 0.6;
		}

		&__pattern {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			bottom: -0.7rem;
			z-index: -1;
			width: 24rem;
			max-width: 75%;
			height: 15rem;
			max-height: 75%;
			background: url( ../images/dots.svg ) repeat bottom left;
			opacity: 0.5;

			@include media-breakpoint-up( $bp ) {
				width: 52rem;
				height: 34rem;
			}
		}

		&__wrapper {
			overflow: hidden;
			height: 100%;
			border-radius: 0.5rem;
		}
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up( $bp ) {
				height: 100%;
				object-fit: cover;
			}
		}
	}
	&__text {
		position: relative;
		z-index: 2;
		flex: 0 0 100%;
		width: 100%;
		margin: -6rem 0 0;
		padding: 0 3rem;

		@include media-breakpoint-up( $bp ) {
			flex: 0 0 45%;
			width: 45%;
			max-width: 45%;
			margin: 0;
			padding: 5rem 4rem 0 4rem;
			font-size: 1.8rem;
		}
		@include media-breakpoint-down($bp-m) {
			p {
				margin-bottom: 0px;
			}
		}

		h1 {
			font-size: 3.6rem;
			font-weight: 700;

			top: 1rem;
			right: 2rem;
			z-index: 2;
			margin-bottom: 2rem;
			white-space: normal;
			left: -5rem;

			@include media-breakpoint-up( $bp ) {
				position: relative;
				margin-left: -10rem;
				// transform: translateX(-20rem);
				z-index: 2;
				margin-bottom: 2rem;
				font-size: 5.6rem;
			}
		}
	}
	&__btn {
		margin-top: 3rem;

		@media ( max-width: 480px ) {
			.btn {
				height: auto;
				padding: 1rem 2rem;
				line-height: 1.2;
			}
		}
		@include media-breakpoint-down($bp-m) {
			margin-top: 2rem;
		}
	}
	&__search {
		margin-left: -14rem;
		width: calc(100% + 7rem);
		margin-top: 4rem;

		form {
			position: relative;
			#autocomplete-searchbar {
				.aa-Autocomplete {
					.aa-Form {
						.aa-InputWrapperPrefix {
							.aa-Label {
								.aa-SubmitButton {
									position: absolute;
									right: 0;
									top: 0;
									bottom: 0;
									width: 7rem;
									height: 7.2rem;
									background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
									background-position: 2.2rem 2.2rem;
									background-size: 3rem;
									background-repeat: no-repeat;
									background-color: #e11d73;
									border-top-right-radius: 0.2rem;
									border-bottom-right-radius: 0.2rem;

									@media ( max-width: 1200px ) {
										width: 5rem;
										height: 5.5rem;
										background-size: 2.5rem;
										background-position: 1.4rem 1.7rem;
									}
								}
							}
						}

						.aa-InputWrapper {
							width: 100%;

							.aa-Input {
								height: auto;
								font-size: 2.6rem;
								padding: 0.8em 3.4em 0.8em 0.8em;
								line-height: 1;
								width: 100%;
								border-radius: 0.5rem;

								@media ( max-width: 1200px ) {
									font-size: 2rem;
								}
							}
						}
					}
				}
			}
		}

		input:not([type=submit]) {
			height: auto;
			font-size: 2.6rem;
			padding: 0.8em 3.4em 0.8em 0.8em;
			line-height: 1;

			@media ( max-width: 1200px ) {
				font-size: 2rem;
			}
		}

		.search-submit {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 7rem;
			background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
			background-position: 2.2rem 2.2rem;
			background-size: 3rem;
			background-repeat: no-repeat;
			background-color: #e11d73;
			border-top-right-radius: 0.5rem;
			border-bottom-right-radius: 0.5rem;

			@media ( max-width: 1200px ) {
				width: 5rem;
				background-size: 2.5rem;
				background-position: 1.4rem 1.7rem;
			}
		}
		@media ( max-width: 1424.98px ) {
			margin-top: 10px;
			width: auto;
		}

		@media ( max-width: 1200px ) {
			margin-top: 0px;
		}

		@media ( max-width: 992px ) {
			margin-left: 0px;
			margin-top: 2rem;
		}

		@media ( max-width: 767px ) {
			display: none;
		}
	}
}

.body-style--light {
	.home-hero {
		&__image:after {
			background: linear-gradient( to right, transparentize(#ccc,1) 0%,transparentize(#ccc,0) 100% );/* stylelint-disable-line */
		}
	}
}
