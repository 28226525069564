body.vendor-shop {
    .wcv-header-container {
        border-radius: 1rem 1rem 0rem 0rem;
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
        .wcv-store-header {
            background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(221, 221, 221, 1) 52%);
            #banner-wrap {
                .wcv-store-banner:not([width="1"]) {
                    width: 100%;
					max-width: 100%;
					height: auto;
					max-height: none;
					min-height: 0;
                }
                #inner-element {
                    background: transparent;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    position: relative;
                    align-items: center;
                    @media screen and (max-width: 767px) {
                        width: 100%;
                        left: 0;
                        right: 0;
                        max-width: 100%;
                        padding: 0px 10px;
                    }
                    .store-brand {
                        .store-icon {
                            width: auto;
                            border-radius: unset;
                        }
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            padding: 0 !important;

                            .store-icon {
                                margin: 2rem auto 0;
                                display: block;
                                float: none;
								height: auto;
                            }
                        }
                    }

                    .store-info {
                        padding-left: 8%;
                        h3 {
                            text-align: left;
                        }
                        p {
                            text-align: left;
                        }
                        @media  screen and (max-width: 767px) {
                            width: 100%;
                            padding: 0;
                            p {
                                padding-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .wcv-store-address-container {
        border-radius: 0rem 0rem 1rem 1rem;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 0.5rem 0;
        margin-right: 0;
        .store-address {
            @media screen and (max-width: 767px) {
                text-align: center;
                padding:0;
            }
        }
        .store-phone {
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                .wcv-icon {
                    transform: rotate(90deg);
                    width: 10px;
                    margin-right: 0.5rem;
                }
            }
        }
    }
}
.wcv-header-container #banner-wrap img.wcv-store-banner[width="1"] {
    width: 1px !important;
    height: 1px !important;
    min-height: 0 !important;
}
.wcv-header-container #banner-wrap img.wcv-store-banner:not([width="1"]) {
    width: 100%;
    height: 100%;
}

.columns {
    #ais-facets {
        width: 22.9%;
        @include media-breakpoint-down( lg ) {
            width: 100%;
        }

        .chamfr-vendor-adv-wrapper {
            text-align: center;
            margin-bottom: 3rem;

            .chamfr-vendor-adv {
                margin-top: 3rem;
                border: none;
                width: 100%;
                display: block;
                min-height: 100px;

                @media screen and (max-width: 767px) {
                    min-height: 100px;
                }
            }
        }
    }
}
.chamfr-vendor-adv-bottom-wrapper {
    width: 100%;
    margin-bottom: 1rem;

    .chamfr-vendor-adv {
        margin-top: 3rem;
        border: none;
        width: 100%;
        display: block;
        min-height: 100px;
        text-align: center;
    }
}