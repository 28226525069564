@mixin button-basic {
	display: inline-block;
	border-radius: 0.5rem;
	color: $dark;
	background-color: $gray;
	border: 2px solid $gray;
	text-transform: uppercase;
	font-size: 16px;
	padding: 15px 20px;
	line-height: 1;
	font-weight: 700;
	text-align: center;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}
@mixin button-outlined {
	@include button-basic();

	color: $chamfr-pink;
	border-color: $chamfr-pink;
	background-color: transparent;

	&:hover,
	&:focus {
		color: $chamfr-raspberry;
		border-color: $chamfr-raspberry;
		background-color: transparent;
	}

	&:disabled {
		// Using "Chamfr Gray" since the current page background doesn't allow to use Light Gray.
		color: $chamfr-gray; //$chamfr-light-gray;
		border-color: $chamfr-gray; //$chamfr-light-gray;
		background-color: transparent;
	}
}

@mixin button-secondary {
	@include button-outlined();
}

@mixin button-tertiary {
	@include button-outlined();

	color: $chamfr-dark-gray;
	border-color: $chamfr-dark-gray;

	&:hover,
	&:focus {
		color: $chamfr-black;
		border-color: $chamfr-black;
	}
}

@mixin button-reverse {
	@include button-outlined();

	color: $chamfr-white;
	border-color: $chamfr-white;

	&:hover,
	&:focus {
		color: $chamfr-black;
		border-color: $chamfr-white;
		background-color: $chamfr-white;
	}
}

@mixin button-primary {
	@include button-basic();

	color: $chamfr-white;
	border-color: $chamfr-pink;
	background-color: $chamfr-pink;

	&:hover,
	&:focus {
		color: $chamfr-white;
		background-color: $chamfr-raspberry;
		border-color: $chamfr-raspberry;
	}

	&:disabled {
		color: $chamfr-white;
		background-color: $chamfr-light-gray;
		border-color: $chamfr-light-gray;
	}
}

@mixin disabled-button() {
	background: $light-gray;
	box-shadow: none;
	cursor: default;

	&:hover,
	&:focus {
		transform: none;
		box-shadow: none;
	}
}

@mixin load-more-button {
	opacity: 0;
	max-height: 0;
	overflow: hidden;
	padding-left: 3rem;
	padding-right: 3rem;
	// add active class to make button visible.
	&.active {
		opacity: 1;
		max-height: 300px; // just a sample bigger then actual button
	}

	// loading state.
	&.loading {
		position: relative;
		&:before {
			content: " ";
			display: block;
			border: 5px solid;
			border-radius: 2rem;
			height: 2rem;
			left: 1.5rem;
			margin: -1rem 0 0 -1rem;
			opacity: 0;
			position: absolute;
			top: 50%;
			width: 2rem;

			animation: pulsate 1s ease-out;
			animation-iteration-count: infinite;
		}
	}
}

@mixin toggle-button() {
	display: inline-block;
	height: 4.2rem;
	font-size: 1.4rem;
	line-height: 1;
	padding: 1.3rem 3.6rem;
	border-radius: 5px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.04em;
	color: $light-gray;
	border: 1px solid $light-gray;
	opacity: 0.5;

	&:hover {
		color: $white;
		border-color: $white;
		text-decoration: none;
		opacity: 1;
	}
}
