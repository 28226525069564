.chamfr-seller-pricing-table {
	thead,
	tbody {
		border-top: 3px solid;
		border-bottom: 3px solid;
	}

	th,
	td {
		border: none;
	}

	th {
		background: $pink;
		color: $white;
		border-color: transparent;
		text-align: center;
		font-size: 120%;
	}

	tbody {
		tr:nth-child(even) {
			background-color: $white;
		}
		tr:nth-child(odd) {
			background-color: $light-gray2;
		}
		td {
			text-align: center;
		}
	}

	&.wide {
		tbody {
			tr:nth-child(even) {
				background-color: $white;
			}
			tr:nth-child(odd) {
				background-color: $light-gray2;
			}
			tr.table-main-header {
				background-color: #000;

				td {
					color: #e11d73;
					text-transform: uppercase;
					font-weight: bold;
				}
			}
			tr.table-sub-header {
				background-color: #808080;

				td {
					color: #ffffff;
					font-weight: bold;
				}
			}
			td {
				text-align: center;
				width: 10%;
	
				&:nth-child(1) {
					text-align: left;
					width: 70%;
				}
			}
		}
	}
}
