@each $classname, $color in $local-colors {
	.has-#{"" + $classname}-background-color {
		background-color: $color;
	}
	
	.has-#{"" + $classname}-color {
		color: $color;
	}
	
	.has-#{"" + $classname}-border-color {
		border-color: $color;
	}
}