.block-logos-carousel {
	margin-bottom: 5rem;

	.logos {
		padding: 0 3.6rem;
	}

	.logo {
		display: flex;
		justify-content: center;
		padding: 0 1rem;
	}

	.slick {
		&-arrow {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;
		}
		&-prev {
			left: 0;
		}
		&-next {
			right: 0;
		}
	}
}
