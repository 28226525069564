/* stylelint-disable no-descending-specificity */
.related {
	padding: 5rem 0;
	@include media-breakpoint-up( $bp ) {
		padding: 10rem 0;
	}

	h3 {
		margin: 0 0 2rem;
		text-align: center;
	}

	.post {
		overflow: hidden;
		height: 100%;
		background: $white;
		border-radius: 0.5rem;

		&__img {
			height: 18rem;
			margin: 0;

			&__wrapper {
				height: 100%;
			}

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&__body {
			position: relative;
			height: calc( 100% - 18rem );
			padding: 2rem 2.5rem 5.5rem;
		}

		&__meta {
			margin: 0 0 0.5rem;
			font-size: 1.2rem;
			font-weight: 600;
			color: $darker-gray;
		}
		&__categories,
		&__date {
			display: inline-block;
		}
		&__categories {
			text-transform: uppercase;

			a {
				color: $darker-gray;

				&:hover,
				&:focus,
				&:active {
					color: $darker-gray;
				}
			}
		}

		&__title {
			h2 {
				font-family: $content-font;
				font-size: 1.8rem;
				font-weight: 400;
				margin: 0;

				a {
					color: $dark-gray;
				}
			}
		}

		&__excerpt {
			margin: 0;
			font-size: 1.4;
		}

		&__link {
			position: absolute;
			left: 2.5rem;
			right: 2.5rem;
			bottom: 2rem;

			&__a {
				display: inline-block;
				padding-bottom: 0.3rem;
				border-bottom: 1px solid $pink;
				text-decoration: none;

				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
				}
			}
		}
	}
}
.slick-related {
	margin: 0 -11px;

	div[class^='col-'] {
		padding: 0 11px;
	}

	&.slick-slider {
		margin: 0 -5px;
		@include overflow-slider-with-all-visible();

		div[class^='col-'] {
			height: 100%;
			padding: 0 5px;
		}
	}

	&__controls {
		margin: 1.5rem 0 0;
		text-align: center;

		@include media-breakpoint-up( md ) {
			display: none;
		}
	}
}
.rp4wp-related-posts {
	display: none;
}
