.block-img-txt {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 0 0 8rem;

	@include media-breakpoint-up( $bp ) {
		padding-bottom: 10rem;
	}

	.img-txt {
		&__image {
			flex: 0 0 100%;
			width: 100%;
			margin-bottom: $spacer;

			@include media-breakpoint-up( $bp ) {
				flex: 0 0 50%;
				width: 50%;
				max-width: 50%;
				margin-bottom: 0;
				padding-right: 2rem;
			}
		}
		&__text {
			flex: 0 0 100%;
			width: 100%;

			@include media-breakpoint-up( $bp ) {
				flex: 0 0 50%;
				width: 50%;
				max-width: 50%;
				padding-left: 2rem;
			}
		}
		&__btn {
			margin-top: 4rem;
		}
	}

	&--right {
		.img-txt {
			&__image {
				order: 1;

				@include media-breakpoint-up( $bp ) {
					order: 2;
					padding-right: 0;
					padding-left: 2rem;
				}
			}
			&__text {
				order: 2;
				@include media-breakpoint-up( $bp ) {
					order: 1;
					padding-left: 0;
					padding-right: 2rem;
				}
			}
		}
	}
}
