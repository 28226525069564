.main-footer{
	margin-top: 60px;
	background-color: $chamfr-deep-gray;
}
.page-footer {
	@include make-container();
	@include chamfr-container-max-widths();
	color: $white;

	padding-bottom: 0;
	padding-top: 0;

	a {
		color: $white;
	}

	.top-footer{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 55px 0 47px;

		@include media-breakpoint-down(md){
			flex-wrap: wrap;
			row-gap: 30px;
		}

		@include media-breakpoint-down(xsm){
			padding: 40px 0 47px;
			row-gap: 0;
		}
	}

	.company {
		@include media-breakpoint-up( lg ) {
			flex: 0 0 50%;
			width: 50%;
		}

		@include media-breakpoint-up( xl ) {
			flex: 0 0 43%;
			width: 43%;
		}

		@include media-breakpoint-up( hd ) {
			flex: 0 0 36%;
			width: 36%;
		}

		.logo {
			@include chamfr-logo();
			margin-bottom: 23px;
			@include media-breakpoint-down(xsm){
				width: 209px;
				height: 48px;
			}
		}

		.footer-paragraph{
			margin-bottom: 30px;
			@include media-breakpoint-down(xsm){
				margin-bottom: 23px;
			}
			p{
				font-size: 14px;
				line-height: 24px;
				text-underline-position: from-font;
				text-decoration-skip-ink: none;
				color: $chamfr-medium-gray;
			}
		}

		.social {
			list-style: none;
			display: flex;
			justify-content: flex-start;
			margin: 0;
			padding: 0;
			font-size: 1.4rem;
			li {
				margin: 0 12px 0 0;
				&:last-child {
					margin-right: 0;
				}

				a {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 52px;
					height: 52px;
					border: 1px solid $chamfr-black;
					font-size: 24px;
					border-radius: 50%;
					transition: all 0.3s ease-in;
					background-color: $chamfr-black;
					i{
						transition: transform 0.3s ease-in, color 0.1s ease-in;
					}

					&:hover {
						text-decoration: none;
						color: $chamfr-white;
						background-color: $chamfr-pink;
						border-color: $chamfr-pink;
						i{
							transform: rotateY(360deg);
						}
					}
				}
			}

			+ p {
				margin-top: 4rem;
				@include media-breakpoint-down( $bp ) {
					margin-top: 2rem;
					text-align: center;
				}
			}
		}
	}

	.footer-newsletter{
		@include media-breakpoint-up( lg ) {
			flex: 0 0 50%;
			width: 50%;
		}

		@include media-breakpoint-up( xl ) {
			flex: 0 0 43%;
			width: 43%;
		}

		@include media-breakpoint-up( hd ) {
			flex: 0 0 36%;
			width: 36%;
		}
	}

	.mid-footer{
		padding: 47px 0 45px;

		@include media-breakpoint-down(xsm){
			padding: 0 0 45px;
		}
		
		.after-menu-item{
			@include media-breakpoint-down(xsm){
				margin: 0 -15px;
				padding: 1.5rem;
				border-bottom: 1px solid $chamfr-dark-gray;
			}
			
			a{
				font-size: 21px;
				line-height: 24.68px;
				color: $chamfr-pink;
				font-weight: 800;

				&:hover{
					text-decoration: none;
				}

				@include media-breakpoint-up(sm){
					font-size: 14px;
					font-weight: 600;
					line-height: 16.45px;
					padding: 20px 0 10px 22px;
					position: relative;
					display: inline-block;
					color: $chamfr-gray;
					&::after{
						content: "";
						position: absolute;
						width: 0.5px;
						height: 100%;
						background-color: $chamfr-medium-gray;
						left: 0;
						top: 0;
						transition: all 0.3s ease-in;
					}
					&:hover{
						text-decoration: none;
						&::after{
							background-color: $chamfr-pink;
						}
					}
				}
			}
		}
	}

	.footer-menu {

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		.menu {
			@include media-breakpoint-up( sm ) {
				display: flex;
			}

			@include media-breakpoint-down(md){
				flex-wrap: wrap;
			}

			> .menu-item {
				position: relative;
				margin: 0 -15px;
				padding: 1.5rem;
				border-top: 1px solid $chamfr-dark-gray;

				&:last-of-type {
					border-bottom: 1px solid $chamfr-dark-gray;
				}
				@include media-breakpoint-only( sm ) {
					font-size: 1.2rem;
				}
				@include media-breakpoint-up( sm ) {
					min-width: 10rem;
					width: 0;
					flex-grow: 1;
					margin: 0;
					padding: 0;
					border: none;

					&:last-of-type {
						padding-right: 0;
						border: none;
					}
				}

				@include media-breakpoint-down(md){
					@include media-breakpoint-up(sm){
						&:first-child{
							width: 100%;
						}
						width: 50%;
						margin-top: 20px;
					}
				}

				&:after {
					content: '';
					position: absolute;
					top: 24px;
					right: 15px;
					transform: rotate( -90deg );
					transition: transform 0.3s ease-out;
					@include chevron-pink-down;
					@include media-breakpoint-up( sm ) {
						display: none;
					}
				}

				.sub-menu {
					overflow: hidden;
					max-height: 0;
					transition: max-height 0.5s ease-out;

					@include media-breakpoint-up( sm ) {
						max-height: none;
						margin-top: 22px;
					}
					> .menu-item {
						&:first-of-type {
							margin-top: 1.6rem;
							@include media-breakpoint-up( sm ) {
								margin-top: 0;
							}
						}

						a{
							padding: 10px 0 10px 22px;
							position: relative;
							display: block;
							font-size: 14px;
							line-height: 16.45px;
							&::after{
								content: "";
								position: absolute;
								width: 0.5px;
								height: 100%;
								background-color: $chamfr-medium-gray;
								left: 0;
								top: 0;
								transition: all 0.3s ease-in;
							}
							&:hover{
								text-decoration: none;
								&::after{
									background-color: $chamfr-pink;
								}
							}
						}
					}
				}
				&.li-open {
					&:after {
						transform: rotate( 0 );
					}
					.sub-menu {
						max-height: 100rem;
						@include media-breakpoint-up( sm ) {
							max-height: none;
						}
					}
				}
				@include media-breakpoint-up( sm ) {
					@for $i from 1 to 5 {
						&.columns-#{$i} {
							flex-grow: $i;

							.sub-menu {
								columns: $i;
							}
						}
					}
				}

				/* stylelint-disable-next-line no-descending-specificity */
				> a {
					display: block;
					color: $chamfr-pink;
					font-size: 24px;
					font-weight: 800;
					line-height: 28.2px;
					&:hover,
					&:focus,
					&:active {
						text-decoration: none;
					}
					@include media-breakpoint-up( sm ) {
						display: inline;
						pointer-events: none;
					}
					@include media-breakpoint-down(xsm){
						font-size: 21px;
						line-height: 24.68px;
					}
				}

				@include media-breakpoint-up(sm){
					&:not(:first-child){
						.sub-menu{
							a{
								font-size: 18px;
								font-weight: 600;
								line-height: 21.15px;
							}
						}
					}
				}
				
			}
		}
	}
	
}

.bottom-footer{
	background-color: $chamfr-black;

	.bottomline {
		padding: 28px 0 27px;
		font-size: 1.4rem;
		text-align: center;

		@include media-breakpoint-up( md ) {
			display: flex;
			text-align: left;
			column-gap: 17px;
		}
		@include media-breakpoint-up(sm){
			padding: 20px 0;
		}

		.copyright {
			margin: 0 0 0.8rem;
			@include media-breakpoint-up( md ) {
				margin: 0;
			}
			p {
				margin: 0 0 1rem;
				color: $chamfr-medium-gray;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.terms{
			ul {
				list-style: none;
				display: block;
				margin: 0;
				padding: 0;
				@include media-breakpoint-up( md ) {
					display: flex;
				}
	
				/* stylelint-disable-next-line no-descending-specificity */
				> li {
					display: inline-block;
					margin: 0 10px 0 0;
					padding: 0 10px 0 0;
					border-right: 1px solid $chamfr-medium-gray;
					&:last-child {
						margin-right: 0;
						padding-right: 0;
						border-right: none;
					}
				}
			}
			a{
				color: $chamfr-medium-gray;
			}
		} 
	}
}

.checkout-footer {
	background-color: $body-black;
	color: $white;
	padding: $spacer 0;

	> div {
		@include make-container();
		@include chamfr-container-max-widths();
		text-align: right;
	}

	a {
		color: $white;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

// Hide pswp (photoswipe on other pages instead product page)
body:not(.product-template-default) .pswp {
	display: none;
}
