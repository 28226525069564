.woocommerce nav.woocommerce-pagination {
	text-align: center;
	margin: 0;
	padding: 5rem 1rem 1rem;

	@include media-breakpoint-down( sm ) {
		padding: 2rem 1rem 1rem;
	}

	ul {
		border: none;
		margin: 0;
		padding: 1rem;
		border-radius: 28px;
		transition: background-color 0.1s ease, box-shadow 0.1s ease;

		@include media-breakpoint-down( sm ) {
			padding: 0.5rem;
			border-radius: 19px;
		}

		li {
			border: none;
			span,
			a {
				min-width: 3.6rem;
				height: 3.6rem;
				margin: 0;
				border-radius: 50%;
				border: 2px solid transparent;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;

				@include media-breakpoint-down( sm ) {
					margin: 0;
					height: 2.8rem;
					min-width: 2.8rem;
				}
			}
			span {
				&.current {
					background-color: transparent;
					color: $pink;
				}
			}
			a {
				color: $black;
				opacity: 0.5;
				&.next,
				&.prev {
					border-color: $black;
					opacity: 1;
				}
			}
		}
	}

	&.fixed {
		position: fixed;
		z-index: 1;
		pointer-events: none;

		ul {
			pointer-events: auto;
			background-color: $white;
			@include medium-shadow();
		}
	}
}
