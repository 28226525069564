.product-categories {
	position: relative;
	padding: 0 0 16rem;
	text-align: center;

	@include media-breakpoint-down($bp-m) {
		padding-bottom: 8rem;
	}

	.btn {
		text-transform: uppercase !important;
	}

	h3 {
		margin: 0 0 4rem;
		text-align: center;

		@include media-breakpoint-down($bp-m) {
			margin: 0 0 2rem;
		}
	}

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		padding-bottom: 3rem;

		@include media-breakpoint-down($bp-m) {
			padding-bottom: 1rem;
		}
	}

	.product-category {
		display: flex;
		padding: 2rem 2.5rem;
		margin: 0 1rem 1rem 0;

		@include media-breakpoint-down($bp-m) {
			padding: 1rem 1rem;
			&:nth-child(2n) {
				margin-right: 0px;
			}
		}
		flex: 0 0 42%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: #323132;
		border-radius: 1rem;
		@include medium-shadow();
		font-size: 0.8rem;
		color: #fff;
		text-decoration: none;
		&.empty {
			background: none;
			box-shadow: none;
			max-height: 0px;
			padding-top: 0px;
			padding-bottom: 0px;
		}
		&:hover {
			color: #E11B72;

			.product-category__title {
				border-bottom: 1px solid $pink;
			}
		}
		> div {
			align-items: center;
			display: flex;
			flex-direction: column;
		}

		@include media-breakpoint-between($bp-down, $bp-sm) {
			flex: 0 0 24%;
		}

		@include media-breakpoint-up( $bp ) {
			padding: 2rem 2.5rem;
			flex: 0 0 23%;
		}
		@include media-breakpoint-up( xl ) {
			font-size: 1.6rem;
		}

		&__icon {
			height: 6rem;
			width: 6rem;
			margin: 0 0 1rem;

			@include media-breakpoint-up( $bp ) {
				height: 10rem;
				width: 10rem;
			}

			> img {
				height: 100%;
				width: 100%;
				object-fit: contain;
			}
		}

		&__title {
			font-size: 1.2rem;
			text-align: center;

			@include media-breakpoint-up( $bp ) {
				font-size: 2rem;
			}
		}

		&__link {
			position: absolute;
			left: 2.5rem;
			right: 2.5rem;
			bottom: 2rem;

			a {
				text-decoration: underline;
				text-underline-position: under;
				color: $dark-pink;

				&:hover,
				&:focus,
				&:active {
					text-decoration: underline;
					color: $dark-pink;
				}
			}
		}
	}

	&__slick-controls {
		margin: 0 0 2rem;
		text-align: right;

		@include media-breakpoint-up( $bp ) {
			position: absolute;
			top: 5.3rem;
			right: 0;
		}

		.slick-next {
			margin-right: 0;
		}
	}
	.btn {
		text-transform: unset;
		@include media-breakpoint-down($bp-m) {
			width: 86%;
		}
	}
}
