@mixin retina {
	@media ( min-device-pixel-ratio: 1.5 ) {
		@content;
	}
}

// grid helpers
@mixin make-container-max-widths-since(
	$since-breakpoint: xs,
	$max-widths: $container-max-widths,
	$breakpoints: $grid-breakpoints
) {
	$since-found: false;

	@each $breakpoint, $container-max-width in $max-widths {
		@if $breakpoint == $since-breakpoint {
			$since-found: true;
		}
		@if $since-found {
			@include media-breakpoint-up( $breakpoint, $breakpoints ) {
				max-width: $container-max-width;
			}
		}
	}
}

/**
 * Back compat to suppress Bootstrap deprecation warning
 */
@mixin chamfr-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
	@each $breakpoint, $container-max-width in $max-widths {
		@include media-breakpoint-up($breakpoint, $breakpoints) {
			max-width: $container-max-width;
		}
	}
}

/* Needed for multiple width's layoyt eg. standard wide container, middle, small containers.
@mixin  make-negative-margins-from-smaller-to-middle(){
  @include media-breakpoint-up(lg){
    max-width: 100vw;
    margin-left: ($middle-single-container-max-width - $smaller-single-container-max-width) / -2;
    margin-right: ($middle-single-container-max-width - $smaller-single-container-max-width) / -2;
  }
}

@mixin  make-negative-margins-from-smaller-to-large(){

  @include media-breakpoint-up(xl){
    max-width: 100vw;
    margin-left: ($large-single-container-max-width - $smaller-single-container-max-width) / -2;
    margin-right: ($large-single-container-max-width - $smaller-single-container-max-width) / -2;
  }
  @include media-breakpoint-up(hd){
    max-width: 100vw;
    margin-left: ($large-single-container-max-width-hd - $smaller-single-container-max-width) / -2;
    margin-right: ($large-single-container-max-width-hd - $smaller-single-container-max-width) / -2;
  }
}
*/

@mixin list-unstyled() {
	list-style: none;
}

@mixin list-unstyled-at-all {
	@include list-unstyled();
	padding: 0;
	margin: 0;
}

@mixin optimize2DAnimationsOn3D() {
	backface-visibility: hidden;
	//perspective: 1000;
	transform: translateZ(0);
}

@mixin border-radius( $radius ) {
	border-radius: $radius;
}

@mixin transform( $transform ) {
	transform: $transform;
}

@mixin transition( $transition ) {
	transition: $transition;
}

@mixin transition-delay( $delay ) {
	transition-delay: $delay;
}
