.membership-levels {
	.wp-block-column {

		.chamfr-seller-pricing-option {
			min-height: 300px;
			color: $white;
			background-color: $black;
			border-radius: 20px;
			padding: 20px;
			text-align: center;

			.caption {
				font-size: 36px;
				font-weight: 700;
				text-transform: uppercase;
			}

			.price {
				font-size: 32px;
				margin-bottom: 0;

				em {
					font-size: 16px;
				}
			}

			.price-note {
				font-size: 12px;
			}
		}

		&.premium {
			padding: 5px 5px 7px 5px;
			margin-top: -25px;
			border-radius: 20px;
			margin-bottom: 0px;

			.chamfr-seller-pricing-option {
				min-height: 290px;
			}

			.most-popular {
				text-align: center;
				color: #fff;
				text-transform: uppercase;
				display: block;
				font-size: 14px;
			}
		}
	}
}