.autocomplete-suggestion {
	background-color: #fff;
	border: 1px solid #ccc;
	padding: 8px;
	cursor: pointer;
}

.autocomplete-suggestion a {
	text-decoration: none;
	color: #333;
}

/* ===== */
.aa-suggestions-category {
	font-weight: bold;
	padding: 5px 10px;
	border-bottom: 1px solid #eee;
	background-color: #f5f5f5;
}

.aa-suggestion {
	display: flex;
	align-items: center;
	padding: 10px;
	border-bottom: 1px solid #eee;
	cursor: pointer;
}

.aa-suggestion img {
	height: 40px;
	width: 40px;
	object-fit: cover;
	margin-right: 10px;
}

.aa-suggestion strong {
	margin-right: auto;
}

.aa-suggestion span {
	margin-left: 10px;
	color: #888;
}

.aa-suggestion .add-to-cart {
	background-color: #e11d73;
	color: white;
	border: none;
	padding: 5px 10px;
	cursor: pointer;
	font-size: 12px;
}

.aa-Panel {
	z-index: 10000;
	.aa-PanelLayout {
		.aa-Source {
			.aa-List {
				.aa-Item {
					.aa-ItemWrapper {
						.aa-ItemContent {
							.aa-ItemContentBody {
								.aa-ItemContentTitle {
									mark {
										padding: 0px;
									}
								}
								.aa-ItemContentDescription {
									mark {
										padding: 0px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

#autocomplete-searchbar {
	.aa-Autocomplete {
		color: #313131;
		margin: 0;

		.aa-Form {
			border-color: transparent;
			box-shadow: none;
			display: block;
			border: none;
			border-radius: 8px;

			&:focus-within {
				border-color: transparent;
				box-shadow: none;
			}

			.aa-InputWrapperPrefix {
				order: 4;
				position: absolute;
				z-index: 3;
				right: 0px;
				top: 0px;
				display: block;
				bottom: 0px;

				.aa-Label {
					.aa-SubmitButton {
						position: absolute;
						right: 0px;
						top: 0px;
						width: 105.05px;
						height: 48px;
						background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
						background-position: center;
						background-size: 25px;
						background-repeat: no-repeat;
						background-color: $chamfr-pink;
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
						border-top-right-radius: 8px;
						border-bottom-right-radius: 8px;
						transition: all .3s ease-out;

						@include media-breakpoint-down(xsm){
							width: 40px;
							height: 40px;
							background-size: 20px;
						}
						
						.aa-SubmitIcon {
							display: none;
						}

						&:hover{
							background-color: $chamfr-raspberry;
						}
					}
				}

				.aa-LoadingIndicator {
					display: none !important;
					.aa-LoadingIcon {
						display: none !important;
					}
				}
			}

			.aa-InputWrapper {
				order: 1;
				width: 100%;
				max-width: 608px;
				display: block;

				@include media-breakpoint-down(lg){
					max-width: 100%;
				}
				.aa-Input {
					width: calc(100% - 105px);
					height: auto;
					padding: 14.5px 16px;
					background-color: $chamfr-white;
					border-radius: 8px;
					border: none;
					appearance: none;
					@include tiny-shadow();
					font-size: 16px;
					line-height: 18.8px;

					@include media-breakpoint-down(xsm){
						width: calc(100% - 40px);
						padding: 10.6px 12px;
						font-size: 12px;
					}
				}
			}

			.aa-InputWrapperSuffix {
				display: none;
				order: 2;
			}
		}
	}
}

.aa {
	&-PanelSections {
		@include media-breakpoint-up(md) {
			column-gap: var(--aa-spacing);
			display: flex;
		}
	}

	&-PanelSection {
		padding: var(--aa-spacing-half);
		display: flex;
		flex-direction: column;
		width: 100%;

		@include media-breakpoint-up(md) {
			&--left {
				width: 65%;
			}

			&--right {
				padding-left: var(--aa-spacing);
				padding-right: var(--aa-spacing);
				width: 35%;
				background-color: $light-gray;
			}
		}
	}

	&-SourceHeaderTitle {
		font-size: 1.3rem;
	}

	&-Category,
	&-Post,
	&-Product,
	&-Supplier {
		&:hover {
			color: inherit;
			text-decoration: none;
		}
	}

	&-Product {
		padding: calc($spacer / 2);
		border-bottom: 1px solid $border-color;

		.aa-ItemContentTitle {
			text-overflow: inherit;
			white-space: inherit;
			overflow: inherit;
			color: $pink;
			font-weight: 700;
		}
	}

	&-SoldBy {
		font-size: 1.2rem;
		color: $gray;
	}

	&-Source {
		&[data-autocomplete-source-id="products"] {
			flex-grow: 1;
			padding-bottom: 4rem;

			.aa-SourceHeader {
				margin-left: calc(var(--aa-spacing) / -2);
				margin-right: calc(var(--aa-spacing) * -1.5);
				padding: calc(var(--aa-spacing) / 2);

				background-color: $light-gray;
			}

			.aa-SourceFooter {
				position: absolute;
				bottom: calc(var(--aa-spacing) / -2);
				left: calc(var(--aa-spacing) / -2);
				right: calc(var(--aa-spacing) * -1.5);
				text-align: right;
				padding: 1.3rem calc(var(--aa-spacing) * 2) 1.3rem;
				font-weight: 700;
			}
		}

		&[data-autocomplete-source-id="suppliers"],
		&[data-autocomplete-source-id="posts"] {
			border-bottom: 1px solid $border-color;
			padding-bottom: 1rem;
			margin-bottom: 1rem;

			&:last-child {
				border-bottom: none;
				padding-bottom: 0;
				margin-bottom: 0;
			}

			.aa-Item {
				padding: 4px calc(var(--aa-spacing) / 2);
			}

			.aa-ItemContentTitle {
				text-overflow: inherit;
				white-space: inherit;
				overflow: inherit;
			}

			.aa-SourceFooter {
				padding: calc(var(--aa-spacing) / 2) 0;
				text-align: right;
				a {
					color: inherit;
				}
			}
		}
	}
}
