@mixin rich-search() {
	flex: 0 0 100%;
	margin-bottom: 9rem;
	@media ( max-width: 767px ) {
		display: none;
	}

	form {
		max-width: 1016px;
		margin-left: auto;
		margin-right: auto;
		position: relative;

		&:before {
			position: absolute;
			content: '';
			left: -1.3em;
			bottom: -1.7em;
			height: 120%;
			width: 80%;
			background: url( ../images/dots.svg ) repeat bottom left;
			opacity: 0.7;
			z-index: -1;
		}
	}

	input[type='text'] {
		font-size: 2.6rem;
		padding: 0.8em 3.4em 0.8em 0.8em;
		line-height: 1;
		height: auto;

		&::placeholder {
			color: transparentize( $black, 0.5 );
		}
	}

	.search-submit {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 7rem;
		/* stylelint-disable-next-line function-url-quotes */
		background-image: url( "data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A" );
		background-position: 2.2rem 2.2rem;
		background-size: 3rem;
		background-repeat: no-repeat;
		background-color: #e11d73;
		border-top-right-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
	}
}
