.login-register {
	padding-top: 7rem;

	> div {
		padding-bottom: 5rem;
	}

	.woocommerce-form-group {
		margin: 0 0 3rem;
	}
	.input-checkbox {
		display: inline-block;
		vertical-align: middle;
		position: static;
		width: 3rem;
		height: 3rem;
		margin-right: 1.5rem;
		appearance: none;

		&:checked {
			background-image: url( ../images/check.svg );
			background-repeat: no-repeat;
			background-position: center;
		}
	}
	label[for='apply_for_vendor'] + br {
		display: none;
	}
	.woocommerce-form p {
		margin: 0 0 3rem;
	}
}
.beta-message-before-login {
	margin: 0 0 5rem;
}
.beta-message-after-login {
	margin: 5rem 0;

	p {
		margin: 3rem 0 0;
	}
}
