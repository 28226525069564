.contact-seller-form {
	display: none;
}

.chamfr-single-product {
	@include make-container();
	@include chamfr-container-max-widths();

	position: relative;

	padding: 5rem 15px 4rem;

	@include media-breakpoint-up( md ) {
		padding: 9rem 15px 5rem;
	}
	@include media-breakpoint-up( hd ) {
		padding: 9rem 165px 5rem;
	}

	h1 {
		margin: 0 0 3rem;
		font-size: 1.5rem;
		@include media-breakpoint-up( sm ) {
			font-size: 3rem;
		}
	}

	&__header {
		display: flex;
		align-items: center;
		margin: 0 0 3rem;
		@include media-breakpoint-up( sm ) {
			margin: 0 0 5rem;
		}
		@include media-breakpoint-up( hd ) {
			display: block;
			position: relative;
			margin: 0 0 3rem;
		}

		h1 {
			margin: 0;
		}
	}

	&__thumb {
		flex: 0 0 6rem;
		width: 6rem;
		height: 6rem;
		margin-right: 1rem;

		@include media-breakpoint-up( sm ) {
			flex: 0 0 12rem;
			width: 12rem;
			height: 12rem;
			margin-right: 3rem;
		}

		@include media-breakpoint-up( hd ) {
			position: absolute;
			top: 50%;
			left: -150px;
			margin: 0;
			transform: translateY( -50% );
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: center;
		}
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
		margin: 0 $grid-gutter-margin;
		@include media-breakpoint-up( lg ) {
			display: block;
			overflow: hidden;
		}
	}
	&__description {
		order: 2;
		float: left;
		position: relative;
		margin: 0 auto;

		&::before {
			content: '';
			display: block;
			clear: both;
		}

		@include media-breakpoint-up( lg ) {
			float: left;
			width: 100%;
		}

		&.wide {
			margin-top: 3rem;
			@include media-breakpoint-up( lg ) {
				width: 100%;
				min-height: 0;
				margin-top: 0;
			}
		}

		& iframe {
			@include media-breakpoint-down( lg ) {
				width: 100%;
			}
		}
	}
	&__details {
		order: 2;
		margin-top: 4.6rem;
		@include make-col-ready();
		@include media-breakpoint-up( lg ) {
			float: left;
			width: 60%;
			min-height: 750px;
		}
		&.wide {
			margin-top: 3rem;
			@include media-breakpoint-up( lg ) {
				width: 100%;
				min-height: 0;
				margin-top: 0;
			}

			.woocommerce-product-attributes-item--attribute_pa_datasheet {
				.woocommerce-product-attributes-item__value {
					@include media-breakpoint-up(xs) {
						max-width: 300px;
					}
					@include media-breakpoint-only(sm) {
						max-width: 414px;
					}
					@include media-breakpoint-only(md) {
						max-width: 593px;
					}

					a {
						@include media-breakpoint-down( md ) {
							text-overflow: ellipsis;
							white-space: nowrap;
							display: block;
							overflow: hidden;

							&:before {
								content: url('../images/pdf_file_icon.svg');
								margin-right: 5px;
								height: 22px;
								width: 20px;
								display: block;
								float: left;
							}
						}
					}
				}
			}
		}
	}
	&__summary {
		order: 3;
		@include make-col-ready();
		@include media-breakpoint-up( lg ) {
			float: left;
			width: 40%;
		}

		&--cart {
			order: 1;
			@include media-breakpoint-up( lg ) {
				float: right;
				margin-top: 4.6rem;
			}
		}
		&--cfrq {
			order: 1;
			@include media-breakpoint-up( lg ) {
				float: right;
			}
		}
	}
	&__meta {
		padding: 2.5rem;
		border: 1px solid $border-color;
		border-radius: 10px;
		margin-top: 1rem;
		margin-bottom: 2rem;
		@include media-breakpoint-up( lg ) {
			//margin-top: 4.6rem;
		}

		.wcvendors_ships_from > br {
			display: none;
		}
	}

	&__cart {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;
		margin: 0 -15px 3rem;
		padding: 2.5rem;
		background-color: $white;
		border-radius: 0;
		@include media-breakpoint-up( sm ) {
			margin: 0 0 3rem;
			border-radius: 10px;
		}
		@include media-breakpoint-up( xsm ) {
			justify-content: center;
		}

		#wc_bis_product_form, #wc_bis_already_registered {
			width: 49%;
			margin: 0;
			text-align: center;

			#wc_bis_email {
				padding: 3px 8px;
				height: auto;
			}
			.button {
				padding: 8px 8px;
			}
			#wc_bis_send_form {
				margin-bottom: 0px;
			}

			.woocommerce-info {
				border: none;
				margin: 0;
				padding: 0;
				background-color: inherit;
				color: inherit;
				word-wrap: inherit;

				&::before {
					display: none;
				}
			}
		}

		&--mobile {
			margin: 0 -15px 6rem;
			border-radius: 0;

			@include media-breakpoint-up( sm ) {
				display: none;
				margin: 0;
			}
		}

		.cart-qty {
			width: 49%;
			text-align: center;
			margin: 0 auto;

			p {
				margin: 0;
				opacity: 0.5;

				&.qty-label {
					font-size: 2.5rem;
				}
			}
			.price {
				flex: 0 0 100%;
				font-size: 2.2rem;
				opacity: 1;
			}
		}

		form.cart {
			flex: 0 0 40%;
			div.quantity {
				float: none;
			}
			.button {
				padding: 8px 8px;
				height: auto;
				line-height: 1;
			}
		}

		.lead-time {
			margin-top: 1rem;
			margin-bottom: 1rem;
			flex: 0 0 100%;
			text-align: center;

			p {
				display: block;
				margin: 0 auto;
				color: $light-green;
				font-size: 13px;
			}
		}
	}

	&__cfrq {
		display: block;
		margin: 0 -15px 3rem;
		padding: 2.5rem;
		background-color: $white;
		border-radius: 0;
		@include media-breakpoint-up( sm ) {
			margin: 0 0 2rem;
			border-radius: 10px;
		}

		h5 {
			margin: 0 0 1rem;
		}
		&-text {
			flex: 0 0 100%;
		}
		&-btn {
			text-align: right;
			margin-top: 1rem;

			.btn {
				padding: 8px 8px;
			}
		}

		&--mobile {
			margin: 0 -15px 6rem;
			border-radius: 0;

			@include media-breakpoint-up( sm ) {
				display: none;
				margin: 0;
			}
		}
	}

	&__related {
		@include make-container();
		@include chamfr-container-max-widths();
		padding-top: 1rem;
		padding-bottom: 8rem;
		@include media-breakpoint-up( md ) {
			padding-bottom: 12rem;
		}
		@include media-breakpoint-up( lg ) {
			padding-top: 5rem;
		}

		&.product-categories {
			h3 {
				margin: 0 0 2rem;
				@include media-breakpoint-up( sm ) {
					margin: 0 0 3rem;
				}
			}
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 2rem 0 0;
			@include media-breakpoint-up( md ) {
				@include make-row();
				align-items: stretch;
			}

			@include media-breakpoint-up( xl ) {
				padding: 5rem 5% 0;
			}
			li {
				width: 100%;
				padding: 0 5px;
				@include media-breakpoint-up( sm ) {
					width: 50%;
				}
				@include media-breakpoint-up( md ) {
					@include make-col-ready();
					width: 33.333%;
					padding-bottom: $grid-gutter-width;
				}
			}
			&.slick-slider {
				margin: 0 -5px 3.5rem;
			}
			.slick-slide {
				opacity: 0.5;
				&.slick-active {
					opacity: 1;
				}
			}

			.slick-list {
				overflow: visible;
			}
		}
		.slick-related-cat__controls {
			margin: 0 -8px;
			text-align: right;
			@include media-breakpoint-up( md ) {
				display: none;
			}
		}
	}
}

.woocommerce div.product form.cart {
	margin: 0;
}
.woocommerce div.product p.price,
.woocommerce div.product span.price,
.woocommerce div.product .stock {
	color: $black;
}
.woocommerce div.product p.stock {
	font-size: inherit;
}
.woocommerce div.product form.cart div.quantity {
	float: none;
	margin: 0 0 4px;
	input {
		width: 100%;
		text-align: left;
		padding: 3px 8px;
		height: auto;
		border-radius: 2px;
	}
}

.tiered-pricing-wrapper {
	padding: 1.5rem;
	margin: 1.5rem;
	background: $chamfr-lightest-gray;
	border-radius: 8px;
	color: $chamfr-dark-gray;

	h3 {
		font-size: 1.8rem;
		text-align: center;
		font-weight: bold;
		margin: 0px;
	}

	table.tiered-pricing-table {
		border: none;
		width: 50%;
		margin: 0 auto;
		border-radius: 0px;
		border-collapse: collapse;

		thead {
			tr {
				th {
					text-align: left;
					padding: 1rem 0px 0px 0px;

					&.tiered-pricing-header {
						padding-left: 1rem;
					}
					span {
						font-size: 1.4rem;
						border-bottom: 1px solid $chamfr-dark-gray;
					}
				}
			}
		}


		tr {
			background-color: transparent;

			&.tiered-pricing--active {
				background-color: transparent !important;

				td {
					background-color: transparent !important;
					color: $chamfr-dark-gray;
					border: none;
					padding: 1px;
				}
			}

			td {
				background-color: transparent !important;
				color: $chamfr-dark-gray;
				border: none;
				padding: 1px;

				&.volume-quantity {
					text-align: left;
				}
				&.volume-price {
					text-align: left;
					padding-left: 1rem;

					.amount {
						background-color: transparent !important;
						color: $chamfr-dark-gray;
						transition: none;
					}
				}
			}
		}
	}

	.note {
		font-size: 1.2rem;
		text-align: center;
		margin-top: 0.5rem;
	}
}

.woocommerce div.product form.cart .button {
	float: none;
	width: 100%;
}
.woocommerce div.product div.images,
.woocommerce-page div.product div.images,
.woocommerce #content div.product div.images,
.woocommerce-page #content div.product div.images {
	float: none;
	width: auto;
}
.woocommerce-product-gallery {
	.flex-viewport {
		background-color: $white;
		border-radius: 10px;
		//padding: 2rem;
		@include medium-shadow2();
	}

	&__image {
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}
.woocommerce div.product div.images .woocommerce-product-gallery__image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.woocommerce-product-gallery__trigger {
	display: none;
}
.woocommerce div.product div.images .flex-control-thumbs {
	margin-top: 2rem;
	margin-left: -1rem;
	margin-right: -1rem;
	li {
		padding: 1rem;
		img {
			background-color: $white;
			border-radius: 10px;
			border: 2px solid $white;

			&.flex-active {
				border-color: $dark-pink;
			}
		}
	}
}
.woocommerce-product-gallery.woocommerce-product-gallery--with-images.images {
	position: relative;
}
.download-pic {
	position: absolute;
	top: 2rem;
	right: 2rem;
	z-index: 200;
	width: 3rem;
	height: 3rem;
	background: $white url( ../images/download.svg ) no-repeat center;
	background-size: 80%;
	cursor: pointer;
}

/* Price Tiers*/
.woocommerce table.shop_table.price-rules-table {
	margin: 0 0 3rem;
	border: none;
	border-collapse: collapse;
	border-top: 1px solid $border-gray;
	border-radius: 0;

	tr {
		background: transparent;
		border-bottom: 1px solid $border-gray;

		&.price-rule-active {
			border: none;
		}
	}

	th,
	td {
		padding: 9px 12px;
		border: none;
		font-weight: 400;
		line-height: 1.5;
	}
	th {
		font-weight: 500;
	}
}
@media print {
	.single-product {
		.page-header,
		.page-footer,
		form.cart,
		.contact-seller,
		.chamfr-single-product__related .btn,
		.product-category__link,
		.helper-cta,
		.download-pic {
			display: none;
		}
		.chamfr-single-product {
			padding: 2rem;
		}
		.price-rule-active td {
			background-color: transparent !important;
			font-weight: 700 !important;
			color: $black !important;
		}
		.price-rule-active .amount {
			color: $black !important;
		}
		.flex-control-thumbs {
			page-break-inside: avoid;
			li {
				page-break-inside: avoid;
				page-break-before: avoid;
				page-break-after: avoid;
			}
		}
		.chamfr-single-product__meta,
		.chamfr-single-product__cart,
		.chamfr-single-product__related {
			page-break-inside: avoid;
		}
	}
}
