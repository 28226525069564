@mixin submenu-bracket() {
	content: '';
	display: block;
	position: absolute;
	bottom: 0.4rem;
	right: 0;
	width: 12px;
	height: 8px;
	background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
	@include media-breakpoint-up( $bp-m ) {
		display: inline;
	}
}
