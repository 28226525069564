.wcv-grid .chamfr-vendor-dashboard-content {
	.button {
		@extend .btn;
		color: $white !important;
	}
	.btn {
		text-decoration: none;
		color: $white;
	}
	.btn-secondary {
		color: $pink;
	}
	.wcv-button {
		@extend .btn;
		// @extend .btn-secondary;

		&.btn-secondary {
			@extend .btn-secondary;
			color: $pink !important;
		}
	}
	.wcv-search {
		overflow: hidden;
		margin-left: -15px; // for bow shadows
		margin-right: -15px;
		padding-left: 15px;
		padding-right: 15px;
		.wcv-button {
			@extend .btn;
			@extend .btn-secondary;
		}
	}
	.all-40 {
		@include media-breakpoint-down( sm ) {
			width: 100%;
		}
	}
	.wcvendors-table-recent_product {
		img {
			&.size-50x50 {
				max-width: 50px;
			}
		}
	}
}
.chamfr-vendor-product, .chamfr-vendor-order {
	.search-title-pagination:last-of-type {
		.wcv_search_results {
			display: none;
		}
	}
}
.wcv-usage-stats {
	margin-bottom: 3rem;
}
.woocommerce-MyAccount-content {
	.stripe-connect.light-blue {
		@extend .btn;
		height: auto;
		padding: 1.6rem 3rem;
		line-height: 1.2;
		margin-bottom: 2rem;

		span {
			font: 600 1.6rem/5rem $content-font;
			line-height: 1.2;
			text-shadow: none;
			color: $white;
			display: inline;
			height: auto;
			padding: 0;
			background: transparent;
			box-shadow: none;
			&:before {
				display: none;
			}
		}
	}
}

/* WC Vendors overrides */
.wcv {
	&-header {
		&-container {
			flex: 0 0 100%;
		}
	}
	&-navigation {
		ul.menu {
			&.black {
				background-color: transparent;

				li.active {
					background-color: transparent;
				}
			}
			&.horizontal {
				> li {
					a {
						display: inline-block;
					}

					&:first-child,
					&:last-child {
						a {
							border-radius: 5px;
						}
					}
				}
			}
		}
	}

	&_dashboard {
		&_datepicker {
			.all-33 {
				.control-group {
					padding-left: 2em;
				}

				@include media-breakpoint-down( xs ) {
					width: 100%;

					.control-group {
						padding-left: 0;
					}
				}
			}
			.tiny-50,
			.tiny-100 {
				@include media-breakpoint-down( sm ) {
					width: 100%;
				}
			}
		}
	}

	&-search-form {
		input:not( [type='submit'] ),
		select,
		textarea {
			width: auto;
		}
		.control.append-button {
			display: flex;

			> span {
				display: block;
				flex: 1 1 100%;
				margin: 0 2rem 0 0;
				input {
					width: 100%;
				}
			}
		}
	}

	&-form {
		input[type='checkbox'],
		input[type='radio'] {
			display: none !important; // sad but need this to override WCV inline styles.
		}

		&-exclude {
			.tiny-50 + .control-group {
				.wcv-cols-group.wcv-horizontal-gutters {
					margin-left: 0;
				}
			}
		}
	}

	&-order-header {
		.align-right {
			padding-top: 10px;
		}
	}

	&-modal {
		> .modal-header {
			display: block;
			position: relative;

			> .modal-close {
				position: absolute;
				top: 2rem;
				right: 2rem;
				background: $dark-pink;
				opacity: 1;
				text-shadow: none;

				&:before {
					content: '\2715';
					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 1;
					transform: translate( -50%, -50% );
					font-size: 1.4rem;
					line-height: 1;
					color: $white;
				}

				.wcv-icon {
					display: none;
				}
			}
			h3 {
				margin-bottom: 1rem;
			}
			> .modal-print {
				color: $dark-pink;
			}
		}
		> .modal-footer,
		> .modal-header,
		> footer,
		> header {
			padding: 1rem 2rem 2rem;
			@include media-breakpoint-up( md ) {
				padding: 1rem 3rem 3rem;
			}
		}
		> .modal-body {
			padding: 2rem 2rem 4rem;
			@include media-breakpoint-up( md ) {
				padding: 3rem 3rem 5rem;
			}
		}
		.wcv-order-customer-details.wcv-cols-group.wcv-horizontal-gutters {
			overflow-x: auto;
			overflow-y: hidden;
			width: 100%;
			margin: 0;

			+ .wcv-cols-group.wcv-horizontal-gutters {
				overflow-x: auto;
				overflow-y: hidden;
				width: 100%;
				margin: 0 !important;

				.all-100 {
					padding: 0 !important;
				}
			}
		}
	}
}
.wcv-grid input.btn {
	color: $white;
}
.wcv_dashboard_table_header {
	&.wcv-order-header .align-right {
		padding-top: 25px;

		br {
			display: none;
		}
	}
	.wcv-form {
		.control-group {
			line-height: 1.2;
			margin-bottom: 2rem;
		}
		label {
			height: 17px;
		}
	}
	.all-20 {
		width: 100%;
		@include media-breakpoint-up( hd ) {
			width: 25%;
			padding-left: 2rem;
		}
		&.align-right {
			text-align: left;
			@include media-breakpoint-up( hd ) {
				text-align: right;
			}
		}
	}
	.all-80 {
		width: 100%;
		@include media-breakpoint-up( hd ) {
			width: 75%;
		}
	}
	.tiny-100 {
		@include media-breakpoint-down( sm ) {
			width: 100%;
		}
	}
	.btn-secondary {
		border: none !important;
	}

	+ .wcv-cols-group.wcv-horizontal-gutters {
		overflow-x: auto;
		overflow-y: hidden;
		width: 100%;
		margin: 0 !important;
		padding-top: 3rem;

		.all-100 {
			padding: 0 !important;
		}
	}
}

table.wcv-table {
	background-color: transparent;
	tr th {
		font-weight: 500;
	}
}

.wcv-grid h1,
.wcv-grid h2,
.wcv-grid h3,
.wcv-grid h4,
.wcv-grid h5,
.wcv-grid h6 {
	font-weight: 500;
	line-height: 1.2;
	color: $dark-gray;
	margin: 3rem 0 2rem;

	@include media-breakpoint-down( sm ) {
		margin-bottom: 1rem;
	}
}
.wcv-grid h1:first-child,
.wcv-grid h2:first-child,
.wcv-grid h3:first-child,
.wcv-grid h4:first-child,
.wcv-grid h5:first-child,
.wcv-grid h6:first-child,
.wcv-grid p:first-child {
	margin-top: 3rem;
}
.wcv-grid h1 {
	@include h1;
}
.wcv-grid h2 {
	@include h2;
}
.wcv-grid h3 {
	@include h3;
}
.wcv-grid h4 {
	@include h4;
}
.wcv-grid h5 {
	@include h5;
}
.wcv-grid h6 {
	@include h6;
}
.wcv-grid a:hover {
	color: $pink;
}
.wcv-form input[type='color'],
.wcv-form input[type='date'],
.wcv-form input[type='datetime-local'],
.wcv-form input[type='datetime'],
.wcv-form input[type='email'],
.wcv-form input[type='month'],
.wcv-form input[type='number'],
.wcv-form input[type='password'],
.wcv-form input[type='search'],
.wcv-form input[type='tel'],
.wcv-form input[type='text'],
.wcv-form input[type='time'],
.wcv-form input[type='url'],
.wcv-form input[type='week'],
.wcv-form select,
.wcv-form textarea {
	padding: 1.5rem;
}
@media screen and ( min-width: 1261px ) {
	.wcv-cols-group.wcv-horizontal-gutters {
		margin-left: -2rem;
	}
	.wcv-cols-group.wcv-horizontal-gutters > [class*='all-'],
	.wcv-cols-group.wcv-horizontal-gutters > [class*='xlarge-'] {
		padding-left: 2rem;
	}
}
@media screen and ( max-width: 1260px ) and ( min-width: 961px ) {
	.wcv-cols-group.wcv-horizontal-gutters {
		margin-left: -2rem;
	}
	.wcv-cols-group.wcv-horizontal-gutters > [class*='all-'],
	.wcv-cols-group.wcv-horizontal-gutters > [class*='xlarge-'] {
		padding-left: 2rem;
	}
}

.wcv-form .control-group .inline li label {
	margin-right: 0;
}
.wcv-form .control-group input[type='checkbox'] + label,
.wcv-form .control-group input[type='radio'] + label {
	margin: 0;
	padding: 0;
}

.wcv-tabs {
	.tabs-nav li {
		a {
			display: block;
			padding: 1.3rem 2rem;
			border: 1px solid $darker-gray;
			border-radius: 5px;
			opacity: 0.5;
			font-size: 1.4rem;
			font-weight: 600;
			line-height: 1;
			text-transform: uppercase;
			text-align: center;
			text-decoration: none;
			white-space: nowrap;
			color: $darker-gray;
		}
		&.active {
			a {
				background: transparent;
				border: 1px solid $darker-gray;
				opacity: 1;
				text-decoration: none;
				color: $darker-gray;
				cursor: default;
			}
		}
	}

	&.top {
		> .tabs-nav {
			border-bottom: none;
			li {
				display: inline-block;
				float: none;
				a {
					margin: 0 1rem 1rem 0;
					border-radius: 5px;
				}
				&.active {
					a {
						margin: 0 1rem 1rem 0;
						border: 1px solid $darker-gray;
					}
				}
			}
		}
	}
}
.media-modal-content {
	.attachments-browser .media-toolbar {
		height: 90px;
	}
	.wp-core-ui .button-primary {
		font: 600 1.6rem/5rem $content-font;
		text-transform: uppercase;
		color: $white !important;
		background: $pink !important;
		border-color: $pink !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}
	.wp-core-ui .button-primary-disabled,
	.wp-core-ui .button-primary.disabled,
	.wp-core-ui .button-primary:disabled,
	.wp-core-ui .button-primary[disabled] {
		color: $light-gray !important;
		background: $dark-pink !important;
		border-color: $dark-pink !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}
	.wp-core-ui .button,
	.wp-core-ui .button-secondary {
		font: 600 1.6rem/5rem $content-font;
		text-transform: uppercase;
		color: $pink;
		border: 2px solid $pink;
		background: transparent;
		vertical-align: top;
		&:hover,
		&:focus,
		&:active {
			color: $pink;
			border: 2px solid $pink;
			background: transparent;
		}
	}
	.media-frame-toolbar .media-toolbar {
		background: $white;
	}
}
.wcv-tabs .tabs-content {
	margin-bottom: 2rem;
}
.wcv-store-msg {
	background: $dark-pink;
}

.wcv-grid .chamfr-vendor-product, .wcv-grid  .chamfr-vendor-order  {
	.all-50 {
		float: none;
		width: 100%;
		margin: 0 0 2rem;
		@include media-breakpoint-up( xl ) {
			float: left;
			width: 50%;
		}
	}
	h4 {
		font-size: 2rem;
		margin: 0 0 1rem;
	}
	.wcv_search_results {
		font-style: normal;
		@include h4();
	}
}
.wcvendors-table-wrapper {
	overflow-x: auto;
	overflow-y: hidden;
	margin: 3rem 0;
	padding: 0;
	border-radius: 10px;
	@include small-shadow();
	@include media-breakpoint-up( $bp ) {
		//overflow: hidden;
	}
	.wcvendors-table {
		border-collapse: collapse;
		width: 100%;
		min-width: 50rem;
		margin: 0;
		font-size: 1.4rem;

		&-product {
			tr {
				th,
				td {
					&:first-child {
						display: none;
						@include media-breakpoint-up( $bp ) {
							display: table-cell;
						}
					}
				}
			}
			img {
				display: none;
				@include media-breakpoint-up( $bp ) {
					display: block;
				}
			}
		}

		&-order {
			tr {
				td:nth-of-type( 3 ),
				th:nth-of-type( 3 ) {
					min-width: 200px;
				}
				td:last-of-type,
				th:last-of-type {
					min-width: 150px;
				}
			}
			br + strong {
				display: block;
				margin-top: 1rem;
			}
			br + strong + br {
				display: none;
			}
		}

		thead {
			background-color: $light-gray;

			> tr > th:first-child {
				border-top-left-radius: 10px;
			}
			> tr > th:last-child {
				border-top-right-radius: 10px;
			}
		}
		tbody {
			background-color: $white;
		}

		tr {
			th,
			td {
				padding: 15px 8px;
				border-bottom: 1px solid $light-gray;
			}
			th {
				font-weight: 700;
			}
		}
	}
}

.wcv-form p.tip {
	line-height: 1.2;
}
.wcv-product-type {
	display: none;
	margin: 0 0 3rem;
	.wcv-cols-group.wcv-horizontal-gutters {
		margin: 0;
		@include media-breakpoint-up( md ) {
			margin: 0 0 0 -2rem;
		}
	}
	.all-50.small-100 {
		float: none;
		width: 100%;
		padding: 0 0 2rem;
		@include media-breakpoint-up( md ) {
			float: left;
			width: 50%;
			padding: 0 0 0 2rem;
		}
	}
}
.wcv-product-shipping {
	.wcv-cols-group.wcv-horizontal-gutters {
		margin: 0;
		@include media-breakpoint-up( md ) {
			margin: 0 0 0 -2rem;
		}
		.all-33 {
			float: none;
			width: 100%;
			padding: 0 0 2rem;
			@include media-breakpoint-up( md ) {
				float: left;
				width: 33.3333%;
				padding: 0 0 0 2rem;
			}
		}
	}
}
.wcv_product_attributes {
	.wcv-column-group.wcv-horizontal-gutters {
		margin: 0;
		border: none !important;

		@include media-breakpoint-up( md ) {
			margin: 0 0 0 -2rem;
		}
		.all-100 {
			display: flex;

			&.align-right {
				display: block;
			}
			.button {
				margin-left: 2rem;
			}
		}
		.all-80 {
			float: none;
			width: 100%;
			padding: 0;
			@include media-breakpoint-up( md ) {
				float: left;
				width: 80%;
				padding: 0 0 0 2rem;
			}
		}
		.all-20 {
			display: none;
			@include media-breakpoint-up( md ) {
				display: block;
			}
		}
		.all-70 {
			float: none;
			width: 100%;
			padding: 0;
			@include media-breakpoint-up( md ) {
				float: left;
				width: 70%;
				padding: 0 0 0 2rem;
			}
		}
		.all-30 {
			float: none;
			width: 100%;
			padding: 0;
			@include media-breakpoint-up( md ) {
				float: left;
				width: 30%;
				padding: 0 0 0 2rem;
			}
		}
	}
	.product_attributes {
		clear: both;
	}
	.select2 {
		margin-bottom: 2rem;
	}
}
.woocommerce button.button.select_all_attributes,
.woocommerce button.button.select_no_attributes,
#product_save_button,
#draft_button {
	width: 100%;
	margin-bottom: 2rem;
	@include media-breakpoint-up( md ) {
		width: auto;
	}
}

#wcv-tiered-pricing-active + label + #wcv-pricing-tiers {
	display: none;
}
#wcv-tiered-pricing-active:checked + label + #wcv-pricing-tiers {
	display: block;
}
.wcv-pricing-tier {
	display: flex;
	justify-content: flex-start;
	margin: 0 0 2rem;

	input {
		margin-right: 2rem;
	}
	span.wcv-pricing-tier-remove {
		content: '';
		cursor: pointer;
		padding: 1.2rem 0.5rem;
		font-family: 'Font Awesome 5 Free'; /* stylelint-disable-line */

		&:before {
			content: '\f057';
		}
	}
}

.wcv-grid table .row-actions {
	&.row-actions-product,
	&.row-actions-order {
		opacity: 1;

		a {
			color: $pink;

			&:hover,
			&:focus,
			&:active {
				text-decoration: underline;
				color: $pink;
			}
		}
	}
}
.products_chart {
	@include media-breakpoint-up( sm ) {
		display: flex;
		align-items: center;
	}

	&_pie {
		flex: 0 0 25rem;
		width: 25rem;
	}
	&_legend {
		margin: 3rem 0 0;
		font-size: 1.2rem;
		@include media-breakpoint-up( sm ) {
			flex: 0 1 calc( 100% - 25rem );
			width: calc( 100% - 25rem );
			margin: 0;
			padding-left: 3rem;
		}

		&_item {
			margin: 0 0 0.5rem;

			span {
				display: inline-block;
				vertical-align: middle;
				width: 1.2rem;
				height: 1.2rem;
			}
		}
	}
}
#orders_chart,
#products_chart {
	padding: 0;
}

.chamfr-vendor-product,
.woocommerce .chamfr-vendor-product,
.chamfr-vendor-order,
.woocommerce .chamfr-vendor-order {
	nav.woocommerce-pagination ul li a.next,
	nav.woocommerce-pagination ul li a.prev {
		border: none;
	}

	.wcvendors-table-wrapper {
		+ .wcv-search {
			display: none;
		}
	}
}
.wcv-submit-btn {
	position: relative;
}
.wcv-submit-btn-disable.on {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

@include media-breakpoint-down( sm ) {
	.wcvendors-table-order .wcv-modal #order_line_items {
		display: initial;
	}
	.wcvendors-table-order td.total {
		display: table-cell;
	}
}
