#chamfr-vendors-dashboard-analytics {
	.chamfr-analytics-section {
		margin: 3rem 0;
		padding: 2rem;
		border-radius: 10px;
		-webkit-box-shadow: 0 3px 15px rgb(0 0 0 / 25%);
		box-shadow: 0 3px 15px rgb(0 0 0 / 25%);

		&_header {
			h4 {
				margin: 0;
			}
		}

		&_wrapper {
			&_numbers {
				@media screen and (max-width: 767px) {
					flex-direction: column;
					justify-content: center;
					align-items: stretch;
				}
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-wrap: nowrap;

				&_item {
					.chamfr-vendor-product & {
						cursor: pointer;
					}
					&:hover {
						background-color: #ffffff;
					}
					&.loading {
						position: relative;
						&:before {
							z-index: 2;
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							backdrop-filter: blur(5px);
							background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
							background-size: 200% 100%;
							background-position: -100% 0;
							animation: chamfr-analytics-loading 1.5s infinite;
						}
					}
					background-color: #eaeaea;
					flex-basis: 0;
					flex-grow: 1;
					min-height: 12rem;
					box-sizing: border-box;
					border: 1px solid #e0e0e0;
					padding: 2rem;
					text-align: center;
					@media screen and (max-width: 767px) {
						margin: 1rem 0;
					}
					margin: 0 2rem;

					h5 {
						margin: 0;
						font-size: 16px;
					}
					p {
						margin-top: 1rem;
						font-size: 40px;
						font-weight: bold;
					}
				}
			}

			&_tables, &_oos_tables {
				&_item {
					//background-color: #ffffff;
					&.loading {
						position: relative;
						&:before {
							z-index: 2;
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							backdrop-filter: blur(5px);
							background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
							background-size: 200% 100%;
							background-position: -100% 0;
							animation: chamfr-analytics-loading 1.5s infinite;
						}
					}
					@media screen and (max-width: 767px) {

					}
					min-height: 12rem;
					box-sizing: border-box;
					border: 1px solid #e0e0e0;
					padding: 1rem 0;
					text-align: center;
					margin: 1rem 0;

					h5 {
						position: relative;
						margin: 0.5rem 1rem;
						text-align: left;
						font-size: 18px;
					}
					table {
						position: relative;
						z-index: 1;
						font-size: 14px;
						border-bottom: 1px solid #808080;
						thead {
							background-color: #eaeaea;
							border-top: 1px solid #808080;
							tr {
								th {
									font-weight: bold;
									&:first-child {
										width: 70%;
									}
									&:last-child {
										width: 15%;
									}
								}
							}

						}
						tr {
							&:hover {
								background-color: #e0e0e0;
							}
						}
					}
				}
			}
		}
	}
}
@keyframes chamfr-analytics-loading {
	0% {
		background-position: -100% 0;
	}
	100% {
		background-position: 100% 0;
	}
}

