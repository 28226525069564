.twitter-feed-block {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #313232;
	padding: 4rem;
	margin: 0 0 15rem;
	@include media-breakpoint-down( md ) {
		flex-direction: column;
	}

	.twitter-timeline {
		padding-bottom: 0.3rem;
		text-decoration: none;
		display: inline-block;
		width: fit-content;

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
			border-bottom: 1px solid $pink;
		}
	}

	.twitter-feed {
		&-info {
			display: flex;
			flex-direction: column;
			width: 50%;
			padding-right: 1rem;

			@include media-breakpoint-down( md ) {
				width: 100%;
				padding-bottom: 1rem;
				padding-right: 0;
			}

			.title {
				color: #fff;
			}

			.description {
				padding-bottom: 2rem;
			}
			a {
				padding-bottom: 0.3rem;
				text-decoration: none;
				display: inline-block;
				width: fit-content;

				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
					border-bottom: 1px solid $pink;
				}
			}
		}

		&-iframe {
			width: 50%;
			height: 200px;
			padding-right: 0;
			@include media-breakpoint-down( md ) {
				width: 100%;
			}
			overflow-y: scroll;
		}
	}
}
