@mixin styled-scrollbar {
	//// lets style scrollbar!
	&::-webkit-scrollbar {
		width: 8px;
		background: transparent;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: transparentize( $white, 0.3 );
		border-radius: 8px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background-color: transparentize( $light-gray2, 0 );
		transition: all 0.2s;
	}

	/* hovered state can be different */
	&:hover {
		&::-webkit-scrollbar-thumb {
			border-radius: 8px;
			transition: all 0.2s;
			background-color: transparentize( $light-gray2, 0 );
		}
	}

	/* If Current browser's window lost focus, e.g. user has gone to another window. */
	&::-webkit-scrollbar-thumb:window-inactive {
		background: transparentize( $light-gray2, 0.2 );
	}
}
