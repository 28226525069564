.seller-top-block-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: normal;
  box-sizing: border-box;
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  margin-bottom: 2rem;
  padding: 1rem 1.5rem;

  .image-wrapper {
    flex-basis: 33.33%;
    flex-grow: 0;
    text-align: center;

    img {

    }
  }

  .text-wrapper {
    flex-basis: 66.66%;
    flex-grow: 0;
    padding-left: 5%;

    .title-wrapper {
      text-align: center;

      h1 {

      }
    }

    .subtitle-wrapper {
      text-align: center;

      h2 {

      }
    }

    .content-wrapper {
      div {

      }
    }
  }
}
