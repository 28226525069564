.checkout-steps {
	h1 {
		font-size: 38px;
		font-weight: 800;
	}
}

.checkout-step {
	border-bottom: 1px solid $chamfr-gray;
	padding: $spacer 0;

	&-heading {
		display: flex;

		h4 {
			font-size: 22px;
			font-weight: 800;
			flex-grow: 1;
			margin: 0;
			color: $chamfr-dark-gray;
		}
	}

	h5 {
		font-size: 18px;
		font-weight: 700;
	}

	&-edit {
		font-size: 14px;
		display: none;

		a {
			color: $text-black;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&-content,
	&-summary {
		display: none;
		padding: $spacer;
		font-size: 14px;

		@include media-breakpoint-down(sm) {
			padding: $spacer 0;
		}
	}

	&-button {
		@include button-primary();
		min-width: 300px;
	}

	&-active {
		h4 {
			color: $text-black;
		}

		.checkout-step-content {
			display: block;
		}
	}

	&-completed {
		h4 {
			color: $pink;

			&:after {
				content: "";
				display: inline-block;
				margin-left: 8px;
				transform: translateY(2px);
				width: 18px;
				height: 18px;
				background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 9.90849L6.44231 15.125L16 2.875' stroke='%23E11D73' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			}
		}

		.checkout-step-edit {
			display: block;
		}

		.checkout-step-summary {
			display: block;
		}
	}

	input.error {
		border-color: $chamfr-red;
	}
	label.error {
		color: $chamfr-red;
	}
}
