.my-account-popup {
	display: none;
	position: absolute;
	top: 60px;
	right: 26.5rem;
	max-width: 433px;
	width: 100%;
	background: $chamfr-white;
	z-index: 9999;
	padding: 5px 3px 8px;
	border-radius: 8px;
	box-shadow: 0px 4px 4px 0px #00000040;

    @include media-breakpoint-down(sm){
        position: fixed;
        top: 0;
        right: 0;
        overflow: scroll;
        height: 100%;
    }

	&.open-my-account{
		display: block;
	}

    .popup-close-btn{
        position: absolute;
        top: 12px;
        right: 15px;
        z-index: 100;
        width: 15px;
        height: 15px;
        background: url(../images/menu-close-gray.svg) no-repeat center/contain;
        transition: opacity 0.3s ease-out;
        opacity: 1;

        @include media-breakpoint-up(md){
            opacity: 0;
        }
    }

    .login-register{
        padding: 0;
    }
    
    .form-top-area{
        padding: 24px;
    }

    .beta-message-before-login{
        margin: 0 0 35px;
        p{
            color: $chamfr-deep-gray;
            font-size: 16px;
            font-weight: 800;
            line-height: 18.8px;
            a{
                text-decoration-line: underline;
                text-decoration-style: solid;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .woocommerce-form{
        border: none !important;
        padding: 0 !important;
        margin: 0 !important;

        .woocommerce-form-group{
            margin: 0 0 32px;
            position: relative;

            .show-password-input{
                top: auto;
                right: 25px;
                bottom: 14px;

                &.display-password{
                    &::after{
                        color: $chamfr-dark-gray;
                    }
                }
                &::after{
                    color: $chamfr-black;
                    display: block;
                    width: 24px;
                    height: 19px;
                    text-align: right;
                }
            }
        }

        label{
            color: $chamfr-black;
            font-size: 14px;
            line-height: 16.45px;
            .required{
                color: $chamfr-red;
            }
        }

        .input-text{
            border: 1px solid $chamfr-gray;
            padding: 15px 16px;
            border-radius: 4px;
            transition: all 0.3s ease-in;

            &:focus{
                border-color: $chamfr-pink;
            }
        }

        .input-checkbox{
            border: 1px solid $chamfr-gray;
            margin-right: 8px;
        }

        .btn{
            max-width: 300px;
            width: 100%;
            border-radius: 8px !important;
            box-shadow: none;
            letter-spacing: 0;
            @include button-primary;
            &:hover{
                background: $chamfr-white;
                color: $chamfr-pink;
                border-color: $chamfr-pink;
            }
        }

        .woocommerce-lost_password{
            a{
                font-size: 14px;
                line-height: 16.45px;
                text-decoration-line: underline;
                text-decoration-style: solid;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .beta-message-after-login{
        margin: 0;
        background-color: $chamfr-lightest-gray;
        border-radius: 0 0 8px 8px;
        padding: 42px;
        text-align: center;

        h3{
            color: $chamfr-black;
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            margin-bottom: 24px;
        }

        .btn{
            max-width: 258px;
            width: 100%;
            border-radius: 8px !important;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            box-shadow: none;
            letter-spacing: 0;
            @include button-secondary;
            &:hover{
                background-color: $chamfr-pink;
                color: $chamfr-white;
                border-color: $chamfr-pink;
            }
        }
    }
}