/* HTML: <div class="loader"></div> */
@mixin loader-dots {
	display: inline-block;
	width: 30px;
	aspect-ratio: 4;
	background: radial-gradient(circle closest-side, #000 90%, #0000) 0 /
		calc(100% / 3) 100% space;
	clip-path: inset(0 100% 0 0);
	animation: ldots 1s steps(4) infinite;
}
@keyframes ldots {
	to {
		clip-path: inset(0 -34% 0 0);
	}
}
