.chamfr-pattern-logo-gallery {

	.wp-block-image {
		margin: 0;
	}

	img {
		max-width: 32rem;
		max-height: 8rem;
		width: 100%;
		height: auto;
	}
}
