#cart-widget-overlay {
	display: none;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 3000;
	background: transparentize($color: $black, $amount: 0.85);
}
.cart-open #cart-widget-overlay {
	display: block;
}
.cart-open .page-header .navbar-toggler{
	z-index: 350;
}
.cart-widget {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 20000;
	width: 54rem;
	background: $white;
	transition: all 0.5s ease-out;
	transform: translateX(100%);
	color: $black;
	overflow: hidden;

	@include media-breakpoint-down(sm) {
		width: 100%;
	}

	.cart-header {
		padding: $spacer;

		.title {
			font-size: 20px;
			font-weight: 700;
			line-height: 20px;
		}

		.close {
			color: $pink;
			opacity: 1;
			line-height: 20px;
			cursor: pointer;
		}
	}

	.cart-items {
		padding: 0 $spacer;
		overflow-y: scroll;

		.cart-item {
			border-top: 1px solid $chamfr-gray;
			padding: $spacer 0;
			display: flex;
			gap: $spacer;

			&-image {
				flex: 0 0 48px;
			}

			&-details {
				flex-grow: 1;
				font-size: 16px;
				line-height: 20px;
				font-weight: 600;

				&-title {
					color: $pink;
				}

				.sold-by {
					font-size: 14px;
					font-weight: 400;
					color: $chamfr-dark-gray;
				}
			}
		}
	}

	.mini-cart-form {
		padding-right: 40px;
		position: relative;
		flex: 0 0 130px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		.details-qty {
			display: none;
		}
		.details-price {
			font-size: 14px;
			font-weight: 700;
		}
		.update {
			@include button-outlined();
			font-size: 14px;
			font-weight: 400;
			padding: 8px 12px;

			&:disabled {
				display: none;
			}

			&.loading {
				cursor: default;
				pointer-events: none;
				opacity: .3;
			}
		}
		.quantity .qty {
			font-size: 14px;
			padding: 10px;
			width: 54px;
			line-height: 1;
			height: 36px;
			text-align: center;
		}

		.remove {
			position: absolute;
			right: 0;
			top: 0;
			font-size: 20px;
			cursor: pointer;
		}
	}

	.cart-footer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: $chamfr-lightest-gray;
		padding: $spacer;
		box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);

		.subtotal {
			font-size: 2rem;
			line-height: 1;
			font-weight: 800;
			padding-bottom: 1rem;
			margin-bottom: 1rem;
			border-bottom: 1px solid $chamfr-gray;

			.amount {
				float: right;
			}
		}

		.control-buttons {
			display: flex;
			justify-content: space-between;
			gap: 1rem;
			margin-bottom: 1rem;

			.go-to-cart {
				@include button-outlined();
				flex-grow: 1;
			}

			.proceed-to-checkout {
				@include button-primary();
				flex-grow: 1;
			}
		}

		.chamfr-cart-control-links {
			a {
				color: $pink;
			}
		}
	}
}

.cart-open #cart-widget-overlay,
.cart-open .cart-widget {
	transform: none;
	box-shadow: 0 0 5rem transparentize($color: $black, $amount: 0.85);
}

.woocommerce button.button.loading {
	padding: 2px 19px 3px 10px !important;
	&::after {
		top: 0.19em;
		right: 0.2em;
		@include media-breakpoint-down(sm) {
			top: 1.15em;
		}
	}
}

body.woocommerce-cart,
body.woocommerce-checkout {
	#mini-cart-icon {
		pointer-events: none;
	}
}

// Hide the old cached mini-cart.
.widget_shopping_cart_content .chamfr-mini-cart-wrapper,
.widget_shopping_cart_content .mini-cart-footer {
	display: none !important;
}

// Hide share cart default rendered button
.wsc_save_share_cart_btns .wsc_share_cart {
	display: none !important;
}