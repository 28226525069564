/* stylelint-disable no-descending-specificity */
// We need this block of stiles to make other forms on the site look like gforms
// Selector .site is used to uply these styles on frontend only, not to affect fields in wp admin
// I remove .site selector to be able to easily override color settings
//.site {
input:not( [type='submit'] ),
select,
textarea {
	width: 100%;
	height: 5rem;
	margin: 0;
	padding: $form_field_padding;
	background: $form_field_bg;
	border: $form_field_border;
	box-shadow: $form_field_shadow;
	color: $form_field_color;
	border-radius: $form_field_border_radius;

	@include placeholder;
}
textarea {
	height: 20rem;
}
label {
	display: block;
	margin: 0 0 $form_label_margin;
	font-size: $form_label_font_size;
	font-weight: $form_label_font_weight;
}
.required {
	display: inline-block;
	vertical-align: baseline;
	padding-left: 0.3em;
	color: $form_required_color;
}
input[type='radio'],
input[type='checkbox'] {
	@include sr-only;

	+ label {
		display: inline-block;
		position: relative;
		font-size: $form_label_font_size;
		font-weight: $form_label_font_weight;

		&:before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			width: $form_field_height-radio;
			height: $form_field_height-radio;
			margin-right: 1rem;
			background-color: $form_field_bg;
			border-radius: $form_field_border_radius;
			border: $form_field_border-radio;
			transition: background-color 0.2s ease-out 0.05s,
				border-color 0.2s ease-out 0.05s, background-image 0.1s 0.3s;
			cursor: pointer;
		}
	}

	&:checked + label {
		&:before {
			background: $form_checkbox-checked_bg;
			border: $form_field_border-radio_checked;
		}
	}
}
input[type='radio']:not(.checkbox-like) + label:before {
	border-radius: 50%;
}
input[type='radio']:not(.checkbox-like):checked + label:before {
	background: $form_radio-checked_bg;
}
//} // .site

// GForms styles
// we need an additional wrapper selector body to overrride default gf styles,
// because gf style file is connected below out theme styles
body {
	.gform_wrapper {
		margin: 0;
		padding: 0 0 1rem; // for submit box-shadow

		.gform_body {
			margin: 0;

			/* ul, li */
			ul.gform_fields {
				padding: 0;
				list-style: none;

				&.top_label div.ginput_container {
					margin: 0 !important;
				}

				.gfield {
					display: inline-block;
					vertical-align: top;
					width: 100%;
					padding: 1rem 0;
					margin: 0 !important;

					@include media-breakpoint-up( lg ) {
						&.gf_left_half,
						&.gf_right_half {
							width: 50%;
						}

						&.gf_left_half {
							padding-right: calc($form_spacer/2);
							padding-left: 0;
						}

						&.gf_right_half {
							padding-left: calc($form_spacer/2);
							padding-right: 0;
						}
					}

					input[type='text'],
					input[type='email'],
					select,
					textarea {
						padding: $form_field_padding;
						background: $form_field_bg;
						border: $form_field_border;
						border-radius: $form_field_border_radius;
						box-shadow: $form_field_shadow;
						color: $dark-gray;
					}

					&.gfield_html {
						margin-top: $spacer;
					}
				}
			}

			/* labels */
			.top_label .gfield_label,
			legend.gfield_label {
				font-size: $form_label_font_size;
				font-weight: $form_label_font_weight;
				letter-spacing: 0;
				line-height: 1.1;
				margin: 0 0 $form_label_margin;
			}
			.field_sublabel_above .ginput_complex.ginput_container label,
			.field_sublabel_above
				div[class*='gfield_time_'].ginput_container
				label {
				/* stylelint-disable-line */
				margin: 0;
			}
			ul.gform_fields.top_label .gfield_label + .ginput_container_checkbox, /* stylelint-disable-line */
			ul.gform_fields.top_label .gfield_label + .ginput_container_radio {
				margin-top: 2 * $form_label_margin !important;
			}
			.gfield_radio li label {
				margin: 0;
			}
			.top_label .field_sublabel_above label.gfield_label-big,
			.top_label .gfield_label_before_complex {
				margin: 1rem 0 $form_label_margin;
				font-size: $form_label_complex_font_size;

				+ .copy_values_option_container {
					margin-top: 2rem;
				}
			}
			.field_sublabel_above .ginput_complex.ginput_container label {
				font-size: $form_label_font_size;
				letter-spacing: 0;
				margin: 0 0 $form_label_margin;
			}
			.gfield_required {
				display: inline-block;
				vertical-align: baseline;
				color: $form_required_color;
			}

			/* inputs */
			.ginput_container input {
				background: $form_field_bg;
				border: $form_field_border;
				border-radius: $form_field_border_radius;
				box-shadow: $form_field_shadow;
			}
			input:not( [type='radio'] ):not( [type='checkbox'] ):not( [type='submit'] ):not( [type='button'] ):not( [type='image'] ):not( [type='file'] ) {
				width: 100%;
				height: $form_field_height;
				padding: $form_field_padding;
				border-radius: $form_field_border_radius;
			}

			/* textarea */
			/* stylelint-disable */
			.ginput_container.ginput_container_textarea textarea {
				padding: $form_field_padding;
				background: $light-gray;
				border: $form_field_border;
				border-radius: $form_field_border_radius;
			}
			/* stylelint-enable */

			/* select */
			.ginput_container.ginput_container_select {
				position: relative;

				&:after {
					content: '';
					position: absolute;
					top: 2px;
					right: 2px;
					width: 20px;
					height: calc( #{$form_field_height} - 4px );
					background-color: $form_field_bg;
					@include inline-svg-bg( $form_select_arrow );
					background-repeat: no-repeat;
					background-position: center;
				}
			}
			ul.gform_fields li.gfield select {
				height: $form_field_height;
				padding: $form_field_padding;
				margin: 0 !important;
				background: $form_field_bg;
				border: $form_field_border;
				border-radius: $form_field_border_radius;
				cursor: pointer;
				-webkit-appearance: none;
				line-height: 1;
			}

			/* checkbox, radio */
			.top_label li ul.gfield_checkbox,
			.top_label li ul.gfield_radio {
				width: 100%;
				margin: 0;
			}
			ul.gfield_checkbox li,
			ul.gfield_radio li {
				display: inline-block;
				width: 100%; // to solve weird columns problem.
				margin: 0 0 1.6rem;
				@include media-breakpoint-down( xs ) {
					display: block;
				}
			}

			ul.gfield_checkbox li input[type='checkbox'],
			ul.gfield_radio li input[type='radio'] {
				@include sr-only;

				+ label {
					display: inline-block;
					position: relative;
					font-size: $form_label_font_size;
					font-weight: $form_label_font_weight;

					&:before {
						content: '';
						display: inline-block;
						vertical-align: middle;
						width: $form_field_height-radio;
						height: $form_field_height-radio;
						margin-right: 1rem;
						background: $form_field_bg;
						border-radius: $form_field_border_radius;
						border: $form_field_border-radio;
						transition: background-color 0.2s ease-out 0.05s,
							border-color 0.2s ease-out 0.05s,
							background-image 0.1s 0.3s;
						cursor: pointer;
					}
				}

				&:checked + label {
					&:before {
						background: $form_checkbox-checked_bg;
						border: $form_field_border-radio_checked;
					}
				}
			}
			/* stylelint-disable-next-line no-descending-specificity */
			ul.gfield_radio li input[type='radio'] + label:before {
				border-radius: 50%;
			}
			ul.gfield_radio li input[type='radio']:checked + label:before {
				background: $form_radio-checked_bg;
				border: $form_field_border-radio_checked;
			}

			/* file */
			.ginput_container_fileupload {
				display: inline;
				margin: 0;
				padding: 0;

				input[type='file'] {
					display: none;
					width: auto;
					height: auto;
					margin: 0;
					padding: 0;
					background: transparent;
					border: none;
					box-shadow: none;
				}
			}
			.input-file-message {
				margin: 1rem 0 0;
				cursor: pointer;
				@include media-breakpoint-up( sm ) {
					margin: 0 0 0 1rem;
				}
				display: inline-block;
			}

			.gfield_password_strength {
				margin: 1rem 0 2rem;
				padding: 5px;
				border: none;
				box-shadow: $form_field_shadow;
				font-size: $form_label_font_size;
				line-height: 1.2;
			}
		}

		/* submit */
		.gform_footer,
		.gform_page_footer {
			margin: 2.5rem 0 0;
			padding: 0;
			text-align: left;

			input.button,
			input[type='submit'] {
				@extend .btn;
				//height: $form_field_height;
				margin: 0;
				@include small-shadow();

				&:focus,
				&:active {
					color: $white;
					background: $pink;
					border: none;
					@include small-shadow();
					text-decoration: none;
					outline: none;
				}
			}
		}

		/* errors */
		&.gform_validation_error {
			div.validation_error {
				border: none;
				color: $form_error_color;
			}

			.gform_body {
				//.gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
				//}

				ul.gform_fields {
					li.gfield.gfield_error,
					li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
						padding-bottom: 0;
						background: transparent;
						border: none;
					}
					li.gfield.gfield_error.gfield_contains_required
						div.ginput_container {
						margin-top: 8px;
					}
					.gfield_error input[type='email'],
					.gfield_error input[type='number'],
					.gfield_error input[type='password'],
					.gfield_error input[type='tel'],
					.gfield_error input[type='text'],
					.gfield_error input[type='url'],
					.gfield_error select,
					.gfield_error textarea,
					li.gfield_error.field_sublabel_above
						.ginput_complex
						input[type='text'] {
						margin: 0 !important;
					}
					li.gfield.gfield_error.gfield_contains_required
						label.gfield_label {
						margin-top: 0;
					}
					li.gfield_error div.ginput_complex.ginput_container label,
					li.gfield_error ul.gfield_checkbox,
					li.gfield_error ul.gfield_radio {
						color: $form_error_color;
					}
					li.gfield_error
						input:not( [type='radio'] ):not( [type='checkbox'] ):not( [type='submit'] ):not( [type='button'] ):not( [type='image'] ):not( [type='file'] ),
					li.gfield_error textarea {
						border-color: $form_border_error_color !important;
					}
					li.gfield.gfield_error:not( .gf_left_half ):not( .gf_right_half ) {
						max-width: 100% !important;
					}
					.gfield_error .gfield_label {
						color: $form_error_color;
					}
					.gfield_error .gfield_required {
						color: $form_error_color;
					}
					li.gfield.gfield_error + li.gfield.gfield_error {
						margin-top: 0;
					}
				}
				.instruction.validation_message {
					margin-top: 2px;
					color: $form_error_color;
				}
				.validation_error {
					margin-bottom: $spacer;
					font-weight: 700;
					color: $form_error_color;
				}
				.validation_message {
					margin: 0.5rem 0 1rem;
					font-size: 1.4rem;
					font-weight: 500;
					color: $form_error_color;
				}
				.captcha .validation_message {
					text-align: center;
				}
			}
		}
	}

	.gf_placeholder {
		@include placeholder;
	}

	@media only screen and ( max-width: 641px ) {
		.gform_wrapper
			input:not( [type='radio'] ):not( [type='checkbox'] ):not( [type='image'] ):not( [type='file'] ) {
			line-height: 1.2;
			min-height: auto;
		}

		.gform_wrapper .ginput_container span:not( .ginput_price ) {
			margin-bottom: 2rem;
		}
	}
	@media only screen and ( min-width: 641px ) {
		.gform_wrapper.gform_validation_error
			.top_label
			li.gfield.gfield_error.gf_left_half,
		.gform_wrapper.gform_validation_error
			.top_label
			li.gfield.gfield_error.gf_right_half {
			max-width: 50%;
			padding-right: 0;
		}
		.gform_wrapper .ginput_container.ginput_complex {
			display: flex;
			&.ginput_container_address {
				display: block;
			}
		}
		.gform_wrapper .ginput_complex.ginput_container_address {
			> span {
				display: block;
				margin: 0;
				padding: 1rem 0;
			}
		}
		.gf_clear {
			clear: both;
		}
		.gform_wrapper .ginput_complex .ginput_left,
		.gform_wrapper .ginput_complex .ginput_right,
		.gform_wrapper .ginput_container_password .ginput_left,
		.gform_wrapper .ginput_container_password .ginput_right,
		.gform_wrapper .ginput_complex.ginput_container_address .ginput_left,
		.gform_wrapper .ginput_complex.ginput_container_address .ginput_right {
			display: block;
			margin: 0;
			padding: 1rem 0;
		}
		.gform_wrapper .ginput_complex.ginput_container_address .ginput_left,
		.gform_wrapper .ginput_complex.ginput_container_address .ginput_right {
			float: left;
		}
		.gform_wrapper .ginput_complex.ginput_container_address .ginput_right {
			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				clear: both;
			}
		}
		.gform_wrapper
			.ginput_complex.ginput_container_address
			span.ginput_right
			+ span.ginput_left.address_zip {
			/* stylelint-disable-line */
			margin: 0;
		}
		.gform_wrapper
			.ginput_complex.ginput_container_address
			span.ginput_right
			+ span.ginput_left {
			/* stylelint-disable-line */
			margin: 0;
			padding-right: calc($form_spacer/2) !important;
		}
		.gform_wrapper .ginput_complex .ginput_right,
		.gform_wrapper .ginput_container_password .ginput_right,
		.gform_wrapper .ginput_complex.ginput_container_address .ginput_right {
			padding-left: calc($form_spacer/2);
		}
		.gform_wrapper .ginput_complex .ginput_left,
		.gform_wrapper .ginput_container_password .ginput_left,
		.gform_wrapper .ginput_complex.ginput_container_address .ginput_left {
			padding-right: calc($form_spacer/2);
		}

		div.ginput_container_name span {
			margin: 0;
			padding: 0;
		}
	}
}
.gform_wrapper.gf_browser_ie .gform_footer input.button {
	padding: 0 3rem;
}
.chamfr-become-seller-form {
	.gform_body {
		.top_label {
			.chamfr-become-seller-tell-us-field {
				.gfield_label {
					font-size: 1.8rem;
				}

				.gfield_description {
					padding-bottom: 1rem;
				}

				.ginput_container {
					&.ginput_container_textarea {
						textarea {
							background: #ffffff;
						}
					}
				}
			}

			.chamfr-become-seller-company-name-field {
				.gfield_label {
					font-size: 1.8rem;
				}
			}

			.chamfr-become-seller-phone-field {
				.gfield_label {
					font-size: 1.8rem;
				}
			}
		}
	}
}
