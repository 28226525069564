.checkout-step-delivery-options {
	.package {
		display: block;
		margin-bottom: $spacer;
		border: 1px solid $chamfr-gray;
		border-radius: calc($spacer / 2);
		overflow: hidden;
	}

	.products {
		padding: $spacer;
	}

	.single-cart-product {
		margin-bottom: calc($spacer);
		padding-bottom: calc($spacer);
		border-bottom: 1px solid $white;
		display: flex;
		font-size: 14px;

		&:last-child {
			margin-bottom: 0;
			border-bottom: none;
		}

		.product-name {
			flex-grow: 1;
			padding-right: $spacer;

			.name {
				font-weight: 600;
			}

			.quantity {
				color: $chamfr-raspberry;
			}

			.lead-time {
				color: $light-green;
				font-size: 13px;
			}
		}

		.product-subtotal {
			font-weight: 800;
			text-align: right;
		}
	}

	.shipping {
		background-color: $white;
		padding: $spacer;
		display: flex;
		font-size: 14px;

		.shipped-by {
			flex: 0 0 50%;
			padding-right: $spacer;
			font-weight: 700;

			p {
				font-weight: 400;
			}
		}

		.shipping-speed {
			flex: 0 0 50%;

			.select2-container {
				width: 100%;
			}
		}
	}
}
