body .gform_wrapper .gform_body ul.gform_fields {
	.onboard-seller {

		&-company-form {
			margin-right: 20px;

			&.gfmc-column50 {
				width: 55%;
			}

			input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
				padding: 0.5rem;
				height: 3.6rem;
			}
	
			li.gfield select {
				padding: 0.5rem;
				height: 3.6rem;
			}
	
			.gfield {
				padding: 0.5rem 0;
			}
	
			.show-password-input {
				top: 0.5em;
				right: 0.5em;
			}
		}

		&-floating-checkout {
			background-color: $border-gray;
			padding: 20px;
			margin-top: -20px;

			&.gfmc-column50 {
				width: 40%;
			}

				
			.gfield {
				padding: 0.5rem 0;
			}
		}

		&-billing-cycle {
			&-label {
				padding-bottom: 0;
			}

			&-select {
				padding-top: 0;

				.gchoice {
					margin-bottom: 0;
				}
			}
		}
	}
}
