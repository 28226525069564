#chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
	@include make-container();
	@include chamfr-container-max-widths();
}

#ais-wrapper.columns {
	flex-grow: 1;
}
/* Hits table Header */
.sortable {
	cursor: pointer;
	user-select: none;
}

.sortable .sort-arrow {
	margin-left: 5px;
	display: inline-block;
	width: 10px;
	@include media-breakpoint-down( md ) {
		display: none;
	}
}
/* Facets */

.custom-search-box {
	.ais-SearchBox-submit {
		display: none;
	}
	.ais-SearchBox-reset {
		display: none;
	}
	.ais-SearchBox {
		width: 100%;
	}
}

.ais-ToggleRefinement {
	&-label {
		input[type="checkbox"] {
			display: none;
		}

		input[type="checkbox"] + span::before {
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 1.6rem;
			height: 1.6rem;
			margin-right: 1rem;
			background-color: transparent;
			border-radius: 3px;
			border: 1px solid $white;
			/* stylelint-disable-next-line */
			transition: background-color 0.2s ease-out 0.05s,
				border-color 0.2s ease-out 0.05s, background-image 0.1s 0.3s;
			cursor: pointer;
		}

		input[type="checkbox"]:checked + span::before {
			background: #e11d73 url(../images/check-white.svg) no-repeat center;
			background-size: 12px;
			border: 1px solid #e11d73;
		}
	}
}

.ais-RefinementList {
	&-label {
		input[type="checkbox"] {
			display: none;
		}

		input[type="checkbox"] + span::before {
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 1.6rem;
			height: 1.6rem;
			margin-right: 0.6rem;
			margin-left: -2.6rem;
			margin-top: -0.3rem;
			background-color: transparent;
			border-radius: 3px;
			border: 1px solid $white;
			/* stylelint-disable-next-line */
			transition: background-color 0.2s ease-out 0.05s,
				border-color 0.2s ease-out 0.05s, background-image 0.1s 0.3s;
			cursor: pointer;
		}
	}

	&-searchBox {
		width: 100%;
	}

	.ais-RefinementList-list {
		padding-left: 0px;
		padding-right: 0px;
	}
	& ul.inner {
		overflow-y: scroll;
		scrollbar-width: none;
		display: flex;
		flex-direction: column;
		max-height: 250px;
		scrollbar-color: #ddd #2b2b2b;
		scrollbar-width: thin!important;
	}
}

.ais-RefinementList {
	&-item--selected {
		.ais-RefinementList-label {
			input[type="checkbox"] + span::before {
				background: #e11d73 url(../images/check-white.svg) no-repeat
					center;
				background-size: 12px;
				border: 1px solid #e11d73;
			}
		}
	}
}

.ais-ClearRefinements {
  text-align: center;
  margin-bottom: 1rem;

  button.button {
    display: none;

    &.show {
      display: inline-block;
    }
  }
}

#chamfr-search-results,
#chamfr-supplier-store,
#chamfr-recently-added-products {
	.columns {
		display: flex;
		align-items: flex-start;
		justify-content: stretch;

		@include media-breakpoint-down(lg) {
			display: block;
		}

		#ais-facets {
			.chamfr-filter {
				padding: 2rem 1rem;
				.caption.widgettitle {
					margin-bottom: 1rem;
				}
				.custom-search-box {
					.ais-SearchBox-submit {
						display: none;
						position: absolute;
						right: 0;
						top: 0;
						width: 35px;
						height: 36px;
						background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
						background-position: 12px 12px;
						background-size: 12px;
						background-repeat: no-repeat;
						background-color: #e11d73;
						border-top-left-radius: 0;
						border-bottom-left-radius: 0;
						border-top-right-radius: .5rem;
						border-bottom-right-radius: .5rem;
						svg {
							display: none;
						}
					}
					.ais-SearchBox {
						form {
							position: relative;
						}

						&-input {
							max-width: 100%;
							height: auto;
							padding: 8px 15px 9px 15px;
							background-color: #fafafa;
							border-radius: .5rem;
							border: none;
							-webkit-appearance: none;
							-moz-appearance: none;
							appearance: none;
							-webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, .15);
							box-shadow: 0 3px 6px rgba(0, 0, 0, .15);
							font-size: 1.4rem;
							line-height: 1.36;
							color: #313131;
							margin: 0;
						}
					}
				}
			}
		}

		#facet-categories,
		#facet-container {
			.ais-RefinementList {
				&-searchBox {
					margin-bottom: 2rem;
				}
			}
		}
	}
}

/* RangeSlide Facet */

.slider-wrapper {
	position: relative;
	min-height: 7rem;

	.chamfr-filter-slider-range-input-field {
		position: absolute;
		top: 0;
		left: 0;
		width: 40%;
		max-width: 8rem;
		font-size: 1.2rem;
		color: #5b5b5b;

		&.from {
		}
		&.to {
			left: auto;
			right: 0;
		}

		.chamfr-filter-slider-range-input {
			font-size: 1.2rem;
			height: 2rem;
			padding: 0 0.5rem;
			width: 100%;
			margin: 0;
			background: #fff;
			border: 1px solid #ddd;
			-webkit-box-shadow: none;
			box-shadow: none;
			color: #313131;
			border-radius: 0.5rem;
		}
	}

	.slider-container {
		margin-bottom: 1.5rem;
		position: absolute;
		bottom: 0px;
		left: 0px;
		right: 0px;

		.noUi-target {
			height: 1px;
			background: rgba(234, 234, 234, 0.2);
			border: none;
			box-shadow: none;
			padding: 0px 0.6rem;
		}

		.noUi-target .noUi-connect {
			background: #b7b7b7;
		}

		.noUi-target .noUi-handle {
			height: 1.2rem;
			width: 1.2rem;
			top: -0.5rem;
			border-radius: 50%;
			cursor: pointer;
			z-index: 10;

			&:before {
				display: none;
			}
			&:after {
				display: none;
			}

			&-lower {
				right: -0.6rem;
			}
			&-upper {
				right: -0.6rem;
			}
		}
	}
}

/* End of RangeSlider Facet */

.widget_ep-facet.hidden {
	display: none;
}

/* Search Bar */

.algolia-search-box-wrapper {
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
}

#algolia-search-box {
	width: 100%;
	max-width: 100%;
	margin-right: auto;
}

/* End of Search Bar */

/* Stats */
#algolia-stats {
	width: 60%;
	margin-bottom: 20px;
	/* Additional margin for separation */
	padding-left: 20px;
	/* Align with ais-main */
}

/* Collapsible button styles */
/* Add space between the title and the categories */

/* Add bottom margin to the categories section */
.chamfr-filter {
	margin-bottom: 20px;
	/* Adjust as needed */
	#facet-container {
		@include media-breakpoint-down(lg) {
			display: none !important;
		}
		&.mobile-on {
			@include media-breakpoint-down(lg) {
				display: block !important;
			}
		}
	}
}

/* End of Collapsible Container */

/* Category List */
.ais-HierarchicalMenu {
	margin-top: 20px;
}
.chamfr-filter ul li.ais-RefinementList-item {
	display: flex;
	align-items: center;
	margin-bottom: 0px;
	clip: auto !important;
	position: relative !important;
	width: auto !important;
	height: auto !important;
	overflow: visible !important;
	line-height: 26px;
	padding-left: 2.6rem;

	div {
		label.ais-RefinementList-label {
			margin: 0px;
		}
	}
}

.ais-HierarchicalMenu-label {
	color: #ccc;
	text-decoration: none;
	font-size: 14px;
	cursor: pointer;
	clip: auto !important;
	/* NEW */
	position: relative !important;
	/* NEW */
	flex-grow: 1;
	/* Allow label to take remaining space */
}

.ais-HierarchicalMenu-label:hover {
	color: #e11d73;
}

/* Additional Widgets */
.not-finding {
	margin-top: 20px;
	background-color: #333;
	padding: 20px;
	border-radius: 8px;
	text-align: center;
}

.not-finding-after-table {
	.not-finding {
		margin-top: 0px;
		padding: 0px;
		background: none;
	}
}

.not-finding p {
	margin: 0;
	color: #ccc;
	font-size: 14px;
}

.not-finding a {
	display: block;
	margin-top: 10px;
	background-color: #e11d73;
	color: white;
	padding: 10px 15px;
	border-radius: 4px;
	text-decoration: none;
	font-size: 14px;
	font-weight: bold;
}

.not-finding a:hover {
	background-color: #d10b65;
}

/* End of Sidebar Container */
/* Reset default list styles */
#algolia-pagination {
	&.hidden {
		display: none;
	}

	position: relative;
	min-height: 120px;

	.ais-Pagination {
		text-align: center;
		margin: 0;
		padding: 50px 10px 10px;

		@include media-breakpoint-down(sm) {
			padding: 20px 10px 10px;
		}

		ul.ais-Pagination-list {
			list-style: none;
			border: none;
			margin: 0;
			padding: 1rem;
			border-radius: 28px;
			transition: background-color 0.1s ease, box-shadow 0.1s ease;

			@include media-breakpoint-down(sm) {
				padding: 0.5rem;
				border-radius: 19px;
			}

			li.ais-Pagination-item {
				display: inline-block;

				&.ais-Pagination-item--firstPage {
					display: none;
				}

				&.ais-Pagination-item--previousPage {
					&.ais-Pagination-item--disabled {
						display: none;
					}

					a {
						color: transparent;
						position: relative;
						border-color: $black;
						opacity: 1;

						&::after {
							content: "←";
							color: #000;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						&:hover {
							color: transparent;
						}
					}
				}

				&.ais-Pagination-item--nextPage {
					&.ais-Pagination-item--disabled {
						display: none;
					}

					a {
						color: transparent;
						position: relative;
						border-color: $black;
						opacity: 1;

						&::after {
							content: "→";
							color: #000;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						&:hover {
							color: transparent;
						}
					}
				}

				&.ais-Pagination-item--lastPage {
					display: none;
				}

				border: none;

				span,
				a {
					text-decoration: none;
					min-width: 3.6rem;
					height: 3.6rem;
					margin: 0;
					border-radius: 50%;
					border: 2px solid transparent;
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: center;

					@include media-breakpoint-down(sm) {
						margin: 0;
						height: 2.8rem;
						min-width: 2.8rem;
					}
				}

				a {
					&:hover {
						background: #e9e6ed;
						color: #816e99;
					}

					color: $black;
					opacity: 0.5;
				}
			}

			li.ais-Pagination-item--selected {
				a {
					background-color: transparent;
					color: $pink;
					opacity: 1;
				}
			}
		}

		&.fixed {
			position: fixed;
			z-index: 1;
			pointer-events: none;
			bottom: 50px;
			margin: 0 auto;
			left: 50%;

			ul {
				pointer-events: auto;
				background-color: $white;
				@include medium-shadow();
			}
		}
	}
}

#algolia-no-results {
	.categories {
		padding-top: 4rem;
		&-list {
			margin: 0 -1rem;
			display: flex;
			flex-wrap: wrap;
		}
	}
	.category {
		flex: 0 0 33.333%;
		padding: 1rem;

		@include media-breakpoint-down(sm) {
			flex: 0 0 50%;
		}
		@include media-breakpoint-down(xs) {
			flex: 0 0 100%;
		}

		> a {
			display: block;
			background: $white;
			border-radius: 1rem;
			padding: 1rem;
			text-align: center;
			@include tiny-shadow();
		}
	}
}

.no-results {
	.chamfr-filter {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	.chamfr-product-list {
		.products {
			width: 100%;
			margin: 0px auto;
		}
	}
}