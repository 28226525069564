.recently-added-products {
	position: relative;
	padding: 0 0 16rem;
	text-align: center;

	@include media-breakpoint-down($bp-m) {
		padding-bottom: 8rem;
	}

	.btn {
		text-transform: uppercase !important;
	}

	h3 {
		margin: 0 0 4rem;
		text-align: center;

		@include media-breakpoint-down($bp-m) {
			margin: 0 0 2rem;
		}
	}

	&__list {
		padding-bottom: 3rem;

		@include media-breakpoint-down($bp-m) {
			padding-bottom: 1rem;
		}
		.slick-slide {
			margin: 1rem;
		}
		&__link:hover {
			text-decoration: none;
		}
		&__item {
			display: block;
			padding: 2rem 2.5rem;
			margin: 0 1rem 1rem 0;
			min-height: 14rem;

			@include media-breakpoint-down($bp-m) {
				padding: 1rem 1rem;
			}
			background: #323132;
			border-radius: 1rem;
			@include medium-shadow();
			font-size: 0.8rem;
			color: #fff;
			text-decoration: none;

			> div {
				text-align: center;
				display: inline-block;
				margin: 0.5rem auto;
				width: 100%;
			}

			@include media-breakpoint-up( $bp ) {
				padding: 2rem 2.5rem;
				min-height: 23rem;
			}

			@include media-breakpoint-up( $bp-xl ) {
				min-height: 20rem;
			}
			@include media-breakpoint-up( xl ) {
				font-size: 1.6rem;
			}

			&__count {
				height: 6rem;
				margin: 0 0 1rem;
				font-size: 5rem;

				@include media-breakpoint-up( $bp ) {
					height: 8rem;
					font-size: 6rem;
				}
			}

			&__category {
				font-size: 1.2rem;
				text-align: center;

				@include media-breakpoint-up( $bp ) {
					font-size: 2rem;
				}
				:hover > & {
					color: #E11B72;
					text-decoration: underline;
					text-underline-offset: 7px;
					text-decoration-thickness: from-font;
				}
			}
		}
	}

	&__slick-controls {
		margin: 0rem;
		text-align: right;

		@include media-breakpoint-up( $bp ) {
			margin: 0 0 2rem;
			position: absolute;
			top: 4rem;
			right: 0;
		}

		.slick-next {
			margin-right: 0;
		}
	}
}