.checkout-step-sign-in {
	form.login {
		border: none;
		border-radius: 0;
		margin: 0;
		padding: 0;

		.form-row-first,
		.form-row-last {
			float: none;
			padding: 0;
			width: auto;
		}

		.input-text {
			border: 1px solid $chamfr-gray;
			border-radius: 4px;
		}

		.woocommerce-form-login__submit {
			@include button-primary();
			box-shadow: none;
			width: 70%;
		}
	}

	.checkout-as-guest-button {
		@include button-outlined();
		@include media-breakpoint-up(sm) {
			width: 70%;
		}
	}
}
