.wp-block-pullquote {
	border-top: 4px solid transparent;
	border-bottom: 4px solid transparent;
	text-align: left;
	padding: 0;

	&__citation,
	& cite,
	& footer {
		font: 400 1.8rem/1.6 $content-font;
		text-transform: none;
	}
}
