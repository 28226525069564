@media print {
  .page-header {
    display: none;
  }
  .print-page-button {
    display: none;
  }
  .subscribe {
    display: none;
  }
  .page-footer {
    display: none;
  }
}

.container {
  position: relative;
}
.print-page-button {
  cursor: pointer;
  background: none;
  position: absolute;
  right: 0px;
  width: 50px;
  height: 50px;
  top: 0px;
}