.chamfr-filter {
	background-color: $darkest-gray;
	color: $white;
	border-radius: 10px;
	padding: 2rem 1.5rem;
	@include medium-shadow();

	flex: 0 0 22.9%;
	margin-bottom: 2rem;
	font-size: 1.4rem;

	@include media-breakpoint-down(lg) {
		flex: 0 0 100%;
	}

	.widget_search {
		position: relative;
		@media (max-width: 1200px) {
			margin: 0 2.5rem 0 2.5rem;
		}
		.caption {
			display: none;
		}
		input[type="text"] {
			border-color: $white;
			height: auto;
			padding: 0.8rem 3.2rem 0.8rem 0.8rem;
			margin-bottom: 0;
		}
		.search-submit {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 3.5rem;
			background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");
			background-position: 1.2rem 1.3rem;
			background-size: 1.2rem;
			background-repeat: no-repeat;
			background-color: #e11d73;
			border-top-right-radius: 0.5rem;
			border-bottom-right-radius: 0.5rem;
			color: transparent;
		}
	}

	&-actions {
		a {
			display: inline-block;
			padding: 1rem 0;
			color: $pink;
			margin-left: 1em;

			&:first-child {
				margin-left: 0;
			}
		}
		a.filters-reset {
			color: $white;
			margin: 1rem 2.5rem;
			display: block;
		}
	}

	.filters-toggle {
		display: none;
		text-align: center;
		border-top: 1px solid transparentize($light-gray, 0.8);
		margin-top: 1rem;
		padding: 1rem 0;

		a {
			color: $white;
			text-decoration: none;
			transition: all none;
			font-size: 0;
			&:before {
				font-size: 1.4rem;
				content: "Filter Results";
			}
			&.on:before {
				content: "Close Filters";
			}
		}

		@include media-breakpoint-down(lg) {
			display: block;
		}
	}

	#filters {
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	.widget_ep-facet {
		padding: 1rem 0;
		border-top: 1px solid transparentize($light-gray, 0.8);

		.caption {
			position: relative;
			font-size: 1.6rem;
			cursor: pointer;

			.toggle {
				display: block;
				position: absolute;
				top: 0.8rem;
				right: 0;
				width: 0;
				height: 0;
				margin-left: 0.5em;
				border-style: solid;
				border-width: 0.4rem 0 0.4rem 0.4rem;
				border-color: transparent transparent transparent $pink;
			}
		}
		.terms.searchable {
			display: none;
			padding-top: 1rem;

			input.facet-search {
				background-color: transparent;

				/* White Search Icon */
				/* stylelint-disable-next-line */
				background-image: url("data:image/svg+xml,%3Csvg id='Search_Icon' data-name='Search Icon' xmlns='http://www.w3.org/2000/svg' width='12.562' height='12.643' viewBox='0 0 12.562 12.643'%3E%3Cg id='Ellipse_1' data-name='Ellipse 1' fill='none' stroke='%23ffffff' stroke-width='1.25'%3E%3Ccircle cx='4.936' cy='4.936' r='4.936' stroke='none'/%3E%3Ccircle cx='4.936' cy='4.936' r='4.311' fill='none'/%3E%3C/g%3E%3Cline id='Line_1' data-name='Line 1' x1='3.435' y1='3.516' transform='translate(8.243 8.243)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-width='1.25'/%3E%3C/svg%3E%0A");

				background-size: 12px;
				background-repeat: no-repeat;
				background-position: 0.7rem 1.3rem;
				border-color: $white;
				height: auto;
				padding: 0.8rem 0.8rem 0.8rem 3.2rem;
				color: $white;
				margin-bottom: 0;

				&::placeholder {
					color: $light-gray;
				}
			}
		}
		.inner {
			display: none;
			margin-top: 1rem;
			max-height: 15em;
			overflow-y: auto;
			scrollbar-width: none;

			&.noscroll {
				max-height: none;
				overflow-y: inherit;
			}

			flex-direction: column;

			&::-webkit-scrollbar {
				width: 4px;
			}

			&::-webkit-scrollbar-track {
				border-radius: 2px;
			}

			&::-webkit-scrollbar-thumb {
				background: $middle-gray;
				border-radius: 2px;
			}

			&::-webkit-scrollbar-thumb:hover {
				background: $light-gray;
			}

			.empty-term {
				display: none;
			}

			.term {
				position: relative;
				margin-top: 0.4rem;

				a {
					color: $white;

					.image {
						display: inline-block;
						width: 3.2rem;
						height: 3.2rem;
						object-fit: contain;
						margin-right: 0.5rem;
						margin-left: 0.5rem;
						margin-bottom: 1rem;
						img {
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}
					&:hover,
					&:focus,
					&:active {
						text-decoration: none;
						color: $white;
					}
				}

				label {
					color: $white;
					display: inline-block;

					&:before {
						content: "";
						display: inline-block;
						vertical-align: middle;
						width: 1.6rem;
						height: 1.6rem;
						margin-right: 1rem;
						background-color: transparent;
						border-radius: 3px;
						border: 1px solid $white;
						/* stylelint-disable-next-line */
						transition: background-color 0.2s ease-out 0.05s,
							border-color 0.2s ease-out 0.05s,
							background-image 0.1s 0.3s;
						cursor: pointer;
					}

					&:hover {
						text-decoration: none;
					}
				}

				// &.selected a:before,
				input[type="checkbox"]:checked + label:before {
					background: $pink url(../images/check-white.svg) no-repeat
						center;
					background-size: 12px;
					border: 1px solid $pink;
				}
				&.filtered {
					display: none;
					&.selected {
						display: block;
					}
				}
				&.selected {
					order: -1;
				}
			}
		}

		&.open,
		&.active {
			.caption {
				.toggle {
					transform: rotate(90deg);
				}
			}
			.terms.searchable,
			.inner {
				display: flex;
			}
		}

		// Custom styles to override Product cat facet
		&#chamfr_facet_product_cat {
			padding: 3rem 0 0.5rem;

			.caption {
				padding-bottom: 1rem;
				font-size: 1.6rem;
			}

			.inner {
				padding-top: 3rem;
				border-top: 1px solid transparentize($light-gray, 0.8);

				.term {
					margin: 0 2.5rem 2.5rem;

					a {
						transition: all 0.3s ease-out;
						font-size: 1.8rem;
						text-decoration: none;
						color: $darker-gray;

						&:hover,
						&:focus,
						&:active {
							color: $white;
						}

						.image {
							display: none;
						}
					}
				}
			}
		}
	}

	.btn {
		height: auto;
		padding: 1.6rem 3rem;
		line-height: 1.2;
	}

	@include media-breakpoint-down(xs) {
		margin-left: $grid-gutter-margin;
		margin-right: $grid-gutter-margin;
		border-radius: 0;
	}

	.heading {
		position: relative;

		@include media-breakpoint-down(md) {
			display: flex;
			justify-content: flex-start;
			align-items: flex-end;
		}

		h6 {
			font-size: 1.6rem;
			font-weight: 400;
			margin: 0;

			@include media-breakpoint-down(md) {
				margin-right: 2rem;
			}
		}

		.reset {
			font-size: 1.3rem;
			position: absolute;
			right: 0;
			bottom: 0;
			line-height: 1;
			display: none;

			@include media-breakpoint-down(md) {
				position: static;
			}

			&.active {
				display: block;
			}
		}

		.toggle {
			position: absolute;
			top: 50%;
			right: 0;
			width: 24px;
			height: 24px;
			transform: translateY(-50%);
			display: flex;
			justify-content: center;
			align-items: center;

			@include media-breakpoint-up(lg) {
				display: none;
			}

			&:before {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0.4rem 0 0.4rem 0.4rem;
				border-color: transparent transparent transparent $pink;
				transition: transform 0.3s ease;
			}

			&.active {
				&:before {
					transform: rotate(90deg);
				}
			}
		}
	}

	form#chamfr-filter {
		border-top: 1px solid transparentize($light-gray, 0.8);
		padding-top: 3rem;
		margin-top: 1rem;

		@include media-breakpoint-down(md) {
			display: none;
			padding-top: 2rem;
			margin-top: 2rem;
		}
	}

	ul {
		list-style-type: none;
		padding: 0 2.5rem;
		margin: 0;
		margin-bottom: 3rem;

		li {
			margin: 0 0 3rem;
			font-size: 1.8rem;
			line-height: 1;

			&:last-child {
				margin-bottom: 0;
			}

			input[type="checkbox"] {
				+ label {
					color: $white;
					opacity: 0.5;
					font-size: 1.8rem;
					margin: 0;
					transition: opacity 0.3s ease-out;
					cursor: pointer;

					&:hover,
					&:focus,
					&:active {
						opacity: 1;
					}

					&:before {
						display: none;
					}
				}

				&:checked {
					+ label {
						opacity: 1;
					}
				}
			}
		}
	}

	.not-finding {
		padding: 3.5rem 2.5rem 2.5rem;
		border-top: 1px solid transparentize($light-gray, 0.8);

		.btn {
			padding-left: 2rem;
			padding-right: 2rem;
		}

		@include media-breakpoint-down(xl) {
			padding: 3.5rem 0 0;
		}

		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}

.not-finding-after-table {
	margin: 0 -15px;
	padding: 2rem;
	background-color: $darkest-gray;
	color: $white;
	text-align: center;
}
