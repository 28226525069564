.thank-you {
	margin: 0 0 10rem;

	@include media-breakpoint-up( $bp ) {
		margin: 3rem 0 18rem;
	}
	@include media-breakpoint-up( hd ) {
		margin: 3rem 0 26rem;
	}

	&__buttons {
		margin-top: 2rem;
		text-align: center;

		@include media-breakpoint-up( sm ) {
			text-align: inherit;
		}

		@include media-breakpoint-up( $bp ) {
			margin-top: 5rem;
		}

		.btn {
			width: 22.5rem;
			margin-bottom: 1.5rem;

			@include media-breakpoint-up( sm ) {
				width: auto;
				margin-right: 1.6rem;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}

body.woocommerce-order-received:not(.logged-in) {
	.ced_my_account_reorder {
		display: none;
	}
}
