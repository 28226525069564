#chamfr-nothing-found {
	.search-again {
		@include rich-search();
		padding: 3rem;
		background-color: $darkest-gray;
		border-radius: 10px;
		margin: 0 0 2rem;
		display: block;
	}
}
