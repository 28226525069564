/* Colors */

$black: #000;
$white: #fff;
$darkest-gray: #2b2b2b;
$darker-gray: #959595;
$dark-gray: #313131;
$lightest-gray: #fafafa;
$light-gray: #eaeaea;
$light-gray2: #ddd;
$light-gray3: #f3f3f3;
$middle-gray: #6b6b6b;
$gray: #5b5b5b;
$pink: #e11d73;
$dark-pink: #9f325d;
$invalid-color: #a00;
$border-gray: #b7b7b7;
$border-color: $border-gray;
$red: #f00;
$light-green: #56b8a1;

// Color Palette June 2024.
$chamfr-pink: #e11d73;
$chamfr-black: #111111;
$chamfr-raspberry: #9f325d;
$chamfr-deep-gray: #2b2b2b;
$chamfr-dark-gray: #636363;
$chamfr-medium-gray: #A9A9A9;
$chamfr-gray: #b4b4b4;
$chamfr-light-gray: #e1e1e1;
$chamfr-lightest-gray: #f6f6f6;
$chamfr-white: #FFFFFF;
$chamfr-teal: #56B8A1;
$chamfr-blue: #4B8B9F;
$chamfr-pink-ambience: #FFA3BD;
$chamfr-red: #CA3737;
$chamfr-green: #297D5A;

$body-black: #0f0f0f;
$text-black: $black;
$body-white: $light-gray;

// used for bg-blue... and so on.
$local-colors: (
	black: $black,
	white: $white,
	dark-gray: $dark-gray,
	light-gray: $light-gray,
	light-gray2: $light-gray2,
	middle-gray: $middle-gray,
	gray: $gray,
	pink: $pink,
) !default;

/* Fonts */
$headings-font: "Public Sans", sans-serif;
$content-font: "Public Sans", sans-serif;

@mixin headings-font() {
	font-family: $headings-font;
	font-weight: 500;
}

@mixin content-font() {
	font-family: $content-font;
}

$font-size: (
	h1: 4.6rem,
	h2: 3.8rem,
	h3: 3.2rem,
	h4: 2.4rem,
	h5: 2rem,
	h6: 1.8rem,
	p: 1.6rem,
);

$font-size-mobile: (
	h1: 3.6rem,
	h2: 3rem,
	h3: 2.6rem,
	h4: 2.2rem,
	h5: 1.8rem,
	h6: 1.6rem,
	p: 1.4rem,
);

/* Other */

/* Slick slider variables  See /src/scss/partials/components/slick.scss */
// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

/* GForms constants */

$form_field_bg: $white;
$form_field_height: 5rem;
$form_field_padding: 1.5rem;
$form_field_border_radius: 0.5rem;
$form_field_border: 1px solid $light-gray2;
$form_field_color: $dark-gray;
$form_field_shadow: none;

// radio & checkbox
$form_field_height-radio: 3rem;
$form_field_border-radio: 1px solid $light-gray2;
$form_field_border-radio_checked: 1px solid $light-gray2;
$form_radio-checked_bg: $white url(../images/circle.svg) no-repeat center;
$form_checkbox-checked_bg: $white url(../images/check.svg) no-repeat center;

$form_select_arrow: '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="4" viewBox="0 0 8 4"><path d="M4,0,8,4H0Z" transform="translate(8 4) rotate(180)" fill="#c05f00"/></svg>';

$form_label_margin: 0.8rem;
$form_label_font_size: 1.4rem;
$form_label_font_weight: 400;
$form_label_complex_font_size: 1.8rem;

$form_required_color: inherit;
$form_error_color: $pink;
$form_border_error_color: $pink;

$form_spacer: 2.4rem; // horizontal distance between two fields in a row

/* Slick constants */

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: $black !default;
$slick-dot-color: $black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "←" !default; //"\2190" !default;
$slick-next-character: "→" !default; //"\2192" !default;
$slick-dot-character: "*" !default;
$slick-dot-size: 20px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

$arrow-down: '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="4" viewBox="0 0 8 4"><path d="M4,0,8,4H0Z" transform="translate(8 4) rotate(180)" fill="#c05f00"/></svg>';
$arrow-left: '<svg xmlns="http://www.w3.org/2000/svg" width="18.044" height="18.044" viewBox="0 0 18.044 18.044"><path id="Arrow" d="M0,0,9.517,9.517M10.345,3.4v6.94H3.156" transform="translate(16.044 9.022) rotate(135)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>';
$arrow-right: '<svg xmlns="http://www.w3.org/2000/svg" width="18.044" height="18.044" viewBox="0 0 18.044 18.044"><path id="Arrow" d="M0,0,9.517,9.517M10.345,3.4v6.94H3.156" transform="translate(2 9.022) rotate(-45)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>';
$arrow-left-white: '<svg xmlns="http://www.w3.org/2000/svg" width="18.044" height="18.044" viewBox="0 0 18.044 18.044"><path id="Arrow" d="M0,0,9.517,9.517M10.345,3.4v6.94H3.156" transform="translate(16.044 9.022) rotate(135)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>';
$arrow-right-white: '<svg xmlns="http://www.w3.org/2000/svg" width="18.044" height="18.044" viewBox="0 0 18.044 18.044"><path id="Arrow" d="M0,0,9.517,9.517M10.345,3.4v6.94H3.156" transform="translate(2 9.022) rotate(-45)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>';
