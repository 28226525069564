.helper-cta {
	position: relative;
	margin: 0 2rem 5rem;
	padding: 5rem 2rem;
	background-color: $darkest-gray;
	border-radius: 10px;
	color: $white;
	@include media-breakpoint-up( sm ) {
		margin: 0 0 5rem;
	}
	@include media-breakpoint-up( md ) {
		margin: 0 0 6rem;
		padding: 5rem;
	}

	&:before,
	&:after {
		content: '';
		width: 20rem;
		height: 16.5rem;
		background: url( ../images/dots.svg ) repeat top left;
		opacity: 0.5;
		position: absolute;
		z-index: -1;
		@include media-breakpoint-up( lg ) {
			width: 52rem;
			height: 18rem;
		}
	}

	&:before {
		top: -1.8rem;
		left: -2rem;
	}
	&:after {
		right: -3rem;
		bottom: -2.5rem;
		background-position: bottom right;
	}

	.content {
		max-width: 62rem;
		margin: 0 auto;
		text-align: center;
	}
}
