.error-404 {
	min-height: 50vh;
	padding: 5rem 0;
	text-align: center;
	@include media-breakpoint-up( $bp ) {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: nowrap;
	}

	&__logo {
		width: 100%;
		max-width: 100%;
		margin: 0 0 4rem;
		@include media-breakpoint-up( $bp ) {
			flex: 0 0 49rem;
			width: 49rem;
		}
	}
	&__txt {
		flex: 1 1 auto;
	}

	&__heading {
		margin: 0 0 4rem;
		font-size: 4.5rem;
	}

	&__copy {
		margin: 0 0 4rem;
		font-size: 3.2rem;
	}

	&__back-link {
		margin: 0;
	}
}
