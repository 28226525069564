@mixin placeholder {
	&::placeholder {
		font-weight: 400;
		color: $dark;
		opacity: 0.5;
	}
}
@mixin placeholder-subscribe {
	&::placeholder {
		font-weight: 400;
		color: $darker-gray;
		opacity: 1;
	}
}
